import React, { useState } from 'react';
import { Dialog } from '@/components/dialog';
import { Card } from '@/components/card';
import { Body, H6, Paragraph, Subtitle } from '@/components/text';
import { styled, theme } from '@/stitches.config';
import { Time } from '@/components/date-time';
import { Table } from '@/views/dashboard-v2/table';
import { InfoIcon as RawInfoIcon } from '@/icons';
import { WORKFLOW_STATUSES } from '../constants';

const columns = [
  {
    id: 'workflowDisplayName',
    heading: 'Proccess Name',
    width: 110,
    render: ({ workflowDisplayName }) => workflowDisplayName,
  },
  {
    id: 'lastSuccess',
    heading: 'Last Sync Date',
    width: 110,
    render: ({ lastSuccess }) => <Time dateTime={lastSuccess} format="Pp" />,
  },
  {
    id: 'status',
    heading: 'Status',
    width: 110,
    render: ({ status, messages }) => {
      if (status === WORKFLOW_STATUSES.ERROR) {
        return (
          <Body css={{ color: theme.colors.red400 }} size="2">
            Error
          </Body>
        );
      }

      const isError = Boolean(messages?.length);

      return (
        <StatusWrapper>
          {status}
          {isError && <InfoIcon />}
        </StatusWrapper>
      );
    },
  },
];

export function IntegrationErrorModal({ name, workflowsStatuses, onClose }) {
  const [activeItemId, setActiveItemId] = useState('');

  const activeItem = workflowsStatuses?.find(({ id }) => id === activeItemId);

  const errors = activeItemId
    ? activeItem?.messages
    : workflowsStatuses.flatMap(({ messages }) => messages);

  return (
    <Dialog
      title={name}
      onClose={onClose}
      scrollContent
      css={{
        width: '75rem',
        minHeight: '40rem',
        maxHeight: '90%',
        overflow: 'hidden',

        '& .content': {
          flex: 1,
        },
      }}>
      <Body css={{ display: 'flex', marginBottom: '$8' }} color="black">
        Select a workflow
      </Body>
      <ContentWrapper>
        <TableWrapper>
          <Table
            loading={false}
            error={false}
            columns={columns}
            data={workflowsStatuses}
            onRowClick={(item) => {
              setActiveItemId(item.id === activeItemId ? '' : item.id);
            }}
            activeRowId={activeItemId}
            withBorder
          />
        </TableWrapper>
        <ErrorWrapper>
          {Boolean(errors?.length) && (
            <>
              <Subtitle css={{ marginBottom: '$8' }}>Sync Details</Subtitle>
              <ErrorsList errors={errors} />
            </>
          )}
        </ErrorWrapper>
      </ContentWrapper>
    </Dialog>
  );
}

function ErrorsList({ errors }) {
  return (
    <>
      {errors.map((error) => {
        let errorForDisplay = error.rawMessage;

        try {
          const parsedError = JSON.parse(error.rawMessage);
          errorForDisplay = JSON.stringify(parsedError, 0, 2);
        } catch {
          /* empty */
        }
        return (
          <Card key={error.createdAt + error.text} css={{ margin: 0, marginBottom: '$16' }}>
            <TextWrapper>
              <H6>
                {error.severity}
                {error.title ? ` - ${error.title}` : ''}
              </H6>
              <Paragraph size={2}>
                <Time dateTime={error.createdAt} format="Pp" />
              </Paragraph>
            </TextWrapper>

            <Paragraph>{error.text}</Paragraph>
            {errorForDisplay && (
              <details>
                <Summary>View Raw Error</Summary>
                <Pre>{errorForDisplay}</Pre>
              </details>
            )}

            <hr />
          </Card>
        );
      })}
    </>
  );
}

const InfoIcon = styled(RawInfoIcon, {
  color: theme.colors.slate200,
});

const StatusWrapper = styled('div', {
  display: 'flex',
  gap: '$4',
  alignItems: 'center',
});

const ErrorWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
});

const ContentWrapper = styled('div', {
  display: 'flex',
  gap: '$24',
  alignItems: 'flex-start',
  height: '100%',
});

const TableWrapper = styled('div', {
  background: '$white',
  height: '100%',
  border: '1px solid #E2E4EA',
  borderRadius: '$4',
  maxWidth: 550,
});

const TextWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const Summary = styled('summary', {
  color: '$iris800',
});

const Pre = styled('pre', {
  maxWidth: '100%',
  overflow: 'auto',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
});
