import React from 'react';
import { createPortal } from 'react-dom';
import { css, styled } from '@/stitches.config';

const overlayStyle = css({
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  zIndex: '1000',
});

export const Dialog = styled('div', {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'auto',
  height: 'auto',
  backgroundColor: '$white',
});

export function Modal({ isOpen, children, onClose }) {
  if (!isOpen) return null;

  const handleClose = (e) => {
    if (e.target.dataset?.overlay) {
      if (onClose) onClose();
    }
  };

  return createPortal(
    <div data-overlay className={overlayStyle()} onClick={handleClose}>
      {children}
    </div>,
    document.getElementById('modal-root')
  );
}
