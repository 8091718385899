import React, { useMemo, useState } from 'react';
import { styled } from '@/stitches.config';
import { ConnectedAppItem } from './connected-app-item';
import { SearchInput } from '../../../../components/inputs/search-input';
import { ItemsLoading } from '../../../../components/items-loading/items-loading';
import useWindowDimensions from '../../../../hooks/use-window-dimensions';

const mapItemSizeToScreenWidth = {
  XS: 'calc(50% - 1rem)',
  SM: 'calc(50% - 1rem)',
  MD: 'calc(33.3% - 1rem)',
  LG: 'calc(25% - 1rem)',
  XL: 'calc(25% - 1rem)',
};

export function ConnectedApps({ connectedApps = [], loading, error, onRefresh, types }) {
  const [searchApp, setAppSearch] = useState('');
  const connectedAppsThatMatchSearch = useMemo(() => {
    return connectedApps.filter((app) =>
      app.displayName.toLowerCase().includes(searchApp.toLowerCase())
    );
  }, [connectedApps, searchApp]);
  const { screenWidthSize } = useWindowDimensions();

  return (
    <ConnectedAppsContainer>
      {types === 'Communication' && <h1>My Connected Integrations</h1>}
      {types === 'Connector' && <h1>My Connected Apps</h1>}
      {types === 'Communication' && (
        <p className="subtitle">You have {connectedApps.length} connected integrations</p>
      )}
      {types === 'Connector' && (
        <p className="subtitle">You have {connectedApps.length} connected apps</p>
      )}
      <div className="search-container">
        <SearchInput name="name" placeholder="Search" maxWidth onChange={setAppSearch} />
      </div>
      <ConnectedAppsList>
        {loading ? (
          <ItemsLoading
            count={16}
            itemHeight={174}
            itemWidth={mapItemSizeToScreenWidth[screenWidthSize]}
            itemMargin={8}
            showAvatar={false}
            itemBackground="$white"
          />
        ) : connectedAppsThatMatchSearch?.length ? (
          connectedAppsThatMatchSearch.map((app) => (
            <ConnectedAppItem key={`${app.name}_${app.id}`} {...app} onRefresh={onRefresh} />
          ))
        ) : (
          <div className="no-items">
            {connectedApps.length === 0 &&
              types === 'Communication' &&
              (error
                ? 'Unfortunately, there was an error loading connected integrations.'
                : 'Looks like you haven’t integrated any integration yet!')}
            {connectedApps.length === 0 &&
              types === 'Connector' &&
              (error
                ? 'Unfortunately, there was an error loading connected apps.'
                : 'Looks like you haven’t integrated any apps yet! Click “Add new App” to do it now')}
            {connectedApps.length > 0 &&
              types === 'Communication' &&
              (error
                ? 'Unfortunately, there was an error loading connected integrations.'
                : 'Unfortunately, there are no integrations that match your search request')}
            {connectedApps.length > 0 &&
              types === 'Connector' &&
              (error
                ? 'Unfortunately, there was an error loading connected apps.'
                : 'Unfortunately, there are no apps that match your search request')}
          </div>
        )}
      </ConnectedAppsList>
    </ConnectedAppsContainer>
  );
}

const ConnectedAppsContainer = styled('div', {
  '& h1': {
    fontWeight: '600',
    fontSize: '1.25rem',
    letterSpacing: '0.1px',
    color: '$blue800',
  },

  '& .subtitle': {
    marginBottom: '0.5rem',
    fontSize: '0.8rem',
    letterSpacing: '0.1px',
    color: '$slate700',
  },

  '& .search-container': {
    width: '100%',

    '& > span': {
      width: '100%',
    },
  },
});

const ConnectedAppsList = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  margin: '0.5rem -0.5rem',

  '& .no-items': {
    width: '100%',
    marginTop: '0.5rem',
    fontSize: '1rem',
    letterSpacing: '0.1px',
    color: '$slate700',
    textAlign: 'center',
  },
});
