import { styled } from '@/stitches.config';
import * as Collapsible from '@radix-ui/react-collapsible';

export const SidebarMenuItem = styled('div', {
  display: 'flex',
  height: '36px',

  borderBottom: '1px solid $slate900',

  'a, .pseudo-link': {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: '4px',
    fontSize: '14px',
    cursor: 'pointer',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: '$white',
    borderLeft: '0.25rem solid transparent',

    '&:hover': {
      backgroundColor: '$blue700',
    },

    '&.active': {
      backgroundColor: '$blue600',
    },

    '&.disabled': {
      backgroundColor: 'transparent',
      cursor: 'default',
    },
  },

  svg: {
    flex: 'none',
    display: 'block',
    width: '1.75rem',
    height: '20px',
    marginLeft: '2px',
  },

  span: {
    flex: '1',
    textAlign: 'left',
    display: 'block',
    padding: '0 4px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});
export const SidebarMenuSubItem = styled('div', {
  height: '36px',
  cursor: 'pointer',

  '& > .sidebar-menu-sublink, .pseudo-link': {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: '0 0 0 48px',
    color: '$white',
    fontWeight: '400',
    fontSize: '14px',
    backgroundColor: '$blue900',

    '&:hover': {
      backgroundColor: '$blue800',
    },

    '&.active': {
      backgroundColor: '$blue600',
    },
  },

  span: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

export const MenuItem = styled(Collapsible.Root, SidebarMenuItem, {
  flexDirection: 'column',
  height: 'auto',
  overflow: 'hidden',
});

export const MenuItemTrigger = styled(Collapsible.Trigger, {
  "&[data-state='open']": {
    '.chevron-down': {
      display: 'inline',
    },
    '.chevron-right': {
      display: 'none',
    },
  },

  "&[data-state='closed']": {
    '.chevron-down': {
      display: 'none',
    },
    '.chevron-right': {
      display: 'inline',
    },
  },

  '&.pseudo-link': {
    minHeight: '36px',
  },
});
