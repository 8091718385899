import React from 'react';
import * as RadixCheckbox from '@radix-ui/react-checkbox';
import { styled, theme } from '@/stitches.config';
import { CheckIcon, MinusIcon } from '@/icons';

export function CheckboxWithLabel({ label, checked, onChange, ...props }) {
  return (
    <CheckboxWrapper>
      <Checkbox checked={checked} onChange={onChange} {...props} />
      {label && (
        <label
          className="Label"
          onClick={(e) => {
            e.preventDefault();
            onChange(!checked);
          }}>
          {label}
        </label>
      )}
    </CheckboxWrapper>
  );
}

export function Checkbox({ checked, onChange, indeterminate: _indeterminate, ...props }) {
  return (
    <CheckboxRoot checked={checked} onCheckedChange={onChange} {...props}>
      <CheckboxIndicator>
        {checked === 'indeterminate' && <MinusIcon color={theme.colors.iris800} />}
        {checked === true && <CheckIcon color={theme.colors.white} />}
      </CheckboxIndicator>
    </CheckboxRoot>
  );
}

const CheckboxWrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$8',
});

const CheckboxRoot = styled(RadixCheckbox.Root, {
  backgroundColor: '$white',
  width: '$16',
  height: '$16',
  borderRadius: 2,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: '1px solid $iris800',

  '&:disabled': {
    borderColor: '#CFD1D7',
    background: '#F3F4F7',
    cursor: 'default',

    '& span': {
      background: '$white',
      borderRadius: 2,
    },
    '& svg path': {
      fill: '#B6B9BF',
    },
  },
});

const CheckboxIndicator = styled(RadixCheckbox.Indicator, {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  height: '100%',
  background: '$iris800',

  '&[data-state="indeterminate"]': {
    background: '$white',
    borderRadius: 2,
  },

  '&:disabled': {},
});
