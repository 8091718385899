import { styled } from '@stitches/react';
import { startCase } from 'lodash';
import { format, isValid } from 'date-fns';

export const ThreeRowsGrid = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gridGap: '$16 $24',
});
export const Label = styled('div', {
  marginBottom: '$4',
  width: '180px',
  fontWeight: '$600',
  fontSize: '$14',
  lineHeight: '$20',
  letterSpacing: '0.1px',
  color: '$slate600',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  marginTop: '$24',
});
export const Value = styled('div', {
  fontWeight: '$400',
  fontSize: '$14',
  lineHeight: '$20',
  letterSpacing: '0.1px',
  color: '$black',
  width: '170px',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
});
export const Row = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$2',
});
export const getValue = (value) => {
  if (value === null || value === undefined) {
    return '-';
  }

  if (typeof value === 'boolean') {
    return startCase(String(value));
  }
  if (isValid(new Date(value))) {
    return format(new Date(value), 'P');
  }
  if (Array.isArray(value)) {
    return value.length ? value.join(', ') : '-';
  }
  return value || '-';
};
