import React from 'react';
import NoData from '@/components/icons/paper-holders.svg';
import { H3, H6 } from '@/components/text';
import { styled } from '@stitches/react';

export function TableNoData({ isVisible, numberOfColumns }) {
  if (!isVisible) {
    return null;
  }

  return (
    <tr>
      <TD colSpan={numberOfColumns}>
        <H3
          css={{
            marginTop: '80px',
            fontWeight: '$400',
            fontSize: '$48',
            color: '$slate900',
          }}>
          No Data
        </H3>
        <H6
          css={{
            marginBottom: '$32',
            letterSpacing: '0.0015em',
            fontWeight: '$400',
            fontSize: '$16',
            color: '$slate700',
          }}>
          There are no items to display
        </H6>
        <NoData />
      </TD>
    </tr>
  );
}

const TD = styled('td', {
  padding: '$8',
  background: '$white',
  color: '$slate400',
  textAlign: 'center',
});
