import React, { CSSProperties } from 'react';
import { Root, Portal, Overlay, Content } from '@radix-ui/react-dialog';
import { CSS as ICSS, keyframes } from '@stitches/react';
import { css, cx } from '@/stitches.config';
import { Dialog as BaseDialog } from './modal';

import { IconButton } from './buttons/icon-button';
import { CloseIcon } from '../icons';
import { AsyncBoundary } from './bounderies/async-boundary';

type DialogProps = {
  title: string;
  onClose(event?: React.MouseEvent<HTMLElement>): void;
  headerPullRightContent?: React.ReactNode;
  footerComponent?: React.ReactNode;
  width?: number | string | undefined;
  minWidth?: number | string | undefined;
  children: React.ReactNode;
  scrollContent?: boolean;
  className?: string;
  allowClose?: boolean;
  css?: ICSS;
};

export function Dialog({
  title,
  onClose,
  headerPullRightContent,
  footerComponent = null,
  width,
  minWidth,
  children,
  scrollContent = true,
  className,
  allowClose = true,
  css: overrideCss = {},
}: DialogProps) {
  const styles = {
    zIndex: 9999,
    ...dialogStyle,
    ...overrideCss,
  };

  // Apply width if passed directly
  if (width) {
    styles.width = width;
  }

  // Apply minWidth if passed directly
  if (minWidth) {
    styles.minWidth = minWidth;
  }

  const onOpenChange = () => {
    onClose();
  };

  return (
    <Root modal open onOpenChange={allowClose ? onOpenChange : undefined}>
      <Portal>
        <Overlay style={overlayStyle} />
        <Content>
          <BaseDialog className={cx(className)} css={styles}>
            <header>
              <h3>{title}</h3>
              <div className="pull-right-content">
                {headerPullRightContent}
                {allowClose && (
                  <IconButton className={closeModalStyle()} onClick={onClose}>
                    <CloseIcon />
                  </IconButton>
                )}
              </div>
            </header>
            <AsyncBoundary>
              <div className={cx('content', scrollContent && 'scroll')}>{children}</div>
              {footerComponent && <footer>{footerComponent}</footer>}
            </AsyncBoundary>
          </BaseDialog>
        </Content>
      </Portal>
    </Root>
  );
}

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const closeModalStyle = css({
  color: '$slate700',

  '&:hover': {
    color: '$black',
  },

  '& svg': {
    width: '1rem',
    height: '1rem',
  },
});

const overlayStyle: CSSProperties = {
  position: 'fixed',
  top: '0',
  left: '0',
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  zIndex: 1000,
  animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
};

const dialogStyle = {
  display: 'flex',
  flexDirection: 'column',
  width: '40rem',
  borderRadius: '1rem',
  animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,

  '& > header': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    background: '$white',
    color: '$blue800',
    padding: '1rem 1.5rem',
    borderTopLeftRadius: '1rem',
    borderTopRightRadius: '1rem',
    borderBottom: '1px solid rgba(238, 238, 238, 0.8)',

    '& h3': {
      fontSize: '1rem',
      fontWeight: 'normal',
    },

    '& .pull-right-content': {
      display: 'flex',
      alignItems: 'center',
    },
  },

  '& > .content': {
    padding: '2rem 1.5rem',
    background: '$gray100',
    borderBottomLeftRadius: '1rem',
    borderBottomRightRadius: '1rem',
    boxShadow: '0px 3px 10px rgba(83, 98, 164, 0.1)',

    '&.scroll': {
      overflow: 'auto',
      maxHeight: 'calc(100vh - 12rem)',
    },
  },

  '& > footer': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    borderTop: '1px solid $gray300',
    padding: '1rem 1.5rem',

    '& > *': {
      boxShadow: 'none',
    },

    '& > * + *': {
      marginLeft: '1rem',
    },
  },
};
