import { useEffect } from 'react';
import { ParsedQs } from 'qs';
import { checkIsAuthomizeUser, checkIsAdminUser, isLimitedReviewerUser } from '@/lib/auth';
import { useCurrentSession, useLogin, useLogout } from '@/queries/use-session-endpoints';
import { SessionModelSession } from '@/models/api';
import { useNavigation } from './use-navigation';

export function useSession() {
  const { navigateTo, location, queryParams } = useNavigation();
  const { data: sessionResponse } = useCurrentSession();
  const { login: loginFunc } = useLogin();
  const { logout: logoutFunc } = useLogout();
  const session = sessionResponse?.session;

  const isConnected = Boolean(session?.email);
  const isAuthomizeAdmin = checkIsAuthomizeUser(session);
  const isAdmin = checkIsAdminUser(session);
  const isLoginPath = location.pathname === '/login' || location.pathname === '/forgot-password';

  useEffect(() => {
    if (!isConnected && !isLoginPath) {
      navigateTo('/login', { redirect: location.pathname + location.search });
    }
  }, [isConnected, isLoginPath, location.pathname, location.search, navigateTo]);

  const login = async (email: string, password: string) => {
    try {
      const { data: response } = await loginFunc({ email, password });
      navigateTo(getRedirectUri(response.session, queryParams));
      return response;
    } catch (e) {
      return { error: e, data: null };
    }
  };

  const logout = async () => {
    try {
      await logoutFunc();
      window.location.href = '/login';
    } catch (e) {
      console.error('Error logging out', e);
    }
  };

  return { session, login, logout, isConnected, isAdmin, isAuthomizeAdmin };
}

export function getRedirectUri(session: SessionModelSession, queryParams: ParsedQs) {
  const redirect = queryParams?.redirect;
  const isRedirectValid = redirect && typeof redirect === 'string' && redirect[0] === '/';

  if (session?.requirePasswordChange) {
    const redirectUrl = isRedirectValid
      ? `/change-password?redirect=${redirect}`
      : '/change-password';

    return redirectUrl;
  }

  if (isRedirectValid) {
    // Security: Verify redirect is a local path
    return redirect;
  }

  // TODO: Need to implement better roles and permission
  if (isLimitedReviewerUser(session)) {
    return '/campaigns/mytasks';
  }

  // by default, redirect to dashboard
  return '/dashboard';
}
