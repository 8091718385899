import React from 'react';
import { NavLink } from 'react-router-dom';
import { settingsMenuWidth, styled } from '@/stitches.config';
import { isGlobalAdminUser } from '../../lib/auth';
import { useRemoteConfig, useSession } from '../../hooks';
import { ArrowLeftIcon, BellIcon, PeopleIcon, SettingsAlternativeIcon } from '../../icons';

export function SettingsSidebar() {
  const { featureFlags } = useRemoteConfig();
  const { session } = useSession();
  const { enableSettingsCompliance = false } = featureFlags;

  const isGlobalAdminUserFlag = isGlobalAdminUser(session);

  const menuSections = [
    {
      title: 'Risk Configurations',
      icon: <SettingsAlternativeIcon />,
      items: [
        {
          path: 'risk-configuration',
          name: 'Risk Configuration',
        },
        {
          path: 'ip-allowlist',
          name: 'IP Allowlist',
        },
      ],
    },
    {
      title: 'Notifications',
      icon: <BellIcon />,
      items: [
        {
          path: 'communication',
          name: 'Slack Integration',
        },
        {
          path: 'notifications/active-channels',
          name: 'Active Channels',
        },
        {
          path: 'notifications/preferences',
          name: 'Preferences',
        },
        {
          path: 'notifications/templates',
          name: 'Templates',
        },
      ],
    },
    enableSettingsCompliance && {
      title: 'Compliance settings',
      icon: <SettingsAlternativeIcon />,
      items: [
        {
          path: 'compliance/default-flows',
          name: 'Default Flows',
        },
      ],
    },
    {
      title: 'Organization settings',
      icon: <PeopleIcon />,
      items: [
        {
          path: 'organization-settings',
          name: 'Branding',
        },
        {
          path: 'users',
          name: 'Users',
          isHidden: !isGlobalAdminUserFlag,
        },
        {
          path: 'sso',
          name: 'SSO',
        },
        {
          path: 'api-tokens',
          name: 'API Tokens',
        },
        {
          path: 'webhooks',
          name: 'Webhooks',
        },
        {
          path: 'webhook-logs',
          name: 'Webhook Logs',
        },
      ],
    },
  ];

  return (
    <SettingsContainer>
      <NavLink to="/dashboard" className="back-to-dashboard">
        <ArrowLeftIcon /> Back to Dashboard
      </NavLink>
      <h2>Settings</h2>
      {menuSections
        .filter((item) => item)
        .map((section) => (
          <Section key={section.title}>
            <Title>
              {section.icon} {section.title}
            </Title>
            {section.items.map((menuItem) => (
              <span key={menuItem.name}>
                {menuItem?.isHidden ? (
                  ''
                ) : (
                  <MenuItem>
                    <NavLink
                      to={`/settings/${menuItem.path}`}
                      className={({ isActive }) => (isActive ? 'active' : '')}>
                      {menuItem.name}
                    </NavLink>
                  </MenuItem>
                )}
              </span>
            ))}
          </Section>
        ))}
    </SettingsContainer>
  );
}

const SettingsContainer = styled('div', {
  position: 'absolute',
  top: '0',
  left: '0',
  width: `${settingsMenuWidth}px`,
  height: '100%',
  boxShadow: '4px 0px 4px rgba(140, 140, 140, 0.06)',
  backgroundColor: '$white',
  overflowX: 'hidden',
  zIndex: '1000',

  '& .back-to-dashboard': {
    display: 'inline-block',
    marginTop: '1.5rem',
    marginBottom: '0.5rem',
    marginLeft: '1.5rem',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    color: '$iris800',

    '& svg': {
      position: 'relative',
      top: '2px',
    },
  },

  '& h2': {
    marginBottom: '1.5rem',
    paddingLeft: '1.5rem',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '32px',
    letterSpacing: '0.25px',
    color: '$blue800',
  },
});

const Section = styled('div', {
  paddingBottom: '0.5rem',
  borderBottom: '1px solid $gray200',
  marginBottom: '1rem',

  '&:last-of-type': {
    borderBottom: 'none',
  },
});

const Title = styled('div', {
  padding: '0 0 0.5rem 1.5rem',
  fontStyle: 'normal',
  fontWeight: 'bold',
  fontSize: '0.75rem',
  lineHeight: '1rem',
  letterSpacing: '0.4px',
  textTransform: 'uppercase',
  color: '$slate500',

  '& svg': {
    position: 'relative',
    top: '4px',
    fontSize: '1rem',
  },
});

const MenuItem = styled('div', {
  '& a': {
    display: 'block',
    padding: '0.5rem 0 0.5rem 1.5rem',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.8rem',
    lineHeight: '1.25rem',
    letterSpacing: '0.1px',
    color: '$black',
    borderLeft: '3px solid $white',

    '&:hover': {
      cursor: 'pointer',
      background: '$iris200',
      borderLeft: '3px solid $iris200',
    },

    '&.active': {
      cursor: 'pointer',
      background: '$iris200',
      borderLeft: '3px solid $iris800',
    },
  },
});
