import { Body, Subtitle } from '@/components/text';
import React from 'react';
import {
  HoverCardArrow,
  hoverCardLogo,
  HoverCardNameLink,
  HoverCardRow,
} from '@/components/entity-cards/entity-card-styles';
import { TagsList } from '@/components/tags-list';
import { IconWrapper } from '@/components/entity-icon';
import { SourceIcon } from '@/components/icons/sources';
import { useApps } from '@/hooks/use-apps';
import { HoverCardActions } from '@/components/entity-cards/hover-card-actions';
import { AssetModel } from '@/models/api';

export function AssetHoverCard({ asset }: { asset: AssetModel }) {
  const { getAppById } = useApps();

  return (
    <>
      <HoverCardArrow />
      <HoverCardRow css={{ flexDirection: 'row', gap: '$8', alignItems: 'center' }}>
        <IconWrapper css={{ display: 'inline-block', minWidth: '40px' }} size="sm">
          <SourceIcon {...getAppById(asset.sourceAppId)} className={hoverCardLogo} />
        </IconWrapper>
        <HoverCardNameLink href={`/inventory/resources/${asset.id}`}>
          {`${asset.name}` || '-'}
        </HoverCardNameLink>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Type
        </Subtitle>
        <Body size="3">{asset.type || '-'}</Body>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Incidents
        </Subtitle>
        <Body size="3">{asset.openIncidentsCount || '-'}</Body>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Source App
        </Subtitle>
        <Body size="3">{(asset.sourceApp || getAppById(asset.sourceAppId))?.name}</Body>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Tags ({asset.tags?.length})
        </Subtitle>
        <TagsList tags={asset.tags} maxLimit={3} />
      </HoverCardRow>
      <HoverCardActions
        SPELink={`/inventory/resources/${asset.id}`}
        graphLink={`/access-explorer?sourceId=${asset.id}&sourceType=asset`}
        externalLink={asset?.href}
      />
    </>
  );
}
