import { styled } from '@/stitches.config';

export const Box = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  padding: '1rem 2rem',
  background: '$white',
  boxShadow:
    '0px 4px 4px rgba(162, 167, 180, 0.1), 0px 2px 4px rgba(197, 199, 204, 0.2), 0px 0px 8px 1px rgba(227, 230, 235, 0.2)',
  borderRadius: '0.5rem',
});

export const Row = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: 0,
});

export const LeftSection = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',

  '& header,  a': {
    display: 'inline',
    color: '$slate500',
    fontWeight: 'bold',
    fontSize: '1rem',
  },

  '& a': {
    '&:hover': {
      color: 'var(--color-deepblue)',
      cursor: 'pointer',
    },
  },
});

export const SubHeader = styled('span', {
  color: '$slate700',
  fontWeight: '300',
  fontSize: '0.75rem',
});

export const FallbackWrapper = styled('div', {
  flex: '1',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});
