import React, { useState } from 'react';
import Cookies from 'js-cookie';
import * as Popover from '@radix-ui/react-popover';
import { styled, theme } from '@/stitches.config';
import { Button } from './buttons/button';
import { Input } from './inputs/input';

const DEV_MODE = 'AUTHOMIZE_DEV_MODE';
const COOKIE_NAME = 'tenant_id';

export function EnvSelector() {
  const isEnabled = localStorage.getItem(DEV_MODE) === 'true';
  const currentEnvCookie = Cookies.get(COOKIE_NAME) || '';
  const [currentEnv, setCurrentEnv] = useState(currentEnvCookie);

  if (!isEnabled) {
    return null;
  }

  const onSave = () => {
    Cookies.set(COOKIE_NAME, currentEnv);
    window.location.reload();
  };

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <Button css={{ position: 'absolute', bottom: 4, left: 2, zIndex: 1000 }}>Env</Button>
      </Popover.Trigger>
      <PopoverContent sideOffset={5}>
        <label>
          Current Env
          <Input
            placeholder="authomizeenv"
            value={currentEnv}
            onChange={(e) => setCurrentEnv(e.target.value)}
          />
        </label>
        <Button type="button" color="secondary" css={{ marginTop: 8 }} onClick={onSave}>
          Save &amp; Reload
        </Button>
        <PopoverArrow />
      </PopoverContent>
    </Popover.Root>
  );
}

const PopoverContent = styled(Popover.Content, {
  marginLeft: '$16',
  borderRadius: 4,
  padding: 20,
  width: 260,
  maxHeight: 400,
  backgroundColor: 'white',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  '&:focus': {
    boxShadow: `hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px, 0 0 0 2px ${theme.colors.purple100}`,
  },
});

const PopoverArrow = styled(Popover.Arrow, {
  fill: 'white',
});

// Allow enabling/disabling the button via DevTools
window.AUTHOMIZE_TOGGLE_DEV_MODE = () => {
  const value = localStorage.getItem(DEV_MODE) === 'true' ? 'false' : 'true';
  localStorage.setItem(DEV_MODE, value);
  window.location.reload();
};
