import React from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { keyframes, styled } from '@/stitches.config';
import { Button } from '@/components/buttons/button';
import { uniq } from 'lodash';
import { CheckboxWithLabel } from './checkbox';

export type DropdownRelatedProps = {
  sectionTitle?: string;
  canNotBeHidden?: boolean;
  initiallyHidden?: boolean;
};

type ItemToString<Item> = (item: Item) => string;

type ColumnsSelectProps<Item> = {
  items: Item[];
  selectedItems: Record<string, boolean | string>;
  onChange: (val: Record<string, boolean | string>) => void;
  triggerElement?: React.ReactNode;
  itemToString: ItemToString<Item>;
  itemToIdentifier: ItemToString<Item>;
};

export function DropdownWithCheckboxSelect<Item extends DropdownRelatedProps>({
  items,
  itemToString,
  itemToIdentifier,
  selectedItems,
  onChange,
  triggerElement = <Button>Select</Button>,
}: ColumnsSelectProps<Item>) {
  const handleSelectionChange = (id: string) => {
    onChange({ ...selectedItems, [id]: !selectedItems[id] });
  };
  const sections = uniq(
    items.filter(({ sectionTitle }) => !!sectionTitle).map(({ sectionTitle }) => sectionTitle)
  );
  const columnsWithoutSections = items.filter(({ sectionTitle }) => !sectionTitle);

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild>{triggerElement}</DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <StyledContent align="end">
          {columnsWithoutSections
            .filter((item) => !!itemToString(item))
            .map((item) => (
              <CheckboxItem
                key={itemToIdentifier(item)}
                item={item}
                visibleItems={selectedItems as Record<string, boolean>}
                handleSelectionChange={handleSelectionChange}
                itemToString={itemToString}
                itemToIdentifier={itemToIdentifier}
              />
            ))}
          {sections.map((title) => {
            return (
              <DropdownMenu.Group key={title}>
                <SectionTitle>{title}</SectionTitle>
                {items
                  .filter(({ sectionTitle }) => sectionTitle === title)
                  .filter((item) => !!itemToString(item))
                  .map((item) => (
                    <CheckboxItem
                      key={itemToIdentifier(item)}
                      item={item}
                      visibleItems={selectedItems as Record<string, boolean>}
                      handleSelectionChange={handleSelectionChange}
                      itemToString={itemToString}
                      itemToIdentifier={itemToIdentifier}
                    />
                  ))}
              </DropdownMenu.Group>
            );
          })}

          <StyledArrow />
        </StyledContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

type ItemProps<Item> = {
  item: Item;
  visibleItems: Record<string, boolean>;
  handleSelectionChange: (id: string) => void;
  itemToString: ItemToString<Item>;
  itemToIdentifier: ItemToString<Item>;
};

function CheckboxItem<Item extends DropdownRelatedProps>({
  item,
  visibleItems,
  handleSelectionChange,
  itemToString,
  itemToIdentifier,
}: ItemProps<Item>) {
  return (
    <StyledItem
      key={itemToIdentifier(item)}
      checked={!!visibleItems[itemToIdentifier(item)]}
      disabled={item.canNotBeHidden}
      onSelect={(e) => {
        e.preventDefault();
        handleSelectionChange(itemToIdentifier(item));
      }}>
      <CheckboxWithLabel
        disabled={item.canNotBeHidden}
        checked={!!visibleItems[itemToIdentifier(item)]}
        onChange={() => handleSelectionChange(itemToIdentifier(item))}
        label={itemToString(item)}
      />
    </StyledItem>
  );
}

export function getInitialVisibleItems<Item extends DropdownRelatedProps>(
  items: Item[],
  itemToIdentifier: ItemToString<Item>,
  persistedState?: Record<string, boolean>
) {
  const state: Record<string, boolean> = {};

  for (const item of items) {
    state[itemToIdentifier(item)] = !item.initiallyHidden;
  }

  return { ...state, ...persistedState };
}

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const SectionTitle = styled(DropdownMenu.Label, {
  fontSize: '$12',
  fontWeight: '$600',
  letterSpacing: '0.4px',
  color: '$slate500',
  borderTop: '1px solid $gray300',
  paddingTop: '$12',
  paddingLeft: '$8',
  paddingRight: '$16',
  marginTop: '$16',
  textTransform: 'uppercase',

  '&:first-of-type': {
    borderTop: 'none',
  },
});

const StyledItem = styled(DropdownMenu.CheckboxItem, {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  color: '$black',
  fontSize: '$14',
  fontWeight: '$300',
  background: 'transparent',
  borderBottom: '1px solid $gray400',
  padding: '$12 $8',
  textAlign: 'left',

  '&:focus': {
    background: 'rgba(66, 102, 255, 0.1)',
  },

  '&[data-disabled]': {
    color: '$slate400',
    cursor: 'default',

    '& svg': {
      opacity: '0.3',
    },
  },

  '& input': {
    marginRight: '0.5rem',
    cursor: 'pointer',

    '&:disabled': {
      cursor: 'not-allowed',
    },
  },
});

const StyledContent = styled(DropdownMenu.Content, {
  background: '$white',
  border: '1px solid $gray400',
  borderRadius: '4px',
  minWidth: '12rem',
  maxHeight: '300px',
  zIndex: '100',
  boxShadow: '0px 4px 4px rgba(200, 200, 200, 0.15), 0px 0px 10px 1px rgba(203, 205, 208, 0.3)',
  overflow: 'auto',

  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const StyledArrow = styled(DropdownMenu.Arrow, {
  fill: '$gray400',
});
