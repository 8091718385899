import {
  format as formatDate,
  formatISO,
  formatRelative,
  formatDistance,
  formatDistanceStrict,
} from 'date-fns';
import * as locales from 'date-fns/locale';
import PropTypes from 'prop-types';
import React from 'react';
import { Tooltip } from '@/components/tooltip';

const DateType = PropTypes.oneOfType([
  PropTypes.instanceOf(Date),
  PropTypes.number,
  PropTypes.string,
]);

Time.propTypes = {
  dateTime: DateType,
  options: PropTypes.object,
  format: PropTypes.string,
  titleFormat: PropTypes.string,
};

export function Time({
  dateTime,
  format = `yyyy-MM-dd'T'HH:mm:ss.SSSxxx`,
  titleFormat = 'PPpp',
  options = {},
  children = null,
  ...props
}) {
  if (!dateTime) return null;
  // HACK: REMOVE IT
  if (dateTime === '0') return null;
  if (typeof dateTime === 'string') dateTime = new Date(dateTime);
  options = { ...options, locale: getLocale() };

  return (
    <Tooltip label={formatDate(dateTime, titleFormat, options)}>
      <time {...props} dateTime={formatISO(dateTime)}>
        {typeof children === 'function'
          ? children(formatDate(dateTime, format, options))
          : children || formatDate(dateTime, format, options)}
      </time>
    </Tooltip>
  );
}

RelativeTime.propTypes = {
  dateTime: DateType.isRequired,
  baseDate: DateType,
  options: PropTypes.object,
};

export function RelativeTime({ dateTime, baseDate = Date.now(), options = {}, ...props }) {
  if (typeof dateTime === 'string') dateTime = new Date(dateTime);
  if (typeof baseDate === 'string') baseDate = new Date(baseDate);
  options = { ...options, locale: getLocale() };

  return (
    <Time {...props} dateTime={dateTime} options={options}>
      {formatRelative(dateTime, baseDate, options)}
    </Time>
  );
}

DistanceTime.propTypes = {
  dateTime: DateType.isRequired,
  baseDate: DateType,
  options: PropTypes.object,
};

export function DistanceTime({
  dateTime,
  baseDate = Date.now(),
  options = { addSuffix: true },
  ...props
}) {
  if (typeof dateTime === 'string') dateTime = new Date(dateTime);
  if (typeof baseDate === 'string') baseDate = new Date(baseDate);
  return (
    <Time {...props} dateTime={dateTime} options={options}>
      {formatDistance(dateTime, baseDate, options)}
    </Time>
  );
}

export function daysLeft(startDateStr, endDateStr) {
  let startDate = new Date(startDateStr);
  const endDate = new Date(endDateStr);
  const nowDate = new Date();

  if (endDate < nowDate) {
    return 0;
  }

  if (startDate < nowDate) {
    startDate = nowDate;
  }

  return formatDistanceStrict(endDate, startDate, { unit: 'day' }).split(' ')[0];
}

export function formatDateWithLocale(dateTime, format, options) {
  return formatDate(dateTime, format, { ...options, locale: getLocale() });
}

function getLocale() {
  const langCountry = navigator.language;
  const langWithoutDash = langCountry.replace('-', '');
  const [lang] = langCountry.split('-');

  return locales[langCountry] || locales[langWithoutDash] || locales[lang];
}
