import { useMutation, useQuery, useQueryClient } from 'react-query';
import { getApiConfig, putApiConfigFeatureFlags, PutApiConfigFeatureFlagsBody } from '@/models/api';

export const configKey = 'configKey';

export function useFeatureFlags() {
  const queryClient = useQueryClient();

  const { data } = useQuery({
    queryKey: [configKey],
    queryFn: async () => getApiConfig(),
    select: (response) => response.data,
    suspense: true,
    onError(error) {
      console.error('Unable to fetch config data', error);
    },
  });

  const mutation = useMutation({
    mutationFn: (body: PutApiConfigFeatureFlagsBody) => {
      return putApiConfigFeatureFlags(body);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: configKey });
    },
  });

  return {
    ...mutation,
    featureFlags: data?.featureFlags || {},
    updateFeatureFlags: mutation.mutateAsync,
  };
}
