import React from 'react';
import { styled } from '@/stitches.config';
import { AlertCircleIcon } from '@/icons';
import { Tooltip } from './tooltip';

export function ErrorIconFallback({ message }: { message?: string }) {
  return (
    <Tooltip label={message || 'Error during render'}>
      <Span>
        <ErrorIcon />
      </Span>
    </Tooltip>
  );
}

const Span = styled('span', {
  lineHeight: '1rem',
});

const ErrorIcon = styled(AlertCircleIcon, {
  width: '$16',
  height: '$16',
  color: '$red400',
});
