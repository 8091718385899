import { css, keyframes } from '@/stitches.config';

const scaleUpAndCenter = keyframes({
  '0%': { transform: 'scale(0)' },
  '100%': { transform: 'scale(1)' },
});

export const scaleUpCenterAnimation = css({
  backfaceVisibility: 'hidden',
  animation: `${scaleUpAndCenter} 0.4s cubic-bezier(0.39, 0.575, 0.565, 1) both`,
});

const scaleVerticalTop = keyframes({
  '0%': {
    transform: 'scaleY(0)',
    transformOrigin: '100% 0%',
  },
  '100%': {
    transform: 'scaleY(1)',
    transformOrigin: '100% 0%',
  },
});

export const scaleVerticalTopAnimation = css({
  backfaceVisibility: 'hidden',
  animation: `${scaleVerticalTop} 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both`,
});

export const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(359deg)' },
});

export const blink = keyframes({
  '0%': { opacity: '0.2' },
  '20%': { opacity: '1' },
  '100%': { opacity: '0.2' },
});
