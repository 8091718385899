import qs from 'qs';
import { BaseResource } from './base-resource';

export default class IntegrationResource extends BaseResource {
  id = undefined;
  eventId = undefined;

  pk() {
    return this.id?.toString() || this.eventId?.toString();
  }

  static connected() {
    const endpoint = this.list();

    return endpoint.extend({
      url(params) {
        return `${IntegrationResource.urlRoot}/connected?${qs.stringify(params)}`;
      },
    });
  }

  static available() {
    const endpoint = this.list();

    return endpoint.extend({
      url(params) {
        return `${IntegrationResource.urlRoot}/available?${qs.stringify(params)}`;
      },
    });
  }

  static partialUpdate() {
    return super.partialUpdate().extend({
      method: 'POST',
    });
  }

  static fetchNewData() {
    const endpoint = this.create();
    return endpoint.extend({
      url({ id }) {
        return `${IntegrationResource.urlRoot}/${id}/refetch`;
      },
    });
  }

  static validate() {
    const endpoint = this.detail();
    return endpoint.extend({
      url({ id }) {
        return `${IntegrationResource.urlRoot}/${id}/health`;
      },
    });
  }

  static getEndpointExtra() {
    return {
      pollFrequency: 3000,
    };
  }

  static urlRoot = '/api/integrations';
}
