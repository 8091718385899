import React from 'react';
import { styled } from '@/stitches.config';
import AuthomizeLogo from '@/components/icons/logo.svg';
import { useBranding } from '@/hooks/use-branding';

const AUTHOMIZE = 'Authomize';

const Logo = styled('div', {
  display: 'flex',
  height: 'inherit',
  alignItems: 'center',
  color: '$white',

  '&.logo-with-company-name': {
    '& img,    svg': {
      display: 'block',
      maxHeight: '28px',
      minWidth: '28px',
    },
  },

  '&.only-logo': {
    '& img': {
      height: '100%',
    },
  },

  '& h1': {
    marginLeft: '$8',
    fontSize: '$16',
    fontWeight: '$600',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

export function OrganizationLogo({ shouldIncludeCompanyName = true }) {
  const { branding, isLoading: loading, error } = useBranding();

  return (
    !loading && (
      <OrganizationLogoContent
        branding={{
          companyName: branding.companyName,
          storageLogoFileName: branding.storageLogoFileName,
          brandingLogoFileName: branding.realLogoFileName,
          logoUrl: branding.publicLogoUrl
            ? branding.publicLogoUrl
            : branding.storageLogoFileName
            ? branding.url
            : '',
          fallbackToDefaultLogo: !!error || !branding.realLogoFileName,
        }}
        shouldIncludeCompanyName={shouldIncludeCompanyName}
      />
    )
  );
}

function OrganizationLogoContent({ branding, shouldIncludeCompanyName }) {
  return shouldIncludeCompanyName ? (
    <LogoWithCompanyName
      fallbackToDefaultLogo={branding.fallbackToDefaultLogo}
      logoUrl={branding.logoUrl}
      companyName={branding.companyName}
    />
  ) : (
    <OnlyLogo logoUrl={branding.logoUrl} />
  );
}

function LogoWithCompanyName({ fallbackToDefaultLogo, logoUrl, companyName }) {
  return (
    <Logo className="logo-with-company-name">
      <>
        {!fallbackToDefaultLogo ? <img src={logoUrl} alt="" /> : <AuthomizeLogo />}

        <h1>{companyName || AUTHOMIZE}</h1>
      </>
    </Logo>
  );
}

function OnlyLogo({ logoUrl }) {
  return logoUrl ? (
    <Logo className="only-logo">
      <img src={logoUrl} alt="" />
    </Logo>
  ) : null;
}
