import React from 'react';
import { styled } from '@/stitches.config';

export function Ribbon({ label, size = 'normal' }) {
  return (
    <RibbonElement size={size}>
      <span>{label}</span>
    </RibbonElement>
  );
}

const RibbonElement = styled('div', {
  width: '8rem',
  height: '8rem',
  overflow: 'hidden',
  position: 'absolute',
  top: '-0.5rem',
  left: '-0.5rem',

  '&::before,  &::after': {
    position: 'absolute',
    zIndex: '-1',
    content: "''",
    display: 'block',
    border: '5px solid $yellow100',
    borderTopColor: 'transparent',
    borderLeftColor: 'transparent',
  },

  '& > span': {
    position: 'absolute',
    display: 'block',
    width: '13.5rem',
    padding: '8px 0',
    backgroundColor: '$yellow100',
    boxShadow: '0 0px 10px rgba(0, 0, 0, 0.1)',
    color: '$black',
    fontSize: '0.75rem',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    textAlign: 'center',
    userSelect: 'none',
  },

  '&::before': {
    top: '0',
    right: '0',
  },

  '&::after': {
    bottom: '0',
    left: '0',
  },

  '& span': {
    right: '-2.1875rem',
    top: '2.5rem',
    transform: 'rotate(-45deg)',
  },

  variants: {
    size: {
      small: {
        width: '5rem',
        '& > span': {
          width: '6.5rem',
          padding: '4px 0',
        },
        '& span': {
          right: '-0.25rem',
          top: '1.25rem',
        },
      },
    },
  },
});
