import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import toast from 'react-hot-toast';
import { useFetcher } from 'rest-hooks';
import { css, styled, theme } from '@/stitches.config';
import { FileUploader } from '@/components/file-uploader';
import { Loading, Spinner } from '@/components/loading';
import { CheckIcon } from '@/icons';
import { InputWithIcon } from '@/components/input-with-icon';
import OrganizationBrandingResource from '@/resources/organization-branding';
import { Input } from '@/components/inputs/input';
import { useBranding } from '@/hooks/use-branding';
import { OrganizationLogoPreview } from './organization-logo-preview';
import { SettingsPageContent, SettingsPageTitle } from '../settings-common-styles';

export const ALLOWED_LOGO_UPLOAD_FILE_TYPES = '.png, .jpg, .jpeg';

const PrimaryLabel = styled('h6', {
  marginBottom: '$4',
  color: '$blue800',
  fontWeight: '$600',
});

const SecondaryLabel = styled('p', {
  fontSize: '0.8rem',
  color: '$slate700',
  marginBottom: '$16',
});

const SettingsContainer = styled('div', {
  marginTop: '1rem',
  marginBottom: '2rem',

  '& .input-wrapper': {
    width: '20rem',

    '& .company-name-input': {
      height: '2.5rem',
      backgroundColor: '$white',
      border: '1px solid var(--color-gray-blue)',
      borderRadius: '4px',
      padding: '0.5rem 0.75rem',
      color: 'rgba(33, 37, 48, 0.9)',

      '&:focus': {
        border: '1px solid $iris800',
      },

      '&:hover': {
        border: '1px solid $iris800',
      },
    },
  },
});

const fileUploaderStyle = css({
  minHeight: '12rem',
});

export function OrganizationSettings() {
  const updateBranding = useFetcher(OrganizationBrandingResource.updateBranding());
  const {
    branding: { companyName: configCompanyName, realLogoFileName, storageLogoFileName },
    isLoading,
  } = useBranding();

  const [brandingSettings, setBrandingSettings] = useState({
    companyName: '',
    brandingLogoFileName: null,
    brandingStorageFileName: null,
  });
  const [companyNameUpdateInProgress, setCompanyNameUpdateInProgress] = useState(null);
  const timeoutRef = useRef();

  const fetchBranding = useFetcher(OrganizationBrandingResource.current());

  useEffect(() => {
    if (!isLoading) {
      setBrandingSettings((oldState) => ({
        ...oldState,
        companyName: configCompanyName || '',
        brandingLogoFileName: realLogoFileName || null,
        brandingStorageFileName: storageLogoFileName || null,
      }));
    }
  }, [isLoading, configCompanyName, realLogoFileName, storageLogoFileName]);

  const updateOrganizationBranding = useCallback(
    async ({ companyName, brandingLogoFileName, brandingStorageFileName }) => {
      try {
        const result = await updateBranding({
          companyName,
          brandingLogoFileName,
          brandingStorageFileName,
        });

        if (!result.ok) {
          toast.error(`Failed to update organization branding. Please try again later.`);
        }
        fetchBranding({});
        return result;
      } catch (e) {
        toast.error(`Failed to update organization branding. Please try again later.`);
        return null;
      }
    },
    [updateBranding, fetchBranding]
  );

  const onLogoUploadHandle = useCallback(
    async ({ originalFileName, storageFileName }) => {
      const newSettings = {
        ...brandingSettings,
        brandingLogoFileName: originalFileName,
        brandingStorageFileName: storageFileName,
      };

      await updateOrganizationBranding(newSettings);

      setBrandingSettings(newSettings);
    },
    [brandingSettings, updateOrganizationBranding]
  );

  const onCompanyNameChangeHandle = async (newCompanyName) => {
    const newSettings = { ...brandingSettings, companyName: newCompanyName };
    setBrandingSettings(newSettings);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(async () => {
      setCompanyNameUpdateInProgress(true);
      await updateOrganizationBranding(newSettings);
      setCompanyNameUpdateInProgress(false);
    }, 500);
  };

  if (isLoading) {
    return <Loading />;
  }

  return (
    <SettingsPageContent>
      <Helmet>
        <title>Branding</title>
      </Helmet>
      <SettingsPageTitle>Branding Settings</SettingsPageTitle>
      <form>
        <SettingsContainer>
          <PrimaryLabel>Company Name</PrimaryLabel>
          <SecondaryLabel>
            Leave blank if you do not want to display Company Name in application header.
          </SecondaryLabel>
          <div className="input-wrapper">
            <InputWithIcon iconRight>
              <Input
                type="string"
                placeholder="Company Name"
                value={brandingSettings.companyName}
                className="company-name-input"
                onChange={(e) => onCompanyNameChangeHandle(e.target.value)}
              />
              {companyNameUpdateInProgress === false && <CheckIcon color={theme.colors.green400} />}
              {companyNameUpdateInProgress && <Spinner />}
            </InputWithIcon>
          </div>
        </SettingsContainer>
        <SettingsContainer>
          <PrimaryLabel>Company Logo</PrimaryLabel>
          <SecondaryLabel>
            Png, .jpeg, or .jpg file format & up to 1 MB
            <br />
            Bright logos will look best
          </SecondaryLabel>
          {brandingSettings.brandingLogoFileName ? (
            <OrganizationLogoPreview
              onDeleteLogo={() => {
                onLogoUploadHandle({
                  originalFileName: null,
                  storageFileName: null,
                });
              }}
              onChangeLogo={() => {
                setBrandingSettings({
                  ...brandingSettings,
                  brandingLogoFileName: null,
                  brandingStorageFileName: null,
                });
              }}
            />
          ) : (
            <UploadWrapper>
              <FileUploader
                customStyles={fileUploaderStyle}
                allowedFileTypes={ALLOWED_LOGO_UPLOAD_FILE_TYPES}
                maxSize={5000000}
                onUpload={onLogoUploadHandle}
                isPublic
              />
            </UploadWrapper>
          )}
        </SettingsContainer>
      </form>
    </SettingsPageContent>
  );
}

const UploadWrapper = styled('div', {
  width: '38rem',
});
