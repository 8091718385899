import React from 'react';
import { useQueryParams } from '@/hooks';
import { Handle } from 'reactflow';
import pluralize from 'pluralize';
import { EntityIcon, EntityIconsStack } from '@/components/entity-icon';
import { Tooltip } from '@/components/tooltip';
import { styled, theme } from '@/stitches.config';
import { startCase } from 'lodash';
import { StyledAnchor } from '@/components/text';
import { TagsList } from '@/components/tags-list';
import { useAtom } from 'jotai';
import { showNodesExtendedDetails } from '@/views/inventory/access-explorer/components/graph-zoom-control';
import * as HoverCard from '@radix-ui/react-hover-card';
import { EntityHoverCard } from '@/components/entity-cards/entity-hover-card';

export function GroupNode({ data }) {
  return (
    <>
      <Handle type="target" style={HANDLE_STYLE} position="top" />
      <NodeContainer>
        <EntityIconsStack
          size="xs"
          entity={{ object: data.entityType, sourceAppId: data.groupedSourceAppId }}
          multiple
        />

        <Tooltip label={data.label}>
          <NodeDetailsContainer>
            <ul>
              <li className="ellipsis">
                {data.groupedNodesCount.toLocaleString()}{' '}
                {data.groupedNodesCount > 1
                  ? pluralize(startCase(data.groupedEntityType))
                  : startCase(data.groupedEntityType)}
              </li>
              <NodeType>{data.displayType}</NodeType>
            </ul>
          </NodeDetailsContainer>
        </Tooltip>
      </NodeContainer>
      <Handle type="source" style={HANDLE_STYLE} position="bottom" />
    </>
  );
}

export function EntityNode({ data }) {
  const { queryParams } = useQueryParams();
  const { sourceId, targetId } = queryParams;
  const [showExtendedDetails] = useAtom(showNodesExtendedDetails);

  const isSourceNode = sourceId === data?.entity?.id;
  const isTargetNode = targetId === data?.entity?.id;

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {!isSourceNode && <Handle type="target" style={HANDLE_STYLE} position="left" />}
      <NodeContainer>
        <EntityIcon size="xs" entity={data.entity} />
        <HoverCard.Root openDelay={300}>
          <HoverCard.Trigger>
            <NodeDetailsContainer>
              <ul>
                <li className="ellipsis">{data.label}</li>
                <NodeType>{data.displayType}</NodeType>
              </ul>
            </NodeDetailsContainer>
          </HoverCard.Trigger>
          <HoverCard.Portal>
            <HoverCard.Content>
              <EntityHoverCard entity={data.entity} />
            </HoverCard.Content>
          </HoverCard.Portal>
        </HoverCard.Root>
      </NodeContainer>
      {showExtendedDetails && data.tags?.length > 0 && (
        <NodeExtendedDetailsContainer>
          <TagsList tags={data.tags} maxLimit={2} />
        </NodeExtendedDetailsContainer>
      )}
      {!isTargetNode && <Handle type="source" style={HANDLE_STYLE} position="right" />}
    </div>
  );
}

const NodeType = styled('li', {
  fontSize: '10px',
  color: '$slate700',
});

const NodeContainer = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  gap: '10px',
  fontSize: '12px',
  alignItems: 'center',
});

const NodeDetailsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '15rem',
});

const NodeExtendedDetailsContainer = styled('div', {
  maxWidth: '15rem',
  borderTop: '1px solid $gray300',
  marginTop: '$8',
  paddingTop: '$8',
});

const HANDLE_STYLE = {
  background: theme.colors.gray400,
};

export const GraphNodeNameLink = styled(StyledAnchor, {
  overflowWrap: 'break-word',
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  whiteSpace: 'normal',
});
