import { usePrefetchRemoteConfig } from './hooks';
import { usePrefetchApps } from './hooks/use-apps';
import { usePrefetchVersion } from './hooks/use-version';

// This component is used to prefetch essential API resources before the app loads
export function Preloader() {
  usePrefetchRemoteConfig();
  usePrefetchApps();
  usePrefetchVersion();

  return null;
}
