import React from 'react';

import ExternalLinkIcon from '@/components/icons/external-link.svg';
import { Body } from '@/components/text';

import { LinkWrapper, SubtitleWithIcon, StyledLink } from './styled-components';

export function IncidentWidgetTitle({ title, linkText, to }) {
  return (
    <LinkWrapper>
      <SubtitleWithIcon size="2" weight="semibold">
        {title}
      </SubtitleWithIcon>
      {linkText && (
        <StyledLink to={to}>
          <Body size="2">{linkText} </Body>
          <ExternalLinkIcon />
        </StyledLink>
      )}
    </LinkWrapper>
  );
}
