import React from 'react';
import TableErrorIcon from '@/components/icons/table-error.svg';
import { H3, H6 } from '@/components/text';

import { styled } from '@stitches/react';
import { getErrorMessage } from '@/lib/api';

export function TableError({ error, isVisible, numberOfColumns }) {
  if (!isVisible) {
    return null;
  }

  return (
    <tr>
      <TD colSpan={numberOfColumns}>
        <H3
          css={{
            marginTop: '$32',
            fontWeight: '$400',
            fontSize: '$48',
            color: '$slate900',
          }}>
          Error
        </H3>
        <H6
          css={{
            marginBottom: '$12',
            letterSpacing: '0.0015em',
            fontWeight: '$400',
            fontSize: '$16',
            color: '$slate700',
          }}>
          {getErrorMessage(error)}
        </H6>
        <IconWrapper>
          <TableErrorIcon />
        </IconWrapper>
      </TD>
    </tr>
  );
}

const IconWrapper = styled('div', {
  '& svg': {
    width: 200,
    height: 200,
  },
});

const TD = styled('td', {
  padding: '$8',
  background: '$white',
  color: '$slate400',
  textAlign: 'center',
});
