import { useStatefulResource } from '@rest-hooks/legacy';
import OrganizationBrandingResource from '@/resources/organization-branding';

export function useBranding() {
  const { data, error, loading } = useStatefulResource(OrganizationBrandingResource.current(), {});

  if (error) console.error('Unable to fetch branding data', error);

  const branding = data || {};

  return { branding, isLoading: loading };
}
