import { GetApiV2IdentitiesCountParams, GetApiV2IdentitiesParams } from '@/models/api';
import {
  getApiV2Identities,
  getApiV2IdentitiesCount,
  getApiV2IdentitiesId,
} from '@/models/api/identities/identities';
import { useQuery, useQueryClient } from 'react-query';
import { usePaginatedQuery } from './use-paginated-query';

export const identitiesQueryKey = 'identities';

export function usePaginatedIdentitiesList(params: GetApiV2IdentitiesParams) {
  return usePaginatedQuery({
    queryKey: [identitiesQueryKey, params],
    queryFn: ({ pageParam = 0 }) => {
      return getApiV2Identities({
        ...params,
        skip: pageParam,
      });
    },
  });
}

export function useIdentityById(id: string) {
  return useQuery({
    queryKey: [identitiesQueryKey, id],
    queryFn: async () => getApiV2IdentitiesId(id),
    select: (response) => response.data.data,
    suspense: true,
    enabled: Boolean(id),
  });
}

export function useIdentitiesCount(params: GetApiV2IdentitiesCountParams, suspense = false) {
  return useQuery({
    queryKey: [identitiesQueryKey, params, 'count'],
    queryFn: async () => getApiV2IdentitiesCount(params),
    select: (response) => response.data.data,
    enabled: Boolean(params),
    suspense,
  });
}

export function useInvalidateIdentityById() {
  const queryClient = useQueryClient();

  const invalidateIdentityById = (id: string) => {
    queryClient.invalidateQueries({ queryKey: [identitiesQueryKey, id] });
  };

  return {
    invalidateIdentityById,
  };
}

export function useInvalidateIdentities() {
  const queryClient = useQueryClient();

  const invalidateIdentities = () => {
    queryClient.invalidateQueries({ queryKey: [identitiesQueryKey] });
  };

  return {
    invalidateIdentities,
  };
}
