import qs from 'qs';
import FetchResource from '@/resources/fetch-resource.js';

export class BaseResource extends FetchResource {
  static detail() {
    return super.detail().extend({
      schema: { data: this },
    });
  }

  static list() {
    return super.list().extend({
      schema: { data: [this], pagination: { skip: 0, limit: 0, total: 0, hasMore: false } },
    });
  }

  static create() {
    return super.create().extend({
      schema: { data: this },
    });
  }

  static update() {
    return super.update().extend({
      schema: { data: this },
    });
  }

  static partialUpdate() {
    return super.partialUpdate().extend({
      schema: { data: this },
    });
  }

  static appendList(newResponse, oldResponse) {
    // In case there are duplicates, Set will eliminate them.
    const set = new Set([...(oldResponse?.data ?? []), ...newResponse.data]);

    return {
      ...newResponse,
      data: [...set.values()],
    };
  }

  static listUrl(searchParams) {
    if (Object.keys(searchParams).length) {
      return `${this.urlRoot}?${qs.stringify(searchParams)}`;
    }

    return this.urlRoot;
  }
}
