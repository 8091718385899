import {
  getApiIncidentsPropertiesPropertyValues,
  GetApiIncidentsPropertiesPropertyValuesParams,
  GetApiV2AccountsAggregationParams,
  getApiV2Incidents,
  getApiV2IncidentsAggregation,
  getApiV2IncidentsCount,
  GetApiV2IncidentsCountParams,
  GetApiV2IncidentsParams,
  getApiV2IncidentsPropertiesPropertyIdValuesSearch,
  GetApiV2IncidentsPropertiesPropertyIdValuesSearchParams,
  IncidentsProperties,
} from '@/models/api';
import { useQuery, useQueryClient } from 'react-query';
import { usePaginatedQuery } from './use-paginated-query';

const incidentsPropertiesQueryKey = 'incidentsProperties';
const incidentsQueryKey = 'incidents';

export function useIncidentsProperties({
  property,
  params,
}: {
  property: IncidentsProperties;
  params: GetApiIncidentsPropertiesPropertyValuesParams;
}) {
  return usePaginatedQuery({
    queryKey: [incidentsPropertiesQueryKey, property, params],
    queryFn: ({ pageParam = 0 }) => {
      return getApiIncidentsPropertiesPropertyValues(property, { ...params, skip: pageParam });
    },
  });
}

export function usePaginatedIncidentsList(params: GetApiV2IncidentsParams) {
  return usePaginatedQuery({
    queryKey: [incidentsQueryKey, params],
    queryFn: ({ pageParam = 0 }) => {
      return getApiV2Incidents({ ...params, skip: pageParam });
    },
    enabled: Boolean(params),
  });
}

export function useIncidentsList(params: GetApiV2IncidentsParams) {
  return useQuery({
    queryKey: [incidentsQueryKey, params],
    queryFn: async () => {
      return await getApiV2Incidents(params);
    },
    select: (response) => response.data,
    suspense: true,
    enabled: Boolean(params),
  });
}

export function useIncidentsPropertiesV2(
  propertyId: string,
  params: GetApiV2IncidentsPropertiesPropertyIdValuesSearchParams
) {
  return usePaginatedQuery({
    queryKey: [incidentsQueryKey, params, 'properties-search'],
    queryFn: ({ pageParam = 0 }) => {
      return getApiV2IncidentsPropertiesPropertyIdValuesSearch(propertyId, {
        ...params,
        skip: pageParam,
      });
    },
  });
}

export function useIncidentsAggregation(params: GetApiV2AccountsAggregationParams) {
  return useQuery({
    queryKey: [incidentsQueryKey, params, 'aggregation'],
    queryFn: async () => getApiV2IncidentsAggregation(params),
    select: (response) => response.data.data,
    suspense: true,
  });
}

export function useIncidentsCount(params: GetApiV2IncidentsCountParams) {
  return useQuery({
    queryKey: [incidentsQueryKey, params, 'count'],
    queryFn: async () => getApiV2IncidentsCount(params),
    select: (response) => response.data.data,
    suspense: true,
    enabled: Boolean(params),
  });
}

export function useInvalidateIncidents() {
  const queryClient = useQueryClient();

  const invalidateIncidents = () => {
    queryClient.invalidateQueries({ queryKey: [incidentsQueryKey] });
  };

  const invalidateIncidentProperties = () => {
    queryClient.invalidateQueries({ queryKey: [incidentsPropertiesQueryKey] });
  };

  return {
    invalidateIncidents,
    invalidateIncidentProperties,
  };
}
