import React, { useEffect } from 'react';
import { atom, useAtomValue } from 'jotai';
import toast from 'react-hot-toast';

import { client } from '@/lib/api';
import { Button } from '@/components/buttons/button';
import { styled } from '@/stitches.config';

const FIVE_MINS_IN_MS = 60000;

const versionAtom = atom(fetchVersion);

export function usePrefetchVersion() {
  const version = useAtomValue(versionAtom);

  useEffect(() => {
    const toastId = Date.now();
    const toastConfig = {
      id: toastId,
      duration: Infinity,
      position: 'bottom-right',
      icon: null,
    };
    const interval = setInterval(async () => {
      const newVersion = await fetchVersion();

      if (version !== newVersion) {
        toast.custom(
          <Wrapper>
            There's a new version available
            <div>
              <Button
                type="button"
                color="secondary"
                onClick={() => {
                  window.location.reload();
                }}>
                Reload Page
              </Button>
              <Button kind="text" color="grey" onClick={() => toast.dismiss(toastId)}>
                Ignore
              </Button>
            </div>
          </Wrapper>,
          toastConfig
        );
        clearInterval(interval);
      }
    }, FIVE_MINS_IN_MS);
    return () => {
      clearInterval(interval);
    };
  }, [version]);
}

async function fetchVersion() {
  try {
    const { data } = await client.get('/health');

    return data?.version;
  } catch (e) {
    console.error('Unable to fetch app version', e);
    return '';
  }
}

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: 475,
  backgroundColor: '$white',
  borderRadius: '$12',
  padding: '$8 $16',
  boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.1)',
});
