import React, { useState } from 'react';
import { cx } from '@/stitches.config';
import UserIcon from '@/components/icons/user-empty.svg';
import LogoutIcon from '@/components/icons/logout.svg';
import { useSession } from '../../../hooks';
import { Dropdown } from '../../../components/dropdown';
import { EditIcon } from '../../../icons';
import { ChangePasswordModal } from './change-password-modal';
import { isUserDefinedAtAuthomize } from '../../../lib/auth';
import { Button } from '../../../components/buttons/button';

const dropdownStyle = {
  '& > button.trigger': {
    border: '0',
    lineHeight: '0',
    color: '$white',

    '&:hover': {
      color: '$blue100',
      background: 'transparent',
    },

    '& svg': {
      width: '1.3em',
      height: '1.3em',
    },
  },

  '& > ul': {
    minWidth: '10rem',
    backgroundColor: '$blue700',
    borderRadius: '4px',
    boxShadow: '0px 0px 6px rgba(71, 70, 70, 0.2), 0px 3px 10px rgba(228, 234, 244, 0.16)',
    border: '0',

    '& > li': {
      fontSize: '0.9rem',
      color: '$white',
      borderBottom: '1px solid $blue200',
      borderRadius: '4px',
      display: 'flex',
      alignItems: 'center',

      '& > svg': {
        display: 'block',
        flex: 'none',
        width: '1ren',
        height: '1rem',
        marginRight: '0.5rem',
      },

      '&:last-child': {
        borderBottom: '0',
      },

      '&:hover,      &.highlight': {
        backgroundColor: '$slate800',
      },
    },
  },
};

export function AccountDropdown() {
  const { session, logout } = useSession();
  const [showChangeModal, setShowChangeModal] = useState(false);

  const items = [
    { label: session?.firstName ? `${session?.firstName} ${session?.lastName}` : session?.email },
    ...(isUserDefinedAtAuthomize(session)
      ? [{ icon: EditIcon, label: 'Change Password', onClick: () => setShowChangeModal(true) }]
      : []),
    { icon: LogoutIcon, label: 'Logout', onClick: logout },
  ];

  const handleOnChange = ({ onClick }) => {
    if (typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <>
      <Dropdown
        items={items}
        onChange={handleOnChange}
        renderToggleButton={({ getToggleButtonProps }) => {
          return (
            <Button kind="text" color="secondary" circle {...getToggleButtonProps()}>
              <UserIcon />
            </Button>
          );
        }}
        renderItem={({ item, index, isHighlighted, getItemProps }) => {
          const Icon = item.icon;
          return (
            <li
              key={item.label}
              className={cx(isHighlighted && 'highlight')}
              {...getItemProps({ item, index })}>
              {Icon && <Icon />}
              {item.label}
            </li>
          );
        }}
        menuPositionX="right"
        css={dropdownStyle}
      />
      {showChangeModal && <ChangePasswordModal onClose={() => setShowChangeModal(false)} />}
    </>
  );
}
