import React from 'react';
import { Label, ThreeRowsGrid, Value } from '@/components/slide-over/helpers';
import { Tooltip } from '@/components/tooltip';
import { TagsList } from '@/components/tags-list';
import { hoverCardLogo } from '@/components/entity-cards/entity-card-styles';
import { SourceIcon } from '@/components/icons/sources';
import { useApps } from '@/hooks/use-apps';

export function GroupOverview({ entity }) {
  const { getAppById } = useApps();

  return (
    <ThreeRowsGrid>
      <div>
        <Label>Name</Label>
        <Value>
          {entity.name ? (
            <Tooltip label={entity.name}>
              <span>{entity.name}</span>
            </Tooltip>
          ) : (
            '-'
          )}
        </Value>
      </div>

      <div>
        <Label>Source App</Label>
        <Value>
          <SourceIcon
            {...(entity.sourceApp || getAppById(entity.sourceAppId))}
            className={hoverCardLogo}
          />
        </Value>
      </div>

      <div>
        <Label>Alternative Name</Label>
        <Value>
          {entity.alternativeName ? (
            <Tooltip label={entity.alternativeName}>
              <span>{entity.alternativeName}</span>
            </Tooltip>
          ) : (
            '-'
          )}
        </Value>
      </div>

      <div>
        <Label>Origin Type</Label>
        <Value>
          {entity.externalType ? (
            <Tooltip label={entity.externalType}>
              <span>{entity.externalType}</span>
            </Tooltip>
          ) : (
            '-'
          )}
        </Value>
      </div>

      <div>
        <Label>Owner</Label>
        <Value>
          {entity?.owner?.name ? (
            <Tooltip label={entity?.owner?.name}>
              <span>{entity?.owner?.name}</span>
            </Tooltip>
          ) : (
            '-'
          )}
        </Value>
      </div>

      <div>
        <Label>Members</Label>
        <Value>
          {entity.membersCount ? (
            <Tooltip label={entity.membersCount}>
              <span>{entity.membersCount}</span>
            </Tooltip>
          ) : (
            '-'
          )}
        </Value>
      </div>

      <div>
        <Label>Source App</Label>
        <Value>{(entity.sourceApp || getAppById(entity.sourceAppId))?.name}</Value>
      </div>

      <div>
        <Label>Tags</Label>
        <TagsList tags={entity.tags} maxLimit={100} />
      </div>
    </ThreeRowsGrid>
  );
}
