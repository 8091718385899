export const COMMON_DATE_FORMAT = 'MM/dd/y';

export const DATEPICKER_CLASSES = {
  selected: 'datepicker-selected',
  today: 'datepicker-today',
  range_start: 'datepicker-range-start',
  range_end: 'datepicker-range-end',
};

export const DEFAULT_PLACEHOLDER = 'mm/dd/yyyy';
