import React from 'react';
import { styled, theme } from '@/stitches.config';
import { AlertCircleIcon } from '@/icons';

import { Button } from '@/components/buttons/button';
import { Body, Paragraph, Subtitle } from '@/components/text';
import { WORKFLOW_STATUSES } from '../constants';

export function WorkflowsStatuses({ workflowsStatuses = [], setShowErrorModal }) {
  const syncedStatuses = workflowsStatuses.filter(
    ({ status }) => status === WORKFLOW_STATUSES.SYNCED
  );
  const errorStatuses = workflowsStatuses.filter(
    ({ status }) => status === WORKFLOW_STATUSES.ERROR
  );
  const fetchingStatuses = workflowsStatuses.filter(
    ({ status }) => status === WORKFLOW_STATUSES.FETCHING
  );
  const initStatuses = workflowsStatuses.filter(({ status }) => status === WORKFLOW_STATUSES.INIT);
  const errorStatusesLength = errorStatuses.length;
  const syncedStatusesLength = syncedStatuses.length;
  const errorStatusLabel = errorStatusesLength && 'Error';
  const syncedStatusLabel = syncedStatusesLength === workflowsStatuses.length && 'Synced';
  const fetchingStatusesLabel = fetchingStatuses?.length && 'In Progress';
  const initStatusesLabel = initStatuses?.length && 'Initializing';

  const statusLabel =
    errorStatusLabel || syncedStatusLabel || fetchingStatusesLabel || initStatusesLabel;

  return (
    <StatusWrapper>
      <div>
        <Subtitle size={1} weight="bold" color="grey">
          {statusLabel}
        </Subtitle>
        <ProccessWrapper>
          <Paragraph size="2" color="grey">
            {syncedStatusesLength}/{workflowsStatuses.length} Processes synced
          </Paragraph>

          <Button
            css={{ paddingLeft: 0 }}
            size="sm"
            kind="text"
            onClick={() => setShowErrorModal(true)}>
            {Boolean(errorStatusesLength) && (
              <Body size="2" css={{ color: theme.colors.red400 }}>
                {errorStatusesLength} {errorStatusesLength === 1 ? 'Error' : 'Errors'}{' '}
              </Body>
            )}
            <AlertIcon />
          </Button>
        </ProccessWrapper>
      </div>
    </StatusWrapper>
  );
}

const AlertIcon = styled(AlertCircleIcon, {
  transform: 'rotate(180deg)',
  color: theme.colors.slate200,
});

const ProccessWrapper = styled('div', {
  display: 'flex',
  gap: '$4',
  alignItems: 'center',
});

const StatusWrapper = styled('div', {
  display: 'flex',
  width: '100%',
  margin: '2px 0',
  marginTop: '$12',
  borderLeft: '1px solid $gray300',
  paddingLeft: '$4',
});
