import React from 'react';
import * as TooltipPrimitive from '@radix-ui/react-tooltip';
import { styled, keyframes } from '@/stitches.config';

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StyledContent = styled(TooltipPrimitive.Content, {
  maxWidth: '20rem',
  whiteSpace: 'pre-line',
  wordWrap: 'break-word',
  wordBreak: 'break-word',
  borderRadius: '$8',
  padding: '$16',
  fontSize: '$14',
  lineHeight: '$20',
  fontWeight: '$300',
  color: '$black',
  backgroundColor: '$white',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="delayed-open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

export const StyledArrow = styled(TooltipPrimitive.Arrow, {
  fill: 'white',
});

export function Tooltip({ label, children, side = 'bottom' }) {
  if (!label) return children;

  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root delayDuration={400}>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <StyledContent sideOffset={5} side={side}>
          {label}
          <StyledArrow />
        </StyledContent>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
}

export function ControlledTooltip({ label, children, open, onOpenChange, side }) {
  return (
    <TooltipPrimitive.Provider>
      <TooltipPrimitive.Root delayDuration={400} open={open} onOpenChange={onOpenChange}>
        <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
        <StyledContent sideOffset={5} side={side}>
          {label}
          <StyledArrow />
        </StyledContent>
      </TooltipPrimitive.Root>
    </TooltipPrimitive.Provider>
  );
}

export function TooltipWrapper({ enable, label, children }) {
  return enable ? <Tooltip label={label}>{children}</Tooltip> : <> {children} </>;
}
