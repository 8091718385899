import { useState, useEffect } from 'react';
import { inRange } from 'lodash';
import { SCREEN_BREAKPOINTS } from '../stitches.config';

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  let screenWidthSize = 'XL';

  if (inRange(width, 0, SCREEN_BREAKPOINTS.XS)) {
    screenWidthSize = 'XS';
  } else if (inRange(width, SCREEN_BREAKPOINTS.XS + 1, SCREEN_BREAKPOINTS.SM)) {
    screenWidthSize = 'SM';
  } else if (inRange(width, SCREEN_BREAKPOINTS.SM + 1, SCREEN_BREAKPOINTS.MD)) {
    screenWidthSize = 'MD';
  } else if (inRange(width, SCREEN_BREAKPOINTS.MD + 1, SCREEN_BREAKPOINTS.LG)) {
    screenWidthSize = 'LG';
  }

  return {
    width,
    height,
    screenWidthSize,
  };
}

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
