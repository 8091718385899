import React from 'react';

import { useApps } from '@/hooks/use-apps';
import { Body, Subtitle } from '@/components/text';
import { AppIconsList } from '@/components/app-icons-list';
import { TagsList } from '@/components/tags-list';
import { useGetEntityById } from '@/queries/use-inventory-entities';

import { styled } from '@/stitches.config';
import { IncidentWidget } from './incident-widget';
import { IncidentWidgetTitle } from './incident-widget-title';

export function IncidentBlastRadiusRiskWidget({
  title,
  description,
  score,
  descriptor_params: { entityId, entityType },
  presentation: { riskFactors },
}) {
  const { getAppById } = useApps();

  // based on the entity type, get the relevant resource
  const { data: entityData } = useGetEntityById(entityType, entityId);

  const sourceApp = getAppById(entityData?.sourceAppId);

  return (
    <IncidentWidget
      score={score}
      titleElement={<IncidentWidgetTitle title={title} />}
      description={description}
      riskDescriptionElement={
        <AccountRiskWrapper style={{ width: '45%' }}>
          {riskFactors.map(({ title: riskTitle, score: riskScore }) => (
            <AccountRiskItem key={riskTitle}>
              <Body size="2" weight="light" color="black">
                {riskTitle}
              </Body>
              <Body size="2" weight="light" color="black">
                +{riskScore}
              </Body>
            </AccountRiskItem>
          ))}
        </AccountRiskWrapper>
      }>
      <ItemsWrapper>
        <Item>
          <Subtitle size="2" color="grey">
            Name
          </Subtitle>
          <Subtitle size="2" weight="light">
            {entityData?.name}
          </Subtitle>
        </Item>
        <Item>
          <Subtitle size="2" color="grey">
            Type
          </Subtitle>
          <Subtitle size="2" weight="light">
            {entityData?.type}
          </Subtitle>
        </Item>
        <Item>
          <Subtitle size="2" color="grey">
            Source App
          </Subtitle>
          {sourceApp && <AppIconsList apps={[sourceApp]} />}
        </Item>
        <Item>
          <Subtitle size="2" color="grey">
            Tags
          </Subtitle>
          <TagsList tags={entityData?.tags} maxLimit={2} emptyPlaceholder="-" />
        </Item>
      </ItemsWrapper>
    </IncidentWidget>
  );
}

const AccountRiskWrapper = styled('div', {
  width: '30%',
});

const AccountRiskItem = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  borderBottom: '1px solid $gray300',
  paddingBottom: '$8',
  paddingTop: '$8',
  '&:last-child': {
    borderBottom: 'none',
  },
});

const Item = styled('div', {
  width: '33%',
  marginTop: '$12',
});

const ItemsWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
});
