import { track } from '@/lib/analytics';
import * as api from '@/lib/api';
import {
  ChangePassword,
  deleteApiUsersUserKey,
  getApiUsersSearch,
  GetApiUsersSearchParams,
  getApiUsersUserId,
  patchApiUsersUserKey,
  patchApiUsersUserKeyBlock,
  PatchApiUsersUserKeyBlockBody,
  PatchApiUsersUserKeyBody,
  postApiUsers,
  PostApiUsersBody,
  postApiUsersChangePassword,
  postApiUsersUserKeyResetPassword,
} from '@/models/api';
import toast from 'react-hot-toast';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import { currentSessionKey } from '@/queries/use-session-endpoints';
import { usePaginatedQuery } from './use-paginated-query';

const userQueryKey = 'users';

export const BOT_ROLES = [
  'apiAdminUser',
  'apiAuthomizeAdminUser',
  'apiAllConnectorsUser',
  'apiSingleConnectorsUser',
  'apiAllPlatformUser',
  'apiAuthomizeGlobalConnectorsUser',
];

export function usePaginatedUsersList(params: GetApiUsersSearchParams) {
  return usePaginatedQuery({
    queryKey: [userQueryKey, params],
    queryFn: ({ pageParam = 0 }) => {
      return getApiUsersSearch({
        ...params,
        skip: pageParam,
      });
    },
  });
}

export function useUserById(userId?: string, suspense = false) {
  return useQuery({
    queryKey: [userQueryKey, userId],
    queryFn: () => {
      if (!userId) {
        throw new Error('User ID is required to fetch user');
      }
      return getApiUsersUserId(userId);
    },
    select: (response) => response?.data.data,
    enabled: Boolean(userId),
    suspense,
  });
}

export function useDeleteUser() {
  const { invalidateUsers } = useInvalidateUsersList();

  const mutation = useMutation({
    mutationFn: (id: string) => {
      return deleteApiUsersUserKey(id);
    },
    onSuccess: () => {
      toast.success(`User deleted Successfully`, { duration: 2000 });
    },
    onError: () => {
      toast.error('Failed to delete user');
    },
    onSettled: () => {
      invalidateUsers();
    },
  });

  return {
    ...mutation,
    deleteUser: mutation.mutateAsync,
  };
}

export function useCreateUser() {
  const { invalidateUsers } = useInvalidateUsersList();

  const mutation = useMutation({
    mutationFn: (body: PostApiUsersBody) => {
      return postApiUsers(body);
    },
    onError: ({ response }) => {
      const error = response.data?.error || response.data?.message;

      toast.error(error || 'Failed to save changes.');
    },
    onSuccess: (_, user) => {
      toast.success(`User created Successfully`, { duration: 2000 });
      track('Local User Created', {
        userName: user.email,
        userRole: user.role,
        userFirstName: user.firstName,
      });
    },
    onSettled: () => {
      invalidateUsers();
    },
  });

  return {
    ...mutation,
    createUser: mutation.mutateAsync,
  };
}

export function useUpdateUser() {
  const { invalidateUsers } = useInvalidateUsersList();

  const mutation = useMutation({
    mutationFn: ({ id, body }: { id: string; body: PatchApiUsersUserKeyBody }) => {
      return patchApiUsersUserKey(id, body);
    },
    onError: ({ response }) => {
      const error = response.data?.error || response.data?.message;

      toast.error(error || 'Failed to save changes.');
    },
    onSuccess: () => {
      toast.success('Saved changes successfully.');
    },
    onSettled: () => {
      invalidateUsers();
    },
  });

  return {
    ...mutation,
    updateUser: mutation.mutateAsync,
  };
}

export function useResetUserPassword() {
  const mutation = useMutation({
    mutationFn: (id: string) => {
      return postApiUsersUserKeyResetPassword(id);
    },
    onError: (error) => {
      toast.error(`Unable to reset user password user password: ${api.getErrorMessage(error)}`);
    },
    onSuccess: () => {
      toast.success(`User password has been reset Successfully`, { duration: 2000 });
    },
  });

  return {
    ...mutation,
    resetPassword: mutation.mutateAsync,
  };
}

export function useChangeUserPassword() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (changePassword: ChangePassword) => {
      return postApiUsersChangePassword(changePassword);
    },
    onError: (error) => {
      toast.error(`Unable to change your passwords user: ${api.getErrorMessage(error)}`);
    },
    onSuccess: () => {
      toast.success(`Your password has been changed`);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: currentSessionKey });
    },
  });

  return {
    ...mutation,
    changePassword: mutation.mutateAsync,
  };
}

export function useBlockUser() {
  const { invalidateUsers } = useInvalidateUsersList();

  const mutation = useMutation({
    mutationFn: ({ id, body }: { id: string; body: PatchApiUsersUserKeyBlockBody }) => {
      return patchApiUsersUserKeyBlock(id, body);
    },
    onSettled: () => {
      invalidateUsers();
    },
  });

  return {
    ...mutation,
    blockUser: mutation.mutateAsync,
  };
}

function useInvalidateUsersList() {
  const queryClient = useQueryClient();

  const invalidateUsers = () => {
    queryClient.invalidateQueries([userQueryKey]);
  };

  return {
    invalidateUsers,
  };
}
