import React, { Fragment, useMemo } from 'react';
import { getBezierPath, getMarkerEnd, EdgeLabelRenderer } from 'reactflow';
import { styled, theme } from '@/stitches.config';
import { Tooltip } from '@/components/tooltip';
import { Body, Subtitle } from '@/components/text';
import { isWithinInterval } from 'date-fns';
import { AsyncAccountCard } from '@/components/entity-cards/entity-cards';
import { Time } from '@/components/date-time';

export function GraphEdge({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  label,
  arrowHeadType,
  markerEndId,
  labelBgStyle,
  data,
}) {
  const {
    createdBy,
    createdAt,
    privilegeTypes,
    highlighted,
    accessType,
    start,
    end,
    selectedEdgeId,
  } = data;
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);
  const Wrapper = label || privilegeTypes || createdBy || createdAt ? Tooltip : Fragment;
  const wrapperProps = {
    label: (
      <PrivilegeTooltip
        label={label}
        privilegeTypes={privilegeTypes}
        createdBy={createdBy}
        createdAt={createdAt}
        accessType={accessType}
      />
    ),
  };

  const shortLabel = label.length > 30 ? `${label.substr(0, 30)}...` : label;

  const inInterval =
    !!createdAt &&
    !!start &&
    !!end &&
    isWithinInterval(new Date(createdAt), {
      start: new Date(start),
      end: new Date(end),
    });

  const { borderColor, background, stroke } = useMemo(() => {
    if (id === selectedEdgeId) {
      return {
        borderColor: theme.colors.iris900,
        background: theme.colors.iris200,
        stroke: highlighted
          ? theme.colors.pink700
          : inInterval
          ? theme.colors.purple500
          : style.stroke,
      };
    }

    if (highlighted) {
      return {
        borderColor: theme.colors.pink700,
        background: labelBgStyle,
        stroke: theme.colors.pink700,
      };
    }

    if (inInterval) {
      return {
        borderColor: theme.colors.purple500,
        background: theme.colors.purple100,
        stroke: theme.colors.purple500,
      };
    }

    return { borderColor: theme.colors.purple100, background: labelBgStyle, stroke: style.stroke };
  }, [highlighted, id, inInterval, labelBgStyle, selectedEdgeId, style.stroke]);

  return (
    <>
      <path
        id={id}
        style={{
          ...style,
          stroke,
        }}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
      />
      <EdgeLabelRenderer>
        <Wrapper {...wrapperProps}>
          <CustomEdgeLabel
            css={{
              background,
              borderColor,
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
              cursor: 'pointer',
            }}
            className="nodrag nopan">
            {shortLabel}
          </CustomEdgeLabel>
        </Wrapper>
      </EdgeLabelRenderer>
    </>
  );
}

function PrivilegeTooltip({ label, privilegeTypes, createdBy, createdAt, accessType }) {
  return (
    <>
      {label && (
        <>
          <Subtitle size={2} css={{ marginBottom: '$4' }}>
            Privilege Name
          </Subtitle>
          <Body size={2} css={{ display: 'inline-block', marginBottom: '$16' }}>
            {label}
          </Body>
        </>
      )}
      {createdBy && (
        <>
          <Subtitle size={2} css={{ marginBottom: '$4' }}>
            {accessType === 'membership' ? 'Added By' : 'Granted By'}
          </Subtitle>
          <Body size={2} css={{ display: 'inline-block', marginBottom: '$16' }}>
            <AsyncAccountCard accountId={createdBy} linkable={false} size="xs" />
          </Body>
        </>
      )}
      {createdAt && (
        <>
          <Subtitle size={2} css={{ marginBottom: '$4' }}>
            {accessType === 'membership' ? 'Added At' : 'Granted At'}
          </Subtitle>
          <Body size={2} css={{ display: 'inline-block', marginBottom: '$16' }}>
            <Time dateTime={createdAt} format="P" titleFormat="P" />
          </Body>
        </>
      )}
      {privilegeTypes.length && (
        <>
          <Subtitle size={2} css={{ marginBottom: '$4' }}>
            Privilege Types
          </Subtitle>
          <Body size={2}>{privilegeTypes.join(', ')}</Body>
        </>
      )}
    </>
  );
}

const CustomEdgeLabel = styled('div', {
  position: 'absolute',
  padding: '0 $12',
  height: '$24',
  background: '$gray200',
  borderRadius: '13px',
  border: '1px solid $gray400',
  pointerEvents: 'all',
  cursor: 'default',
  fontWeight: '600',
  fontSize: '$12',
  lineHeight: '22px',
  color: '$slate500',
  boxShadow: '0px 0px 12px rgba(0, 0, 0, 0.05)',
});
