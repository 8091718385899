import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { cx } from '@/stitches.config';
import { SearchInputBase } from '@/components/inputs/common-styles';
import { Input } from '@/components/inputs/input';
import { CloseIcon, SearchIcon } from '@/icons';
import { InputWithIcon } from '@/components/input-with-icon';

type SearchInputProps = {
  initialValue?: string;
  onChange: (value: string) => void;
  debounceValueMs?: number;
  maxWidth?: boolean;
  color?: string;
  disabled?: boolean;
  props: unknown;
};

// v2 Input, specifically for Search
// TODO: Replace all instances of <Input /> with <SearchInput />
export function SearchInput({
  initialValue = '',
  onChange,
  debounceValueMs = 300,
  maxWidth = false,
  color = 'white',
  disabled = false,
  ...props
}: SearchInputProps) {
  const [inputValue, setInputValue] = useState(initialValue || '');
  const handleDebouncedInput = useDebouncedCallback((debouncedInputValue: string) => {
    if (onChange) onChange(debouncedInputValue);
  }, debounceValueMs);

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  const handleInputChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
    handleDebouncedInput(e.target.value);
  };

  const showResetIcon = inputValue.length > 0;

  return (
    <SearchInputBase maxWidth={maxWidth} color={color}>
      <InputWithIcon iconRight iconLeft>
        <Input
          placeholder="Search..."
          className="search-input"
          {...props}
          value={inputValue}
          disabled={disabled}
          onChange={handleInputChanged}
        />
        <SearchIcon className={cx('search-icon')} />
        {showResetIcon && (
          <CloseIcon
            onClick={() => {
              setInputValue('');
              onChange('');
            }}
            className="reset-icon"
          />
        )}
      </InputWithIcon>
    </SearchInputBase>
  );
}
