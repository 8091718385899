import { ActionsMenu } from '@/components/actions-menu';
import React, { PropsWithChildren, ReactNode } from 'react';
import { HelpIcon } from '@/icons';
import ExternalLinkIcon from '@/components/icons/external-link.svg';
import { Button } from '@/components/buttons/button';
import { styled } from '@/stitches.config';
import { helpItems } from '@/views/layout/components/sidebar/account-sections';

type Item = {
  text: string;
  icon?: ReactNode;
  link?: string;
};

type Props = {
  item: Item;
};

function RenderCustomItem({ item, children }: PropsWithChildren<Props>) {
  return (
    <StyledItem>
      <a href={item.link} target="_blank">
        {children}
      </a>
      <ExternalLink>
        <ExternalLinkIcon />
      </ExternalLink>
    </StyledItem>
  );
}

export default function SupportActionsMenu() {
  return (
    <ActionsMenu
      renderItem={RenderCustomItem}
      triggerButton={
        <Button kind="text" color="secondary" circle title="Support">
          <HelpIcon />
        </Button>
      }
      items={helpItems.map((item) => ({ ...item, onSelect: () => window.open(item.link) }))}
    />
  );
}

const StyledItem = styled('li', {
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'space-between',
  width: '100%',
  alignItems: 'center',
  color: '$white',
  fontSize: '0.875rem',
  backgroundColor: '$blue700',
  padding: '0.5rem 0.5rem',
  textAlign: 'left',
  boxShadow: 'inset 0px 1.2px 0px $slate500',

  '&:hover': {
    background: '$blue600',
  },

  '&:focus': {
    background: '$blue600',
  },
});

const ExternalLink = styled('div', {
  '& svg': {
    width: '0.8rem',
    marginRight: '0.1rem',
    color: '$blue100',
  },
});
