/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Authomize Application API
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  GetApiIncidentsPropertiesPropertyValues200,
  GetApiIncidentsPropertiesPropertyValuesParams,
  IncidentsProperties,
  GetApiV2Incidents200,
  GetApiV2IncidentsParams,
  GetApiV2IncidentsAggregation200,
  GetApiV2IncidentsAggregationParams,
  GetApiV2IncidentsPropertiesPropertyIdValuesSearch200,
  GetApiV2IncidentsPropertiesPropertyIdValuesSearchParams,
  GetApiV2IncidentsCount200,
  GetApiV2IncidentsCountParams,
  IncidentTaskModel,
  PostApiV2IncidentsUpdateByFilterBody,
} from '../api.schemas';

export const getApiIncidentsPropertiesPropertyValues = <
  TData = AxiosResponse<GetApiIncidentsPropertiesPropertyValues200>
>(
  property: IncidentsProperties,
  params?: GetApiIncidentsPropertiesPropertyValuesParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/incidents/properties/${property}/values`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
export const getApiV2Incidents = <TData = AxiosResponse<GetApiV2Incidents200>>(
  params?: GetApiV2IncidentsParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/v2/incidents`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
export const getApiV2IncidentsAggregation = <
  TData = AxiosResponse<GetApiV2IncidentsAggregation200>
>(
  params: GetApiV2IncidentsAggregationParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/v2/incidents/aggregation`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
export const getApiV2IncidentsPropertiesPropertyIdValuesSearch = <
  TData = AxiosResponse<GetApiV2IncidentsPropertiesPropertyIdValuesSearch200>
>(
  propertyId: string,
  params?: GetApiV2IncidentsPropertiesPropertyIdValuesSearchParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/v2/incidents/properties/${propertyId}/values/search`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
export const getApiV2IncidentsCount = <TData = AxiosResponse<GetApiV2IncidentsCount200>>(
  params?: GetApiV2IncidentsCountParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/v2/incidents/count`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
export const postApiV2IncidentsUpdateByFilter = <TData = AxiosResponse<IncidentTaskModel>>(
  postApiV2IncidentsUpdateByFilterBody: PostApiV2IncidentsUpdateByFilterBody,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(
    `/api/v2/incidents/update-by-filter`,
    postApiV2IncidentsUpdateByFilterBody,
    options
  );
};
export const getApiV2IncidentsTasksId = <TData = AxiosResponse<IncidentTaskModel>>(
  id: string,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/v2/incidents/tasks/${id}`, options);
};
