import React from 'react';
import { styled } from '@/stitches.config';
import AwsIcon from './icons/sources/aws.svg';
import { SourceIcon } from './icons/sources';
import { UserIcon, GroupIcon, RobotIcon, FileIcon, FolderIcon } from '../icons';
import { ACCOUNT, GROUP, IDENTITY, RESOURCE } from '../views/campaigns/hooks/constants';
import { normalizeEntityType } from './entity-cards/entity-cards';
import { useApps } from '../hooks/use-apps';

const SQUARE_ICON_ENTITY_TYPES = [RESOURCE];

export function EntityIcon({ entity: rawEntity, size, isActive, children }) {
  const { getAppById } = useApps();
  const entityType = normalizeEntityType(rawEntity);
  const entity = { ...rawEntity, entityType };
  const sourceApp = entity.sourceApp
    ? entity.sourceApp
    : entity.sourceAppId
    ? getAppById(entity.sourceAppId)
    : null;

  const isCircle = !SQUARE_ICON_ENTITY_TYPES.includes(entityType);

  // Resource & Account icons are squares, we need to adjust child style a bit
  if (SQUARE_ICON_ENTITY_TYPES.includes(entityType)) {
    children = formatSquareIconChildElements(children);
  }

  const isGroup = entity?.object === GROUP;
  const isAccount = entity?.object === ACCOUNT;
  const icon = getIconByEntityType({ ...entity, entityType, sourceApp });

  return (
    <IconWrapper size={size} isActive={isActive} circle={isCircle} title={entity.name}>
      {children}
      {icon}
      {(isGroup || isAccount) && sourceApp && sourceApp?.logoUrl && (
        <div className="mini-wrapper">
          <img src={sourceApp.logoUrl} alt={sourceApp.name} />
        </div>
      )}
    </IconWrapper>
  );
}

function formatSquareIconChildElements(children) {
  return React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { style: { top: '-.5rem', right: '-.5rem' } });
    }

    return child;
  });
}

function getIconByEntityType(entity) {
  switch (entity.entityType) {
    case IDENTITY:
    case ACCOUNT:
    case GROUP:
      return <EntityTypeIcon entity={entity} />;

    case RESOURCE: {
      const { source, sourceApp } = entity;
      const sourceProps = normalizeSource(source);

      if (sourceApp?.logoUrl) {
        return <img src={sourceApp.logoUrl} alt={sourceApp.name} />;
      }

      const name = sourceProps?.name || sourceApp?.name;

      return <SourceIcon {...sourceProps} name={name} />;
    }
  }
}

export const IconWrapper = styled('div', {
  position: 'relative',
  width: '3.5rem',
  height: '3.5rem',
  borderRadius: '4px',
  border: '1px solid var(--color-gray-blue)',
  background: '$white',
  padding: '0.25rem',
  borderColor: 'var(--color-gray-blue)',

  variants: {
    isActive: {
      true: {
        borderColor: 'var(--color-system-color)',
      },
    },
    circle: {
      true: {
        borderRadius: '50%',
        padding: '0.15rem',
      },
    },
    size: {
      xxs: {
        width: '1.25rem',
        height: '1.25rem',
        padding: '0 1px',
      },
      xs: {
        width: '1.75rem',
        height: '1.75rem',
        padding: '0 1px',
      },
      sm: {
        width: '2.5rem',
        height: '2.5rem',
      },
      max: {
        width: '100%',
        height: '100%',

        '.mini-wrapper': {
          width: '40px',
          height: '40px',
          '& > svg, & > img': {
            width: '26px',
            height: '26px',
          },
        },
      },
    },
  },

  compoundVariants: [
    { circle: true, size: 'xxs', css: { padding: '0 1px' } },
    { circle: true, size: 'xs', css: { padding: '0 1px' } },
  ],

  '& > svg, & > img': {
    display: 'inline-block',
    width: '100%',
    height: '100%',
  },

  '& .mini-wrapper': {
    backgroundColor: '$white',
    border: '1px solid var(--color-gray-blue)',
    boxShadow: '0px 0px 2px rgba(170, 170, 170, 0.25)',
    borderRadius: '50%',
    position: 'absolute',
    zIndex: '10',
    width: '20px',
    height: '20px',
    top: '-8px',
    right: '-8px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',

    '& > svg, & > img': {
      display: 'inline-block',
      width: '16px',
      height: '16px',
    },
  },
});

const SVGWrapper = styled('div', {
  width: '100%',
  height: '100%',
  overflow: 'hidden',
  background: 'var(--color-light-blue)',
  borderRadius: '50%',
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& > svg, & > img': {
    position: 'relative',
    width: '70%',
    height: '100%',
    background: 'transparent',
    color: '$blue300',
  },
});

const entityTypeToIcon = {
  group: GroupIcon,
  Group: GroupIcon,
  ServiceAccount: RobotIcon,
  User: UserIcon,
  account: UserIcon,
  entitlementProxy: AwsIcon,
  Folder: FolderIcon,
  File: FileIcon,
};

export function EntityTypeIcon({ entity }) {
  const { type, object } = entity;
  const subType = object === GROUP ? object : type || object;

  const Icon = entityTypeToIcon[subType]
    ? entityTypeToIcon[subType]
    : entity.entityType === IDENTITY
    ? entityTypeToIcon.User
    : entityTypeToIcon.File;

  return (
    <SVGWrapper>
      <Icon />
    </SVGWrapper>
  );
}

// Source can be either a String or Object, normalize it to Object
function normalizeSource(source) {
  return typeof source === 'string'
    ? { connectorName: source, name: source }
    : {
        connectorName: source?.connectorName,
        serviceType: source?.serviceType,
        name: source?.name || source?.value,
        sourceKey: source?.sourceKey,
        serviceId: source?.serviceId,
        logoUrl: source?.logoUrl,
      };
}

export function EntityIconsStack(props) {
  return (
    <IconsWrapper>
      <EntityIcon {...props} />
      <EntityIcon {...props} />
      <EntityIcon {...props} />
    </IconsWrapper>
  );
}

const IconsWrapper = styled('div', {
  position: 'relative',
  '> div': {
    position: 'absolute',

    '&:first-of-type': {
      top: '-1px',
      right: '-1px',
    },

    '&:nth-of-type(2)': {
      top: '-2px',
      right: '-2px',
    },

    '&:last-of-type': {
      position: 'relative',
    },
  },
});
