import React from 'react';
import { css, cx } from '@/stitches.config';
import { FileIcon } from '@/icons';

// @ts-ignore
const iconModules = import.meta.globEager('./*.svg');

const logoStyle = css({
  maxWidth: '1.5rem',
  height: '1.5rem',
});

const sourceToFileMap = new Map([
  // App based
  ['AuthomizeProvider', './authomize.svg'],
  ['Authomize', './authomize.svg'],
  ['Bitbucket', './bitbucket.svg'],
  ['GoogleDrive', './google-drive.svg'],
  ['GCP', './google-cloud.svg'],
  ['AdminDirectory', './google.svg'],
  ['TbdAppA', './google.svg'],
  ['Okta', './okta.svg'],
  ['ActiveDirectory', './azure-active-directory-enlarged.svg'],
  ['OneDrive', './microsoft-onedrive.svg'],
  ['SharePoint', './microsoft-sharepoint.svg'],
  ['Office365', './microsoft-office.svg'],
  ['Azure', './microsoft-azure.svg'],
  ['GitHub', './github.svg'],
  ['Github', './github.svg'],
  ['GitHubEnterprise', './github.svg'],
  ['GitHub (SCIM)', './github.svg'],
  ['JumpCloud', './jumpcloud.svg'],
  ['Salesforce', './salesforce.svg'],
  ['Dropbox', './dropbox.svg'],
  ['BambooHR', './bamboohr.svg'],
  ['Onelogin', './onelogin.svg'],
  ['HiBob', './hibob.svg'],
  ['Hi Bob', './hibob.svg'],
  ['SecretServer', './secret_server.svg'],
  ['Delinea Secret Server', './secret_server.svg'],
  ['Mulesoft', './mulesoft.svg'],
  ['Box', './box.svg'],
  ['AWS', './aws.svg'],
  ['aws/awssso', './awssso.svg'],
  ['AwsSso', './awssso.svg'],
  ['Jira', './jira.svg'],
  ['FileProvider', './fileprovider.svg'],
  ['Microsoft', './microsoft.svg'],
  ['Google', './google.svg'],
  ['PingIdentity', './pingidentity.svg'],
  ['PagerDuty', './pagerduty.svg'],
  ['SCIM', './scim.svg'],
  ['Slack', './slack.svg'],
  ['GitLab', './gitlab.svg'],
  ['RestApiImport', './authomize.svg'],
  // Source based
  ['google/googledrive', './google-drive.svg'],
  ['google/cloudplatform', './google-cloud.svg'],
  ['google/bigquery', './google-bigquery.svg'],
  ['microsoft/azure', './microsoft-azure.svg'],
  ['microsoft/office365', './microsoft-office.svg'],
  ['microsoft/sharepoint', './microsoft-sharepoint.svg'],
  ['microsoft/activedirectory', './azure-active-directory-enlarged.svg'],
  ['microsoft/onedrive', './microsoft-onedrive.svg'],
  ['okta - manual owners', './okta.svg'],
  ['okta - manual owners/', './okta.svg'],
  ['okta - manual owners/okta - manual owners', './okta.svg'],
  ['google/googledrive/', './google-drive.svg'],
  ['google/cloudplatform/', './google-cloud.svg'],
  ['google/bigquery/', './google-bigquery.svg'],
  ['microsoft/azure/', './microsoft-azure.svg'],
  ['microsoft/office365/', './microsoft-office.svg'],
  ['microsoft/sharepoint/', './microsoft-sharepoint.svg'],
  ['FileProvider.jira', './jira.svg'],
  ['FileProvider.active directory', './microsoft.svg'],
  ['FileProvider.active directory - elevated', './microsoft.svg'],
  ['FileProvider.active batch 9', './active-batch.svg'],
  ['FileProvider.gitlab', './gitlab.svg'],
  ['FileProvider.ilevel tacopps/pe', './ilevel.svg'],
  ['FileProvider.ms teams', './ms-teams.svg'],
  ['FileProvider.oracle-ebs', './oracle-ebs.svg'],
  ['FileProvider.snowflake', './snowflake.svg'],
  ['FileProvider.workday', './workday.svg'],
  ['FileProvider.thycotic', './thycotic.svg'],
  ['FileProvider.team city prod', './teamcity.svg'],
  ['FileProvider.tableau', './tableau.svg'],
  ['FileProvider.bx-workflows', './blackstone.svg'],
  ['FileProvider.axiom mar', './axiom-mar.svg'],
  ['FileProvider.local admin', './admin.svg'],
  ['FileProvider.global view', './adp-globalview.svg'],
  ['FileProvider.gso- expense allocation system (eas)', './integri-data.svg'],
  ['FileProvider.investran7', './sungard.svg'],
  ['FileProvider.niagara production', './blackstone.svg'],
  ['FileProvider.hedgehog', './bx-baam-hedgehog.svg'],
  ['FileProvider.diligent', './diligent.svg'],
  ['FileProvider.arcesium', './arcesium.svg'],
  ['FileProvider.salesforce - bx', './salesforce.svg'],
  ['FileProvider.coupa approval', './coupa.svg'],
  ['FileProvider.o365', './microsoft-office.svg'],
  ['FileProvider.gso-axiomdata', './axiom-mar.svg'],
  ['FileProvider.investran ops', './sungard.svg'],
  ['FileProvider.wall street suite', './wallstreet-suite.svg'],
  ['FileProvider.mobile iron prod', './mobileIron.svg'],
  ['FileProvider.symantec message labs', './symantec.svg'],
  ['FileProvider.bxaccess production', './blackstone.svg'],
  ['FileProvider.hedgesphere production', './hedgesphere.svg'],
  ['FileProvider.phoenix', './phoenix.svg'],
  ['FileProvider.hazeltree', './hazeltree.svg'],
  ['FileProvider.clearwater', './clearwater-international.svg'],
  ['FileProvider.auditboard', './auditboard.svg'],
  ['FileProvider.db utility', './db.svg'],
  ['FileProvider.gso - restricted trade list', './blackstone.svg'],
  ['FileProvider.gso - wall street office', './blackstone.svg'],
  ['FileProvider.gso - restricted trade list tac opp', './blackstone.svg'],
  ['FileProvider.active directory - user accounts ou', './microsoft.svg'],
  ['FileProvider.gso-compliancemod', './blackstone.svg'],
  ['FileProvider.active directory - elevated users ou', './microsoft.svg'],
  ['FileProvider.gso-reconnet production', './blackstone.svg'],
  ['FileProvider.gso-geneva', './blackstone.svg'],
  ['FileProvider.360 evaluation', './microsoft-office.svg'],
  ['FileProvider.manad plus', './manad_plus.svg'],
  ['FileProvider.sageintacct', './sageintact.svg'],
  ['FileProvider.policyone', './policyone.svg'],
  ['FileProvider.core', './core.svg'],
  ['FileProvider.carta', './carta.svg'],

  ['fileprovider/jira', './jira.svg'],
  ['fileprovider/active directory', './microsoft.svg'],
  ['fileprovider/active directory - elevated', './microsoft.svg'],
  ['fileprovider/active batch 9', './active-batch.svg'],
  ['fileprovider/gitlab', './gitlab.svg'],
  ['fileprovider/ilevel tacopps/pe', './ilevel.svg'],
  ['fileprovider/ms teams', './ms-teams.svg'],
  ['fileprovider/oracle-ebs', './oracle-ebs.svg'],
  ['fileprovider/snowflake', './snowflake.svg'],
  ['fileprovider/workday', './workday.svg'],
  ['fileprovider/thycotic', './thycotic.svg'],
  ['fileprovider/team city prod', './teamcity.svg'],
  ['fileprovider/tableau', './tableau.svg'],
  ['fileprovider/bx-workflows', './blackstone.svg'],
  ['fileprovider/axiom mar', './axiom-mar.svg'],
  ['fileprovider/local admin', './admin.svg'],
  ['fileprovider/global view', './adp-globalview.svg'],
  ['fileprovider/gso- expense allocation system (eas)', './integri-data.svg'],
  ['fileprovider/investran7', './sungard.svg'],
  ['fileprovider/niagara production', './blackstone.svg'],
  ['fileprovider/hedgehog', './bx-baam-hedgehog.svg'],
  ['fileprovider/diligent', './diligent.svg'],
  ['fileprovider/arcesium', './arcesium.svg'],
  ['fileprovider/salesforce - bx', './salesforce.svg'],
  ['fileprovider/coupa approval', './coupa.svg'],
  ['fileprovider/o365', './microsoft-office.svg'],
  ['fileprovider/gso-axiomdata', './axiom-mar.svg'],
  ['fileprovider/investran ops', './sungard.svg'],
  ['fileprovider/wall street suite', './wallstreet-suite.svg'],
  ['fileprovider/mobile iron prod', './mobileIron.svg'],
  ['fileprovider/symantec message labs', './symantec.svg'],
  ['fileprovider/bxaccess production', './blackstone.svg'],
  ['fileprovider/hedgesphere production', './hedgesphere.svg'],
  ['fileprovider/phoenix', './phoenix.svg'],
  ['fileprovider/hazeltree', './hazeltree.svg'],
  ['fileprovider/clearwater', './clearwater-international.svg'],
  ['fileprovider/auditboard', './auditboard.svg'],
  ['fileprovider/db utility', './db.svg'],
  ['fileprovider/gso - restricted trade list', './blackstone.svg'],
  ['fileprovider/gso - wall street office', './blackstone.svg'],
  ['fileprovider/gso - restricted trade list tac opp', './blackstone.svg'],
  ['fileprovider/active directory - user accounts ou', './microsoft.svg'],
  ['fileprovider/gso-compliancemod', './blackstone.svg'],
  ['fileprovider/active directory - elevated users ou', './microsoft.svg'],
  ['fileprovider/gso-reconnet production', './blackstone.svg'],
  ['fileprovider/gso-geneva', './blackstone.svg'],
  ['fileprovider/360 evaluation', './microsoft-office.svg'],
  ['fileprovider/manad plus', './manad_plus.svg'],
  ['fileprovider/sageintacct', './sageintact.svg'],
  ['fileprovider/policyone', './policyone.svg'],
  ['fileprovider/core', './core.svg'],
  ['fileprovider/carta', './carta.svg'],
  ['fileprovider/domo', './domo.svg'],
  ['fileprovider/financial force', './financial-force.svg'],

  ['restapiimport/bamboo_hr', './bamboohr.svg'],
  ['restapiimport/ping_one', './pingidentity.svg'],
  ['restapiimport/pagerduty', './pagerduty.svg'],
  ['restapiimport/SCIM', './scim.svg'],
  ['restapiimport/github_scim', './github.svg'],
  ['restapiimport/rightangle', './rightangle.svg'],
  ['restapiimport/slack', './slack.svg'],
  ['restapiimport/mulesoft', './mulesoft.svg'],
  ['restapiimport/onelogin', './onelogin.svg'],
  ['restapiimport/secret_server', './secret_server.svg'],
  ['restapiimport', './restapiimport.svg'],
  ['restapiimport/jumpcloud', './jumpcloud.svg'],
  ['Jump Cloud', './jumpcloud.svg'],
  ['restapiimport/hibob', './hibob.svg'],
  ['custom', './custom.svg'],
  ['industry-standard', './industry-standard.svg'],
]);

function findIconBySource(name = '', connectorName = '', serviceType = '') {
  const connectorNameAndServiceKey = `${connectorName.toLowerCase()}/${serviceType.toLowerCase()}`;
  const fileProviderFallbackPath = './fileprovider.svg';
  const fallbackPath = `./${connectorName.toLowerCase()}.svg`;
  let iconModule;
  if (sourceToFileMap.has(name)) {
    iconModule = iconModules[sourceToFileMap.get(name)];
  } else if (sourceToFileMap.has(connectorNameAndServiceKey)) {
    iconModule = iconModules[sourceToFileMap.get(connectorNameAndServiceKey)];
  } else if (name.startsWith('FileProvider')) {
    iconModule = iconModules[fileProviderFallbackPath];
  } else {
    iconModule = iconModules[fallbackPath];
  }

  return iconModule?.default;
}

type SourceIconProps = {
  name?: string;
  connectorName?: string;
  serviceType?: string;
  logoUrl?: string;
  className?: string;
};

export function SourceIcon({
  name,
  connectorName,
  serviceType,
  logoUrl,
  className = '',
}: SourceIconProps) {
  if (logoUrl) {
    return <img src={logoUrl} alt={name || connectorName} className={cx(logoStyle, className)} />;
  }

  const Icon = findIconBySource(name || '', connectorName || '', serviceType || '');
  return Icon ? (
    <Icon className={cx(className)} />
  ) : (
    <FileIcon title={name || connectorName} className={cx(className)} />
  );
}
