import {
  ArrowRightIcon,
  BellIcon,
  ChevronDownIcon,
  FileTextIcon,
  HelpIcon,
  OutlineMessageIcon,
  PeopleIcon,
} from '@/icons';
import * as Collapsible from '@radix-ui/react-collapsible';
import UserIcon from '@/components/icons/user-empty.svg';
import React, { MouseEventHandler, useState } from 'react';
import { isUserDefinedAtAuthomize } from '@/lib/auth';
import { useNavigation, useSession } from '@/hooks';
import {
  MenuItem,
  MenuItemTrigger,
  SidebarMenuSubItem,
} from '@/views/layout/components/sidebar/sidebar-styles';
import { ChangePasswordModal } from '@/views/settings/users/change-password-modal';

export const helpItems = [
  {
    text: 'Product Updates',
    icon: <BellIcon />,
    link: 'https://support.authomize.com/hc/en-us/sections/11833276966929-2023',
  },
  {
    text: 'User Guide',
    icon: <PeopleIcon />,
    link: 'https://support.authomize.com/hc/en-us/categories/6658464060561-User-Guide',
  },
  {
    text: 'API Reference ',
    icon: <FileTextIcon />,
    link: 'https://api.authomize.com/documentation',
  },
  {
    text: 'Contact Support',
    icon: <OutlineMessageIcon />,
    link: 'https://support.authomize.com/hc/en-us/requests/new',
  },
];

type AccountSectionsProps = {
  isOpened: boolean;
  isHovering: boolean;
};

export function AccountSections({ isOpened, isHovering }: AccountSectionsProps) {
  const { isAuthomizeAdmin, session, logout } = useSession();
  const { navigateTo } = useNavigation();
  const [showChangeModal, setShowChangeModal] = useState(false);

  const accountItems = [
    {
      label: 'My Reviews',
      onClick: () => {
        navigateTo('/campaigns/mytasks');
      },
    },
    ...(isAuthomizeAdmin
      ? [
          {
            label: 'Internal',
            onClick: () => {
              navigateTo('/internal');
            },
          },
        ]
      : []),
    ...(isUserDefinedAtAuthomize(session)
      ? [
          {
            label: 'Change Password',
            onClick: () => {
              setShowChangeModal(true);
            },
          },
        ]
      : []),
    {
      label: 'Logout',
      onClick: () => {
        logout();
      },
    },
  ];

  const [openedMenu, setOpenedMenu] = React.useState<string | null>(null);

  return (
    <div>
      <MenuItem
        open={openedMenu === 'help'}
        onOpenChange={(val) => setOpenedMenu(val ? 'help' : null)}>
        <MenuItemTrigger asChild>
          <span className="pseudo-link">
            <HelpIcon />
            <span>Help</span>
            <ChevronDownIcon className="chevron-down" />
            <ArrowRightIcon className="chevron-right" />
          </span>
        </MenuItemTrigger>
        <Collapsible.Content>
          <ul>
            {helpItems.map((item) => {
              return (
                <SidebarMenuSubItem key={item.text}>
                  <a href={item.link} target="_blank">
                    {(isOpened || isHovering) && <span>{item.text}</span>}
                  </a>
                </SidebarMenuSubItem>
              );
            })}
          </ul>
        </Collapsible.Content>
      </MenuItem>

      <MenuItem
        open={openedMenu === 'account'}
        onOpenChange={(val) => setOpenedMenu(val ? 'account' : null)}>
        <MenuItemTrigger asChild>
          <span className="pseudo-link">
            <UserIcon />
            <span>
              {session?.firstName ? `${session?.firstName} ${session?.lastName}` : session?.email}
            </span>
            <ChevronDownIcon className="chevron-down" />
            <ArrowRightIcon className="chevron-right" />
          </span>
        </MenuItemTrigger>
        <Collapsible.Content>
          <ul>
            {accountItems.map(
              (item: { label: string; onClick: MouseEventHandler<HTMLDivElement> }) => {
                return (
                  <SidebarMenuSubItem key={item.label} onClick={item.onClick}>
                    <span className="pseudo-link">
                      {(isOpened || isHovering) && <span>{item.label}</span>}
                    </span>
                  </SidebarMenuSubItem>
                );
              }
            )}
          </ul>
        </Collapsible.Content>
      </MenuItem>
      {showChangeModal && <ChangePasswordModal onClose={() => setShowChangeModal(false)} />}
    </div>
  );
}
