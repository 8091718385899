import { hasNextPage } from '@/lib/api';
import { PaginationResponse } from '@/models/api';
import { AxiosResponse } from 'axios';
import { useMemo } from 'react';
import { useInfiniteQuery, QueryFunction, UseInfiniteQueryOptions } from 'react-query';

type PaginatedResponse<T> = {
  data: T[];
  pagination: PaginationResponse;
  metadata?: Record<string, unknown>;
};

export function usePaginatedQuery<T>({
  queryKey = '',
  queryFn,
  ...restOptions
}: UseInfiniteQueryOptions<AxiosResponse<PaginatedResponse<T>>>) {
  const { data, ...queryInfo } = useInfiniteQuery(
    queryKey,
    queryFn as QueryFunction<AxiosResponse<PaginatedResponse<T>>>,
    {
      getNextPageParam: (lastPage) => {
        const nextPage = lastPage.data.pagination.skip + lastPage.data.pagination.limit;

        return hasNextPage(lastPage.data.pagination) ? nextPage : undefined;
      },
      ...restOptions,
    }
  );

  const formattedData = useMemo(() => {
    return data
      ? {
          data: data.pages.flatMap((page) => page.data.data),
          pagination: data.pages[data.pages.length - 1].data.pagination,
          metadata: data.pages[data.pages.length - 1].data?.metadata,
        }
      : { data: [], pagination: { total: 0 } };
  }, [data]);

  return useMemo(
    () => ({
      ...queryInfo,
      ...(formattedData as PaginatedResponse<T>),
    }),
    [formattedData, queryInfo]
  );
}
