/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Authomize Application API
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  GetApiMemberships200,
  GetApiMembershipsParams,
  GetApiMembershipsId200,
  GetApiMembershipsCount200,
  GetApiMembershipsCountParams,
  GetApiV2MembershipsAggregation200,
  GetApiV2MembershipsAggregationParams,
} from '../api.schemas';

export const getApiMemberships = <TData = AxiosResponse<GetApiMemberships200>>(
  params?: GetApiMembershipsParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/memberships`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
export const getApiMembershipsId = <TData = AxiosResponse<GetApiMembershipsId200>>(
  id: string,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/memberships/${id}`, options);
};
export const getApiMembershipsCount = <TData = AxiosResponse<GetApiMembershipsCount200>>(
  params?: GetApiMembershipsCountParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/memberships/count`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
export const getApiV2MembershipsAggregation = <
  TData = AxiosResponse<GetApiV2MembershipsAggregation200>
>(
  params: GetApiV2MembershipsAggregationParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/v2/memberships/aggregation`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
