import React from 'react';
import { useLoadingFunction } from '@/hooks';
import { Spinner } from '../loading';
import { Button } from './button';

export function FetchingButton({ onClick, children, disabled = false, ...props }) {
  const [clicker, loading] = useLoadingFunction(onClick);

  return (
    <Button {...props} onClick={clicker} disabled={loading || disabled}>
      {loading ? <Spinner /> : children}
    </Button>
  );
}
