import { useCallback, useEffect, useRef, useState } from 'react';
import { Option, OptionsMap } from './models';

type useExpandProps = {
  expandFn?: (values?: string[]) => Promise<OptionsMap>;
  values?: string[];
  once?: boolean;
};

export function useExpandValues({ expandFn, values, once }: useExpandProps) {
  const [map, setMap] = useState<OptionsMap>(new Map());
  const [isLoading, setIsLoading] = useState(true);
  const fetchOnceRef = useRef(false);

  useEffect(() => {
    async function expand() {
      if (expandFn && values && values.length > 0) {
        setIsLoading(true);
        const optionsMap = await expandFn(values);
        setMap(optionsMap);
        fetchOnceRef.current = true;
      }
      setIsLoading(false);
    }

    if (once && fetchOnceRef.current) return;
    expand();
  }, [expandFn, values, once]);

  const addIfNotExists = useCallback(
    (option: Option) => {
      if (option && !map.has(option.value)) {
        map.set(option.value, option);
      }
    },
    [map]
  );

  return { expandedValues: map, addIfNotExists, isLoading };
}
