import React, { useState, useCallback, useRef, DragEventHandler } from 'react';
import { useFetcher } from 'rest-hooks';
import axios, { AxiosRequestHeaders } from 'axios';
import toast from 'react-hot-toast';
import { useDropzone } from 'react-dropzone';
import _ from 'lodash';
import { styled, cx } from '@/stitches.config';
import { CheckIcon } from '@/icons';
import { Button } from './buttons/button';
import { Loading } from './loading';
import StorageFileResource from '../resources/storage';

const COULD_NOT_UPLOAD_FILE_ERROR = 'Could not upload the file';
const ONE_GB = 1073741824;

type FileUploaderProps = {
  allowedFileTypes: string;
  maxSize?: number;
  onUpload: (val: { originalFileName: string; storageFileName: string }) => void;
  customStyles: string;
  isPublic?: boolean;
  required?: boolean;
};

export function FileUploader({
  allowedFileTypes,
  maxSize,
  onUpload,
  customStyles,
  isPublic = false,
  required = false,
}: FileUploaderProps) {
  const fetchUploadUrl = useFetcher(
    isPublic ? StorageFileResource.publicUploadUrl() : StorageFileResource.uploadUrl()
  );
  const [uploadingStatus, setUploadingStatus] = useState<{
    ok: boolean | null;
    error: unknown | null;
    uploading: boolean;
  }>({
    ok: null,
    error: null,
    uploading: false,
  });

  const getUploadUrl = useCallback(
    async (
      fileExtension: string
    ): Promise<{ url: string; fileName: string; extraHeaders?: AxiosRequestHeaders }> => {
      const response = await fetchUploadUrl({ fileExtension });
      return response.data as StorageFileResource;
    },
    [fetchUploadUrl]
  );

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (acceptedFiles.length) {
        setUploadingStatus((oldStatus) => ({ ...oldStatus, error: null, uploading: true }));
        const uploadFile = async (originalFile: File) => {
          try {
            const extension = _.last(originalFile.name.split('.')) as string;
            const { url, fileName, extraHeaders } = await getUploadUrl(extension);
            await axios.put(url, originalFile, {
              headers: {
                ...extraHeaders,
              },
            });
            onUpload({ originalFileName: originalFile.name, storageFileName: fileName });
            setUploadingStatus({ error: null, ok: true, uploading: false });
          } catch (error: unknown) {
            setUploadingStatus({ error, ok: false, uploading: false });
            toast.error(COULD_NOT_UPLOAD_FILE_ERROR);
          }
        };

        await uploadFile(acceptedFiles[0]);
      } else {
        toast.error(COULD_NOT_UPLOAD_FILE_ERROR);
      }
    },
    [getUploadUrl, onUpload]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    accept: allowedFileTypes,
    maxSize: maxSize || ONE_GB,
  });

  const rootProps = useRef(getRootProps());

  // We need dragOverHandle and dragLeaveHandle to properly handle style changes
  // on dragover as :hover css does not work properly
  const dragOverHandle: DragEventHandler<HTMLDivElement> = (e) => {
    if (rootProps?.current?.onDragOver) {
      rootProps.current.onDragOver(e);
    }
    rootProps.current.ref.current.style.border = DROPZONE_SOLID_BORDER;
  };

  const dragLeaveHandle: DragEventHandler<HTMLDivElement> = (e) => {
    if (rootProps?.current?.onDragLeave) {
      rootProps.current.onDragLeave(e);
    }
    rootProps.current.ref.current.style.border = DROPZONE_DASHED_BORDER;
  };

  return (
    <section>
      <DropzoneContainer
        {...rootProps.current}
        className={cx(customStyles)}
        onDragOver={dragOverHandle}
        onDragLeave={dragLeaveHandle}>
        {!uploadingStatus.uploading && !uploadingStatus.ok && (
          <div>
            <input required={required} {...getInputProps()} />
            <div>Drag and drop your file here</div>
            <div>OR</div>
            <Button type="button" onClick={open}>
              Browse File
            </Button>
          </div>
        )}
        {uploadingStatus.uploading && <Loading />}
        {!uploadingStatus.uploading && uploadingStatus.ok && <SuccessfullyUploadedFile />}
      </DropzoneContainer>
    </section>
  );
}

const DROPZONE_DASHED_BORDER = '1px dashed var(--color-system-color)';
const DROPZONE_SOLID_BORDER = '1px solid var(--color-system-color)';

const DropzoneContainer = styled('div', {
  padding: '1rem',
  background: '$iris200',
  border: DROPZONE_DASHED_BORDER,
  boxSizing: 'border-box',
  borderRadius: '0.5rem',
  fontSize: '0.875rem',
  lineHeight: '1.5rem',
  textAlign: 'center',
  color: '$slate400',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  '&:hover': {
    border: DROPZONE_SOLID_BORDER,
  },
});

function SuccessfullyUploadedFile() {
  return (
    <div>
      <StyledCheck />
      File uploaded successfully
    </div>
  );
}

const StyledCheck = styled(CheckIcon, {
  height: '$14',
  color: '$green800',
  marginRight: '$8',
});
