import React, { useState } from 'react';
import { startCase } from 'lodash';
import * as HoverCard from '@radix-ui/react-hover-card';
import { styled } from '@/stitches.config';
import ExternalLinkIcon from '@/components/icons/external-link.svg';
import { useApps } from '@/hooks/use-apps';
import { useAccountById } from '@/queries/use-accounts';
import { LoadingDots } from '@/components/loading';
import { HoverLink } from '../text';
import { EntityIcon } from '../entity-icon';
import { EntityHoverCard } from './entity-hover-card';
import { AsyncBoundary } from '../bounderies/async-boundary';
import { ErrorIconFallback } from '../error-icon-fallback';

export const ENTITY_TYPES = {
  IDENTITY: 'identity',
  GROUP: 'group',
  ACCOUNT: 'account',
  ASSET: 'resource',
};

export function EntityCard({ entity, ...props }) {
  const entityType = normalizeEntityType(entity);

  const formattedEntity = {
    ...entity,
    entityType,
  };

  if (entityType === ENTITY_TYPES.IDENTITY) {
    return <IdentityCard identity={formattedEntity} {...props} />;
  }

  if (entityType === ENTITY_TYPES.ACCOUNT) {
    return <AccountCard account={formattedEntity} {...props} />;
  }

  if (entityType === ENTITY_TYPES.GROUP) {
    return <GroupCard group={formattedEntity} {...props} />;
  }

  if (entityType === ENTITY_TYPES.ASSET) {
    return <AssetCard resource={formattedEntity} {...props} />;
  }

  console.warn(
    `EntityCard:: Unrecognized entity to render. Valid entityTypes are: ${Object.values(
      ENTITY_TYPES
    )}`,
    entity
  );
  return null;
}

function IdentityCard({ identity, showLabel = false, ...props }) {
  const { name, isDeleted, deletedAt } = identity;

  return (
    <BaseCard
      {...props}
      entity={identity}
      name={name}
      labels={
        <>
          {showLabel && <Label css={{ backgroundColor: '$iris300' }}>Identity</Label>}
          {showLabel && (isDeleted || deletedAt) && (
            <Label css={{ backgroundColor: 'rgba(235, 236, 241, 1)' }}>Deleted</Label>
          )}
        </>
      }
      description={getDescription(identity)}
    />
  );
}

export function AsyncAccountCard(props) {
  return (
    <AsyncBoundary pendingFallback={<LoadingDots />}>
      <AsyncAccountCardInner {...props} />
    </AsyncBoundary>
  );
}

export function AsyncAccountCardInner({ accountId, ...props }) {
  const { data: account } = useAccountById(accountId);

  return account ? <AccountCard {...props} account={account} /> : null;
}

function AccountCard({ account, showLabel = false, ...props }) {
  const { getAppById } = useApps();
  const { name, email, type, isDeleted, deletedAt } = account;
  const hasIdentityId = Boolean(account.identity || account.identityId);
  const sourceApp = account.sourceApp || getAppById(account.sourceAppId);

  const description = email || sourceApp?.name;

  return (
    <BaseCard
      {...props}
      linkable={hasIdentityId && props.linkable}
      entity={account}
      description={props.singleLine ? null : description}
      name={name}
      labels={
        <>
          {showLabel && <Label css={{ backgroundColor: '$yellow200' }}>{type}</Label>}
          {showLabel && (isDeleted || deletedAt) && (
            <Label css={{ backgroundColor: 'rgba(235, 236, 241, 1)' }}>Deleted</Label>
          )}
        </>
      }
    />
  );
}

function GroupCard({ group, showLabel = false, ...props }) {
  const { getAppById } = useApps();
  const { name, alternativeName, isDeleted, deletedAt } = group;
  const sourceApp = group.sourceApp || getAppById(group.sourceAppId);

  return (
    <BaseCard
      {...props}
      linkable={props.linkable}
      entity={group}
      description={alternativeName || sourceApp?.name}
      name={name}
      labels={
        showLabel &&
        (isDeleted || deletedAt) && (
          <Label css={{ backgroundColor: 'rgba(235, 236, 241, 1)' }}>Deleted</Label>
        )
      }
    />
  );
}

function AssetCard({ resource, showLabel = false, ...props }) {
  const { name, href, type, description, isDeleted, deletedAt } = resource;

  return (
    <BaseCard
      {...props}
      entity={resource}
      name={name}
      labels={
        <>
          {' '}
          {showLabel && href && (
            <ExternalLink href={href} target="_blank" title="View in Source App">
              <ExternalLinkIcon />
            </ExternalLink>
          )}{' '}
          {showLabel && (isDeleted || deletedAt) && (
            <Label css={{ backgroundColor: 'rgba(235, 236, 241, 1)' }}>Deleted</Label>
          )}
        </>
      }
      description={description || startCase(type)}
    />
  );
}

const Card = React.forwardRef(
  (
    {
      entity,
      size = 'base',
      variant,
      singleLine = false,
      name = '<Empty Name>',
      labels,
      description,
      linkable = true,
      openLinkInNewTab = false,
      ...restProps
    },
    ref
  ) => {
    const LinkElement = linkable ? HoverLink : 'span';
    const linkElementProps = linkable
      ? {
          to: entityToUrl(entity),
          target: openLinkInNewTab ? '_blank' : '_self',
        }
      : {};

    return (
      <AsyncBoundary errorFallback={<ErrorIconFallback />}>
        <Container className="entity-card" ref={ref} {...restProps}>
          <LinkElement {...linkElementProps}>
            <EntityIcon entity={entity} size={size} isActive={variant === 'active'} />
          </LinkElement>
          <Details size={size} singleLine={singleLine} className="ellipsis">
            <NameLine variant={variant} className="ellipsis">
              <LinkElement className="ellipsis" {...linkElementProps}>
                {name}
              </LinkElement>
              {labels}
            </NameLine>
            <DescriptionLine variant={variant} className="ellipsis" title={description}>
              {singleLine && description && <>&nbsp; | </>}
              {description}
            </DescriptionLine>
          </Details>
        </Container>
      </AsyncBoundary>
    );
  }
);

function BaseCard({ enableHoverCard = false, ...props }) {
  const [hoverCardOpen, setHoverCardOpen] = useState(false);

  if (enableHoverCard) {
    return (
      <HoverCard.Root openDelay={300} open={hoverCardOpen} onOpenChange={setHoverCardOpen}>
        <HoverCard.Trigger asChild>
          <Card {...props} />
        </HoverCard.Trigger>
        <HoverCard.Portal>
          <HoverCard.Content>
            <EntityHoverCard entity={props.entity} />
          </HoverCard.Content>
        </HoverCard.Portal>
      </HoverCard.Root>
    );
  }

  return <Card {...props} />;
}

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
});

const Details = styled('div', {
  flex: '1',
  fontSize: '1.2rem',
  marginLeft: '1rem',
  display: 'block',

  variants: {
    size: {
      xs: {
        fontSize: '0.9rem',
      },
      sm: {
        fontSize: '1rem',
      },
    },
    singleLine: {
      true: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
  },
});

const Label = styled('span', {
  display: 'inline-block',
  padding: '0.1rem 0.25rem',
  color: '$slate900',
  fontSize: '0.75rem',
  borderRadius: '4px',
});

const ExternalLink = styled('a', {
  color: '$slate700',

  '&:hover': {
    color: '$black',
  },

  '& > svg': {
    width: '0.75rem',
    height: '0.75rem',
  },
});

const NameLine = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  fontSize: '1em',
  color: 'var(--color-black-text)',
  fontWeight: '600',

  // Make sure any first child name line will have ellipsis effect
  '& > *:first-child': {
    minWidth: '0',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },

  variants: {
    variant: {
      active: {
        color: 'var(--color-system-color)',
      },
    },
  },
});

const DescriptionLine = styled('div', {
  fontSize: '0.8em',
  fontWeight: '400',
  color: 'var(--color-light-black)',

  variants: {
    variant: {
      active: {
        color: 'var(--color-system-color)',
      },
    },
  },
});

function getDescription({ type, title, department, description }) {
  if (description) {
    return description;
  }

  if (type && type !== 'User') {
    return startCase(type);
  }

  let descriptionText = '';
  if (title) {
    descriptionText = title;
  }

  if (department) {
    descriptionText += title ? ` | ${department}` : department;
  }

  return descriptionText;
}

export function entityToUrl({ id, entityType, identityId }) {
  switch (entityType) {
    case 'identity':
      return `/inventory/identities/${id}`;
    case 'account':
      return `/inventory/identities/${identityId}/accounts?accountId=${id}`;
    case 'resource':
    case 'asset':
      return `/inventory/resources/${id}`;
    case 'group':
      return `/inventory/groups/${id}`;
    default:
      console.warn(`entityToUrl:: unknown entityType: ${entityType}`);
  }
}

export function normalizeEntityType(entity) {
  const currentEntityType = (entity?.object || entity?.entityType || '').toLowerCase();
  return currentEntityType === 'asset' ? ENTITY_TYPES.ASSET : currentEntityType;
}
