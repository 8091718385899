import React, { InputHTMLAttributes } from 'react';
import type * as Stitches from '@stitches/react';
import { styled } from '@/stitches.config';
import { StyledComponent } from '@/models/common';
import { FormFieldDescription, FormFieldName, InputFormHelperText } from '@/components/form-field';

export enum InputKinds {
  Error = 'error',
  Success = 'success',
}

export enum InputSizes {
  LG = 'lg',
  MD = 'md',
  SM = 'sm',
}

interface InputFieldsProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'size'> {
  kind?: InputKinds;
  size?: InputSizes;
  title?: string;
  subTitle?: string;
  helperText?: string;
  css?: Stitches.CSS;
  maxWidth?: boolean;
}

export const InputField = React.forwardRef((props: InputFieldsProps, ref) => {
  const { title = '', subTitle = '', helperText = '', ...inputProps } = props;

  return (
    <div>
      {title && <FormFieldName>{title}</FormFieldName>}
      {subTitle && <FormFieldDescription>{subTitle}</FormFieldDescription>}
      <Input {...inputProps} ref={ref} />
      {helperText && (
        <InputFormHelperText kind={inputProps?.kind} css={{ marginTop: '$4' }}>
          {helperText}
        </InputFormHelperText>
      )}
    </div>
  );
});

export const Input: StyledComponent = styled('input', {
  minWidth: '12rem',
  height: '$32',
  background: '$white',
  borderRadius: '$4',
  fontSize: '$16',
  outline: 'none',
  padding: '$8',
  border: '1px solid $gray300',
  boxShadow: 'none',
  color: '$slate900',

  transition: '200ms all ease-in-out',

  '&:hover': {
    border: '1px solid $iris800',
  },

  '&:focus': {
    boxShadow: '0px 0px 4px rgba(66, 102, 255, 0.5)',
    border: '1px solid $iris800',
  },

  '&::placeholder': {
    color: '$slate700',
  },

  '&:disabled': {
    background: '$gray200',
    color: '$slate200',
    border: '1px solid $slate100',

    '&::placeholder': {
      color: '$slate200',
    },
  },

  variants: {
    maxWidth: {
      true: {
        width: '100%',
      },
    },
    kind: {
      [InputKinds.Error]: {
        boxShadow: '0px 0px 4px rgba(243, 76, 97, 0.5)',
        border: '1px solid $red500',
      },
      [InputKinds.Success]: {
        boxShadow: '0px 0px 4px rgba(6, 155, 90, 0.5)',
        border: '1px solid $green700',
      },
    },
    size: {
      [InputSizes.LG]: {
        height: 40,
        fontSize: '$16',
        padding: '$8 $12',
      },
      [InputSizes.MD]: {
        height: 32,
        fontSize: '$16',
        padding: '$4 $12',
      },
      [InputSizes.SM]: {
        height: 24,
        fontSize: '$14',
        padding: '$2 $12',
      },
    },
  },
});
