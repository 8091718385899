import { Body, Subtitle } from '@/components/text';
import React from 'react';
import {
  HoverCardArrow,
  hoverCardLogo,
  HoverCardNameLink,
  HoverCardRow,
} from '@/components/entity-cards/entity-card-styles';
import { TagsList } from '@/components/tags-list';
import { IconWrapper } from '@/components/entity-icon';
import { SourceIcon } from '@/components/icons/sources';
import { useApps } from '@/hooks/use-apps';
import { HoverCardActions } from '@/components/entity-cards/hover-card-actions';
import { styled } from '@/stitches.config';

import { AccountModel } from '@/models/api';
import { ManagerField } from './manager-field';
import { AsyncBoundary } from '../bounderies/async-boundary';
import { LoadingDots } from '../loading';
import { Tooltip } from '../tooltip';

export function AccountHoverCard({ account }: { account: AccountModel }) {
  const { getAppById } = useApps();

  const accountDepartment = account?.identity?.department;
  const accountTitle = account?.identity?.title;

  const isDividerShown = accountTitle && accountDepartment;

  return (
    <>
      <HoverCardArrow />
      <HoverCardRow
        css={{
          flexDirection: 'row',
          alignItems: 'center',
          gap: '$8',
        }}>
        <IconWrapper css={{ display: 'inline-block', minWidth: '40px' }} size="sm">
          <SourceIcon
            {...(account.sourceApp || getAppById(account.sourceAppId))}
            className={hoverCardLogo}
          />
        </IconWrapper>
        <HoverCardRow css={{ gap: 0 }}>
          <HoverCardNameLinkWithOverflow
            href={`/inventory/identities/${
              account.identity?.id || account.identityId
            }/accounts/?accountId=${account.id}`}>
            {`${account.name}` || '-'}
          </HoverCardNameLinkWithOverflow>
          <HeaderWrapper>
            {accountTitle && (
              <Tooltip label={accountTitle}>
                <Body
                  size="3"
                  css={{
                    maxWidth: isDividerShown ? '50%' : '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}>
                  {accountTitle}
                </Body>
              </Tooltip>
            )}
            {isDividerShown && <DividerWrapper>|</DividerWrapper>}
            {accountDepartment && (
              <Tooltip label={accountDepartment}>
                <Body
                  size="3"
                  css={{
                    maxWidth: isDividerShown ? '50%' : '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}>
                  {accountDepartment}
                </Body>
              </Tooltip>
            )}
          </HeaderWrapper>
        </HoverCardRow>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Account Name
        </Subtitle>
        <Body size="3">{account.name || '-'}</Body>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Email
        </Subtitle>
        <Body size="3">{account.email || '-'}</Body>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Manager
        </Subtitle>
        <AsyncBoundary pendingFallback={<LoadingDots />} errorFallback={<>-</>}>
          <ManagerField managerId={account?.identity?.managerId || ''} />
        </AsyncBoundary>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Incidents
        </Subtitle>
        <Body size="3">{account.openIncidentsCount || '-'}</Body>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Source App
        </Subtitle>
        <Body size="3">{(account.sourceApp || getAppById(account.sourceAppId))?.name}</Body>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Tags ({account.tags?.length})
        </Subtitle>
        <TagsList tags={account.tags} maxLimit={10} />
      </HoverCardRow>

      <HoverCardActions
        SPELink={`/inventory/identities/${
          account.identity?.id || account.identityId
        }/accounts?accountId=${account.id}`}
        graphLink={`/access-explorer?sourceId=${account.id}&sourceType=account`}
        externalLink={account?.href}
      />
    </>
  );
}

const DividerWrapper = styled('div', {
  color: '$slate700',
});

const HeaderWrapper = styled('div', {
  display: 'flex',
  maxWidth: '100%',
  gap: '$4',
});

const HoverCardNameLinkWithOverflow = styled(HoverCardNameLink, {
  display: 'block',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  maxWidth: '100%',
});
