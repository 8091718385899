import React, { useCallback, useState } from 'react';
import { useFetcher } from 'rest-hooks';
import toast from 'react-hot-toast';
import { styled } from '@/stitches.config';
import { Button } from '../buttons/button';
import { Dialog } from '../dialog';
import { InfoIcon } from '../../icons';
import { getErrorMessage } from '../../lib/api';
import { Field } from '../../views/settings/integration/components/fields';
import { INFO_BANNER_TYPE, InfoBanner } from '../info-banner';
import { GOOGLE, MS } from '../../views/settings/integration/constants';
import { Loading } from '../loading';
import { usePollIntegrationProgress } from './use-poll-integration-progress';
import { StepDescription } from './components/step-description';
import IntegrationResource from '../../resources/integration';
import { isEmptyValue } from '../../lib/form-utils.js';
import { track } from '../../lib/analytics';

export const CONNECTOR_MODAL_MODE = {
  EDIT: 'EDIT',
  CREATE: 'CREATE',
};

export function ConnectorModal({
  id: updateId,
  connector,
  extendedConfig = [],
  mode = CONNECTOR_MODAL_MODE.CREATE,
  onSubmit,
  onClose,
}) {
  const [connectorId, setConnectorId] = useState(updateId);
  const create = useFetcher(IntegrationResource.create());
  const update = useFetcher(IntegrationResource.partialUpdate());
  const { id, displayName, title, installLink, configSchema, lastError, lastSyncStatus, steps } =
    connector;
  const isEditMode = mode === CONNECTOR_MODAL_MODE.EDIT;
  const initialFormState = getInitialFormState(isEditMode ? extendedConfig : configSchema);

  const [formState, setFormState] = useState(initialFormState);

  const onSuccessCb = useCallback(() => {
    if (onSubmit) onSubmit();
    if (onClose) onClose();
  }, [onClose, onSubmit]);

  const onErrorCb = useCallback(
    (error) => {
      toast.error(error);
      if (onClose) onClose();
    },
    [onClose]
  );

  const {
    pollIntegrationProgress,
    validating,
    startValidating,
    errorsState,
    isIntegrationInvalid,
    clearError,
    totalTests,
    successfulTests,
    stopValidation,
  } = usePollIntegrationProgress(onSuccessCb, onErrorCb);

  const handleInputChanged = ({ target }) => {
    const { name, type } = target;
    const value = type === 'checkbox' ? target.checked : target.value;
    setFormState((state) => ({ ...state, [name]: value }));
    clearError(name);
  };

  const canSubmit = () => {
    for (const param of configSchema) {
      if (param.required && isEmptyValue(formState[param.name])) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startValidating();
    const autoTrimmedState = {};
    for (const [key, value] of Object.entries(formState)) {
      const config = configSchema.find((field) => field.name === key);
      autoTrimmedState[key] = typeof value === 'string' ? value.trim() : value;

      if (config?.type === 'identity') {
        // Save user id in identity type field
        autoTrimmedState[key] = value?.id;
      }
    }

    let result;
    try {
      if (mode === CONNECTOR_MODAL_MODE.CREATE) {
        result = await create({}, { availableIntegrationId: id, config: autoTrimmedState });
      } else {
        result = await update({ id: connectorId }, { config: autoTrimmedState });
      }

      setConnectorId(result.data.id);
      pollIntegrationProgress(result.data.id);
      // submit analytics event about new connection
      track('New App Added', {
        integrationName: result.data.name,
        integrationId: result.data.availableIntegrationId,
        integrationDisplayName: result.data.displayName,
      });
    } catch (error) {
      if (error?.response?.status === 409) {
        toast.error(`${formState?.integrationName} already exists`);
      } else {
        toast.error(`Error: ${getErrorMessage(error)}`);
      }
      stopValidation();
    }
  };

  return (
    <Dialog
      css={{
        width: '62rem',
        height: '38rem',

        '& > .content.scroll': {
          overflow: 'auto',
          height: 'calc(100vh - 12rem)',
        },
      }}
      title={
        mode === CONNECTOR_MODAL_MODE.CREATE
          ? `Integrate ${displayName || title}`
          : `Update ${displayName || title} integration`
      }
      headerPullRightContent={
        <SetupGuideLink href={installLink} target="_blank">
          <InfoIcon /> Setup Guide
        </SetupGuideLink>
      }
      onClose={onClose}
      allowClose={false}
      footerComponent={
        <>
          <Button kind="text" color="gray" onClick={onClose}>
            Cancel
          </Button>
          <Button
            disabled={isIntegrationInvalid() || validating || !canSubmit()}
            type="submit"
            form="connector-form">
            {mode === CONNECTOR_MODAL_MODE.CREATE ? 'Create' : 'Update'}
          </Button>
        </>
      }>
      <ConnectorModalContent>
        {!validating && lastSyncStatus === 'Failure' && (
          <div className="info-banner-wrapper">
            <InfoBanner type={INFO_BANNER_TYPE.ERROR}>
              <span>
                {lastError ||
                  'Something went wrong when fetching the data. Please contact Authomize support for more details.'}
              </span>
            </InfoBanner>
          </div>
        )}
        {validating ? (
          <LoadingContainer>
            <Loading className="validation-loader" />
            <h2>We are testing the connection, it might take a minute</h2>
            <p>
              {totalTests ? (
                <>
                  {successfulTests}\{totalTests} tests
                </>
              ) : (
                ' '
              )}
            </p>
          </LoadingContainer>
        ) : (
          <>
            {errorsState.genericError && (
              <div className="info-banner-wrapper">
                <InfoBanner type={INFO_BANNER_TYPE.ERROR}>
                  <span>{errorsState.genericError}</span>
                </InfoBanner>
              </div>
            )}
            {mode === CONNECTOR_MODAL_MODE.EDIT &&
              (connector.id === GOOGLE || connector.id === MS) && (
                <div className="info-banner-wrapper">
                  <InfoBanner type={INFO_BANNER_TYPE.WARN}>
                    <span>
                      Please note that editing this integration will affect other
                      <h6> {displayName || title} </h6>
                      integrations too.
                    </span>
                  </InfoBanner>
                </div>
              )}
            <form id="connector-form" onSubmit={handleSubmit} autoComplete="off">
              {steps.map(
                ({ configNames, description, link, linkTitle, title: stepTitle }, index) => (
                  <StepContainer key={index + stepTitle}>
                    <StepDescription
                      index={index}
                      title={stepTitle}
                      description={description}
                      linkTitle={linkTitle}
                      link={link}
                    />
                    {!!configNames?.length && (
                      <FieldsContainer>
                        {configNames.map((fieldName) => {
                          const fieldConfig = configSchema.find(({ name }) => fieldName === name);
                          const extendedFieldConfig = extendedConfig.find(
                            ({ name }) => fieldName === name
                          );

                          const field = isEditMode
                            ? { ...fieldConfig, ...extendedFieldConfig }
                            : fieldConfig;

                          return (
                            <Field
                              key={field.name}
                              {...field}
                              value={formState[field.name]}
                              onChange={handleInputChanged}
                              errors={errorsState[field.name]}
                            />
                          );
                        })}
                      </FieldsContainer>
                    )}
                  </StepContainer>
                )
              )}
            </form>
          </>
        )}
      </ConnectorModalContent>
    </Dialog>
  );
}

const ConnectorModalContent = styled('div', {
  position: 'relative',

  '& .info-banner-wrapper': {
    marginBottom: '1.5rem',

    '& h6': {
      display: 'inline',
    },
  },
});

const LoadingContainer = styled('div', {
  display: 'flex',
  height: '30rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  '& .validation-loader': {
    marginBottom: '1rem',
    fontSize: '4rem',
    color: '$slate400',
  },

  '& h2': {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '24px',
    textAlign: 'center',
    letterSpacing: '0.0015em',
    color: '$black',
  },

  '& p': {
    marginTop: '0.5rem',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '14px',
    lineHeight: '20px',
    textAlign: 'center',
    letterSpacing: '0.1px',
    color: '$slate600',
  },
});

const SetupGuideLink = styled('a', {
  display: 'inline-flex',
  alignItems: 'center',
  gap: '0.25rem',
  marginRight: '0.75rem',
  fontWeight: '600',
  fontSize: '0.87rem',
  lineHeight: '1rem',
  letterSpacing: '0.1px',
  color: '$blue500',
});

const StepContainer = styled('div', {
  margin: '1.5rem 0',
  padding: '1.5rem',
  background: '$white',
  boxShadow:
    '0px 2px 4px rgba(114, 114, 114, 0.15), 0px 0px 4px rgba(154, 162, 185, 0.15),\n    0px 3px 10px rgba(228, 234, 244, 0.32)',
  borderRadius: '4px',
  borderLeft: '4px solid $iris500',

  '&:first-of-type': {
    marginTop: '0',
  },

  '&:last-of-type': {
    marginBottom: '0',
  },

  '& h3': {
    fontWeight: '600',
    fontSize: '1rem',
    lineHeight: '2rem',
    letterSpacing: '0.1px',
    color: '$blue800',
  },

  '& p': {
    marginBottom: '0.5rem',
    fontWeight: '300',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    letterSpacing: '0.1px',
    color: '$slate700',
  },

  '& a': {
    fontWeight: '600',
    fontSize: '0.87rem',
    lineHeight: '1rem',
    letterSpacing: '0.1px',
    color: '$blue500',

    '& svg': {
      position: 'relative',
      top: '2px',
      marginRight: '3px',
    },
  },
});

const FieldsContainer = styled('div', {
  marginTop: '0.5rem',
});

function getInitialFormState(config) {
  const initialState = {};
  for (const field of config) {
    initialState[field.name] =
      field?.value || field?.defaultValue || (field?.type === 'checkbox' ? false : '');
  }
  return initialState;
}
