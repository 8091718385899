import React from 'react';
import { styled, theme } from '@/stitches.config';

export const SEVERITY_TO_COLOR = {
  Critical: `${theme.colors.red500}`,
  High: `${theme.colors.orange500}`,
  Medium: `${theme.colors.yellow400}`,
  Low: `${theme.colors.green500}`,
};

export function SeverityDot({ value, ...props }) {
  return <Dot {...props} css={{ background: SEVERITY_TO_COLOR[value] }} />;
}

export const Dot = styled('span', {
  flex: 'none',
  display: 'inline-block',
  borderRadius: '50%',
  marginRight: '0.5rem',
  width: '0.5rem',
  height: '0.5rem',
  background: '$slate100',

  variants: {
    size: {
      big: {
        width: '1rem',
        height: '1rem',
      },
    },
  },
});

export function getSeverityColor(severity) {
  return SEVERITY_TO_COLOR[severity];
}
