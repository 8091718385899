import { useCallback, useMemo } from 'react';
import { atom, useAtomValue } from 'jotai';
import { client } from '@/lib/api';

const LIMIT = 500;

// Apps Array
const appsAtom = atom(async () => {
  return await fetchApps();
});

// Derive apps map from apps array atom
const appsMapAtom = atom(async (get) => {
  const apps = await get(appsAtom);
  return new Map(apps.map((app) => [app.id, app]));
});

export function useApps() {
  const apps = useAtomValue(appsAtom);
  const appsMap = useAtomValue(appsMapAtom);

  const appsNotDeleted = useMemo(() => apps.filter((app) => !app.isDeleted), [apps]);

  const sourceApps = useMemo(
    () => appsNotDeleted.filter((app) => !app.isFederated),
    [appsNotDeleted]
  );

  const getAppById = useCallback(
    (id) => {
      if (appsMap.has(id)) {
        return appsMap.get(id);
      }
      if (import.meta.env.PROD) {
        console.warn('useApps::getAppById: Unable to match app by id', id);
      }
    },
    [appsMap]
  );

  return {
    apps: appsNotDeleted,
    sourceApps,
    getAppById,
  };
}

// Used by Prefetch component
export function usePrefetchApps() {
  useAtomValue(appsAtom);
}

// Recursively fetch all tags
async function fetchApps(skip = 0, apps = []) {
  try {
    const {
      data: { data, pagination },
    } = await client.get(`/assets/apps`, {
      params: {
        skip,
        limit: LIMIT,
      },
    });

    const newApps = apps.concat(data);

    if (pagination.total > newApps.length && pagination.total > LIMIT) {
      return await fetchApps(pagination.skip + LIMIT, newApps);
    }

    return newApps;
  } catch (e) {
    console.error('Unable to fetch apps', e);
    return [];
  }
}
