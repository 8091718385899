import { css, keyframes, styled } from '@/stitches.config';
import * as HoverCard from '@radix-ui/react-hover-card';
import { StyledAnchor } from '@/components/text';

export const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)', zIndex: 1000 },
});

export const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)', zIndex: 1000 },
});

export const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)', zIndex: 1000 },
});

export const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)', zIndex: 1000 },
});

export const HoverCardContent = styled(HoverCard.Content, {
  display: 'flex',
  flexDirection: 'column',
  gap: '$12',
  borderRadius: '$8',
  padding: '$16',
  width: '260px',
  backgroundColor: '$white',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
  animationDuration: '400ms',
  animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
  willChange: 'transform, opacity',
  zIndex: '1000',

  [`&[data-side='top']`]: {
    animationName: slideDownAndFade,
  },
  [`&[data-side='right']`]: {
    animationName: slideLeftAndFade,
  },
  [`&[data-side='bottom']`]: {
    animationName: slideUpAndFade,
  },
  [`&[data-side='left']`]: {
    animationName: slideRightAndFade,
  },
});

export const HoverCardArrow = styled(HoverCard.Arrow, {
  fill: '$white',
});

export const HoverCardRow = styled('div', {
  display: 'flex',
  gap: '$4',
  flexDirection: 'column',
  lineHeight: '16px',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
});

export const hoverCardLogo = css({ maxWidth: '100%' });

export const HoverCardNameLink = styled(StyledAnchor, {
  overflowWrap: 'break-word',
  display: '-webkit-box',
  '-webkit-line-clamp': '2',
  '-webkit-box-orient': 'vertical',
  overflow: 'hidden',
  whiteSpace: 'normal',
});
