import {
  GetApiAccountsCountParams,
  GetApiAccountsParams,
  GetApiAccountsByIdsParams,
  GetApiV2AccountsAggregationParams,
} from '@/models/api';
import {
  getApiAccounts,
  getApiAccountsByIds,
  getApiAccountsCount,
  getApiAccountsId,
  getApiAccountsIdRisk,
  getApiV2AccountsAggregation,
} from '@/models/api/accounts/accounts';
import { useQuery, useQueryClient } from 'react-query';
import { usePaginatedQuery } from './use-paginated-query';

export const accountsQueryKey = 'accounts';
const accountsRiskQueryKey = 'accountsRisk';
const accountsAggregationQueryKey = 'accountsAggregation';
const accountsCountQueryKey = 'accountsCount';

export function usePaginatedAccountsList(params: GetApiAccountsParams) {
  return usePaginatedQuery({
    queryKey: [accountsQueryKey, params],
    queryFn: ({ pageParam = 0 }) => {
      return getApiAccounts({
        ...params,
        skip: pageParam,
      });
    },
  });
}

export function useAccountsList(params: GetApiAccountsParams, enabled = true) {
  return useQuery({
    queryKey: [accountsQueryKey, params],
    queryFn: () => getApiAccounts(params),
    select: (response) => response.data.data,
    suspense: true,
    enabled,
  });
}

export function useAccountsAggregationV2(params: GetApiV2AccountsAggregationParams) {
  return useQuery({
    queryKey: [accountsAggregationQueryKey, params],
    queryFn: async () => getApiV2AccountsAggregation(params),
    select: (response) => response.data.data,
    suspense: true,
  });
}

const defaultQueryOptions = { suspense: true };

export function useAccountsCount(
  params: GetApiAccountsCountParams,
  queryOptions = defaultQueryOptions
) {
  queryOptions = {
    ...defaultQueryOptions,
    ...queryOptions,
  };
  return useQuery({
    queryKey: [accountsCountQueryKey, params],
    queryFn: async () => getApiAccountsCount(params),
    select: (response) => response.data.data,
    ...queryOptions,
  });
}

export function useAccountById(id: string) {
  return useQuery({
    queryKey: [accountsQueryKey, id],
    queryFn: async () => getApiAccountsId(id),
    select: (response) => response.data.data,
    suspense: true,
    enabled: Boolean(id),
  });
}

export function useAccountRisk(id: string) {
  return useQuery({
    queryKey: [accountsQueryKey, id, accountsRiskQueryKey],
    queryFn: async () => getApiAccountsIdRisk(id),
    select: (response) => response.data.data,
    suspense: true,
    enabled: Boolean(id),
  });
}

export function useAccountsByIds(params: GetApiAccountsByIdsParams) {
  return useQuery({
    queryKey: [accountsQueryKey, params],
    queryFn: async () => getApiAccountsByIds(params),
    select: (response) => response.data.data,
    suspense: true,
    enabled: Boolean(params.ids?.length),
  });
}

export function useInvalidateAccountById() {
  const queryClient = useQueryClient();

  const invalidateAccountById = (id: string) => {
    queryClient.invalidateQueries({ queryKey: [accountsQueryKey, id] });
  };

  return {
    invalidateAccountById,
  };
}

export function useInvalidateAccounts() {
  const queryClient = useQueryClient();

  const invalidateAccounts = () => {
    queryClient.invalidateQueries({ queryKey: [accountsQueryKey] });
  };

  return {
    invalidateAccounts,
  };
}
