import _ from 'lodash';
import qs from 'qs';

export function serializeForm(target) {
  const data = {};
  const formData = new FormData(target);
  for (const [key, value] of formData.entries()) {
    data[key] = value;
  }
  return data;
}

export function getFormattedNumber(num, digits = 1) {
  const { value, symbol } = [
    { value: 1, symbol: '' },
    { value: 1e3, symbol: 'k' },
    { value: 1e6, symbol: 'M' },
    { value: 1e9, symbol: 'G' },
    { value: 1e12, symbol: 'T' },
    { value: 1e15, symbol: 'P' },
    { value: 1e18, symbol: 'E' },
  ].reduce((res, i) => (num >= i.value ? i : res));

  const formattedValue = (num / value).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1');

  return { symbol, value: formattedValue };
}

export function nFormatter(num, digits = 1) {
  const { value, symbol } = getFormattedNumber(num, digits);

  return value + symbol;
}

export function nFormatterPart(num, part = 'number', digit = 1) {
  const formattedNumber = nFormatter(num, digit);
  const [, ...results] = formattedNumber.match(/(\d*[.]?\d*)([\s\S]*)/);
  const [number, symbol] = results;
  return part === 'symbol' ? symbol : parseFloat(number);
}

export function humanizeKeys(key) {
  const PRETTY_KEYS = {
    createdAt: 'Created',
    lastLoginAt: 'Last Login',
  };

  return PRETTY_KEYS[key] || _.startCase(key);
}

export function mapEntityEntries(entity, visibleKeys = []) {
  const mainEntries = _.isEmpty(visibleKeys)
    ? Object.entries(entity)
    : Object.entries(entity).filter(([key]) => visibleKeys.includes(key));
  const propertiesEntries = entity.properties ? Object.entries(entity.properties) : [];
  return mainEntries.concat(propertiesEntries).filter(([, val]) => !_.isEmpty(val));
}

export function removeByIndex(arr, index) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)];
}

export function tabsWithCounters(tabs, counters = {}) {
  return tabs.map(({ path, title, counterKey }) => ({
    path,
    title,
    counter: counters[counterKey],
  }));
}

export function makeUrl(path, query) {
  const queryString = query && typeof query === 'object' ? qs.stringify(query) : query;
  return queryString ? `${path}?${queryString}` : path;
}

export const alphabeticalSort = (firstItem, secondItem) => firstItem.localeCompare(secondItem);

export function titleBySource(source) {
  const genericConnectors = ['fileprovider'];
  if (source) {
    return source.serviceType && genericConnectors.includes(source.serviceType.toLowerCase())
      ? source.serviceId
      : source.serviceType;
  }
  return null;
}

export function sleep(ms) {
  // eslint-disable-next-line no-promise-executor-return
  return new Promise((resolve) => setTimeout(resolve, ms));
}
