/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Authomize Application API
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  PostApiAccountsBaseMergeRequests200,
  PostApiAccountsBaseMergeRequestsBody,
  PostApiAccountsMergeRequests200,
  PostApiAccountsMergeRequestsBody,
  PostApiAccountsSplitRequests200,
  PostApiAccountsSplitRequestsBody,
  GetApiAccounts200,
  GetApiAccountsParams,
  GetApiAccountsByIds200,
  GetApiAccountsByIdsParams,
  GetApiAccountsCount200,
  GetApiAccountsCountParams,
  PostApiAccountsPropertiesPropertyIdValuesSearch200,
  PostApiAccountsPropertiesPropertyIdValuesSearchBody,
  GetApiAccountsId200,
  GetApiV2AccountsAggregation200,
  GetApiV2AccountsAggregationParams,
  GetApiAccountsIdRisk200,
} from '../api.schemas';

export const postApiAccountsBaseMergeRequests = <
  TData = AxiosResponse<PostApiAccountsBaseMergeRequests200>
>(
  postApiAccountsBaseMergeRequestsBody: PostApiAccountsBaseMergeRequestsBody,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(
    `/api/accounts/base-merge-requests`,
    postApiAccountsBaseMergeRequestsBody,
    options
  );
};
export const postApiAccountsMergeRequests = <
  TData = AxiosResponse<PostApiAccountsMergeRequests200>
>(
  postApiAccountsMergeRequestsBody: PostApiAccountsMergeRequestsBody,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/api/accounts/merge-requests`, postApiAccountsMergeRequestsBody, options);
};
export const postApiAccountsSplitRequests = <
  TData = AxiosResponse<PostApiAccountsSplitRequests200>
>(
  postApiAccountsSplitRequestsBody: PostApiAccountsSplitRequestsBody,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/api/accounts/split-requests`, postApiAccountsSplitRequestsBody, options);
};
export const getApiAccounts = <TData = AxiosResponse<GetApiAccounts200>>(
  params?: GetApiAccountsParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/accounts`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
export const getApiAccountsByIds = <TData = AxiosResponse<GetApiAccountsByIds200>>(
  params: GetApiAccountsByIdsParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/accounts/by-ids`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
export const getApiAccountsCount = <TData = AxiosResponse<GetApiAccountsCount200>>(
  params?: GetApiAccountsCountParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/accounts/count`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
export const postApiAccountsPropertiesPropertyIdValuesSearch = <
  TData = AxiosResponse<PostApiAccountsPropertiesPropertyIdValuesSearch200>
>(
  propertyId: string,
  postApiAccountsPropertiesPropertyIdValuesSearchBody: PostApiAccountsPropertiesPropertyIdValuesSearchBody,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(
    `/api/accounts/properties/${propertyId}/values/search`,
    postApiAccountsPropertiesPropertyIdValuesSearchBody,
    options
  );
};
export const getApiAccountsId = <TData = AxiosResponse<GetApiAccountsId200>>(
  id: string,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/accounts/${id}`, options);
};
export const getApiV2AccountsAggregation = <TData = AxiosResponse<GetApiV2AccountsAggregation200>>(
  params: GetApiV2AccountsAggregationParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/v2/accounts/aggregation`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
export const getApiAccountsIdRisk = <TData = AxiosResponse<GetApiAccountsIdRisk200>>(
  id: string,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/accounts/${id}/risk`, options);
};
