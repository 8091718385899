import React, { useCallback, useEffect, useState } from 'react';
import { useFetcher } from 'rest-hooks';
import Confetti from 'react-confetti';
import { Helmet } from 'react-helmet';
import { styled } from '@/stitches.config';
import { Button } from '@/components/buttons/button';
import { AsyncBoundary } from '../../../components/bounderies/async-boundary';
import { RecommendedApps } from './components/recommended-apps';
import { ConnectedApps } from './components/connected-apps';
import {
  CONNECTOR_MODAL_MODE,
  ConnectorModal,
} from '../../../components/connector-modal/connector-modal';
import IntegrationResource from '../../../resources/integration';
import { SettingsPageContent } from '../settings-common-styles';
import { toastAppConnected } from './components/connect-app-toast';
import { AppsLibraryModal } from './components/apps-library-modal';

export function ApplicationIntegrations() {
  return <Integrations types="Connector" />;
}

export function CommunicationIntegrations() {
  return <Integrations types="Communication" />;
}

/**
 * This is new Integrations page component. It will replace old IntegrationSettings component after all integrations
 * with API
 * @returns {JSX.Element}
 * @constructor
 */
export function Integrations({ types }) {
  const fetchRecommendedApps = useFetcher(IntegrationResource.available());
  const [recommendedApps, setRecommendedApps] = useState([]);
  const [recommendedAppsLoading, setRecommendedAppsLoading] = useState(false);
  const [recommendedAppsError, setRecommendedAppsError] = useState(false);
  const [showAppsLibModal, setShowAppsLibModal] = useState(false);

  const fetchConnectedApps = useFetcher(IntegrationResource.connected());
  const [connectedApps, setConnectedApps] = useState([]);
  const [connectedAppsLoading, setConnectedAppsLoading] = useState(false);
  const [connectedAppsError, setConnectedAppsError] = useState(false);

  const [selectedConnector, setSelectedConnector] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);

  const getRecommendedApps = useCallback(async () => {
    setRecommendedAppsLoading(true);

    try {
      const result = await fetchRecommendedApps({ types });

      setRecommendedApps(result.data || []);
    } catch (e) {
      setRecommendedAppsError(true);
    }
    setRecommendedAppsLoading(false);
  }, [
    types,
    fetchRecommendedApps,
    setRecommendedApps,
    setRecommendedAppsError,
    setRecommendedAppsLoading,
  ]);

  const getConnectedApps = useCallback(async () => {
    setConnectedAppsLoading(true);

    try {
      const result = await fetchConnectedApps({ types });

      setConnectedApps(result.data || []);
    } catch (e) {
      setConnectedAppsError(true);
    }
    setConnectedAppsLoading(false);
  }, [types, fetchConnectedApps, setConnectedApps, setConnectedAppsError, setConnectedAppsLoading]);

  const onConnectorModalSubmit = useCallback(() => {
    toastAppConnected();

    setShowConfetti(true);

    setTimeout(() => {
      setShowConfetti(false);
    }, 5000);

    getConnectedApps();
    getRecommendedApps();
  }, [getConnectedApps, getRecommendedApps]);

  const onConnectorModalCancel = useCallback(() => {
    setSelectedConnector(null);
  }, [setSelectedConnector]);

  const handleRefresh = useCallback(() => {
    getConnectedApps();
    getRecommendedApps();
  }, [getConnectedApps, getRecommendedApps]);

  useEffect(() => {
    getConnectedApps();
    getRecommendedApps();
  }, [types, getConnectedApps, getRecommendedApps]);

  return (
    <SettingsPageContent>
      <Helmet>
        <title>Integrations</title>
      </Helmet>
      <AsyncBoundary>
        {showAppsLibModal && (
          <AppsLibraryModal
            types={types}
            onClose={() => setShowAppsLibModal(false)}
            onAppSelect={setSelectedConnector}
          />
        )}
        {showConfetti && (
          <ConfettiWrapper>
            <Confetti />
          </ConfettiWrapper>
        )}
        {selectedConnector && (
          <ConnectorModal
            mode={CONNECTOR_MODAL_MODE.CREATE}
            connector={selectedConnector}
            onSubmit={onConnectorModalSubmit}
            onClose={onConnectorModalCancel}
          />
        )}
        {types === 'Connector' && (
          <PullRightWrapper>
            <Button onClick={() => setShowAppsLibModal(true)}>Add New App</Button>
          </PullRightWrapper>
        )}
        {types === 'Communication' && (
          <RecommendedApps
            recommendedApps={recommendedApps}
            loading={recommendedAppsLoading}
            error={recommendedAppsError}
            onAddConnector={setSelectedConnector}
            types={types}
          />
        )}
        <ConnectedApps
          connectedApps={connectedApps}
          loading={connectedAppsLoading}
          error={connectedAppsError}
          onRefresh={handleRefresh}
          types={types}
        />
      </AsyncBoundary>
    </SettingsPageContent>
  );
}
const ConfettiWrapper = styled('div', {
  position: 'fixed',
  top: '0',
  bottom: '0',
  left: '0',
  right: '0',
});

const PullRightWrapper = styled('div', {
  textAlign: 'right',
});
