import React, { useCallback, useMemo, useRef } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useTable, useRowSelect, useMountedLayoutEffect, useColumnOrder } from 'react-table';
import { css, cx, styled } from '@/stitches.config';
import { useNavigation } from '@/hooks';
import { makeTableColumns, makeInitialState, toBooleanMap } from '@/components/table/table-utils';
import { defaultOnSelectedEntities, defaultRenderBulkAction } from '@/components/table/constants';
import { ArrowUpIcon, ArrowDownIcon } from '@/icons';
import {
  useColumnsResize,
  resizerStyles,
  RESIZER_CLASS_NAME,
} from '@/components/table/use-columns-resize';
import { useColumnsDragNDrop } from '@/components/table/use-columns-drag-n-drop';
import { createPortal } from 'react-dom';
import { times } from 'lodash-es';
import { ItemsLoading } from '@/components/items-loading/items-loading';
import { LoadingDots } from '../loading';
import { Checkbox } from '../checkbox';
import { TableError } from './table-error';
import { TableNoData } from './table-no-data';

const defaultStyles = {
  container: css({
    position: 'relative',
    overflow: 'auto',
    width: '100%',
    height: '100%',
  }),
  table: css({
    position: 'absolute',
    overflow: 'auto',
    width: '100%',
    maxWidth: '100%',
    borderRadius: '$6',
    background: '$white',
    tableLayout: 'fixed',
    borderCollapse: 'separate',

    tr: {
      'td.show-on-hover': {
        opacity: 0,
      },
      '&.pointer': {
        cursor: 'pointer',
      },
    },

    'tr:hover': {
      'td.show-on-hover': {
        opacity: 1,
      },
    },
  }),
  thead: css({
    position: 'sticky',
    top: '0',
    zIndex: '$5',
    '& th': {
      height: '3.5rem',
      padding: '$12',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '$14',
      lineHeight: '18px',
      color: '$slate400',
      textAlign: 'left',
      backgroundColor: '$white',
      background: 'linear-gradient(to bottom, rgb(255, 255, 255) 65px, rgb(235, 236, 241) 1px)',
      boxShadow: '12px 0 12px 0px rgba(0, 0, 0, 0.1)',

      '&.selection': {
        paddingLeft: '$12',
      },

      '&.active-drag': {
        borderLeft: '2px solid $slate400',
      },
      '&.sortable': {
        '.sort-icon': {
          position: 'absolute',
          fontSize: '$16',
          color: '$slate100',
          marginLeft: '$4',
          opacity: 0,
          '&.active': {
            opacity: 1,
            color: '$slate500',
          },
        },
      },
      '&:hover': {
        '&.sortable': {
          '.sort-icon': {
            opacity: 1,
          },
        },
      },
    },
  }),
  cell: css({
    padding: '$12',
    borderTop: '1px solid $gray300',
    color: '$black',
    fontSize: '$14',

    '&.selection': {
      paddingLeft: '$12',
      textAlign: 'left',
    },

    '&.actions': {
      textAlign: 'right',
    },
  }),
};

const emptyStyle = css({
  padding: '$8',
  background: '$white',
  color: '$slate400',
  textAlign: 'center',
});

const getRowId = (row) => (row.rowId ? row.rowId : row.id);

const defaultFields = [
  { id: 'name', label: 'Name' },
  { id: 'type', label: 'Type' },
];

const INITIAL_SELECTED_ROW_IDS = [];

/**
 * IMPORTANT!
 * This component should no longer be used directly.
 * Please use the Table component instead.
 */
export function DeprecatedToUseDirectlyTable({
  id: tableId = 'tableScroll',
  initialParams,
  selectedRowIds: initialSelectedIds = INITIAL_SELECTED_ROW_IDS,
  fields = defaultFields,
  activeRowId = null,
  onRowClick,
  visibleFields,
  cells,
  data,
  onFetchMore,
  hasNextPage,
  loading,
  error,
  allowColumnsResize = true,
  allowDragNDrop = true,
  bulkActionsEnabled = true,
  renderBulkActions = defaultRenderBulkAction,
  styles = defaultStyles,
  onSelectedEntities = defaultOnSelectedEntities,
  toggleRowSelectedOnClick = false,
  scrollOutsideTable,
}) {
  const navigation = useNavigation();

  const [sentryRef, { rootRef }] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: onFetchMore,
    delayInMs: 200,
  });

  const results = useMemo(() => data, [data]);
  const columns = useMemo(
    () => makeTableColumns({ fields, visibleFields, cells }),
    [fields, visibleFields, cells]
  );

  const initialState = useMemo(
    () => makeInitialState(navigation.queryParams, initialParams, initialSelectedIds),
    [navigation.queryParams, initialParams, initialSelectedIds]
  );

  const useControlledState = useCallback(
    (state) => {
      // If user started selecting rows, use that state and ignore initial selected ids
      if (Object.keys(state.selectedRowIds).length) {
        return state;
      }

      return {
        ...state,
        selectedRowIds: toBooleanMap(initialSelectedIds),
      };
    },
    [initialSelectedIds]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setColumnOrder,
    state: { selectedRowIds },
    toggleAllRowsSelected,
  } = useTable(
    {
      columns,
      data: results,
      getRowId,
      initialState,
      manualSortBy: true,
      disableSortRemove: true,
      disableMultiSort: true,
      autoResetSelectedRows: false,
      useControlledState,
    },
    useRowSelect,
    useColumnOrder,
    (hooks) => {
      if (!bulkActionsEnabled) return;
      hooks.visibleColumns.push((visibleColumns) => [
        {
          id: 'selection',
          width: 40,
          nonResizable: true,
          disableDragNDrop: true,
          disableSortBy: true,
          Header: ({ getToggleAllRowsSelectedProps }) => {
            const { checked, onChange, indeterminate, ...rest } = getToggleAllRowsSelectedProps();

            return (
              <div>
                <Checkbox
                  {...rest}
                  checked={checked || (indeterminate && 'indeterminate')}
                  onChange={(value) => {
                    onChange({ target: { value } });
                  }}
                />
              </div>
            );
          },
          Cell: ({ row }) => {
            const { checked, onChange, ...rest } = row.getToggleRowSelectedProps({
              onClick: (e) => e.stopPropagation(),
            });

            return (
              <div>
                <Checkbox
                  {...rest}
                  checked={checked}
                  onChange={(value) => {
                    onChange({ target: { value } });
                  }}
                />
              </div>
            );
          },
        },
        ...visibleColumns,
      ]);
    }
  );

  const selectedEntitiesIds = Object.keys(selectedRowIds);
  const selectedEntities = results.filter((entity) => selectedEntitiesIds.includes(entity.id));

  // Keep parent/store state in sync with local state
  // No need to update on mount since we are passing initial state
  useMountedLayoutEffect(() => {
    onSelectedEntities?.(selectedRowIds);
  }, [selectedRowIds]);

  const tableRef = useRef();
  const columnsSavedSizes = useColumnsResize(tableRef, columns, tableId);

  const numberOfColumns = headerGroups.length ? headerGroups[0].headers.length : 1;

  const setColumns = (cols) => setColumnOrder(cols.map(({ id: colId }) => colId));

  const { handleDragStart, handleDragOver, handleOnDrop, handleDragEnter, dragOverColumnId } =
    useColumnsDragNDrop({
      columns: headerGroups[0].headers,
      setColumns,
    });

  const sort = useMemo(
    () => navigation.queryParams?.sort || initialParams.sort,
    [navigation.queryParams, initialParams]
  );
  const order = useMemo(
    () => navigation.queryParams?.order || initialParams.order,
    [navigation.queryParams, initialParams]
  );

  const bulkActionsContainer = useMemo(
    () => document.getElementById('table-bulk-actions-container'),
    []
  );

  return (
    <>
      <div
        id={tableId}
        className={cx(defaultStyles.container, styles?.container)}
        ref={scrollOutsideTable ? null : rootRef}>
        <table
          {...getTableProps()}
          className={cx(defaultStyles.table, styles?.table, resizerStyles)}
          style={{ position: scrollOutsideTable ? 'static' : 'absolute' }}
          ref={tableRef}>
          <thead className={cx(defaultStyles.thead, styles?.thead)}>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => {
                  const isDraggable = allowDragNDrop && !column.disableDragNDrop;

                  const isSorted = column.sortFieldId === sort || column.id === sort;
                  const isSortedDesc = order === 'desc';
                  const { width: defaultWidth, ...columnStyling } = getColumnStyling(column);
                  return (
                    <th
                      id={column.id}
                      {...column.getHeaderProps()}
                      style={{
                        ...columnStyling,
                        width: columnsSavedSizes[column.id] || defaultWidth,
                        cursor: column.disableDragNDrop ? 'inherit' : 'pointer',
                      }}
                      className={cx(
                        column.id,
                        column.id === dragOverColumnId && 'active-drag',
                        !column.disableSortBy && 'sortable'
                      )}
                      draggable={isDraggable}
                      onDragStart={(e) => isDraggable && handleDragStart(e, column.id)}
                      onDragOver={handleDragOver}
                      onDrop={(e) => isDraggable && handleOnDrop(e, column.id)}
                      onDragEnter={() => isDraggable && handleDragEnter(column.id)}>
                      {!(i === headerGroup.headers.length - 1 || column.nonResizable) &&
                        allowColumnsResize && <div className={RESIZER_CLASS_NAME} />}
                      <div
                        onClick={
                          !column.disableSortBy
                            ? () => {
                                const params = {
                                  sort: column.sortFieldId || column.id,
                                  order: isSortedDesc ? 'asc' : 'desc',
                                };

                                navigation.updateQueryParams(params);
                              }
                            : () => null
                        }
                        style={{
                          cursor:
                            column.disableDragNDrop && column.disableSortBy ? 'default' : 'pointer',
                        }}>
                        {column.render('Header')}
                        {!column.disableSortBy && (
                          <>
                            {isSorted && isSortedDesc && (
                              <ArrowDownIcon className={cx('sort-icon', 'active')} />
                            )}
                            {isSorted && !isSortedDesc && (
                              <ArrowUpIcon className={cx('sort-icon', 'active')} />
                            )}
                            {!isSorted && <ArrowUpIcon className="sort-icon" />}
                          </>
                        )}
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              const selected = selectedRowIds[row.id];

              return (
                <tr
                  {...row.getRowProps()}
                  className={cx(
                    selected && 'selected',
                    onRowClick && 'pointer',
                    row.id === activeRowId && 'quickView'
                  )}
                  onClick={() => {
                    onRowClick?.(row.original);
                    if (toggleRowSelectedOnClick) {
                      row.toggleRowSelected();
                    }
                  }}>
                  {row.cells.map((cell) => {
                    const { align, ellipsis, showOnHover } = fields.find(
                      ({ id }) => id === cell.column.id
                    ) || { align: 'left', ellipsis: false };
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        align={align}
                        ellipsis={ellipsis}
                        className={cx(
                          defaultStyles.cell,
                          styles?.cell,
                          cell.column.id,
                          showOnHover && 'show-on-hover',
                          cell.column.id === dragOverColumnId && 'active-drag'
                        )}>
                        {cell.render('Cell')}
                      </TableCell>
                    );
                  })}
                </tr>
              );
            })}
            <TableError
              isVisible={!loading && rows.length === 0 && error}
              numberOfColumns={numberOfColumns}
              error={error}
            />
            <TableNoData
              numberOfColumns={numberOfColumns}
              isVisible={!loading && rows.length === 0 && !error}
            />

            {hasNextPage && (
              <tr>
                <td ref={sentryRef} className={cx(emptyStyle)} colSpan={numberOfColumns}>
                  Loading more <LoadingDots />
                </td>
              </tr>
            )}
          </tbody>
          {loading && !hasNextPage && (
            <tbody>
              {times(15).map((_, idx) => (
                <tr key={`skeleton-row-${idx}`}>
                  {headerGroups[0].headers.map((col) => (
                    <td key={col.id}>
                      {col.id === 'selection' ? (
                        <ItemsLoading
                          itemWidth={40}
                          maxLines={1}
                          itemHeight={30}
                          count={1}
                          itemMargin="0 0 0 10px"
                        />
                      ) : (
                        <ItemsLoading itemHeight={40} count={1} itemMargin="16px 0 15px 10px" />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
      {scrollOutsideTable ? (
        createPortal(
          <BulkActionsContainer open={selectedEntities.length > 0}>
            {bulkActionsEnabled && selectedEntities.length > 0 && (
              <BulkActionsContent>
                {renderBulkActions({
                  selectedEntities,
                  rowsCount: rows.length,
                  hasMoreEntities: hasNextPage,
                  toggleAllRowsSelected,
                })}
              </BulkActionsContent>
            )}
          </BulkActionsContainer>,
          bulkActionsContainer
        )
      ) : (
        <BulkActionsContainer open={selectedEntities.length > 0}>
          {bulkActionsEnabled && selectedEntities.length > 0 && (
            <BulkActionsContent>
              {renderBulkActions({
                selectedEntities,
                rowsCount: rows.length,
                hasMoreEntities: hasNextPage,
                toggleAllRowsSelected,
              })}
            </BulkActionsContent>
          )}
        </BulkActionsContainer>
      )}
    </>
  );
}

function getColumnStyling(field = {}) {
  const { width, minWidth } = field;
  return minWidth ? { minWidth, width: minWidth } : width ? { width } : {};
}

const TableCell = styled('td', {
  '&.active-drag': {
    borderLeft: '2px solid $slate400',
  },
  variants: {
    align: {
      left: {
        textAlign: 'left',
      },
      center: {
        textAlign: 'center',
      },
      right: {
        textAlign: 'right',
      },
    },
    ellipsis: {
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 0,
      },
    },
  },
});

const BulkActionsContainer = styled('div', {
  position: 'relative',
  maxHeight: 0,
  transition: 'height 1s ease-in-out',
  boxShadow:
    '0px -4px 20px 4px rgba(225, 225, 225, 0.15), 0px -4px 8px 1px rgba(216, 221, 236, 0.15), 0px -2px 4px rgba(228, 234, 244, 0.25)',
  borderTop: '1px solid $gray300',
  background: '$white',
  zIndex: '$3',

  variants: {
    open: {
      true: {
        maxHeight: 999,
        transition: 'height 1s ease-in-out',
      },
    },
  },
});

const BulkActionsContent = styled('div', {
  position: 'relative',
  display: 'flex',
  width: '100%',
  bottom: 0,
  padding: '$32',
  justifyContent: 'space-between',
  alignItems: 'center',
});
