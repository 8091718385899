import React from 'react';
import { Label, ThreeRowsGrid, Value } from '@/components/slide-over/helpers';
import { Tooltip } from '@/components/tooltip';
import { AppIdsIconsList } from '@/components/app-icons-list';
import { TagsList } from '@/components/tags-list';

export function AssetOverview({ entity }) {
  return (
    <ThreeRowsGrid>
      <div>
        <Label>Name</Label>
        <Value>
          {entity.name ? (
            <Tooltip label={entity.name}>
              <span>{entity.name}</span>
            </Tooltip>
          ) : (
            '-'
          )}
        </Value>
      </div>

      <div>
        <Label>Type</Label>
        <Value>
          {entity.type ? (
            <Tooltip label={entity.type}>
              <span>{entity.type}</span>
            </Tooltip>
          ) : (
            '-'
          )}
        </Value>
      </div>

      <div>
        <Label>Origin Type</Label>
        <Value>-</Value>
      </div>

      <div>
        <Label>Source App</Label>
        <Value>
          {entity.sourceAppId ? <AppIdsIconsList appIds={[entity.sourceAppId]} /> : '-'}
        </Value>
      </div>

      <div>
        <Label>Tags</Label>
        <TagsList tags={entity.tags} maxLimit={100} />
      </div>
    </ThreeRowsGrid>
  );
}
