import { useEffect, useState } from 'react';
import { formatGraphElements } from '@/views/inventory/access-explorer/utils/format';
import { atom, useSetAtom } from 'jotai';
import { getApiV3Graph } from '@/models/api';
import { useQuery } from 'react-query';

// used in GraphControls to trigger graph re-centering on data change
export const latestGraphDataTimestamp = atom(null);

export function useGraphData(params) {
  const setTimeStamp = useSetAtom(latestGraphDataTimestamp);

  const {
    data: rawData,
    isLoading: loading,
    error,
  } = useQuery({
    queryKey: ['graph', params],
    queryFn: () => {
      return getApiV3Graph(params);
    },
    select: (response) => response?.data?.data,
    enabled: Boolean(params.sourceId),
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000, // 5 mins
  });

  const [elements, setElements] = useState({ nodes: [], edges: [] });

  useEffect(() => {
    if (loading || !rawData) return;

    // To make sure graph is already rendered when it will be centered
    setTimeout(() => setTimeStamp(Date.now()), 100);

    const { nodes: formattedNodes, edges: formattedEdges } = formatGraphElements({
      nodes: rawData.nodes,
      edges: rawData.edges,
      start: params.start,
      end: params.end,
      selectedEdgeId: params.selectedEdgeId,
    });

    setElements({ nodes: formattedNodes, edges: formattedEdges });
  }, [
    loading,
    params.end,
    params.selectedEdgeId,
    params.start,
    rawData,
    setElements,
    setTimeStamp,
  ]);

  return {
    data: {
      elements,
      setElements,
    },
    error,
    loading,
  };
}
