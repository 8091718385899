import { useCallback, useEffect, useRef, useState } from 'react';

/**
 * Takes an async function and tracks resolution as a boolean.
 */
export function useLoadingFunction(func, { onError } = {}) {
  const [loading, setLoading] = useState(false);
  const isMountedRef = useRef(true);
  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    []
  );
  const wrappedClick = useCallback(
    async (...args) => {
      if (!func) return;

      let returnValue;
      setLoading(true);
      try {
        returnValue = await func(...args);
      } catch (e) {
        if (onError) {
          onError(e);
        } else {
          throw e;
        }
      } finally {
        if (isMountedRef.current) setLoading(false);
      }
      return returnValue;
    },
    [func, onError]
  );

  return [wrappedClick, loading];
}
