import { Link } from 'react-router-dom';
import { css, styled } from '@/stitches.config';
import { StyledComponent } from '@/models/common';

const text = css({
  fontWeight: '$400',
  fontSize: '$16',
  lineHeight: '$24',
  letterSpacing: '0.5px',
  color: '$slate700',

  variants: {
    size: {
      1: { fontSize: '$16', lineHeight: '$24', letterSpacing: '0.5px' },
      2: { fontSize: '$14', lineHeight: '$20', letterSpacing: '0.25px' },
      3: { fontSize: '$12', lineHeight: '$16', letterSpacing: '0.4px;' },
    },
    weight: {
      light: { fontWeight: '$300' },
      regular: { fontWeight: '$400' },
      semibold: { fontWeight: '$600' },
      bold: { fontWeight: '$700' },
    },
    color: {
      black: { color: '$black' },
      grey: { color: '$slate700' },
      lightgrey: { color: '$slate400' },
    },
    // other variants like color, weight, style, line-height etc.
  },
});

export const Body: StyledComponent = styled('span', text);

export const Subtitle: StyledComponent = styled('p', text, {
  color: '$slate900',
  fontSize: '$16',
  lineHeight: '$24',
  letterSpacing: '0.15px',

  variants: {
    size: {
      1: { fontSize: '$16', lineHeight: '$24', letterSpacing: '0.15px' },
      2: { fontSize: '$14', lineHeight: '$20', letterSpacing: '0.1px' },
      verySmall: { fontSize: '$12', lineHeight: '$16', letterSpacing: '0.4px;' },
    },
    ellipsis: {
      true: {
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
  },
});

const headline = css({
  color: '$slate900',
  fontWeight: '$600',

  variants: {
    weight: {
      light: { fontWeight: '$300' },
      regular: { fontWeight: '$400' },
      semibold: { fontWeight: '$600' },
      bold: { fontWeight: '$700' },
    },
  },
});

export const H1: StyledComponent = styled('h1', text, headline, {
  fontSize: '$72',
  lineHeight: '$120',
  letterSpacing: '-0.015em',
});

export const H2: StyledComponent = styled('h2', text, headline, {
  fontSize: '$56',
  lineHeight: '$72',
  letterSpacing: '-0.005em',
});

export const H3: StyledComponent = styled('h3', text, headline, {
  fontSize: '$48',
  lineHeight: '$56',
  letterSpacing: '0',
});

export const H4: StyledComponent = styled('h4', text, headline, {
  fontSize: '$24',
  lineHeight: '$32',
  letterSpacing: '0.0025em',
});

export const H5: StyledComponent = styled('h5', text, headline, {
  fontSize: '$20',
  lineHeight: '$32',
  letterSpacing: '0',
});

export const H6: StyledComponent = styled('h6', text, headline, {
  fontSize: '$16',
  lineHeight: '$24',
  letterSpacing: '0.0015em',
});

export const Paragraph = styled('p', text);
export const TextLabel = styled('span', text, {
  fontWeight: '$600',
  color: '$slate700',
});
export const Small = styled('small', text);
export const Deleted = styled('del', text);

// declare additional styles where necessary
export const Ins = styled('ins', text, { textDecoration: 'none' });
export const Em = styled('em', text, { fontStyle: 'italic' });
export const Strong = styled('strong', text, { fontWeight: '$600' });

// define additional variants where necessary
export const StyledLink = styled(Link, text, {
  color: '$iris800',
  '&:hover': {
    textDecoration: 'underline',
  },
});

// Why do we have two styles for links??
export const HoverLink = styled(Link, text, {
  '&:hover': {
    color: '$iris800',
  },
});

export const StyledAnchor = styled('a', text, {
  color: '$iris800',
  '&:hover': {
    textDecoration: 'underline',
  },
});
