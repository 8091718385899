import { useCallback } from 'react';
import { useFetcher } from 'rest-hooks';
import { BaseResource } from '@/resources/base-resource';
import { useMemoWithDeepCompare } from '@/hooks/use-memo-with-deep-compare';

export function usePaginator(endpoint, params) {
  const getNextPage = useFetcher(endpoint, true);

  const memoizedParams = useMemoWithDeepCompare(params);

  return useCallback(
    ({ skip, limit }) => {
      // Update skip parameter to fetch additonal data
      return getNextPage({ ...memoizedParams, skip: skip + limit }, undefined, [
        // this instructs Rest Hooks to update the cache results specified by the first two members
        // with the merge algorithm of the third.
        [endpoint, memoizedParams, BaseResource.appendList],
      ]);
      // "params && endpoint.key(params)" is a method to serialize params
    },
    [endpoint, getNextPage, memoizedParams]
  );
}
