import React from 'react';
import { styled } from '@/stitches.config';
import { Button } from '@/components/buttons/button';
import { ExternalLink, GraphIcon, LayoutIcon } from '@/icons';
import { Link } from 'react-router-dom';
import { Tooltip } from '@/components/tooltip';

export function HoverCardActions({
  SPELink,
  externalLink,
  graphLink,
  showExternal = true,
}: {
  SPELink: string;
  externalLink?: string;
  graphLink: string;
  showExternal?: boolean;
}) {
  const sourceAppTooltip = externalLink
    ? 'View in Source App'
    : 'The link of the entity in the source app is not available';

  return (
    <HoverCardActionsContainer>
      <Tooltip label="Investigate in Access Explorer Graph">
        <Button color="grey" circle as={Link} target="_blank" to={graphLink} disabled={!SPELink}>
          <GraphIcon />
        </Button>
      </Tooltip>

      {showExternal && (
        <Tooltip label={sourceAppTooltip}>
          <span>
            <Button
              color="grey"
              circle
              as="a"
              target="_blank"
              href={externalLink}
              disabled={!externalLink}>
              <ExternalLink />
            </Button>
          </span>
        </Tooltip>
      )}

      <Tooltip label="View in Single Entity Page">
        <Button color="grey" circle as={Link} target="_blank" to={SPELink} disabled={!graphLink}>
          <LayoutIcon />
        </Button>
      </Tooltip>
    </HoverCardActionsContainer>
  );
}

export const HoverCardActionsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-around',
  borderTop: '1px solid #EBECF1',
  padding: '$16 $48 0 $48',
  margin: '0 -$16',
});
