export const ROLES = {
  ADMIN: 'admin',
  AUTHOMIZE_ADMIN: 'authomizeAdmin',
  AUTHOMIZE_GLOBAL_ADMIN: 'authomizeGlobalAdmin',
  REVIEWER: 'reviewer',
};

export function isUserDefinedAtAuthomize(session) {
  return !!session?.userKey;
}

export function isUserRequiresPasswordChange(session) {
  return session?.requirePasswordChange === true;
}

export function checkIsAuthomizeUser(session) {
  return session?.role === ROLES.AUTHOMIZE_ADMIN || session?.role === ROLES.AUTHOMIZE_GLOBAL_ADMIN;
}

export function isGlobalAdminUser(session) {
  return (
    session?.role === ROLES.ADMIN ||
    session?.role === ROLES.AUTHOMIZE_ADMIN ||
    session?.role === ROLES.AUTHOMIZE_GLOBAL_ADMIN
  );
}

export function checkIsAdminUser(session) {
  return session?.role !== ROLES.REVIEWER;
}

export function isLimitedReviewerUser(session) {
  return session?.role === ROLES.REVIEWER;
}

export function isNotLocal(session) {
  if (!session.email) {
    return false;
  }
  const userEmail = session.email.toLowerCase();
  return (
    userEmail &&
    userEmail.indexOf('@acme.com') === -1 &&
    window.location.host.indexOf('localhost') === -1 &&
    window.location.host.indexOf('127.0.0.1') === -1
  );
}

export function isAuthomizeGlobalAdmin(session) {
  return session?.role === ROLES.AUTHOMIZE_GLOBAL_ADMIN;
}

export function isNotAuthomizeEmail(session) {
  if (!session.email) {
    return false;
  }
  const userEmail = session.email.toLowerCase();
  return userEmail && userEmail.indexOf('@authomize.com') === -1;
}
