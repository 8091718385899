import { StyledComponent } from '@/models/common';
import { styled } from '@/stitches.config';
import { Button } from '@/components/buttons/button';

export const SelectedItem: StyledComponent = styled('span', {
  height: '22px',
  backgroundColor: '$gray300',
  borderRadius: '$4',
  gap: '$8',
  padding: '0 $8',
  fontSize: '$14',
  color: '$slate700',
  fontWeight: '$400 !important',
});

export const PopoverWrapper = styled('span', {
  position: 'relative',
});

export const HiddenInput = styled('input', {
  position: 'absolute',
  width: '100%',
  height: '100%',
  left: 0,
  top: 0,
  zIndex: -1,
});

export const Placeholder: StyledComponent = styled('span', {
  color: '$slate700',
});

export const TriggerButton: StyledComponent = styled(Button, {
  minHeight: '32px',
  height: 'auto !important',
  justifyContent: 'space-between',
  padding: '$4 $12 !important',
  '&:hover': {
    borderColor: '$iris800',
    backgroundColor: '$white !important',
  },
  variants: {
    disabled: {
      true: {
        [`${Placeholder}`]: {
          color: '$gray400',
        },
      },
    },
  },
});

export const ComboboxWrapper = styled('div', {
  width: '20rem',
  maxHeight: 300,
  background: '$white',
  boxShadow: '0px 4px 4px rgba(80, 80, 80, 0.15), 0px 0px 10px 1px rgba(203, 205, 208, 0.3)',
  borderRadius: '$4',
});

export const ListWrapper = styled('div', {
  width: '100%',
  margin: '0',
  borderTop: '0',
  background: 'white',
  boxShadow:
    '0px 4px 4px rgba(0, 0, 0, 0.04), 0px 0px 4px rgba(154, 162, 185, 0.15),\n    0px 3px 10px rgba(228, 234, 244, 0.32)',
  borderBottomLeftRadius: '4px',
  borderBottomRightRadius: '4px',
  padding: '0',
});

export const List = styled('ul', {
  overflowY: 'auto',
  maxHeight: '200px',
  listStyle: 'none',
});

export const ListItem: StyledComponent = styled('li', {
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',
  padding: '$8 $12',
  fontSize: '$14',
  borderTop: '1px solid $gray300',
  cursor: 'pointer',

  '&:first-child': {
    borderTop: 0,
  },

  variants: {
    empty: {
      true: {
        color: '$slate400',
        fontSize: '0.85rem',
      },
    },
    highlighted: {
      true: {
        backgroundColor: '$iris300',
      },
    },
    selected: {
      true: {
        fontWeight: '$600',
      },
    },
  },
});

export const SelectedOptionsAndInput = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '0.25rem',
  minWidth: '0',
  cursor: 'default',
  padding: '0.5rem',
  backgroundColor: '$gray200',
  borderTopLeftRadius: '$4',
  borderTopRightRadius: '$4',

  '& > .selected-item': {
    fontSize: '0.8rem',
    padding: '0 0.25rem',
    cursor: 'pointer',
  },
});

export const Search = styled('input', {
  minWidth: '3.75rem',
  background: 'transparent',
  outline: 'none',
  fontSize: '0.9rem',
  fontWeight: '$300',
  borderTopLeftRadius: '$4',
  borderTopRightRadius: '$4',
});
