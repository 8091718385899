import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
  getApiSession,
  postApiLogin,
  LoginBodyModel,
  postApiLogout,
  postApiResetPassword,
  ResetPasswordBodyModel,
} from '@/models/api';

const POLL_FREQUENCY = 600000; // 10 minutes
export const currentSessionKey = 'currentSessionKey';

export function useCurrentSession() {
  return useQuery({
    queryKey: [currentSessionKey],
    queryFn: async () => getApiSession(),
    select: (response) => response.data,
    suspense: true,
    refetchInterval: POLL_FREQUENCY,
  });
}

export function useLogin() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (body: LoginBodyModel) => {
      return postApiLogin(body);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: currentSessionKey });
    },
  });

  return {
    ...mutation,
    login: mutation.mutateAsync,
  };
}

export function useLogout() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => {
      return postApiLogout();
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: currentSessionKey });
    },
  });

  return {
    ...mutation,
    logout: mutation.mutateAsync,
  };
}

export function useResetPassword() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (body: ResetPasswordBodyModel) => {
      return postApiResetPassword(body);
    },
    onSettled: () => {
      queryClient.invalidateQueries({ queryKey: currentSessionKey });
    },
  });

  return {
    ...mutation,
    resetPassword: mutation.mutateAsync,
  };
}
