import React from 'react';
import { styled } from '@/stitches.config';
import { Button } from '@/components/buttons/button';
import { TagModel } from '@/models/api';
import { Dot } from './severity-dot';
import { CloseIcon, TagIcon } from '../icons';
import { Tooltip } from './tooltip';
import { Strong, Body } from './text';

type TagProps = {
  tag: TagModel;
  onRemove?: (tag: TagModel) => Promise<void>;
  isDisabled?: boolean;
  truncate?: boolean;
};

export function Tag({ tag, onRemove, isDisabled = false, truncate = true }: TagProps) {
  const tagStyle = truncate ? {} : { maxWidth: 'auto ' };
  return (
    <Tooltip label={<TagDescription {...tag} />}>
      <StyledTag css={tagStyle}>
        <Dot css={{ backgroundColor: isDisabled ? '$slate100' : tag.color }} />
        <span className="ellipsis">{tag.name}</span>
        {onRemove && (
          <TagDeleteBtn
            circle
            onClick={async (e: Event) => {
              e.preventDefault();
              e.stopPropagation();
              await onRemove(tag);
            }}>
            <CloseIcon />
          </TagDeleteBtn>
        )}
      </StyledTag>
    </Tooltip>
  );
}

function TagDescription({ name, description, color }: TagModel) {
  if (!description) {
    return (
      <Body size={2} color="black">
        {name}
      </Body>
    );
  }

  return (
    <>
      <TagNameLine>
        <StyledTagIcon css={{ color }} />
        <Strong size={2}>{name}</Strong>
      </TagNameLine>
      <Body size={2} color="black">
        {description}
      </Body>
    </>
  );
}

const TagNameLine = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$4',
  marginBottom: '$4',
});

const StyledTagIcon = styled(TagIcon, {
  transform: 'rotate(90deg)',
});

const TagDeleteBtn = styled(Button, {
  compoundVariants: [
    {
      color: 'primary',
      circle: 'true',
      css: {
        opacity: '0',
        width: '14px',
        height: '14px',
        fontSize: '10px',
        position: 'relative',
        top: '-8px',
        right: '-14px',
        transition: '.2s linear',
      },
    },
  ],
});

const StyledTag = styled('span', {
  display: 'inline-flex',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0.1rem 0.5rem',
  borderRadius: '4px',
  backgroundColor: '$white',
  border: '1px solid rgba(196, 196, 196, 0.5)',
  boxShadow: '0px 1px 4px rgba(224, 224, 224, 0.15), 0px 1px 10px rgba(228, 234, 244, 0.2)',
  maxWidth: '10rem',
  minWidth: '3.8rem',
  fontSize: '0.8rem',

  '&:hover > button': {
    opacity: '1',
    transition: '.2s linear',
  },
});
