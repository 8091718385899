import React from 'react';
import { IdentityHoverCard } from '@/components/entity-cards/identity-hover-card';
import { GroupHoverCard } from '@/components/entity-cards/group-hover-card';
import { AccountHoverCard } from '@/components/entity-cards/account-hover-card';
import { AssetHoverCard } from '@/components/entity-cards/asset-hover-card';
import { HoverCardContent } from './entity-card-styles';
import { AsyncBoundary } from '../bounderies/async-boundary';

const OBJECT_TYPES = {
  Identity: 'identity',
  Account: 'account',
  Group: 'group',
  Asset: 'asset',
};

export function EntityHoverCard({ entity }) {
  return (
    <HoverCardContent onClick={(e) => e.stopPropagation()}>
      <AsyncBoundary>
        <EntityHoverCardInner entity={entity} />
      </AsyncBoundary>
    </HoverCardContent>
  );
}

function EntityHoverCardInner({ entity }) {
  switch (entity.object) {
    case OBJECT_TYPES.Identity:
      return <IdentityHoverCard identity={entity} />;
    case OBJECT_TYPES.Account:
      return <AccountHoverCard account={entity} />;
    case OBJECT_TYPES.Group:
      return <GroupHoverCard group={entity} />;
    case OBJECT_TYPES.Asset:
      return <AssetHoverCard asset={entity} />;
    default:
      console.warn(
        `EntityHoverCard:: Unrecognized entity to render. Valid objectType are: ${Object.values(
          OBJECT_TYPES
        )}`,
        entity
      );
      return null;
  }
}
