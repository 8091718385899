import React from 'react';
import { Link } from 'react-router-dom';
import MarkdownToJSX from 'markdown-to-jsx';
import buildTemplate from '@/lib/simple-template';
import { DistanceTime } from '@/components/date-time';

export function Markdown({ content, data = {} }) {
  if (!content) {
    return null;
  }

  return (
    <MarkdownToJSX
      children={buildTemplate(content, data)}
      options={{
        overrides: {
          a: {
            component: ({ href, ...props }) =>
              href.startsWith('/') ? (
                <Link {...props} to={href} />
              ) : (
                <a {...props} href={href} target="_blank" />
              ),
          },
          DistanceTime: {
            component: DistanceTime,
          },
        },
      }}
    />
  );
}
