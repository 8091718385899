import React from 'react';
import { Input } from '@/components/inputs/input';
import { css } from '@stitches/react';
import { cx } from '@/stitches.config';
import { IconButton } from '@/components/buttons/icon-button';
import { EyeIcon, EyeOffIcon } from '@/icons';

export default function ChangePasswordFormFields({
  plainTextFields,
  passwordsState,
  handleInputPasswordsChanged,
  togglePasswordVisibility,
}) {
  return (
    <>
      <label>Old password</label>
      <div className={cx(inputContainerStyle)}>
        <Input
          type={plainTextFields.includes('oldPassword') ? 'text' : 'password'}
          name="oldPassword"
          value={passwordsState.oldPassword}
          onChange={handleInputPasswordsChanged}
          placeholder="Old Password"
          autoComplete="new-password"
        />
        <IconButton
          className={cx(eyeStyle)}
          onClick={() => togglePasswordVisibility('oldPassword')}>
          {plainTextFields.includes('oldPassword') ? <EyeIcon /> : <EyeOffIcon />}
        </IconButton>
      </div>
      <label>New password</label>
      <div className={cx(inputContainerStyle)}>
        <Input
          type={plainTextFields.includes('newPassword') ? 'text' : 'password'}
          name="newPassword"
          onChange={handleInputPasswordsChanged}
          value={passwordsState.newPassword}
          placeholder="New Password"
          autoComplete="new-password"
        />
        <IconButton
          className={cx(eyeStyle)}
          onClick={() => togglePasswordVisibility('newPassword')}>
          {plainTextFields.includes('newPassword') ? <EyeIcon /> : <EyeOffIcon />}
        </IconButton>
      </div>
      <label>Confirm new password</label>
      <div className={cx(inputContainerStyle)}>
        <Input
          type={plainTextFields.includes('confirmPassword') ? 'text' : 'password'}
          name="confirmPassword"
          onChange={handleInputPasswordsChanged}
          value={passwordsState.confirmPassword}
          placeholder="Confirm New Password"
          autoComplete="new-password"
        />
        <IconButton
          className={cx(eyeStyle)}
          onClick={() => togglePasswordVisibility('confirmPassword')}>
          {plainTextFields.includes('confirmPassword') ? <EyeIcon /> : <EyeOffIcon />}
        </IconButton>
        <div />
      </div>
    </>
  );
}

const inputContainerStyle = css({
  alignItems: 'center',
  display: 'flex',
  position: 'relative',
  marginBottom: '1.5rem',
  '& > input': {
    width: '100%',
    marginBottom: '0 !important',
  },
});

const eyeStyle = css({
  position: 'absolute',
  right: '0.875rem',
  top: '50%',
  transform: 'translateY(-50%)',
  border: '1px',

  '& svg': {
    width: '1rem',
    height: '1rem',
  },

  '&:hover': {
    color: '$iris800',
  },
});
