/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Authomize Application API
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  SessionModel,
  LoginBodyModel,
  PostApiLoginPre200,
  LoginPreBodyModel,
  ResetPasswordModel,
  ResetPasswordBodyModel,
} from '../api.schemas';

export const getApiSession = <TData = AxiosResponse<SessionModel>>(
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/session`, options);
};
export const postApiLogin = <TData = AxiosResponse<SessionModel>>(
  loginBodyModel: LoginBodyModel,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/api/login`, loginBodyModel, options);
};
export const postApiLogout = <TData = AxiosResponse<void>>(
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/api/logout`, undefined, options);
};
export const postApiLoginPre = <TData = AxiosResponse<PostApiLoginPre200>>(
  loginPreBodyModel: LoginPreBodyModel,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/api/login/pre`, loginPreBodyModel, options);
};
export const postApiResetPassword = <TData = AxiosResponse<ResetPasswordModel>>(
  resetPasswordBodyModel: ResetPasswordBodyModel,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/api/reset-password`, resetPasswordBodyModel, options);
};
