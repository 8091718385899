import React, { PropsWithChildren, ReactElement } from 'react';
import * as DropdownMenu from '@radix-ui/react-dropdown-menu';
import { styled, keyframes } from '@/stitches.config';
import { ThreeDotsIcon } from '../icons';
import { Button } from './buttons/button';

type ItemType = {
  text: string;
  link?: string;
  icon?: ReactElement;
  isDisabled?: boolean;
  onSelect: () => void;
};

type RenderItem = (
  props: PropsWithChildren<{
    item: ItemType;
    onSelect: (item: ItemType) => void;
    isDisabled?: boolean;
  }>
) => ReactElement;

type ActionsMenuProps = {
  triggerButton?: ReactElement;
  renderItem?: RenderItem;
  items: ItemType[];
};

export function ActionsMenu({
  triggerButton = defaultTriggerButton,
  renderItem: Item,
  items,
}: ActionsMenuProps) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger asChild onClick={(event) => event.stopPropagation()}>
        {triggerButton}
      </DropdownMenu.Trigger>
      <DropdownMenu.Portal>
        <StyledContent align="end">
          {items.map((item) =>
            Item ? (
              <Item
                key={item.text}
                item={item}
                isDisabled={item.isDisabled}
                onSelect={item.onSelect}>
                {item.icon} {item.text}
              </Item>
            ) : (
              <StyledItem
                key={item.text}
                disabled={item.isDisabled}
                onClick={(event) => event.stopPropagation()}
                onSelect={item.onSelect}>
                {item.icon} {item.text}
              </StyledItem>
            )
          )}
          <StyledArrow />
        </StyledContent>
      </DropdownMenu.Portal>
    </DropdownMenu.Root>
  );
}

const slideUpAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideRightAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(-2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const slideDownAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateY(-2px)' },
  '100%': { opacity: 1, transform: 'translateY(0)' },
});

const slideLeftAndFade = keyframes({
  '0%': { opacity: 0, transform: 'translateX(2px)' },
  '100%': { opacity: 1, transform: 'translateX(0)' },
});

const StyledItem = styled(DropdownMenu.Item, {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  color: '$black',
  fontSize: '0.875rem',
  background: 'transparent',
  borderBottom: '1px solid $gray400',
  padding: '0.5rem 0.5rem',
  textAlign: 'left',
  borderLeft: '1px solid $gray400',
  borderRight: '1px solid $gray400',

  '&:first-of-type': {
    borderTop: '1px solid $gray400',
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',
  },

  '&:last-of-type': {
    borderBottom: '1px solid $gray400',
    borderBottomRightRadius: '4px',
    borderBottomLeftRadius: '4px',
  },

  '&:focus': {
    background: 'rgba(66, 102, 255, 0.1)',
  },

  '&[data-disabled]': {
    color: '$slate400',
    cursor: 'default',

    '& svg': {
      opacity: '0.3',
    },
  },

  '& svg': {
    width: '1rem',
    maxHeight: '1.25rem',
    marginRight: '0.5rem',
  },
});

const StyledContent = styled(DropdownMenu.Content, {
  background: '$white',
  borderRadius: '4px',
  minWidth: '12rem',
  zIndex: '100',
  boxShadow: '0px 4px 4px rgba(200, 200, 200, 0.15), 0px 0px 10px 1px rgba(203, 205, 208, 0.3)',
  overflow: 'hidden',

  '@media (prefers-reduced-motion: no-preference)': {
    animationDuration: '400ms',
    animationTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
    animationFillMode: 'forwards',
    willChange: 'transform, opacity',
    '&[data-state="open"]': {
      '&[data-side="top"]': { animationName: slideDownAndFade },
      '&[data-side="right"]': { animationName: slideLeftAndFade },
      '&[data-side="bottom"]': { animationName: slideUpAndFade },
      '&[data-side="left"]': { animationName: slideRightAndFade },
    },
  },
});

const StyledArrow = styled(DropdownMenu.Arrow, {
  fill: '$gray400',
});

const defaultTriggerButton = (
  <Button color="gray" kind="text" circle>
    <ThreeDotsIcon />
  </Button>
);
