/**
 * This module manage analytics sent to GoogleAnalytics and MixPanel
 */
import { datadogRum } from '@datadog/browser-rum';
import mixpanel from 'mixpanel-browser';
import { isLimitedReviewerUser, isNotAuthomizeEmail, isNotLocal } from './auth';

// Our config is stored in global "_CONFIG" variable
const config = window._CONFIG;
let isAnalyticsInitilized = false;

// Use this method to initilize analytics scripts
export function init() {
  isAnalyticsInitilized = true;

  mixpanel.init(config.MIXPANEL_TOKEN, { ip: false, debug: true });

  // init datadog logs
  datadogRum.init({
    applicationId: config.DD_APPLICATION_ID,
    clientToken: config.DD_CLIENT_ID,
    site: 'datadoghq.com',
    service: 'authomize-web-app',
    env: config.APP_ENV || 'production',
    // eslint-disable-next-line no-undef
    version: __APP_VERSION__,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100, // if not included, the default is 100
    trackUserInteractions: true,
    trackFrustrations: true,
    trackResources: true,
    trackLongTasks: true,
  });
}

export function setIdentity(userId, session) {
  if (!isAnalyticsInitilized) return;

  if (!userId) return;
  if (config.APP_ENV === 'production') {
    initDataDogRecording(userId, session);
  }
  if (isNotLocal(session) && isNotAuthomizeEmail(session)) {
    initMixpanel(userId, session);
    track('User login', { userId });
  } else {
    mixpanel.opt_out_tracking();
  }

  if (isNotLocal(session) && !isLimitedReviewerUser(session)) {
    initPendo(userId, session);
  }
}

function isMixpanelLoaded() {
  return isAnalyticsInitilized && mixpanel && mixpanel.get_distinct_id();
}

export function track(event, params) {
  const paramsWithGlobals = {
    ...params,
    page: window.location,
  };

  if (isMixpanelLoaded()) {
    mixpanel.track(event, { paramsWithGlobals });
  }
}

export function pageView(path) {
  if (isMixpanelLoaded()) {
    mixpanel.track('Page View', { path });
  }
}

function initDataDogRecording(userEmail, session) {
  datadogRum.setUser({
    id: userEmail,
    email: userEmail,
    customer: session.customerKey,
  });
  datadogRum.startSessionReplayRecording();
}

export function initPendo(userEmail, session) {
  window.pendo.initialize({
    visitor: {
      id: userEmail,
      email: userEmail,
      name: userEmail,
    },
    account: {
      id: session.customerKey,
    },
  });
}

export function initMixpanel(userEmail, session) {
  mixpanel.identify(userEmail);
  mixpanel.people.set({ $email: userEmail });
  if (session && userEmail) {
    mixpanel.people.set({
      name: ''.concat(session.firstName, ' ', session.lastName),
      email: userEmail,
      firstName: session.firstName,
      lastName: session.lastName,
      company: session.customerKey,
    });
    mixpanel.people.set_once('firstLoginDate', new Date().toISOString());
  }
}

export function resetIdentity() {
  if (isMixpanelLoaded()) {
    mixpanel.track('Logout');
    mixpanel.reset();
  }
}
