import { usePaginatedResource } from '@/hooks/use-paginated-resource';
import { useIncidentsList, usePaginatedIncidentsList } from '@/queries/use-incidents';
import IncidentResource from '@/resources/incidents/incident';
import React from 'react';
import { useResource } from 'rest-hooks';

export function withIncidentsV1(Component, params = null) {
  return (props) => {
    const { data: incidents, pagination } = useResource(IncidentResource.list(), params);

    return <Component {...props} incidents={incidents} pagination={pagination} />;
  };
}

export function withIncidentsV2(Component, params = null) {
  return (props) => {
    const { data } = useIncidentsList(params);

    const { data: incidents, pagination } = data || {};

    return <Component {...props} incidents={incidents} pagination={pagination} />;
  };
}

export function withPaginatedIncidentsV2(Component, params = null) {
  return (props) => {
    const {
      data: incidents,
      isLoading,
      error,
      pagination,
      hasNextPage,
      fetchNextPage,
    } = usePaginatedIncidentsList(params);

    return (
      <Component
        {...props}
        incidents={incidents}
        loading={isLoading}
        error={error}
        hasNextPage={hasNextPage}
        fetchMore={fetchNextPage}
        pagination={pagination}
      />
    );
  };
}

export function withPaginatedIncidentsV1(Component, params = null) {
  return (props) => {
    const {
      data: incidents,
      loading,
      error,
      pagination,
      fetchMore,
    } = usePaginatedResource(IncidentResource.list(), params);

    return (
      <Component
        {...props}
        incidents={incidents}
        loading={loading}
        error={error}
        fetchMore={fetchMore}
        hasNextPage={pagination.hasNextPage}
        pagination={pagination}
      />
    );
  };
}
