import React, { ErrorInfo, PropsWithChildren, ReactElement } from 'react';
import axios, { AxiosError } from 'axios';
import { theme } from '@/stitches.config';
import { Card } from '@/components/card';
import { DetailedErrorResponse } from '@/components/detailed-error-response';

type ErrorBoundaryState = {
  error: Error | string;
  errorInfo: ErrorInfo | string;
  hasError: boolean;
};

export class ErrorBoundary extends React.Component<PropsWithChildren, ErrorBoundaryState> {
  constructor(props: PropsWithChildren) {
    super(props);
    this.state = {
      error: '',
      errorInfo: '',
      hasError: false,
    };
  }
  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.log({ error, errorInfo });
    this.setState({ errorInfo });
  }
  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;

    if (hasError) {
      return (
        <Card>
          There was an error in loading this page.{' '}
          <span
            style={{ cursor: 'pointer', color: theme.colors.blue500 as unknown as string }}
            onClick={() => {
              window.location.reload();
            }}>
            Reload this page
          </span>
          <br />
          <DetailedError error={error as Error} />
        </Card>
      );
    }

    return children;
  }
}

type DetailedErrorProps = {
  error: Error | AxiosError;
};
function DetailedError({ error }: DetailedErrorProps) {
  let errorComponent: string | ReactElement = error.message || 'Unknown Error';

  if (axios.isAxiosError(error)) {
    errorComponent = <DetailedErrorResponse response={error.response} />;
  }

  return (
    <details>
      <summary>Details</summary>
      {errorComponent}
    </details>
  );
}
