import { useState } from 'react';

export function useColumnsDragNDrop({ columns, setColumns }) {
  const [cols, setCols] = useState(columns);
  const [dragOverColumnId, setDragOverColumnId] = useState('');

  const handleDragStart = (e, dragIdCol) => {
    const idx = cols.findIndex(({ id }) => id === dragIdCol);

    e.dataTransfer.setData('colIdx', idx);
  };

  const handleDragOver = (e) => e.preventDefault();

  const handleOnDrop = (e, dragIdCol) => {
    const droppedColIdx = cols.findIndex(({ id }) => id === dragIdCol);

    const draggedColIdx = e.dataTransfer.getData('colIdx');
    const tempCols = [...cols];

    tempCols[draggedColIdx] = cols[droppedColIdx];
    tempCols[droppedColIdx] = cols[draggedColIdx];
    setCols(tempCols);

    setDragOverColumnId('');

    setColumns(tempCols);
  };

  return {
    handleDragStart,
    handleDragOver,
    handleOnDrop,
    handleDragEnter: (id) => setDragOverColumnId(id),
    setDragOverColumnId,
    dragOverColumnId,
  };
}
