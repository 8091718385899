import React from 'react';
import { getValue, Label, ThreeRowsGrid, Value } from '@/components/slide-over/helpers';
import { Tooltip } from '@/components/tooltip';
import { TagsList } from '@/components/tags-list';
import { AppIdsIconsList } from '@/components/app-icons-list';

export function IdentityOverview({ entity }) {
  return (
    <ThreeRowsGrid>
      <div>
        <Label>Name</Label>
        <Value>
          {entity.name ? (
            <Tooltip label={entity.name}>
              <span>{entity.name}</span>
            </Tooltip>
          ) : (
            '-'
          )}
        </Value>
      </div>

      <div>
        <Label>Email</Label>
        <Value>
          {entity.email ? (
            <Tooltip label={entity.email}>
              <span>{entity.email}</span>
            </Tooltip>
          ) : (
            '-'
          )}
        </Value>
      </div>

      <div>
        <Label>Title</Label>
        <Value>
          {entity.title ? (
            <Tooltip label={entity.title}>
              <span>{entity.title}</span>
            </Tooltip>
          ) : (
            '-'
          )}
        </Value>
      </div>

      <div>
        <Label>Email</Label>
        <Value>
          {entity.email ? (
            <Tooltip label={entity.email}>
              <span>{entity.email}</span>
            </Tooltip>
          ) : (
            '-'
          )}
        </Value>
      </div>

      <div>
        <Label>Department</Label>
        <Value>
          {entity.department ? (
            <Tooltip label={entity.department}>
              <span>{entity.department}</span>
            </Tooltip>
          ) : (
            '-'
          )}
        </Value>
      </div>

      <div>
        <Label>Incidents</Label>
        <Value>{getValue(entity.openIncidentsCount)}</Value>
      </div>

      <div>
        <Label>Accounts</Label>
        <Value>
          <AppIdsIconsList
            appIds={entity?.sourceAppIds || entity?.sourceApps?.map(({ id }) => id)}
            maxLimit={5}
          />
        </Value>
      </div>

      <div>
        <Label>Tags</Label>
        <TagsList tags={entity.tags} maxLimit={3} />
      </div>
    </ThreeRowsGrid>
  );
}
