import React, { useCallback, useState } from 'react';
import { Button } from '@/components/buttons/button';
import { TimeIcon } from '@/icons';
import { styled } from '@/stitches.config';
import { formatISO, subHours } from 'date-fns';
import { useQueryParams } from '@/hooks';
import { Dialog } from '@/components/dialog';
import { DATEPICKER_CLASSES } from '@/components/date-pickers/constants';
import { DayPicker } from 'react-day-picker';
import { DatePickerContainer } from '@/components/date-pickers/dates-common-styles';
import { defaultTheme, Provider, TimeField } from '@adobe/react-spectrum';
import { FormFieldName } from '@/components/form-field';
import { Time } from '@internationalized/date';
import * as Select from '@radix-ui/react-select';

const NONE = 'none';
const CUSTOM = 'custom';

export function HighlightEdgesByTimeAdded() {
  const { queryParams, updateQueryParams } = useQueryParams();

  const [selectedReadableValue, setSelectedReadableValue] = useState(
    queryParams.start && queryParams.end ? CUSTOM : NONE
  );
  const [showCustomModal, setShowCustomModal] = useState(false);

  const handleIntervalSelection = useCallback(
    (intervalReadableValue) => {
      setSelectedReadableValue(intervalReadableValue);

      if (intervalReadableValue === NONE) {
        updateQueryParams({
          ...queryParams,
          start: undefined,
          end: undefined,
        });
        return;
      }

      if (intervalReadableValue === CUSTOM) {
        setShowCustomModal(true);
        return;
      }

      updateQueryParams({
        ...queryParams,
        start: subHours(new Date(), intervalReadableValue),
        end: new Date(),
      });
    },
    [queryParams, updateQueryParams]
  );

  return (
    <div style={{ position: 'relative', zIndex: '10' }}>
      <Select.Root value={selectedReadableValue} onValueChange={handleIntervalSelection}>
        <Trigger>
          <Select.Value>
            <Button
              color={selectedReadableValue !== NONE ? 'primary' : 'gray'}
              circle
              size="lg"
              css={{ zIndex: '$4' }}>
              <TimeIcon style={{ width: '20px', height: '20px' }} />
            </Button>
          </Select.Value>
        </Trigger>
        <Select.Portal>
          <Content align="start" side="right" position="popper" sideOffset={0}>
            <TimeIcon style={{ width: '20px', height: '20px', stroke: '#4266FF' }} />
            <Select.Item value={NONE}>
              <Button
                color={selectedReadableValue === NONE ? 'primary' : 'gray'}
                css={{ fontSize: selectedReadableValue === NONE ? '$14' : '$12' }}
                kind="text">
                None
              </Button>
            </Select.Item>
            <Select.Item value={24}>
              <Button
                color={selectedReadableValue === 24 ? 'primary' : 'gray'}
                css={{ fontSize: selectedReadableValue === 24 ? '$14' : '$12' }}
                kind="text">
                24h
              </Button>
            </Select.Item>
            <Select.Item value={48}>
              <Button
                color={selectedReadableValue === 48 ? 'primary' : 'gray'}
                css={{ fontSize: selectedReadableValue === 48 ? '$14' : '$12' }}
                kind="text">
                48h
              </Button>
            </Select.Item>
            <Select.Item value={72}>
              <Button
                color={selectedReadableValue === 72 ? 'primary' : 'gray'}
                css={{ fontSize: selectedReadableValue === 72 ? '$14' : '$12' }}
                kind="text">
                72h
              </Button>
            </Select.Item>
            <Select.Item value={CUSTOM}>
              <Button
                color={selectedReadableValue === CUSTOM ? 'primary' : 'gray'}
                css={{ fontSize: selectedReadableValue === CUSTOM ? '$14' : '$12' }}
                kind="text">
                Custom
              </Button>
            </Select.Item>
          </Content>
        </Select.Portal>
      </Select.Root>
      {showCustomModal && (
        <IntervalDateTimePicker
          onCancel={() => {
            setSelectedReadableValue(NONE);
            setShowCustomModal(false);
          }}
          onSave={({ start, end }) => {
            updateQueryParams({ ...queryParams, start, end });
            setSelectedReadableValue(CUSTOM);
            setShowCustomModal(false);
          }}
        />
      )}
    </div>
  );
}

const Trigger = styled(Select.Trigger, {
  background: 'transparent',
});

const Content = styled(Select.Content, {
  display: 'flex',
  flexDirection: 'row !important',
  margin: '-1px 0 0 -40px',
  backgroundColor: '$white',
  borderRadius: '24px',
  border: '1px solid $slate200',
  padding: '$4 $12 $4 10px',
  justifyContent: 'center',
  alignItems: 'center',
});

const disabledDays = [{ after: new Date() }];

function IntervalDateTimePicker({ onCancel, onSave }) {
  const { queryParams } = useQueryParams();

  const defaultStartDate = queryParams.start
    ? new Date(queryParams.start)
    : new Date(new Date().setHours(0, 0, 0, 0));

  const defaultEndDate = queryParams.end
    ? new Date(queryParams.end)
    : new Date(new Date().setHours(23, 59, 59, 59));

  const [customInterval, setCustomInterval] = useState({
    from: defaultStartDate,
    to: defaultEndDate,
  });
  const [intervalStartTime, setIntervalStartTime] = useState(
    new Time(
      defaultStartDate.getHours(),
      defaultStartDate.getMinutes(),
      defaultStartDate.getSeconds()
    )
  );
  const [intervalEndTime, setIntervalEndTime] = useState(
    new Time(defaultEndDate.getHours(), defaultEndDate.getMinutes(), defaultEndDate.getSeconds())
  );

  const handleCustomIntervalSelection = useCallback(({ from, to }) => {
    setCustomInterval({
      from,
      to,
    });
  }, []);

  const handleSave = useCallback(() => {
    const start = new Date(
      customInterval.from.getFullYear(),
      customInterval.from.getMonth(),
      customInterval.from.getDate(),
      intervalStartTime.hour,
      intervalStartTime.minute
    );
    const end = new Date(
      customInterval.to.getFullYear(),
      customInterval.to.getMonth(),
      customInterval.to.getDate(),
      intervalEndTime.hour,
      intervalEndTime.minute
    );

    onSave({
      start: formatISO(start),
      end: formatISO(end),
    });
  }, [
    customInterval.from,
    customInterval.to,
    intervalEndTime.hour,
    intervalEndTime.minute,
    intervalStartTime.hour,
    intervalStartTime.minute,
    onSave,
  ]);

  return (
    <Dialog
      title="Select Interval"
      onClose={onCancel}
      width="43rem"
      footerComponent={
        <>
          <Button kind="text" color="gray" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="submit" onClick={handleSave}>
            Apply
          </Button>
        </>
      }>
      <DatePickerContainer>
        <DayPicker
          numberOfMonths={2}
          mode="range"
          modifiersClassNames={DATEPICKER_CLASSES}
          selected={customInterval}
          onSelect={handleCustomIntervalSelection}
          disabled={disabledDays}
        />
        <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
          <div style={{ width: '50%' }}>
            <Provider
              theme={defaultTheme}
              UNSAFE_style={{ background: 'transparent', padding: '0 24px', marginBottom: '20px' }}>
              <TimeField
                label={<FormFieldName>Start Time</FormFieldName>}
                value={intervalStartTime}
                UNSAFE_style={{
                  background: 'transparent',
                }}
                onChange={(value) => {
                  setIntervalStartTime(value);
                }}
              />
            </Provider>
          </div>
          <div style={{ width: '50%' }}>
            <Provider
              theme={defaultTheme}
              UNSAFE_style={{ background: 'transparent', padding: '0 24px', marginBottom: '20px' }}>
              <TimeField
                label={<FormFieldName>End Time</FormFieldName>}
                value={intervalEndTime}
                UNSAFE_style={{
                  background: 'transparent',
                }}
                onChange={(value) => {
                  setIntervalEndTime(value);
                }}
              />
            </Provider>
          </div>
        </div>
      </DatePickerContainer>
    </Dialog>
  );
}
