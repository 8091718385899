import { styled } from '@stitches/react';

export const BreakdownItem = styled('li', {
  display: 'flex',
  fontSize: '0.75rem',
  margin: '2px 0',

  alignItems: 'center',

  '& .label': {
    width: '4rem',
    color: '$slate600',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  '& .value': {
    flex: '1',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  '& .value-with-indicator': {
    display: 'flex',
    gap: '$6',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& .value': {
      textTransform: 'capitalize',
    },

    '& .indicator': {
      width: '0.75rem',
      height: '0.75rem',
      lineHeight: '0.75rem',
      backgroundColor: 'transparent',
    },
  },
});
