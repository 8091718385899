/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Authomize Application API
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  GetApiConfig200,
  PutApiConfigFeatureFlags200,
  PutApiConfigFeatureFlagsBody,
} from '../api.schemas';

export const getApiConfig = <TData = AxiosResponse<GetApiConfig200>>(
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/config`, options);
};
export const putApiConfigFeatureFlags = <TData = AxiosResponse<PutApiConfigFeatureFlags200>>(
  putApiConfigFeatureFlagsBody: PutApiConfigFeatureFlagsBody,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.put(`/api/config/feature-flags`, putApiConfigFeatureFlagsBody, options);
};
