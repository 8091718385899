import { useCallback, useEffect, useMemo } from 'react';
import { css } from '@/stitches.config';
import storage from '@/lib/storage';
import { useDebouncedCallback } from 'use-debounce';

export const RESIZER_CLASS_NAME = 'resizer';

export const resizerStyles = css({
  thead: {
    tr: {
      th: {
        position: 'relative',

        [`.${RESIZER_CLASS_NAME}`]: {
          position: 'absolute',
          top: '0',
          right: '0',
          width: '7px',
          height: '100%',
          cursor: 'col-resize',
          userSelect: 'none',
          backgroundColor: '$white',
          border: '3px solid $white',
          zIndex: '1',

          '&:hover': {
            backgroundColor: '$gray400',
            border: '2px solid $white',
            transition: 'all 0.1s linear',
            cursor: 'col-resize',
          },
        },
      },
    },

    [`&:hover .${RESIZER_CLASS_NAME}`]: {
      backgroundColor: '$gray400',
      transition: 'all 0.1s linear',
    },
  },
});

export function useColumnsResize(tableRef, columns, tableId = 'identities-table') {
  const columnsInitialSizes = useMemo(() => {
    const config = storage.get(tableId);

    return config?.columnsSizes || {};
  }, [tableId]);

  const updateColumnsSizesInStorage = useDebouncedCallback((id, size) => {
    const config = storage.get(tableId) || {};

    storage.set(tableId, {
      ...config,
      columnsSizes: {
        ...(config?.columnsSizes || {}),
        [id]: size,
      },
    });
  }, 100);

  const createResizableColumn = useCallback(
    (col, resizer) => {
      // Track the current position of mouse
      let x = 0;
      let w = 0;

      const mouseDownHandler = (e) => {
        e.preventDefault();
        // Get the current mouse position
        x = e.clientX;

        // Calculate the current width of column
        const styles = window.getComputedStyle(col);
        w = parseInt(styles.width, 10);

        // Attach listeners for document's events
        document.addEventListener('mousemove', mouseMoveHandler);
        document.addEventListener('mouseup', mouseUpHandler);
      };

      const mouseMoveHandler = (e) => {
        // Determine how far the mouse has been moved
        const dx = e.clientX - x;

        const minWidth = parseInt(col.style.minWidth, 10) || 70;

        // Update the width of column
        col.style.width = `${Math.max(w + dx, minWidth)}px`;
        updateColumnsSizesInStorage(col.id, Math.max(w + dx, minWidth));
      };

      // When user releases the mouse, remove the existing event listeners
      const mouseUpHandler = () => {
        document.removeEventListener('mousemove', mouseMoveHandler);
        document.removeEventListener('mouseup', mouseUpHandler);
      };

      resizer.addEventListener('mousedown', mouseDownHandler);
    },
    [updateColumnsSizesInStorage]
  );

  useEffect(() => {
    if (!tableRef.current) {
      return;
    }
    // Query the table
    const table = tableRef.current;

    // Query all headers
    const cols = table.querySelectorAll('th');

    // Loop over them
    [].forEach.call(cols, (col) => {
      // Create a resizer element
      const resizer = col.querySelector(`.${RESIZER_CLASS_NAME}`);

      if (resizer) {
        createResizableColumn(col, resizer);
      }
    });
  }, [createResizableColumn, tableRef]);

  return columnsInitialSizes;
}
