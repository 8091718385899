import React, { useMemo, useState } from 'react';
import { NavLink as Link } from 'react-router-dom';
import qs from 'qs';
import * as Collapsible from '@radix-ui/react-collapsible';
import {
  MenuItem,
  MenuItemTrigger,
  SidebarMenuItem,
  SidebarMenuSubItem,
} from '@/views/layout/components/sidebar/sidebar-styles';
import { ArrowRightIcon, ChevronDownIcon } from '@/icons';
import { styled } from '@stitches/react';

const SIDEBAR_ITEM_OPEN_LOCAL_STORAGE_KEY = 'authomize-sidebar-item-opened';

export function NavItem({
  path,
  query,
  icon,
  title,
  end,
  disabled,
  items = [],
  isMenuOpen = false,
}) {
  const filteredItems = items.filter(Boolean);
  if (filteredItems.length > 0) {
    return (
      <NavMenu
        icon={icon}
        title={title}
        items={filteredItems}
        isMenuOpen={isMenuOpen}
        end={end}
        path={path}
        query={query}
      />
    );
  }

  return (
    <NavLink
      path={path}
      query={query}
      icon={icon}
      title={title}
      isMenuOpen={isMenuOpen}
      end={end}
      disabled={disabled}
    />
  );
}

function NavMenu({ icon: Icon, title, items = [], isMenuOpen = false }) {
  const lsKey = useMemo(() => `${SIDEBAR_ITEM_OPEN_LOCAL_STORAGE_KEY}-${title}`, [title]);
  const defaultValue = localStorage.getItem(lsKey) !== 'false';

  const [open, setOpen] = useState(defaultValue);

  return (
    <MenuItem
      open={open}
      onOpenChange={(isOpened) => {
        localStorage.setItem(lsKey, isOpened);

        setOpen(isOpened);
      }}
      defaultOpen={defaultValue}>
      {isMenuOpen && (
        <MenuItemTrigger asChild>
          <span className="pseudo-link">
            <Icon />
            <span>{title}</span>
            <ChevronDownIcon className="chevron-down" />
            <ArrowRightIcon className="chevron-right" />
          </span>
        </MenuItemTrigger>
      )}

      <SubMenu open={isMenuOpen}>
        {items.map((item) => (
          <SidebarMenuSubItem key={title + item.title}>
            <Link
              to={{
                pathname: item.path,
                search: item?.query ? `?${qs.stringify(item.query)}` : '',
              }}
              className={({ isActive }) => `sidebar-menu-sublink ${isActive ? 'active' : ''}`}
              end={false}
              onClick={(e) => item?.disabled && e.preventDefault()}>
              {isMenuOpen && <span>{item.title}</span>}
            </Link>
          </SidebarMenuSubItem>
        ))}
      </SubMenu>
    </MenuItem>
  );
}

function NavLink({ path, query, icon: Icon, title, disabled, isMenuOpen }) {
  return (
    isMenuOpen && (
      <SidebarMenuItem>
        <Link
          to={{ pathname: path, search: query ? `?${qs.stringify(query)}` : '' }}
          end={false}
          className={({ isActive }) => {
            return isActive ? 'active' : '';
          }}
          onClick={(e) => {
            if (disabled) e.preventDefault();
          }}>
          <Icon />
          {isMenuOpen && <span>{title}</span>}
        </Link>
      </SidebarMenuItem>
    )
  );
}

const SubMenu = styled(Collapsible.Content, {
  height: '0',
  overflow: 'hidden',

  variants: {
    open: {
      true: {
        height: 'auto',
      },
      false: {
        opacity: 0,
      },
    },
  },
});
