import React from 'react';
import { styled } from '@/stitches.config';
import { Button } from '@/components/buttons/button';
import { Tooltip } from '@/components/tooltip';
import { SourceIcon } from '@/components/icons/sources';
import { PlusIcon } from '@/icons';

export function RecommendedAppItem({
  name,
  displayName,
  connectorName,
  serviceId,
  serviceType,
  onClick,
}) {
  return (
    <ConnectorContainer onClick={onClick}>
      <IconWrapper>
        <SourceIcon
          name={name}
          connectorName={connectorName}
          serviceId={serviceId}
          serviceType={serviceType}
        />
      </IconWrapper>

      <Tooltip label={displayName || name}>
        <h6>{displayName || name}</h6>
      </Tooltip>

      <ConnectAppButton size="sm" circle onClick={onClick}>
        <PlusIcon />
      </ConnectAppButton>
    </ConnectorContainer>
  );
}

const ConnectorContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  cursor: 'pointer',
  maxWidth: '11rem',
  height: '2.5rem',
  margin: '0.8rem',
  padding: '0.8rem',
  background: '$gray300',
  borderRadius: '0.25rem',
  alignItems: 'center',

  '&:hover': {
    background: '$iris200',
  },

  '& h6': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '0.8rem',
    lineHeight: '1.18rem',
    color: '$slate700',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
});

const IconWrapper = styled('div', {
  position: 'relative',
  minWidth: '1.5rem',
  height: '1.5rem',
  borderRadius: '50%',
  marginRight: '0.4rem',

  '& > svg': {
    display: 'inline-block',
    width: '100%',
    height: '100%',
    filter: 'grayscale(100%)',
  },
});

const ConnectAppButton = styled(Button, {
  position: 'absolute',
  transform: 'translate(40%, 40%)',
  bottom: '0',
  right: '0',
});
