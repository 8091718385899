import React, { useEffect, useState } from 'react';
import { styled } from '../stitches.config';

export function DetailedErrorResponse({ response }) {
  const [body, setBody] = useState(null);

  useEffect(() => {
    async function parseResponseBody() {
      if (response.body) {
        setBody(await response.json());
      }
    }
    parseResponseBody();
  }, [response]);

  return (
    <Table>
      <thead>
        <tr>
          <th>Key</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>URL</td>
          <td>{response.url}</td>
        </tr>
        <tr>
          <td>Status</td>
          <td>{response.status}</td>
        </tr>
        <tr>
          <td>Status Text</td>
          <td>{response.statusText}</td>
        </tr>
        <tr>
          <td>Body</td>
          <td>
            <pre>{JSON.stringify(body, 0, 2)}</pre>
          </td>
        </tr>
      </tbody>
    </Table>
  );
}

const Table = styled('table', {
  width: '100%',
  color: '#333',
  background: 'white',
  border: '1px solid grey',
  fontSize: '12pt',
  borderCollapse: 'collapse',

  'thead th': {
    color: '#777',
    background: 'rgba(0,0,0,.1)',
  },

  'th, td': {
    padding: '.5em',
    border: '1px solid lightgrey',
  },
});
