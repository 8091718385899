import { styled } from '@/stitches.config';
import { StyledComponent } from '@/models/common';

export const SearchInputBase: StyledComponent = styled('span', {
  position: 'relative',
  display: 'inline-block',

  variants: {
    maxWidth: {
      true: {
        width: '100%',
      },
    },
    color: {
      gray: {
        background: '$gray300',
        borderRadius: '$4',
        '& > input.search-input': {
          background: '$gray300',
        },
      },
    },
  },

  '&[data-disabled]': {
    '& .search-icon': {
      color: '$slate400',
    },
  },

  '& > .search-icon': {
    position: 'absolute',
    left: '0.5rem',
    top: '0',
    bottom: '0',
    width: '1rem',
    height: '1rem',
    margin: 'auto',
    color: 'var(--color-light-black)',
    pointerEvents: 'none',
  },

  '& > input.search-input': {
    width: '100%',
    paddingLeft: '1.75rem',
    paddingRight: '1.45rem',
  },

  '& > .reset-icon': {
    position: 'absolute',
    right: '0.4rem',
    top: '0',
    bottom: '0',
    width: '0.8rem',
    height: '0.8rem',
    margin: 'auto',
    color: 'var(--color-light-black)',
    cursor: 'pointer',

    '&:hover': {
      color: 'var(--color-black-color)',
    },
  },
});

export const StyledInput: StyledComponent = styled('input', {
  minWidth: '12rem',
  height: '2rem',
  background: '$white',
  borderRadius: '4px',
  fontSize: '1rem',
  outline: 'none',
  padding: '0.5rem',
  border: '1px solid $gray300',

  '&:focus,  &:hover': {
    border: '1px solid $iris800',
  },

  '&:disabled': {
    background: '$gray200',
    color: '$gray500',

    '&:hover': {
      border: '1px solid $gray300',
    },
  },
});
