import React from 'react';
import { styled } from '@/stitches.config';
import { LoadingDots } from '@/components/loading';
import { AlertCircleIcon } from '@/icons';
import { Tooltip } from '../tooltip';

export function TotalResults({ total, itemsLabel, itemLabel, maxLimit, warning }) {
  return (
    <TotalResultsCount>
      {total === undefined ? (
        <LoadingDots />
      ) : (
        <>
          {total === 0 && 'No Results'}
          {total === 1 && `Showing 1 ${itemLabel}`}
          {total > 1 && total < maxLimit && `Showing ${total.toLocaleString()} ${itemsLabel}`}
          {total > 1 && total >= maxLimit && `Showing 50K+ ${itemsLabel}`}
          {warning && (
            <Tooltip label={warning}>
              <span>
                <AlertCircleIcon />
              </span>
            </Tooltip>
          )}
        </>
      )}
    </TotalResultsCount>
  );
}

export const TotalResultsCount = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '$4',
  margin: '$16 $24 $4 $12',
  fontWeight: '$400',
  fontSize: '$14',
  letterSpacing: '0.1px',
  color: '$slate500',
  '>span': {
    lineHeight: '$16',
    color: '$yellow800',
  },
});
