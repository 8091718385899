import React from 'react';
import { css, cx } from '@/stitches.config';

const iconButtonStyle = css({
  background: 'transparent',
  lineHeight: '0',
  color: '$slate400',

  '&:hover': {
    '&:not(:disabled)': {
      color: '$black',
    },
  },

  '&:disabled': {
    cursor: 'auto',
  },

  '& svg': {
    width: '1.3em',
    height: '1.3em',
    pointerEvents: 'none',
  },
});

type IconButtonProps = {
  as?: 'string';
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  children: React.ReactNode;
  className?: string;
  props?: unknown;
};

export const IconButton = React.forwardRef<unknown, IconButtonProps>(
  ({ as = 'button', onClick, children, className, ...props }, ref) => {
    return React.createElement(
      as,
      {
        type: 'button',
        ref,
        className: cx(iconButtonStyle, className),
        onClick,
        ...props,
      },
      children
    );
  }
);
