import React from 'react';

import { styled } from '@/stitches.config';
import { Card } from '@/components/card';
import { Button } from '@/components/buttons/button';
import { AttachIcon, DeleteIcon, UploadIcon } from '@/icons';
import { ErrorInline } from '@/components/error';
import { useBranding } from '@/hooks/use-branding';

export function OrganizationLogoPreview({ onDeleteLogo, onChangeLogo }) {
  const { branding } = useBranding();
  const isPublicLogoUrl = Boolean(branding.publicLogoUrl);

  const downloadLink = isPublicLogoUrl ? branding.publicLogoUrl : branding.url;

  return (
    <LogoPreview>
      <Card className="settings-container">
        <p className="file-name">
          <AttachIcon className="attach-file-icon" />
          {branding.realLogoFileName}
        </p>
        {downloadLink ? (
          <img src={downloadLink} alt="" />
        ) : (
          <ErrorInline className="error-msg">
            Error has occurred while trying to fetch organization logo
          </ErrorInline>
        )}
        <hr />
        <Button color="gray" circle className="delete-btn" onClick={onDeleteLogo}>
          <DeleteIcon />
        </Button>
        <Button kind="outlined" className="upload-btn" onClick={onChangeLogo}>
          <UploadIcon className="upload-icon" />
          Replace File
        </Button>
      </Card>
    </LogoPreview>
  );
}

const LogoPreview = styled('div', {
  '& .settings-container': {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    width: '38rem',
    marginBottom: '2.5rem',
    marginLeft: '0',

    '& .file-name': {
      color: 'var(--color-black-text)',
      fontWeight: '300',

      '& svg.attach-file-icon': {
        position: 'relative',
        top: '2px',
        color: '$slate400',
      },
    },

    '& .loading': {
      display: 'flex',
      height: '4rem',
      marginTop: '20px',
      alignSelf: 'flex-start',
      fontSize: '26px',
    },

    '& img': {
      height: '4rem',
      marginTop: '20px',
      alignSelf: 'flex-start',
    },

    '& .error-msg': {
      marginTop: '1rem',
    },

    '& hr': {
      height: '1px',
      backgroundColor: '$gray300',
      margin: '20px -20px',
    },

    '& .delete-btn': {
      position: 'absolute',
      top: '21px',
      right: '21px',
      backgroundColor: 'var(--color-white)',
    },

    '& .upload-btn': {
      alignSelf: 'flex-end',

      '& svg': {
        height: '20px',
        width: '20px',
      },
    },
  },
});
