import React from 'react';
import { styled } from '@/stitches.config';

import { Markdown } from '@/components/markdown';
import { Small } from '@/components/text';
import { AddedRiskLabel } from './added-risk-label';

export function IncidentWidget({
  titleElement,
  children,
  score,
  riskDescriptionElement = null,
  content = null,
  description = null,
  zeroPadding = false,
  showRiskLabel = true,
}) {
  return (
    <IncidentsWidgetWrapper zeroPadding={zeroPadding}>
      {titleElement}
      <Small>{description}</Small>
      {content && (
        <MarkdownContainer>
          <Markdown content={content} />
        </MarkdownContainer>
      )}
      {children}
      {showRiskLabel && <AddedRiskLabel score={score} />}
      {Boolean(riskDescriptionElement) && riskDescriptionElement}
    </IncidentsWidgetWrapper>
  );
}

const IncidentsWidgetWrapper = styled('div', {
  width: '100%',
  backgroundColor: '$white',
  borderRadius: '$4',

  '& .entity-card': {
    width: '50%',
  },

  variants: {
    zeroPadding: {
      true: {
        padding: 0,
      },
    },
  },
});

const MarkdownContainer = styled('div', {
  color: '#57617d',
  fontWeight: '300',
  fontSize: '0.9rem',
  maxWidth: '100%',

  '& a': {
    color: 'var(--color-system-color)',

    '&:hover': {
      textDecoration: 'underline',
    },
  },

  '& h1,  h2,  h3,  h4,  h5,  h6': {
    marginBottom: '0.5rem',
  },

  '& table': {
    display: 'block',
    width: 'fit-content',
    maxWidth: '100%',
    overflowX: 'auto',
    wordBreak: 'break-all',
    borderCollapse: 'collapse',
    background: '#fff',

    '& th': {
      backgroundColor: '#f8f9fa',
    },

    '& th,    td': {
      padding: '0.5rem',
      textAlign: 'left',
      border: '1px solid #ccc',
    },
  },

  '& ul': {
    listStyle: 'disc',
    paddingLeft: '1rem',
    marginBottom: '1rem',
  },

  '& ol': {
    listStyle: 'decimal',
    paddingLeft: '1rem',
    marginBottom: '1rem',
  },
});
