import { SlideOver } from '@/components/slide-over/slide-over';
import React, { useMemo } from 'react';
import { CollapsableCard } from '@/components/collapsable-card';
import { Tooltip } from '@/components/tooltip';
import { useAccountById } from '@/queries/use-accounts';
import { getApiAccessPolicyId, getApiMembershipsId } from '@/models/api';
import { useQuery } from 'react-query';
import { Time } from '@/components/date-time';
import { AsyncBoundary } from '@/components/bounderies/async-boundary';
import { getValue, Label, ThreeRowsGrid, Value } from '@/components/slide-over/helpers';
import { Body } from '@/components/text';
import { Link } from 'react-router-dom';
import qs from 'qs';
import { Button } from '@/components/buttons/button';
import { AccountOverview } from '@/views/inventory/access-explorer/components/edge-detailed-view/account-overview';
import { AssetOverview } from '@/views/inventory/access-explorer/components/edge-detailed-view/asset-overview';
import { GroupOverview } from '@/views/inventory/access-explorer/components/edge-detailed-view/group-overview';
import { IdentityOverview } from '@/views/inventory/access-explorer/components/edge-detailed-view/identity-overview';
import { startCase } from 'lodash-es';

const edgeDetailedViewAccess = 'edgeDetailedViewAccess';

export function EdgeDetailedView({ edge, source, target, onClose }) {
  const header = edge
    ? `${source?.name} ${edge?.data?.accessType === 'membership' ? 'member of' : 'access to'} ${
        target?.name
      }`
    : '';

  return (
    <SlideOver key={edge?.id} isOpen={!!edge} onClose={onClose} header={header} subheader="">
      {edge && (
        <AsyncBoundary>
          <EdgeDetailedViewInner edge={edge} source={source} target={target} />
        </AsyncBoundary>
      )}
    </SlideOver>
  );
}

function EdgeDetailedViewInner({ edge, source, target }) {
  return (
    <>
      <CollapsableCard header={`Source (${startCase(source.object)})`}>
        <AsyncBoundary>
          <EntityDetails entity={source} />
        </AsyncBoundary>
      </CollapsableCard>
      <CollapsableCard header="Access">
        <AsyncBoundary>
          <AccessDetails edge={edge} />
        </AsyncBoundary>
      </CollapsableCard>
      <CollapsableCard header={`Target (${startCase(target.object)})`}>
        <AsyncBoundary>
          <EntityDetails entity={target} />
        </AsyncBoundary>
      </CollapsableCard>
    </>
  );
}

function EntityDetails({ entity }) {
  if (entity?.object === 'account') {
    return <AccountOverview entity={entity} />;
  }

  if (entity?.object === 'identity') {
    return <IdentityOverview entity={entity} />;
  }

  if (entity?.object === 'group') {
    return <GroupOverview entity={entity} />;
  }

  if (entity?.object === 'asset') {
    return <AssetOverview entity={entity} />;
  }

  return <Body>No data available</Body>;
}

function AccessDetails({ edge }) {
  const { data: account } = useAccountById(edge?.data?.createdBy);

  const accessFetchFunction = useMemo(
    () => (edge?.data?.accessType === 'membership' ? getApiMembershipsId : getApiAccessPolicyId),
    [edge?.data?.accessType]
  );

  const { data: access } = useQuery({
    queryKey: [edgeDetailedViewAccess, edge?.data?.accessId],
    queryFn: async () => accessFetchFunction(edge?.data?.accessId),
    select: (response) => response.data.data,
    suspense: true,
    enabled: Boolean(edge?.data?.accessId),
  });

  return access ? (
    <>
      <ThreeRowsGrid>
        {access.object === 'access-policy' && (
          <>
            <div>
              <Label>Privilege Origin Name</Label>
              <Value>
                {edge?.label ? (
                  <Tooltip label={edge?.label}>
                    <span>{edge?.label}</span>
                  </Tooltip>
                ) : (
                  '-'
                )}
              </Value>
            </div>
            <div>
              <Label>Privilege Type</Label>
              <Value>
                {edge?.data?.privilegeTypes.length ? (
                  <Tooltip label={edge?.data?.privilegeTypes.join(', ')}>
                    <span>{edge?.data?.privilegeTypes.join(', ')}</span>
                  </Tooltip>
                ) : (
                  '-'
                )}
              </Value>
            </div>
          </>
        )}

        <div>
          <Label>Direct</Label>
          <Value>{getValue(access.isDirect)}</Value>
        </div>

        <div>
          <Label>{edge?.data?.accessType === 'membership' ? 'Added' : 'Granted'} By</Label>
          <Value>
            {account?.name ? (
              <Tooltip label={account.name}>
                <span>{account.name}</span>
              </Tooltip>
            ) : (
              '-'
            )}
          </Value>
        </div>

        <div>
          <Label>{edge?.data?.accessType === 'membership' ? 'Added' : 'Granted'} At</Label>
          <Value>
            <Time dateTime={edge.data.createdAt} format="P" titleFormat="P" />
          </Value>
        </div>
      </ThreeRowsGrid>
      <ThreeRowsGrid>
        {edge.data.activityId && (
          <Button
            kind="text"
            size="sm"
            css={{ paddingLeft: 0, justifyContent: 'left' }}
            as={Link}
            to={{
              pathname: `/inventory/activities`,
              search: `?${qs.stringify({
                selectedActivityId: edge.data.activityId,
                filter: JSON.stringify({
                  $and: [{ 'activity.id': { $in: [edge.data.activityId] } }],
                }),
              })}`,
            }}>
            See related Activity
          </Button>
        )}
      </ThreeRowsGrid>
    </>
  ) : (
    <Body>No data available</Body>
  );
}
