import { styled } from '@/stitches.config';

export const Card = styled('div', {
  position: 'relative',
  margin: '10px',
  padding: '20px',
  borderRadius: '4px',
  backgroundColor: '$white',
  boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.1)',
});
