import React, { useEffect, useState } from 'react';
import { matchPath } from 'react-router';
import { HeaderContainer } from '@/views/layout/default-layout-common-styles';
import Header from '../views/layout/header';
import Sidebar from '../views/layout/components/sidebar/sidebar';
import { useSession } from './use-session';

function DefaultHeader() {
  return (
    <HeaderContainer>
      <Header />
    </HeaderContainer>
  );
}

function DefaultSidebar() {
  return <Sidebar />;
}

export function useCurrentRouteHasCustomNavigation(currentRoute, routes) {
  const [header, setHeader] = useState(null);
  const [sidebar, setSidebar] = useState(null);
  const { session } = useSession();

  useEffect(() => {
    const role = session?.role;
    const result = routes.find(({ path }) => {
      const match = matchPath(currentRoute, {
        path,
        end: true,
        strict: false,
      });

      return match?.isExact;
    }) || {
      roles: {
        default: {
          header: <DefaultHeader />,
          sidebar: <DefaultSidebar />,
        },
      },
    };

    const config = result.roles[role] || result.roles.default || {};

    if (config.header !== undefined) {
      setHeader(config.header);
    } else {
      setHeader(<DefaultHeader />);
    }

    if (config.sidebar !== undefined) {
      setSidebar(config.sidebar);
    } else {
      setSidebar(<DefaultSidebar />);
    }
  }, [currentRoute, routes, session?.role]);

  return { header, sidebar };
}
