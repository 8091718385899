import loadable from '@loadable/component';
import DefaultLayout from './views/layout';

export const routes = [
  {
    path: '/login',
    component: loadable(() => import('./views/login')),
  },
  {
    path: '/forgot-password',
    component: loadable(() => import('./views/login/forgot-password')),
  },
  {
    path: '/change-password',
    component: loadable(() => import('./views/login/change-password')),
  },
  {
    path: '/*',
    component: DefaultLayout,
  },
];
