import { ReactElement } from 'react';
import { FixMeLater } from '@/models/common';

// TODO selectedEntities are supposed to be the same type as data for table fix this when we add types for table
type RenderBulkActionType = (params: {
  selectedEntities: FixMeLater[];
  rowsCount: number;
  hasMoreEntities: boolean;
}) => ReactElement | null;

export const defaultRenderBulkAction: RenderBulkActionType = () => null;

type OnSelectedEntitiesType = (params: Record<string, boolean>) => void;

export const defaultOnSelectedEntities: OnSelectedEntitiesType = () => null;

export interface TableCell<R> {
  row: { original: R };
  value: unknown;
}

export type Field = {
  id: string;
  label: unknown;
  disableSortBy?: boolean;
  sortFieldId?: string;
  width?: number;
  minWidth?: number;
  align?: 'left' | 'right' | 'center';
  ellipsis?: boolean;
  showOnHover?: boolean;
  canNotBeHidden?: boolean;
  initiallyHidden?: boolean;
  sectionTitle?: string;
  disableDragNDrop?: boolean;
};
