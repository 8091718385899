export default Factory(localStorage);

export function Factory(storage) {
  return {
    get: (name, defaultValue) => {
      try {
        return JSON.parse(storage.getItem(name)) || defaultValue;
      } catch (e) {
        return defaultValue;
      }
    },
    set: (name, value) => storage.setItem(name, JSON.stringify(value)),
    remove: (name) => storage.removeItem(name),
    clear: () => storage.clear(),
  };
}
