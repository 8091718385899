import React from 'react';
import { FixMeLater } from '@/models/common';
import { styled } from '@/stitches.config';
import { EmptyPlaceholder } from '@/components/empty-placeholder';
import { Loading } from '@/components/loading';
import { Small } from '@/components/text';
import BalloonsIcon from '../../components/icons/balloons.svg';
import { FallbackWrapper } from './page';

function defaultRowIdentifier(row: FixMeLater) {
  return row.id;
}

type Column<T> = {
  id: string;
  heading: string;
  ellipsis?: boolean;
  align?: 'left' | 'right' | 'center';
  width?: number;
  render: (entity: T) => React.ReactNode;
};

type Props<T> = {
  loading?: boolean;
  error?: { message: string };
  columns: Column<T>[];
  data: T[];
  noDataContent: React.ElementType;
  withBorder?: boolean;
  activeRowId?: string;
  getRowIdentifier?: (row: T) => string;
  onRowClick?: (item: T) => void;
};

export function Table<T>({
  loading,
  error,
  columns,
  data,
  noDataContent: NoDataContent = () => (
    <NoDataWrapper>
      <Small>No Data Yet</Small>
      <BalloonsIcon />
    </NoDataWrapper>
  ),
  withBorder = false,
  activeRowId,
  getRowIdentifier = defaultRowIdentifier,
  onRowClick,
}: Props<T>) {
  if (loading || !data) {
    return (
      <FallbackWrapper>
        <Loading />
      </FallbackWrapper>
    );
  }

  if (error) {
    return <FallbackWrapper>{error.message}</FallbackWrapper>;
  }

  return data.length === 0 ? (
    <EmptyPlaceholder>
      <NoDataContent />
    </EmptyPlaceholder>
  ) : (
    <BaseTable>
      <thead>
        <HeaderTR withBorder={withBorder}>
          {columns.map(({ id, heading, align, width }) => (
            <TH key={id} align={align} css={{ width }}>
              {heading}
            </TH>
          ))}
        </HeaderTR>
      </thead>
      <tbody>
        {data.map((item) => (
          <BodyTR
            key={getRowIdentifier(item)}
            onClick={() => onRowClick?.(item)}
            active={Boolean(activeRowId) && (item as FixMeLater)?.id === activeRowId}>
            {columns.map(({ id, align, render, ellipsis }) => (
              <TD key={id} align={align} ellipsis={ellipsis}>
                {render(item)}
              </TD>
            ))}
          </BodyTR>
        ))}
      </tbody>
    </BaseTable>
  );
}

const NoDataWrapper = styled('div', {
  minWidth: 350,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
});

const BodyTR = styled('tr', {
  '&:hover': {
    background: 'rgba(40, 110, 230, 0.1)',
  },

  variants: {
    active: {
      true: {
        background: '#EAEEFF',
      },
    },
  },
});

const HeaderTR = styled('tr', {
  variants: {
    withBorder: {
      true: {
        borderBottom: '1px solid #E2E4EA',
      },
    },
  },
});

const TH = styled('th', {
  textAlign: 'left',
  color: '$slate500',
  fontWeight: '$500',
  fontSize: '$14',
  padding: '$8 $16',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',

  variants: {
    align: {
      center: {
        textAlign: 'center',
      },
      left: {
        textAlign: 'left',
      },
      right: {
        textAlign: 'right',
      },
    },
  },
});

const TD = styled('td', {
  textAlign: 'left',
  fontWeight: '300',
  fontSize: '$14',
  padding: '$8 $16',

  variants: {
    align: {
      center: {
        textAlign: 'center',
      },
      left: {
        textAlign: 'left',
      },
      right: {
        textAlign: 'right',
      },
    },
    ellipsis: {
      true: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        maxWidth: 0,
      },
    },
  },
});

const BaseTable = styled('table', {
  tableLayout: 'fixed',
  width: '100%',
});
