import React, { useState } from 'react';
import { styled } from '@/stitches.config';
import { RecommendedAppItem } from './recommended-app-item';
import { Button } from '../../../../components/buttons/button';
import { AppsLibraryModal } from './apps-library-modal';
import { ItemsLoading } from '../../../../components/items-loading/items-loading';

export function RecommendedApps({ recommendedApps, loading, error, onAddConnector, types }) {
  const [showAppsLibModal, setShowAppsLibModal] = useState(false);

  return (
    <RecommendedAppsContainer>
      {showAppsLibModal && (
        <AppsLibraryModal
          types={types}
          onClose={() => setShowAppsLibModal(false)}
          onAppSelect={(app) => onAddConnector(app)}
        />
      )}
      {types === 'Communication' && (
        <div>
          <h1>Available Ticketing and Communication Integrations</h1>
          <p className="subtitle">Suggested integrations for your your organization </p>
        </div>
      )}
      {types === 'Connector' && (
        <div>
          <h1>Available Apps</h1>
          <p className="subtitle">Authomize has identified these apps in your organization </p>
        </div>
      )}

      {types === 'Connector' && (
        <Button onClick={() => setShowAppsLibModal(true)}>Add New App</Button>
      )}

      <RecommendedAppsList>
        {loading ? (
          <div style={{ width: '550px' }}>
            <ItemsLoading
              count={4}
              itemHeight={40}
              itemMargin="13px 0 12px 16px"
              itemBackground="$white"
              itemWidth={120}
              showAvatar
            />
          </div>
        ) : recommendedApps?.length ? (
          recommendedApps.map((app) => (
            <RecommendedAppItem key={app.name} {...app} onClick={() => onAddConnector(app)} />
          ))
        ) : (
          <div className="no-items">
            {error
              ? 'Unfortunately, there was an error loading available apps.'
              : 'You’re all set! We haven’t found any apps to integrate'}
          </div>
        )}
      </RecommendedAppsList>
    </RecommendedAppsContainer>
  );
}

const RecommendedAppsContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',

  '& h1': {
    fontWeight: '600',
    fontSize: '1.25rem',
    letterSpacing: '0.1px',
    color: '$blue800',
  },

  '& .subtitle': {
    marginBottom: '0.5rem',
    fontSize: '0.8rem',
    letterSpacing: '0.1px',
    color: '$slate700',
  },
});

const RecommendedAppsList = styled('div', {
  display: 'flex',
  width: '100%',
  flexWrap: 'wrap',
  margin: '0 -0.75rem 3.5rem',

  '& .no-items': {
    width: '100%',
    marginTop: '0.5rem',
    fontSize: '1rem',
    letterSpacing: '0.1px',
    color: '$slate700',
    textAlign: 'center',
  },
});
