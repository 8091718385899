import { useFetcher } from 'rest-hooks';
import { useStatefulResource } from '@rest-hooks/legacy';
import { useCallback, useState } from 'react';
import { getErrorMessage, hasNextPage } from '../lib/api';
import { usePaginator } from './use-paginator';

const defaultData = [];

export function usePaginatedResource(endpoint, params = null) {
  const [loadingMore, setLoadingMore] = useState(false);
  const fetchList = useFetcher(endpoint);

  const {
    data: { data = defaultData, metadata, pagination },
    loading,
    error,
  } = useStatefulResource(endpoint, params);

  const getNextPage = usePaginator(endpoint, {
    ...params,
  });

  const handleGetNextPage = useCallback(async () => {
    setLoadingMore(true);
    try {
      const response = await getNextPage(pagination);
      setLoadingMore(false);
      return response;
    } finally {
      setLoadingMore(false);
    }
  }, [setLoadingMore, getNextPage, pagination]);

  const handleRefetch = useCallback(async () => {
    await fetchList({
      ...params,
    });
  }, [fetchList, params]);

  return {
    data,
    metadata,
    loading: loading || loadingMore,
    error: getErrorMessage(error),
    pagination: {
      ...pagination,
      hasNextPage: hasNextPage(pagination),
      total: loading ? undefined : pagination.total,
    },
    params,
    fetchMore: handleGetNextPage,
    refetch: handleRefetch,
  };
}
