import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { styled, theme } from '@/stitches.config';
import { UserPickerForUserId } from '@/components/pickers/user-picker';
import { FileUploader } from '../../../../components/file-uploader';
import { ALLOWED_LOGO_UPLOAD_FILE_TYPES } from '../../organization-settings/organization-settings';
import { InputWithIcon } from '../../../../components/input-with-icon';
import { AlertCircleIcon } from '../../../../icons';

export const ALLOWED_UPLOAD_FILE_TYPES = '.xlsx, .xls, .csv, .json';

export function Field({
  name,
  label,
  type,
  required = false,
  disabled = false,
  value,
  errors,
  onChange,
}) {
  if (type === 'file') {
    return (
      <FileUploadField
        name={name}
        label={label}
        type={type}
        onChange={onChange}
        errors={errors}
        required={required && value ? false : required}
      />
    );
  }
  if (type === 'logo') {
    return (
      <LogoUploadField
        name={name}
        label={label}
        type={type}
        onChange={onChange}
        required={required}
      />
    );
  }

  if (type === 'identity') {
    return (
      <UserPickerForUserId
        value={value}
        onSelected={(user) => onChange({ target: { name, value: user } })}
        inputProps={{ required }}
        excludeReviewerRole
      />
    );
  }

  const inputValue = type === 'checkbox' ? value === 'true' || value === true : value;

  return (
    <Label isCheckbox={type === 'checkbox'}>
      {type === 'checkbox' ? (
        <Checkbox
          label={label}
          name={name}
          type={type}
          errors={errors}
          required={required}
          disabled={disabled}
          inputValue={inputValue}
          onChange={onChange}
        />
      ) : (
        <Input
          label={label}
          name={name}
          type={type}
          errors={errors}
          required={required}
          disabled={disabled}
          inputValue={inputValue}
          onChange={onChange}
        />
      )}
    </Label>
  );
}

Field.propTypes = {
  type: PropTypes.oneOf(['identity', 'text', 'password', 'logo', 'checkbox', 'file']),
};

function FileUploadField({ name, label, type, errors, onChange, required }) {
  const onUploadHandle = useCallback(
    ({ storageFileName }) => {
      onChange({
        target: {
          name,
          type,
          value: storageFileName,
        },
      });
    },
    [name, type, onChange]
  );

  return (
    <Label isCheckbox={false}>
      <span>
        {label} {required && <RequiredSymbol />}
      </span>
      <FileUploader
        allowedFileTypes={ALLOWED_UPLOAD_FILE_TYPES}
        onUpload={onUploadHandle}
        required={required}
      />
      {errors?.length > 0 && errors.map((error) => <InlineError key={error}>{error}</InlineError>)}
    </Label>
  );
}

function LogoUploadField({ name, label, type, onChange, required }) {
  const onUploadHandle = useCallback(
    ({ storageFileName }) => {
      onChange({
        target: {
          name,
          type,
          value: storageFileName,
        },
      });
    },
    [name, type, onChange]
  );

  const isPublicFile = true;
  return (
    <Label isCheckbox={false}>
      <span>
        {label} {required && <RequiredSymbol />}
      </span>
      <FileUploader
        allowedFileTypes={ALLOWED_LOGO_UPLOAD_FILE_TYPES}
        onUpload={onUploadHandle}
        isPublic={isPublicFile}
      />
    </Label>
  );
}

function Checkbox({ label, name, type, errors, required, disabled, inputValue, onChange }) {
  return (
    <>
      <span>
        {label || name} {required && <RequiredSymbol />}
      </span>
      <input
        name={name}
        type={type}
        className={errors?.length ? 'invalid' : ''}
        required={required}
        disabled={disabled}
        value={inputValue}
        checked={inputValue}
        onChange={onChange}
        autoComplete="off"
      />
      {errors?.length > 0 && errors.map((error) => <InlineError key={error}>{error}</InlineError>)}
    </>
  );
}

function Input({
  label,
  name,
  type,
  errors,
  required,
  disabled,
  inputValue,
  onChange,
  placeholder,
}) {
  return (
    <>
      <span>
        {label || name} {required && <RequiredSymbol />}
      </span>

      <InputWithIcon iconRight>
        <input
          name={name}
          type={type}
          className={errors?.length ? 'invalid' : ''}
          required={required}
          disabled={disabled}
          value={inputValue}
          checked={inputValue}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete="off"
        />
        {errors?.length > 0 && <AlertCircleIcon color={theme.colors.red300} />}
      </InputWithIcon>
      {errors?.length > 0 && errors.map((error) => <InlineError key={error}>{error}</InlineError>)}
    </>
  );
}

const RequiredSymbol = styled('span', {
  '&::after': {
    content: "'*'",
    color: '$orange900',
  },
});

const Label = styled('label', {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  paddingBottom: '1rem',

  variants: {
    isCheckbox: {
      true: {
        flexDirection: 'row-reverse',

        '& span': {
          marginBottom: 'auto',
          marginTop: '3px',
        },
      },
    },
  },

  '&:last-child': {
    paddingBottom: '0',
  },

  '& span': {
    fontWeight: '600',
    fontSize: '0.87rem',
    lineHeight: '1.125rem',
    color: '$slate700',
    marginBottom: '0.5rem',
    marginTop: 0,
  },

  '& input': {
    display: 'block',
    width: '100%',
    height: '2rem',
    padding: '0.5rem',
    borderRadius: '4px',
    border: '1px solid $gray300',

    '&:focus,    &:hover': {
      border: '1px solid $iris800',
    },

    '&.invalid': {
      border: '1px solid $red500',
    },
  },

  "& input[type='checkbox']": {
    marginRight: '0.5rem',
    width: '1.5rem',
    height: '1.5rem',
  },
});

const InlineError = styled('div', {
  width: '100%',
  marginTop: '0.25rem',
  marginBottom: '0',
  fontWeight: '600',
  fontSize: '12px',
  lineHeight: '16px',
  color: '$red600',
});
