const TEMPLATE_BLOCK_DELIMITER = /\{\{\s*([^}]+)\s*\}\}/g;
const TEMPLATE_OPTIONS_DELIMITER = /\s*\|\s*/g;

export default function buildTemplate(template, params) {
  template = template.split(TEMPLATE_BLOCK_DELIMITER);
  let result = template.shift();
  while (template.length) {
    const [attr, ...opts] = template.shift().split(TEMPLATE_OPTIONS_DELIMITER);
    if (attr in params) {
      let val = params[attr].value;
      while (opts.length) {
        val = applyTemplateOption(opts.shift(), val);
      }
      result += String(val);
    }
    result += template.shift();
  }
  return result;
}

function applyTemplateOption(option, value) {
  switch (option) {
    case 'distanceTime':
      return `<DistanceTime dateTime="${value}" />`;
    case 'relativeTime':
      return `<RelativeTime dateTime="${value}" />`;
    default:
      console.warn(`unknown options "${option}"`);
      return value;
  }
}
