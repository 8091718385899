import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Navigate, Routes, Route } from 'react-router-dom';
import { QueryClientProvider, QueryClient } from 'react-query';
import { Toaster } from 'react-hot-toast';
import { routes } from './routes';
import { getRedirectUri, useQueryParams, useSession, useTracking } from './hooks';
import { Loading } from './components/loading';
import { Preloader } from './preloader';
import { EnvSelector } from './components/env-selector';
import { ErrorBoundary } from './components/bounderies/error-boundary';

// Create a client
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 60 * 1000,
    },
  },
});

export default function App() {
  useTracking();

  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<Loading center />}>
        <Helmet defaultTitle="Authomize" titleTemplate="%s - Authomize" />
        <ErrorBoundary>
          <Router />
        </ErrorBoundary>
        <EnvSelector />
        <Toaster />
      </Suspense>
    </QueryClientProvider>
  );
}

function Router() {
  const { session, isConnected } = useSession();
  const { queryParams } = useQueryParams();

  return (
    <>
      {isConnected && <Preloader />}
      <Routes>
        {/* Handle redirect logic after login */}
        <Route path="" element={<Navigate replace to={getRedirectUri(session, queryParams)} />} />
        {/* Redirect old issues urls to incidents page */}
        <Route path="/issues/:issueId" element={<Navigate replace to="/incidents/:issueId" />} />
        {routes.map(({ component: Component, ...props }, i) => (
          <Route key={i} {...props} element={<Component />} />
        ))}
      </Routes>
    </>
  );
}
