import { styled } from '@/stitches.config';

export const EmptyPlaceholder = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '1rem',
  flex: '1',
  margin: '2rem 0',
});
