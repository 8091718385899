import React from 'react';

import { Body, Subtitle } from '@/components/text';

export function AddedRiskLabel({ score }) {
  return (
    Boolean(score) && (
      <Subtitle weight="semibold" size="2" color="black" css={{ marginTop: '$16' }}>
        Added risk{' '}
        <Body weight="semibold" size="2" css={{ color: '$red600' }}>
          +{score}
        </Body>
      </Subtitle>
    )
  );
}
