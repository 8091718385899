function mapFieldToColumn({ id, label, accessor, ...props }, cells) {
  const extraProps = {};
  if (cells[id]) {
    extraProps.Cell = cells[id];
  }
  return {
    id,
    Header: label,
    accessor: accessor || id,
    ...props,
    ...extraProps,
  };
}

function getFieldById(fields, fieldId) {
  return fields.find((field) => field.id === fieldId);
}

export function makeTableColumns({ fields, visibleFields, cells }) {
  return visibleFields
    .map((id) => {
      const field = getFieldById(fields, id);
      return field && mapFieldToColumn(field, cells);
    })
    .filter(Boolean);
}

export function makeInitialState(queryParams, defaultParams) {
  const sort = queryParams.sort || defaultParams.sort;
  const order = queryParams.order || defaultParams.order;

  return {
    sortBy: [{ id: sort, desc: order === 'desc' }],
  };
}

export function makeSortByParams(sortBy = [], defaultParams = {}, fields = []) {
  if (sortBy.length > 0) {
    const sort = sortBy[0].id;
    const sortFieldId = fields.find((field) => field.id === sort)?.sortFieldId;
    const order = sortBy[0].desc ? 'desc' : 'asc';

    return { sort: sortFieldId || sort, order };
  }

  return {
    sort: defaultParams.sort,
    order: defaultParams.order,
  };
}

export function toBooleanMap(rowIds) {
  const map = {};
  for (const rowId of rowIds) {
    map[rowId] = true;
  }
  return map;
}
