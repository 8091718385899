import React from 'react';
import { useSelect } from 'downshift';
import { styled, cx } from '@/stitches.config';

function defaultRenderToggleButton({ selectedItem, getToggleButtonProps }) {
  return (
    <button type="button" className="trigger" {...getToggleButtonProps()}>
      {selectedItem?.label}
    </button>
  );
}

function defaultRenderItem({ item, index, isHighlighted, getItemProps }) {
  return (
    <li key={index} className={cx(isHighlighted && 'active')} {...getItemProps({ item, index })}>
      {item.label}
    </li>
  );
}

export function Dropdown({
  items,
  selectedItem,
  renderToggleButton = defaultRenderToggleButton,
  renderItem = defaultRenderItem,
  className,
  css,
  onChange,
  menuPositionX = 'left',
}) {
  const { isOpen, getToggleButtonProps, getMenuProps, highlightedIndex, getItemProps } = useSelect({
    items,
    selectedItem,
    onSelectedItemChange: (changes) => onChange(changes.selectedItem),
  });

  return (
    <DropdownContainer className={cx(className)} css={css}>
      {renderToggleButton({ selectedItem, getToggleButtonProps, isOpen })}
      <ul {...getMenuProps()} className={cx(`position-${menuPositionX}`, isOpen && 'is-open')}>
        {items.map((item, index) =>
          // TODO: isOpen was added here in order to solve an issue with some of our svgs
          // (icons in apps-caoursel were hidden when app-filter-dropdown was closed)
          // we should find a better solution, and remove this prop eventually
          renderItem({
            item,
            index,
            getItemProps,
            isHighlighted: index === highlightedIndex,
            isOpen,
          })
        )}
      </ul>
    </DropdownContainer>
  );
}

const DropdownContainer = styled('span', {
  position: 'relative',
  display: 'inline-block',

  '& > .trigger': {
    borderRadius: '4px',
    padding: '0.25rem 0.5rem',
    background: 'transparent',
    border: '1px solid $gray400',

    '&:hover': {
      background: '$gray200',
    },
  },

  '& > ul': {
    position: 'absolute',
    top: 'calc(2rem + 0.25rem)',
    background: '$white',
    border: '1px solid $gray400',
    borderRadius: '4px',
    minWidth: '12rem',
    zIndex: '100',
    boxShadow: '0px 4px 4px rgba(200, 200, 200, 0.15), 0px 0px 10px 1px rgba(203, 205, 208, 0.3)',
    transform: 'scale(0)',
    transition: 'all 0.25s',

    '&.is-open': {
      transform: 'scale(1)',
    },

    '&.position-left': {
      left: '0',
      transformOrigin: '0% 0%',
    },

    '&.position-right': {
      right: '0',
      transformOrigin: '100% 0%',
    },

    '& > li': {
      background: 'transparent',
      borderBottom: '1px solid $gray400',
      padding: '0.5rem 0.5rem',
      textAlign: 'left',

      '&.active,      &.highlight,      &:hover': {
        background: 'rgba(66, 102, 255, 0.1)',
      },

      '&.active': {
        fontWeight: '600',
        color: 'var(--color-system-color)',
      },

      '&:last-child': {
        borderBottom: '0',
      },

      '&.disabled': {
        color: 'var(--color-inactive-blue)',
      },
    },
  },
});
