import React, { useMemo, useState } from 'react';
import { useInvalidator, useResource } from 'rest-hooks';
import { styled } from '@/stitches.config';
import IntegrationResource from '../../../../resources/integration';
import { SearchInput } from '../../../../components/inputs/search-input';
import { Dialog } from '../../../../components/dialog';
import { SourceIcon } from '../../../../components/icons/sources';
import { Tooltip } from '../../../../components/tooltip';
import { AsyncBoundary } from '../../../../components/bounderies/async-boundary';
import { Ribbon } from '../../../../components/ribbon';

export function AppsLibraryModal({ onClose, onAppSelect, types }) {
  const [searchText, setSearchText] = useState('');

  return (
    <Dialog
      title="Pick an App"
      onClose={onClose}
      css={{
        width: '62rem',
        height: '38rem',
        overflow: 'hidden',

        '& .content.scroll': {
          minHeight: 'calc(100% - 3.5rem)',
        },
      }}>
      <>
        <SearchInput name="name" placeholder="Search" maxWidth onChange={setSearchText} />

        <AsyncBoundary
          errorFallback={<>Unfortunately, there was an error loading available apps</>}>
          <AppsLibrary
            types={types}
            searchText={searchText}
            onClick={(app) => {
              onAppSelect(app);
              onClose();
            }}
          />
        </AsyncBoundary>
      </>
    </Dialog>
  );
}

function AppsLibrary({ searchText, onClick, types }) {
  const { data: availableApps = [] } = useResource(IntegrationResource.available(), { types });
  const invalidateAppsResource = useInvalidator(IntegrationResource.available());

  const availableAppsThatMatchSearch = useMemo(() => {
    return availableApps.filter((app) =>
      app.displayName.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [availableApps, searchText]);

  return (
    <AvailableAppsList>
      {availableAppsThatMatchSearch?.length ? (
        availableAppsThatMatchSearch.map((app) => (
          <AppCard
            key={app.name}
            onClick={() => {
              invalidateAppsResource({ types });
              onClick(app);
            }}>
            {app.isBeta && <Ribbon label="Beta" size="small" />}
            <SourceIcon name={app.name} logoUrl={app.logoUrl} />
            <Tooltip label={app.displayName}>
              <h6>{app.displayName}</h6>
            </Tooltip>
          </AppCard>
        ))
      ) : (
        <div className="no-items">
          Unfortunately, there are no apps that match your search request
        </div>
      )}
    </AvailableAppsList>
  );
}

const AvailableAppsList = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  margin: '0.5rem -0.5rem',

  '& .no-items': {
    width: '100%',
    marginTop: '0.5rem',
    fontSize: '1rem',
    letterSpacing: '0.1px',
    color: '$slate700',
    textAlign: 'center',
  },
});

const AppCard = styled('div', {
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  width: 'calc(25% - 1rem)',
  height: '8rem',
  margin: '0.5rem',
  padding: '1rem',
  background: 'var(--color-white)',
  boxShadow: '0px 0px 2px rgba(181, 181, 181, 0.15), 0px 4px 4px rgba(176, 176, 176, 0.2)',
  borderRadius: '0.5rem',
  justifyContent: 'center',
  alignItems: 'center',

  '& svg': {
    width: '2.5rem',
    height: '2.5rem',
  },

  '& h6': {
    width: '100%',
    marginTop: '0.5rem',
    fontSize: '0.8rem',
    fontWeight: 'normal',
    lineHeight: '1.25rem',
    textAlign: 'center',
    letterSpacing: '0.1px',
    color: '$blue800',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },

  '&:hover': {
    backgroundColor: '$iris200',
    cursor: 'pointer',
  },
});
