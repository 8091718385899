/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Authomize Application API
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  GetApiAccessPolicy200,
  GetApiAccessPolicyParams,
  GetApiAccessPolicyId200,
  GetApiAccessPolicyCount200,
  GetApiAccessPolicyCountParams,
  GetApiV2AccessPolicyAggregation200,
  GetApiV2AccessPolicyAggregationParams,
} from '../api.schemas';

export const getApiAccessPolicy = <TData = AxiosResponse<GetApiAccessPolicy200>>(
  params?: GetApiAccessPolicyParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/access-policy`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
export const getApiAccessPolicyId = <TData = AxiosResponse<GetApiAccessPolicyId200>>(
  id: string,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/access-policy/${id}`, options);
};
export const getApiAccessPolicyCount = <TData = AxiosResponse<GetApiAccessPolicyCount200>>(
  params?: GetApiAccessPolicyCountParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/access-policy/count`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
export const getApiV2AccessPolicyAggregation = <
  TData = AxiosResponse<GetApiV2AccessPolicyAggregation200>
>(
  params: GetApiV2AccessPolicyAggregationParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/v2/access-policy/aggregation`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
