import React, { useCallback, useMemo, useRef, useState } from 'react';
import ReactFlow, { applyEdgeChanges, applyNodeChanges, ReactFlowProvider } from 'reactflow';
import qs from 'qs';
import { styled } from '@/stitches.config';
import { useNavigation } from '@/hooks';
import { GroupNode, EntityNode } from '@/views/inventory/access-explorer/components/graph-nodes';
import { isEmpty } from 'lodash';
import { GraphEdge } from '@/views/inventory/access-explorer/components/graph-edge';
import { GraphControls } from '@/views/inventory/access-explorer/components/graph-zoom-control';
import { EdgeDetailedView } from '@/views/inventory/access-explorer/components/edge-detailed-view/edge-detailed-view';

import 'reactflow/dist/style.css';

export const GRAPH_VIEW_ZOOM_LEVEL = 1.0;

const nodeTypes = {
  entityNode: EntityNode,
  groupNode: GroupNode,
};

const edgeTypes = {
  default: GraphEdge,
};

export default function GraphView({
  nodes,
  edges,
  setElements,
  navigateToExplore = false,
  setSource,
  params,
  selectedEdgeId,
  onEdgeSelect,
}) {
  const { makeUrl } = useNavigation();

  const container = useRef();
  const reactFlowRef = useRef();

  const onInit = (reactFlowInstance) => {
    reactFlowRef.current = reactFlowInstance;
    reactFlowInstance.fitView({ minZoom: 0.7 });
  };

  const [highlightedEdges, setHighlightedEdges] = useState([]);

  const edgesWithHighlightedPaths = useMemo(() => {
    return edges.map((e) => {
      const shouldBeHighlighted = highlightedEdges[e.data.targetId];

      if (shouldBeHighlighted) {
        return {
          ...e,
          data: {
            ...e.data,
            highlighted: true,
          },
        };
      }
      return e;
    });
  }, [edges, highlightedEdges]);

  const handleEdgeClick = useCallback(
    (node) => {
      if (!highlightedEdges[node.data.id]) {
        let edgesToHighlight = { [node.data.id]: true };
        let pathIsUnfinished = false;

        do {
          pathIsUnfinished = false;

          // eslint-disable-next-line no-loop-func
          edges.forEach((e) => {
            const shouldBeHighlighted = edgesToHighlight[e.data.targetId];

            if (shouldBeHighlighted && !edgesToHighlight[e.data.sourceId]) {
              pathIsUnfinished = true;
              edgesToHighlight = { ...edgesToHighlight, [e.data.sourceId]: true };
            }
          });
        } while (pathIsUnfinished);

        setHighlightedEdges(edgesToHighlight);
      } else {
        setHighlightedEdges({});
      }
    },
    [edges, highlightedEdges]
  );

  const selectedEdgeData = useMemo(() => {
    const edge = edges.find(({ id }) => id === selectedEdgeId);
    if (edge) {
      try {
        const source = nodes.find(({ data }) => data.id === edge.data.sourceId);
        const target = nodes.find(({ data }) => data.id === edge.data.targetId);

        if (source?.type === 'groupNode' || target?.type === 'groupNode') {
          return {
            source: null,
            target: null,
            edge: null,
          };
        }
        return {
          source: source?.data.entity,
          target: target?.data.entity,
          edge,
        };
      } catch (error) {
        console.error("Can't find corresponding nodes for an edge", error);
        return {
          source: null,
          target: null,
          edge,
        };
      }
    }

    return {
      source: null,
      target: null,
      edge: null,
    };
  }, [edges, nodes, selectedEdgeId]);

  return (
    <>
      <GraphWrapper ref={container}>
        <ReactFlowProvider>
          <ReactFlow
            minZoom={0.01}
            maxZoom={GRAPH_VIEW_ZOOM_LEVEL}
            nodes={nodes}
            edges={edgesWithHighlightedPaths}
            onNodesChange={(changes) =>
              setElements((prevState) => ({
                edges: prevState.edges,
                nodes: applyNodeChanges(changes, prevState.nodes),
              }))
            }
            onEdgesChange={(changes) =>
              setElements((prevState) => ({
                edges: applyEdgeChanges(changes, prevState.edges),
                nodes: prevState.nodes,
              }))
            }
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            onInit={onInit}
            onNodeClick={(event, node) => handleEdgeClick(node)}
            onEdgeClick={(e, edge) => onEdgeSelect && onEdgeSelect(edge)}
            onNodeDoubleClick={(event, node) => {
              if (node.type === 'groupNode') {
                const { inventoryLink } = node.data;
                if (inventoryLink) {
                  switch (inventoryLink.apiType) {
                    case 'memberships':
                    case 'access-policies':
                      window.open(
                        makeUrl(`/inventory/${inventoryLink.apiType}`, {
                          sourceType: inventoryLink?.sourceType,
                          targetType: inventoryLink?.targetType,
                          ...(!isEmpty(inventoryLink.sourceFilter)
                            ? {
                                sourceFilter: JSON.stringify(inventoryLink.sourceFilter),
                              }
                            : {}),
                          ...(!isEmpty(inventoryLink.targetFilter)
                            ? {
                                targetFilter: JSON.stringify(inventoryLink.targetFilter),
                              }
                            : {}),
                          ...(!isEmpty(inventoryLink.privilegeFilter)
                            ? {
                                privilegeFilter: JSON.stringify(inventoryLink.privilegeFilter),
                              }
                            : {}),
                        })
                      );
                      break;
                    case 'asset-entity-nested-assets':
                      window.open(
                        makeUrl(`/inventory/resources/${inventoryLink.assetId}/children`, {
                          ...(!isEmpty(inventoryLink.assetFilter)
                            ? { filter: JSON.stringify(inventoryLink.assetFilter) }
                            : {}),
                        })
                      );
                      break;
                    default:
                      console.error("Couldn't parse apiType");
                  }
                }
              } else {
                const sourceTypeValue = node?.data?.entity?.object;

                setSource({
                  sourceId: node?.data?.entity?.id,
                  sourceType: sourceTypeValue,
                });
              }
            }}>
            <GraphControls
              reactFlowRef={reactFlowRef}
              container={container}
              navigateToExplore={navigateToExplore}
              graphUrl={`/graph?${qs.stringify(filterNonNullParams(params))}`}
            />
          </ReactFlow>
        </ReactFlowProvider>
      </GraphWrapper>
      <EdgeDetailedView
        edge={selectedEdgeData.edge}
        source={selectedEdgeData.source}
        target={selectedEdgeData.target}
        onClose={() => onEdgeSelect(null)}
      />
    </>
  );
}

function filterNonNullParams(obj) {
  return Object.fromEntries(Object.entries(obj).filter(([, v]) => v));
}

const GraphWrapper = styled('div', {
  width: '100%',
  height: '100%',
  backgroundColor: '$gray200',
  '&:fullscreen': {
    backgroundColor: '$gray200',
  },

  '.clickable-node:hover': {
    borderColor: '$iris900 !important',
  },
});
