import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useInvalidator, useResource, useSubscription } from 'rest-hooks';
import IntegrationResource from '../../resources/integration';

const NUMBER_OF_ATTEMPTS = 60;
const TIMEOUT = 3000;
const GENERIC_ERROR_MSG =
  "Something went wrong when checking the connector's compatibility. Please contact\n" +
  '                    Authomize support for more details.';

export function usePollIntegrationProgress(onSuccess, onError) {
  const [appId, setAppId] = useState(null);
  const [validating, setValidating] = useState(false);
  const [errorsState, setErrorsState] = useState({});
  const [totalTests, setTotalTests] = useState(0);
  const [successfulTests, setSuccessfulTests] = useState(0);
  const attemptsLeft = useRef(NUMBER_OF_ATTEMPTS);

  const params = useMemo(() => (appId ? { id: appId } : null), [appId]);
  const { data: testConnectorHealthStatusParams } = useResource(
    IntegrationResource.validate(),
    params
  );
  const invalidateIntegrationValidation = useInvalidator(IntegrationResource.validate());

  useSubscription(IntegrationResource.validate(), params);

  const pollIntegrationProgress = (value) => {
    setAppId(value);
  };

  const updateProgressTimeoutRef = useRef(null);

  const clearTimeoutRef = useCallback((ref) => {
    if (ref.current) {
      clearTimeout(ref.current);
      ref.current = null;
    }
  }, []);

  const updateProgress = useCallback(
    (newSuccess, currentSuccess) => {
      if (newSuccess <= currentSuccess) {
        clearTimeoutRef(updateProgressTimeoutRef);
        return;
      }

      const delta = TIMEOUT / newSuccess;
      setSuccessfulTests(currentSuccess + 1);

      updateProgressTimeoutRef.current = setTimeout(() => {
        updateProgress(newSuccess, currentSuccess + 1);
      }, delta);
    },
    [clearTimeoutRef, setSuccessfulTests]
  );

  const startValidating = () => {
    setValidating(true);
  };

  const parseErrorMessages = (errors, errorMap) => {
    const newErrorState = {};
    const keys = Object.keys(errors);

    keys.forEach((key) => {
      newErrorState[key] = errors[key].map((errorType) => errorMap[errorType]);
    });

    if (keys.length === 0) {
      newErrorState.genericError = GENERIC_ERROR_MSG;
    }
    setErrorsState(newErrorState);
  };

  const clearError = (fieldName) => {
    const newErrorState = { ...errorsState };
    delete newErrorState[fieldName];

    setErrorsState(newErrorState);
  };

  const isIntegrationInvalid = () => {
    const errors = Object.keys(errorsState);

    return errors.length === 1 ? !errorsState.genericError : errors.length > 1;
  };

  useEffect(() => {
    if (
      testConnectorHealthStatusParams?.status !== 'Error' &&
      testConnectorHealthStatusParams?.status !== 'Success' &&
      testConnectorHealthStatusParams?.status !== 'Partial'
    ) {
      attemptsLeft.current -= 1;
    }
  }, [testConnectorHealthStatusParams]);

  useEffect(() => {
    if (testConnectorHealthStatusParams?.status === 'Error') {
      parseErrorMessages(
        testConnectorHealthStatusParams.errorNameMap,
        testConnectorHealthStatusParams.errorToDisplayMessageMap
      );
      setAppId(null);
      setValidating(false);
      attemptsLeft.current = NUMBER_OF_ATTEMPTS;
      invalidateIntegrationValidation(params);
    } else if (
      testConnectorHealthStatusParams?.status === 'Success' ||
      testConnectorHealthStatusParams?.status === 'Partial'
    ) {
      setAppId(null);
      setValidating(false);
      onSuccess();
      invalidateIntegrationValidation(params);
    } else {
      setTotalTests(testConnectorHealthStatusParams?.numberOfTests || 0);
      updateProgress(
        testConnectorHealthStatusParams?.numberOfTestsSuccessful || 0,
        successfulTests
      );

      if (attemptsLeft.current < 0) {
        onError(GENERIC_ERROR_MSG);
      }
    }
  }, [
    testConnectorHealthStatusParams,
    invalidateIntegrationValidation,
    onError,
    onSuccess,
    params,
    successfulTests,
    updateProgress,
  ]);

  useEffect(() => {
    return () => {
      setAppId(null);
    };
  }, []);

  const stopValidation = () => {
    setValidating(false);
  };

  return {
    pollIntegrationProgress,
    validating,
    startValidating,
    errorsState,
    isIntegrationInvalid,
    clearError,
    totalTests,
    successfulTests,
    stopValidation,
  };
}
