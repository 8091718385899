/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Authomize Application API
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import type { GetApiV3GraphParams } from '../api.schemas';

export const getApiV3Graph = <TData = AxiosResponse<void>>(
  params: GetApiV3GraphParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/v3/graph`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
