/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Authomize Application API
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  GetApiV2Identities200,
  GetApiV2IdentitiesParams,
  GetApiV2IdentitiesCount200,
  GetApiV2IdentitiesCountParams,
  PostApiV2IdentitiesPropertiesPropertyIdValuesSearch200,
  PostApiV2IdentitiesPropertiesPropertyIdValuesSearchBody,
  GetApiV2IdentitiesId200,
} from '../api.schemas';

export const getApiV2Identities = <TData = AxiosResponse<GetApiV2Identities200>>(
  params?: GetApiV2IdentitiesParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/v2/identities`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
export const getApiV2IdentitiesCount = <TData = AxiosResponse<GetApiV2IdentitiesCount200>>(
  params?: GetApiV2IdentitiesCountParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/v2/identities/count`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
export const postApiV2IdentitiesPropertiesPropertyIdValuesSearch = <
  TData = AxiosResponse<PostApiV2IdentitiesPropertiesPropertyIdValuesSearch200>
>(
  propertyId: string,
  postApiV2IdentitiesPropertiesPropertyIdValuesSearchBody: PostApiV2IdentitiesPropertiesPropertyIdValuesSearchBody,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(
    `/api/v2/identities/properties/${propertyId}/values/search`,
    postApiV2IdentitiesPropertiesPropertyIdValuesSearchBody,
    options
  );
};
export const getApiV2IdentitiesId = <TData = AxiosResponse<GetApiV2IdentitiesId200>>(
  id: string,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/v2/identities/${id}`, options);
};
