import { Resource } from '@rest-hooks/rest';

const endpointPath = '/api/branding';

export default class OrganizationBrandingResource extends Resource {
  baseUrl = null;
  companyName = null;
  realLogoFileName = null;
  storageLogoFileName = null;
  url = null;
  publicLogoUrl = null;

  static automaticValidation = 'silent';

  pk(response) {
    return response?.staticPk || '';
  }

  static current() {
    const endpoint = this.detail();

    return endpoint.extend({
      async fetch(params) {
        const response = await endpoint.fetch.call(this, params);
        // To use staticPk as cache key in pk method
        response.staticPk = this.url();
        return response;
      },
      url() {
        return OrganizationBrandingResource.urlRoot;
      },
    });
  }

  static updateBranding() {
    const endpoint = this.update();
    return endpoint.extend({
      async fetch({ companyName, brandingLogoFileName, brandingStorageFileName }) {
        const response = await endpoint.fetch.call(
          this,
          {},
          { companyName, brandingLogoFileName, brandingStorageFileName }
        );
        // To use staticPk as cache key in pk method
        response.staticPk = this.url();
        return response;
      },
      url() {
        return OrganizationBrandingResource.urlRoot;
      },
    });
  }

  static urlRoot = endpointPath;
}
