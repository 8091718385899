import React from 'react';
import { getValue, Label, ThreeRowsGrid, Value } from '@/components/slide-over/helpers';
import { Tooltip } from '@/components/tooltip';
import { Severity } from '@/views/incidents/components/severity';
import { startCase } from 'lodash';
import { TagsList } from '@/components/tags-list';

export function AccountOverview({ entity }) {
  return (
    <ThreeRowsGrid>
      <div>
        <Label>Name</Label>
        <Value>
          {entity.name ? (
            <Tooltip label={entity.name}>
              <span>{entity.name}</span>
            </Tooltip>
          ) : (
            '-'
          )}
        </Value>
      </div>

      <div>
        <Label>Type</Label>
        <Value>
          {entity.type ? (
            <Tooltip label={entity.type}>
              <span>{entity.type}</span>
            </Tooltip>
          ) : (
            '-'
          )}
        </Value>
      </div>

      <div>
        <Label>Origin Type</Label>
        <Value>
          {entity.externalType ? (
            <Tooltip label={entity.externalType}>
              <span>{entity.externalType}</span>
            </Tooltip>
          ) : (
            '-'
          )}
        </Value>
      </div>

      <div>
        <Label>Email</Label>
        <Value>
          {entity.email ? (
            <Tooltip label={entity.email}>
              <span>{entity.email}</span>
            </Tooltip>
          ) : (
            '-'
          )}
        </Value>
      </div>

      <div>
        <Label>Risk</Label>
        <Value>
          {entity.identityOverallRiskSeverity ? (
            <Severity severity={entity.identityOverallRiskSeverity} />
          ) : (
            '-'
          )}
        </Value>
      </div>

      <div>
        <Label>Is External</Label>
        <Value>{getValue(entity.isExternal)}</Value>
      </div>

      <div>
        <Label>Account Status</Label>
        <Value>
          {entity.status ? (
            <Tooltip label={startCase(entity.status)}>
              <span>{startCase(entity.status)}</span>
            </Tooltip>
          ) : (
            '-'
          )}
        </Value>
      </div>

      <div>
        <Label>MFA Enabled</Label>
        <Value>{getValue(entity.hasTwoFactorAuthenticationEnabled)}</Value>
      </div>

      <div>
        <Label>Tags</Label>
        <TagsList tags={entity.tags} maxLimit={100} />
      </div>
    </ThreeRowsGrid>
  );
}
