import loadable from '@loadable/component';
import React, { useMemo } from 'react';
import { ROLES } from '@/lib/auth';
import { SettingsSidebar } from '../settings/settings-sidebar';
import { SettingsSidebarContainer } from './default-layout-common-styles';
import { ApplicationIntegrations } from '../settings/integration/integrations';

// This array should contain all routes that should have different combinations of header / sidenav based on roles
export const ROUTES_WITH_CUSTOM_NAVIGATION = [
  {
    path: '/campaigns',
    roles: {
      [ROLES.REVIEWER]: {
        sidebar: null,
      },
    },
  },
  {
    path: '/campaigns/:campaignId/review',
    roles: {
      default: {
        sidebar: null,
      },
    },
  },
  {
    path: '/campaigns/mytasks',
    roles: {
      [ROLES.REVIEWER]: {
        sidebar: null,
      },
    },
  },
  {
    path: '/campaigns/:campaignId/review/*',
    roles: {
      default: {
        sidebar: null,
      },
    },
  },
  {
    path: '/email',
    roles: {
      default: {
        header: null,
        sidebar: null,
      },
    },
  },
  {
    path: '/settings/*',
    roles: {
      default: {
        sidebar: (
          <SettingsSidebarContainer>
            <SettingsSidebar />
          </SettingsSidebarContainer>
        ),
      },
    },
  },
];

export const useRoutes = () =>
  useMemo(
    () => [
      {
        path: '/dashboard/*',
        component: loadable(() => import('../dashboard-v2')),
      },
      {
        path: '/email',
        component: loadable(() => import('../email')),
      },
      {
        path: '/inventory/*',
        component: loadable(() => import('../inventory')),
      },
      {
        path: '/settings/*',
        component: loadable(() => import('../settings')),
      },
      {
        path: '/internal/*',
        component: loadable(() => import('../internal')),
      },
      {
        path: '/incidents/*',
        component: loadable(() => import('../incidents')),
      },
      {
        path: '/campaigns/*',
        component: loadable(() => import('../campaigns')),
      },
      {
        path: '/policies/*',
        component: loadable(() => import('../policies')),
      },
      {
        path: '/playground',
        component: loadable(() => import('../playground')),
      },
      {
        path: '/r/:path/*',
        component: loadable(() => import('../redirect')),
      },
      {
        path: '/access-explorer',
        component: loadable(() => import('../inventory/access-explorer/access-explorer')),
      },
      {
        path: '/graph',
        component: loadable(() =>
          import('../inventory/access-explorer/components/graph-old-url-redirect')
        ),
      },
      {
        path: '/dynamic-scope/*',
        component: loadable(() => import('../dynamic-scope')),
      },
      {
        path: '/dynamic-scope/templates',
        component: loadable(() =>
          import('../dynamic-scope/templates/dynamic-scope-templates-table')
        ),
      },
      {
        path: '/webhook-logs',
        component: loadable(() => import('../settings/webhooks/webhook-logs/webhook-logs')),
      },
      {
        path: '/data-sources',
        component: ApplicationIntegrations,
      },
      {
        path: '/reports',
        component: loadable(() => import('../reports/reports')),
      },
      {
        path: '/apps-overview',
        component: loadable(() => import('../apps-overview/apps-overview')),
      },
      {
        path: '/posture',
        component: loadable(() => import('../posture/posture-view')),
      },
      {
        path: '/alerts',
        component: loadable(() => import('../alerts/alerts-view')),
      },
      {
        component: () => (
          <h1 style={{ fontSize: '2.4em', textAlign: 'center', margin: 20 }}>
            404 - Page Not Found
          </h1>
        ),
      },
    ],
    []
  );
