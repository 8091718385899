import React from 'react';
import toast from 'react-hot-toast';
import { css, cx } from '@/stitches.config';
import { Dialog } from '@/components/dialog';
import { Button } from '@/components/buttons/button';
import { LockIcon } from '@/icons';
import { useSession } from '@/hooks';
import { useChangeUserPassword } from '@/queries/use-users';
import { useChangePassword } from '@/hooks/use-change-password';
import ChangePasswordFormFields from './change-password-form-fields';

export function ChangePasswordModal({ onClose, requirePasswordChange = false }) {
  const { changePassword } = useChangeUserPassword();
  const { logout } = useSession();

  const {
    plainTextFields,
    passwordsState,
    checkPasswordsValidations,
    setPasswordsState,
    handleInputPasswordsChanged,
    togglePasswordVisibility,
  } = useChangePassword();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (checkPasswordsValidations()) {
      const { data } = await changePassword({
        newPassword: passwordsState.newPassword,
        oldPassword: passwordsState.oldPassword,
      });

      if (data.success) {
        setPasswordsState({ oldPassword: '', newPassword: '', confirmPassword: '' });
        onClose();
      } else {
        toast.error(`The old password you have entered is incorrect`);
      }
    }
  };

  return (
    <Dialog
      title="Change Password"
      onClose={() => onClose()}
      allowClose={!requirePasswordChange}
      footerComponent={
        <>
          {!requirePasswordChange && (
            <Button kind="text" color="gray" onClick={() => onClose()}>
              Cancel
            </Button>
          )}
          {requirePasswordChange && (
            <Button kind="outlined" onClick={logout}>
              Logout
            </Button>
          )}
          <Button onClick={handleSubmit}>Save</Button>
        </>
      }>
      {requirePasswordChange && <div>You must set a new password to continue</div>}
      <div className={cx(contentStyle)}>
        <form className={cx(userEditStyle)} onSubmit={handleSubmit}>
          <ChangePasswordFormFields
            plainTextFields={plainTextFields}
            handleInputPasswordsChanged={handleInputPasswordsChanged}
            togglePasswordVisibility={togglePasswordVisibility}
            passwordsState={passwordsState}
          />
        </form>
        <LockIcon />
      </div>
    </Dialog>
  );
}

const userEditStyle = css({
  '& label': {
    width: '200px',
    display: 'inline-block',
    marginTop: '0.75rem',
    marginBottom: '0.5rem',
    color: '$slate800',
  },
});

const contentStyle = css({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',

  '& > svg': {
    marginRight: '2rem',
    color: '$blue100',
    width: '6rem',
    height: '6rem',
  },
});
