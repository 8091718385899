import dagre from 'dagre';
import { isNode } from 'reactflow';
import { theme } from '@/stitches.config';

const EDGE_TYPE_TO_LABEL = {
  derivesFrom: 'Has Account',
  aliasOf: 'Alias',
  memberOf: 'Member',
  linksTo: 'Attached',

  has_account: 'Has Account',
  membership: 'Member',
  permission: 'Permission',
  inheritance: 'Inheritance',
};

const NODE_STYLE = {
  border: `1px solid ${theme.colors.gray400}`,
  padding: 10,
  background: theme.colors.white,
  borderRadius: 3,
};

const NODE_WIDTH = 472;
const NODE_HEIGHT = 36;

const RIGHT_TO_LEFT_ORIENTATIONS = {
  rankdir: 'RL',
  ranksep: 70,
  nodesep: 80,
  edgesep: 10,
};

const LEFT_TO_RIGHT_ORIENTATIONS = {
  rankdir: 'LR',
  ranksep: 70,
  nodesep: 80,
  edgesep: 10,
};

const TOP_DOWN_ORIENTATIONS = {
  rankdir: 'TD',
  ranksep: 70,
  nodesep: 80,
  edgesep: 60,
};

const orientations = {
  TD: TOP_DOWN_ORIENTATIONS,
  LR: LEFT_TO_RIGHT_ORIENTATIONS,
  RL: RIGHT_TO_LEFT_ORIENTATIONS,
};

const EDGE_STYLE = {
  strokeWidth: 1,
  stroke: theme.colors.gray400,
};

const EDGE_LABEL_BG = theme.colors.gray200;

const ADMIN_EDGE_LABEL_BG = theme.colors.red100;

export function formatGraphElements({
  nodes,
  edges,
  orientation = 'LR',
  start,
  end,
  selectedEdgeId,
}) {
  const elements = nodes
    .map((node) => formatNodeToElement(node))
    .concat(edges.map((edge) => formatEdgeToElement(edge, start, end, selectedEdgeId)));

  const positionedElements = enrichGraphElementsPositions({ elements, orientation });

  return {
    nodes: positionedElements.filter((el) => isNode(el)),
    edges: positionedElements.filter((el) => !isNode(el)),
  };
}

function formatNodeToElement(node) {
  const id = formatNodeId(node);
  return {
    id,
    data: {
      ...node,
      id: node.id,
      label: node?.entity?.name,
      displayType: getNodeDisplayType(node),
      entity: node?.entity,
      entityType: node?.entityType,
      tags: node?.entity?.tags,
    },
    style: NODE_STYLE,
    className: node?.inventoryLink?.apiType ? 'clickable-node' : '',
    type: node?.isGroupedNode ? 'groupNode' : 'entityNode',
    position: { x: 0, y: 0 },
  };
}

function getNodeDisplayType(node) {
  if (node?.entity?.object === 'identity') {
    return 'Identity';
  }

  if (node?.entity?.externalType) {
    return node?.entity?.externalType;
  }

  return (node?.entity?.type || '').replace('_', ' ');
}

function formatEdgeToElement(edge, start, end, selectedEdgeId) {
  const edgeType = edge.id.substr(0, edge.id.indexOf('/'));

  // Hack to support v2 & v3 graph
  if (edgeType === 'entitledTo') {
    edge.accessType = 'permission';
  }

  const source = `${edge.sourceObject}-${edge.sourceId}`;
  const target = `${edge.targetObject}-${edge.targetId}`;

  return {
    id: edge.id,
    type: 'default',
    source,
    target,
    label: getEdgeLabel(edge, edgeType),
    markerEnd: { type: 'arrow', color: theme.colors.gray400.value },
    style: EDGE_STYLE,
    labelBgStyle: edge.privilegeTypes.includes('Administrative')
      ? ADMIN_EDGE_LABEL_BG
      : EDGE_LABEL_BG,
    data: {
      privileges: edge.privileges || [],
      privilegeTypes: edge.privilegeTypes || [],
      createdBy: edge.relatedActivityCreatedBy,
      createdAt: edge.relatedActivityCreatedAt,
      activityId: edge.relatedActivityId,
      targetId: edge.targetId,
      targetObject: edge.targetObject,
      sourceId: edge.sourceId,
      sourceObject: edge.sourceObject,
      accessId: edge.accessId,
      accessType: edge.accessType,
      start,
      end,
      selectedEdgeId,
    },
  };
}

function getEdgeLabel(edge, edgeType) {
  if (edge.accessType === 'permission') {
    return edge?.label ? edge.label : 'Has Privilege';
  }

  return EDGE_TYPE_TO_LABEL[edge.accessType] || EDGE_TYPE_TO_LABEL[edgeType] || '';
}

function enrichGraphElementsPositions({ elements, orientation }) {
  const dagreGraph = new dagre.graphlib.Graph();
  dagreGraph.setDefaultEdgeLabel(() => ({}));

  dagreGraph.setGraph(orientations[orientation]);

  elements.forEach((el) => {
    if (isNode(el)) {
      dagreGraph.setNode(el.id, { width: NODE_WIDTH, height: NODE_HEIGHT });
    } else {
      dagreGraph.setEdge(el.source, el.target);
    }
  });

  dagre.layout(dagreGraph);

  return elements.map((el) => {
    if (isNode(el)) {
      const nodeWithPosition = dagreGraph.node(el.id);
      el.position = { x: nodeWithPosition.x, y: nodeWithPosition.y };
    }

    return el;
  });
}

function formatNodeId(node) {
  if (node?.isGroupedNode) {
    return `${node?.entityType}-${node.id}`;
  }

  return `${node?.entity?.object}-${node.id}`;
}
