import React from 'react';
import { styled } from '@/stitches.config';
import { StyledComponent } from '@/models/common';

// Needed to define default props on button component as stitches
// does not provide API for defining default props using the `styled` function
const _button = React.forwardRef<HTMLButtonElement | null, object>((props, ref) => {
  return <button type="button" {...props} ref={ref} />;
});

export const Button: StyledComponent = styled(_button, {
  border: 'none',
  backgroundColor: 'transparent',
  fontWeight: '$400',
  padding: '0',
  cursor: 'pointer',

  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '$8',
  textAlign: 'center',
  whiteSpace: 'nowrap',

  borderRadius: '$4',

  transition: '200ms all ease-in-out',

  '& svg': {
    height: '1em',
    flex: 'none',
  },

  '@media screen and (-ms-high-contrast: active)': {
    border: '$2 solid currentcolor',
  },

  variants: {
    ellipsis: {
      true: {
        display: 'block',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },
    },
    disabled: {
      true: {
        pointerEvents: 'none',
        border: '1px solid $colors$gray300  !important',
        backgroundColor: '$gray200 !important',
        color: '$gray400  !important',
        boxShadow: 'none !important',
      },
    },
    kind: {
      contained: {
        '&:disabled': {
          border: '1px solid $colors$gray300',
          backgroundColor: '$gray200',
          color: '$gray400',
        },
      },
      outlined: {
        backgroundColor: '$white',
        borderStyle: 'solid',
        borderWidth: '$1',
        '&:disabled': {
          borderColor: '$gray300',
          backgroundColor: '$white',
          color: '$gray400',
        },
      },
      text: {
        backgroundColor: 'transparent',
        '&:disabled': {
          color: '$gray400',
        },
      },
    },
    color: {
      primary: {
        backgroundColor: '$iris800',
        color: '$white',
        '&:hover, &:focus, &:active': {
          backgroundColor: '$iris900',
        },
      },
      secondary: {
        backgroundColor: '$iris200',
        color: '$iris800',
        '&:hover, &:focus, &:active': {
          backgroundColor: '$iris300',
        },
      },
      gray: {
        backgroundColor: '$gray200',
        color: '$black',
        '&:hover, &:focus, &:active': {
          backgroundColor: '$gray300',
        },
      },
      red: {
        backgroundColor: '$red500',
        color: '$white',
        '&:hover, &:focus, &:active': {
          backgroundColor: '$red600',
        },
      },
    },
    size: {
      lg: {
        height: 40,
        fontSize: '$16',
        padding: '$8 $16',
      },
      md: {
        height: 32,
        fontSize: '$16',
        padding: '$4 $16',
      },
      sm: {
        height: 24,
        fontSize: '$14',
        padding: '$4 $8',
        gap: '$4',
      },
    },
    circle: {
      true: {
        borderRadius: '$circle',
        padding: '$4',
        width: 40,
        height: 40,
      },
    },
    block: {
      true: {
        width: '100%',
      },
    },
  },

  compoundVariants: [
    {
      color: 'primary',
      kind: 'contained',
      css: {
        boxShadow:
          '0px 1px 2px -4px rgba(14, 14, 44, 0.08), inset 0px -1px 0px rgba(14, 14, 44, 0.4)',
      },
    },
    {
      color: 'secondary',
      kind: 'contained',
      css: {
        boxShadow:
          'rgba(54, 54, 82, 0.06) 0px 1px 2px -4px, inset 0px -1px 0px rgb(227, 232, 255) ',
      },
    },
    {
      color: 'gray',
      kind: 'contained',
      css: {
        boxShadow: '0px 1px 2px -4px $colors$gray400, inset 0px -1px 0px $colors$gray400',
      },
    },
    {
      color: 'primary',
      kind: 'outlined',
      css: {
        backgroundColor: '$white',
        borderColor: '$iris400',
        color: '$iris800',
        '&:hover, &:focus, &:active': {
          backgroundColor: '$white',
          borderColor: '$iris800',
        },
      },
    },
    {
      color: 'gray',
      kind: 'outlined',
      css: {
        backgroundColor: '$white',
        borderColor: '$gray300',
        '&:hover, &:focus, &:active': {
          borderColor: '$iris800',
        },
      },
    },
    {
      color: 'red',
      kind: 'outlined',
      css: {
        backgroundColor: '$white',
        borderColor: '$red200',
        color: '$red600',
        '&:hover, &:focus, &:active': {
          backgroundColor: '$white',
          borderColor: '$red600',
        },
      },
    },
    {
      color: 'primary',
      kind: 'text',
      css: {
        backgroundColor: 'transparent',
        color: '$iris800',
        '&:hover, &:focus, &:active': {
          backgroundColor: 'transparent',
          color: '$iris900',
        },
      },
    },
    {
      color: 'gray',
      kind: 'text',
      css: {
        backgroundColor: 'transparent',
        '&:hover, &:focus, &:active': {
          backgroundColor: 'transparent',
          color: '$iris900',
        },
      },
    },
    {
      color: 'gray',
      kind: 'text',
      circle: true,
      css: {
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: '$gray200',
          color: '$slate900',
        },

        '&:focus, &:active': {
          backgroundColor: '$gray300',
          color: '$slate900',
        },
      },
    },
    {
      color: 'secondary',
      kind: 'text',
      circle: true,
      css: {
        backgroundColor: 'transparent',
        color: '$iris200',

        '&:hover': {
          color: '$iris400',
          backgroundColor: 'transparent',
        },

        '&:focus, &:active': {
          color: '$iris400',
          backgroundColor: 'transparent',
        },
      },
    },
    {
      color: 'red',
      kind: 'text',
      circle: true,
      css: {
        backgroundColor: 'transparent',
        color: '$red500',

        '&:hover': {
          color: '$red600',
          backgroundColor: 'transparent',
        },

        '&:focus, &:active': {
          color: '$red600',
          backgroundColor: 'transparent',
        },
      },
    },
    {
      disabled: true,
      kind: 'outlined',
      css: {
        border: '1px solid $colors$gray300',
        backgroundColor: '$white',
        color: '$gray400',
      },
    },
    {
      disabled: true,
      kind: 'text',
      css: {
        border: 'none',
        backgroundColor: 'transparent',
        color: '$gray400',
      },
    },
    {
      size: 'md',
      circle: true,
      css: {
        padding: '$2',
        width: 32,
        height: 32,
      },
    },
    {
      size: 'sm',
      circle: true,
      css: {
        padding: '$1',
        width: 24,
        height: 24,
      },
    },
  ],

  defaultVariants: {
    kind: 'contained',
    color: 'primary',
    size: 'md',
  },
});
