export const IDENTITY = 'identity';
export const RESOURCE = 'resource';
export const GROUP = 'group';
export const ACCOUNT = 'account';

export const ENTITLED_TO = 'entitledTo';

export type Entities = 'identity' | 'asset' | 'group' | 'account';

export type AccessPolicyAction = 'pending' | 'keep' | 'certified' | 'revoke';

export const REVOKE = 'revoke';
export const KEEP = 'keep';
export const CHANGE = 'change';
export const PENDING = 'pending';
export const CERTIFIED = 'certified';

export const ENTITY_TYPE_OPTIONS = [
  { name: 'Identities', value: IDENTITY },
  { name: 'Access', value: ENTITLED_TO },
];
