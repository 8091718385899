import React from 'react';
import { theme } from '@/stitches.config';
import { CheckCircleIcon, ResetIcon } from '@/icons';
import { useSession } from '@/hooks';
import { BreakdownItem } from './common';

export function StatusesItem({ lastSyncStatus }) {
  const { isAuthomizeAdmin } = useSession();
  const lastSyncStatusDisplay = isAuthomizeAdmin ? lastSyncStatus : 'Synced';

  return (
    <BreakdownItem>
      <div className="label">Status</div>
      <div className="value-with-indicator">
        <div className="value">
          {lastSyncStatusDisplay === 'Synced' && 'Synced'}
          {lastSyncStatusDisplay === 'Failure' && 'Error'}
          {lastSyncStatusDisplay === 'DataFetching' && 'Fetching'}
          {lastSyncStatusDisplay === 'DataPostProccessing' && 'Processing'}
          {lastSyncStatusDisplay === 'NeverExecuted' && 'Configuration Saved'}
        </div>
        <div className="indicator">
          {lastSyncStatusDisplay === 'Synced' && <CheckCircleIcon color={theme.colors.green700} />}
          {lastSyncStatusDisplay === 'DataFetching' && <ResetIcon color={theme.colors.green700} />}
          {lastSyncStatusDisplay === 'DataPostProccessing' && (
            <ResetIcon color={theme.colors.green700} />
          )}
          {lastSyncStatusDisplay === 'NeverExecuted' && <ResetIcon color={theme.colors.green700} />}
        </div>
      </div>
    </BreakdownItem>
  );
}
