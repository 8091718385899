import React, { ErrorInfo, PropsWithChildren, ReactElement, Suspense, cloneElement } from 'react';
import { NetworkError } from 'rest-hooks';
import { styled } from '@/stitches.config';
import { Loading } from '@/components/loading';

type AsyncBoundaryProps = {
  errorFallback?: ReactElement | null;
  pendingFallback?: ReactElement;
};

export function AsyncBoundary({
  errorFallback,
  pendingFallback = <Loading />,
  children,
}: PropsWithChildren<AsyncBoundaryProps>) {
  return (
    <ErrorBoundary fallback={errorFallback}>
      <Suspense fallback={pendingFallback}>{children}</Suspense>
    </ErrorBoundary>
  );
}

type ErrorBoundaryProps = {
  fallback?: ReactElement | null;
};

type ErrorBoundaryState = {
  hasError: boolean;
  message: string;
  error: Error | NetworkError | null;
};

class ErrorBoundary extends React.Component<
  PropsWithChildren<ErrorBoundaryProps>,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, message: '' };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true, error, message: error.message };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  render() {
    const { hasError, message, error } = this.state;
    const { children, fallback } = this.props;
    if (hasError) {
      return fallback ? (
        cloneElement(fallback, {
          message,
          error,
        })
      ) : (
        <>
          <Header>Oh no! Something went wrong!</Header>
          <ErrorMessage>{message}</ErrorMessage>
        </>
      );
    }
    return children;
  }
}

const Header = styled('header', {
  fontWeight: '600',
});

const ErrorMessage = styled('div', {
  padding: '1rem',
});
