import React, { useMemo } from 'react';
import RemoveMarkdown from 'remove-markdown';
import { styled } from '@stitches/react';
import { Table } from '@/components/table/table';
import { Time } from '@/components/date-time';
import { useNavigation } from '@/hooks';
import { Severity } from './components/severity';

import { IncidentWidget } from './incident-widget';
import { IncidentWidgetTitle } from './incident-widget-title';
import { withIncidentsV2 } from './with-incidents';

const fields = [
  {
    id: 'title',
    label: 'Name',
    ellipsis: true,
    disableSortBy: true,
    width: '60%',
    canNotBeHidden: true,
  },
  {
    id: 'severity',
    label: 'Severity',
    ellipsis: true,
    disableSortBy: true,
    canNotBeHidden: true,
  },
  {
    id: 'createdAt',
    label: 'Creation date',
    ellipsis: true,
    disableSortBy: true,
    canNotBeHidden: true,
  },
];

const initialParams = {};

function IncidentSimilarIncidentsView({ incidents, score, title, description }) {
  const { navigateTo } = useNavigation();
  const cells = useMemo(() => {
    return {
      title: ({ value, row }) => {
        return (
          <>
            {value}
            <br />
            <Subtitle>{RemoveMarkdown(row.original.content?.description)}</Subtitle>
          </>
        );
      },
      severity: ({ value }) => {
        return <Severity size="lg" severity={value} />;
      },
      createdAt: ({ value }) => <Time dateTime={value} format="P" />,
    };
  }, []);

  if (!incidents) return null;

  return (
    <>
      <br />
      <IncidentWidget
        zeroPadding
        score={score}
        description={description}
        titleElement={<IncidentWidgetTitle title={title} linkText="View All" to="/incidents" />}>
        <TableWrapper>
          <Table
            id="similar-incidents-table"
            initialParams={initialParams}
            fields={fields}
            cells={cells}
            data={incidents}
            loading={false}
            total={false}
            hasNextPage={false}
            bulkActionsEnabled={false}
            toggleRowSelectedOnClick
            onSelectedEntities={(e) => {
              const keys = Object.keys(e);
              if (keys && keys.length > 0) {
                navigateTo(`/incidents/${keys[0]}`);
              }
            }}
          />
        </TableWrapper>
      </IncidentWidget>
    </>
  );
}

export function IncidentSimilarIncidents({ entities, incidentId, ...restProps }) {
  const entitiesSplitByType = {
    resourcesIds: [],
    groupIds: [],
    distinctIdentitiesIds: [],
    accountIds: [],
  };

  entities.forEach((entity) => {
    if (entity.object === 'group') {
      entitiesSplitByType.groupIds.push(entity.id);
    }
    if (entity.object === 'asset') {
      entitiesSplitByType.resourcesIds.push(entity.id);
    }
    if (entity.object === 'identity') {
      entitiesSplitByType.distinctIdentitiesIds.push(entity.id);
    }
    if (entity.object === 'account') {
      entitiesSplitByType.accountIds.push(entity.id);
    }
  });

  const params = {
    limit: 5,
    skip: 0,
    sort: 'severity',
    order: 'desc',
    filter: {
      $and: [
        {
          $or: Object.entries(entitiesSplitByType)
            .map(([key, value]) =>
              value.length
                ? {
                    [key]: { $in: value },
                  }
                : null
            )
            .filter((item) => !!item),
        },
        {
          $and: [
            {
              ids: { $nin: [incidentId] },
            },
          ],
        },
      ],
    },
  };

  const View = withIncidentsV2(IncidentSimilarIncidentsView, params);

  return <View {...restProps} />;
}

const TableWrapper = styled('div', {
  height: 326,
});

const Subtitle = styled('h2', {
  fontSize: '0.75rem',
  fontWeight: '500',
  color: '$slate700',
  marginBottom: '0.25rem',
  display: '-webkit-box',
  webkitLineClamp: '2',
  webkitBoxOrient: 'vertical',
  overflow: 'hidden',
});
