import React from 'react';
import { cx, styled } from '@/stitches.config';
import { CloseIcon } from '../../icons';
import { AsyncBoundary } from '../bounderies/async-boundary';

export const slideOverWidthSm = 500;
export const slideOverWidthMd = 750;

export function SlideOver({
  header,
  subheader,
  actions,
  isOpen,
  onClose,
  children,
  headerCss = {},
}) {
  return (
    <SlideOverContainer className={cx(isOpen && 'open')}>
      <Header>
        <HeaderWrapper css={headerCss}>
          <AsyncBoundary>
            {typeof header === 'string' ? <TopHeader>{header}</TopHeader> : header}
            <SubHeader>{subheader}</SubHeader>
          </AsyncBoundary>
        </HeaderWrapper>
        <ClosSection>
          {actions}
          <CloseIcon onClick={onClose} />
        </ClosSection>
      </Header>
      {isOpen && (
        <ContentContainer>
          <AsyncBoundary>{children}</AsyncBoundary>
        </ContentContainer>
      )}
    </SlideOverContainer>
  );
}

const HeaderWrapper = styled('div', {});

const SlideOverContainer = styled('div', {
  position: 'fixed',
  top: '0',
  right: '0',
  visibility: 'hidden',
  transition: 'all 0.4s',
  height: '100%',
  width: '100%',
  padding: '0 $36 $36 $36',
  backgroundColor: '$white',
  boxShadow:
    '0px 4px 15px rgb(115 115 115 / 25%), 0px 0px 4px rgb(154 162 185 / 7%),\n    0px 3px 10px rgb(228 234 244 / 32%)',
  overflow: 'auto',
  zIndex: '$10',

  '&.open': {
    visibility: 'visible',
  },

  '@sm': {
    width: '500px',
  },

  '@md': {
    width: '750px',
  },
});

const Header = styled('div', {
  position: 'sticky',
  top: '0',
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'space-between',
  cursor: 'pointer',
  background: '$white',
  paddingTop: '$36',
  paddingBottom: '$16',
  zIndex: '$3',
});

const TopHeader = styled('div', {
  flexGrow: '1',
  fontWeight: '$600',
  fontSize: '$20',
  lineHeight: '$32',
  color: '$black',
  cursor: 'pointer',
});

const SubHeader = styled('div', {
  width: '100%',
  fontWeight: '$400',
  fontSize: '$16',
  lineHeight: '$24',
  letterSpacing: '0.15px',
  color: '$slate700',
});

const ClosSection = styled('div', {
  display: 'flex',
  fontSize: '$20',
  alignItems: 'center',
  gap: '$8',

  '& svg': {
    cursor: 'pointer',
  },
});

const ContentContainer = styled('div', {
  marginTop: '$16',
});
