import { Link } from 'react-router-dom';
import { styled } from '@/stitches.config';

import { Subtitle } from '@/components/text';

export const SubtitleWithIcon = styled(Subtitle, {
  display: 'flex',
  '& > .icon': {
    display: 'inline-flex',

    '& svg': {
      width: '1.15em',
      height: '1.3rem',
    },
  },
});

export const StyledLink = styled(Link, {
  display: 'flex',
  alignItems: 'center',
  '& span': {
    color: '$iris800',
    marginRight: '$4',
  },
  '& svg': {
    width: 15,
  },
  '& svg path': {
    fill: '$iris800',
  },
});

export const LinkWrapper = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  marginBottom: '$8',
});
