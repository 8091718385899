import React, { ReactNode, useMemo, useState } from 'react';
import { AxiosResponse } from 'axios';
import { FetchNextPageOptions, InfiniteQueryObserverResult } from 'react-query';
import { DeprecatedToUseDirectlyTable } from '@/components/table/deprecated-to-use-directly-table';
import { css, styled } from '@/stitches.config';
import { FixMeLater } from '@/models/common';
import { PaginationResponse } from '@/models/api';
import storage from '@/lib/storage';
import { Field } from '@/components/table/constants';
import useEffectOnce from '@/hooks/use-effect-once';
import {
  DropdownWithCheckboxSelect,
  getInitialVisibleItems,
} from '@/components/dropdown-with-checkbox-select';
import { ColumnsIcon } from '@/icons';
import { Button } from '@/components/buttons/button';
import { AsyncBoundary } from '../bounderies/async-boundary';
import { TotalResults } from './total-results';

type TableProps<T> = {
  id: string;
  initialParams?: Record<string, unknown>;
  selectedRowIds?: string[];
  fields: Field[];
  cells: Record<string, (props: { row: { original: T }; value: unknown }) => ReactNode>;
  data: T[];
  onFetchMore?: (
    params: FetchNextPageOptions | undefined
  ) => Promise<void | InfiniteQueryObserverResult<
    AxiosResponse<{ data: T[]; pagination: PaginationResponse }, FixMeLater>,
    unknown
  >>;
  hasNextPage?: boolean;
  total: number;
  totalPlaceholder?: { singular: string; plural: string };
  loading: boolean;
  error?: string;
  pullRightHeaderComponent?: ReactNode;
  bulkActionsEnabled?: boolean;
  renderBulkActions?: (params: {
    selectedEntities: T[];
    rowsCount: number;
    hasMoreEntities: boolean;
  }) => ReactNode;
  onSelectedEntities?: () => void;
  toggleRowSelectedOnClick?: boolean;
  maxLimit?: number;
  activeRowId?: string | null;
  onRowClick?: (row: T) => void;
  filtersEnabled?: boolean;
  allowColumnsResize?: boolean;
  allowDragNDrop?: boolean;
  scrollOutsideTable?: boolean;
  warning?: string;
};

/**
 * This component is created to decorate the old table with total feature
 * and incorporate common styles that should be used for all table components.
 * These components should be merged into one as soon as old inventory pages are deprecated
 * and scope picker table is updated.
 */
export function Table<T>({
  id,
  loading,
  total,
  totalPlaceholder = { singular: 'Item', plural: 'Items' },
  pullRightHeaderComponent = null,
  maxLimit: MAX_ITEMS = Infinity,
  warning,
  ...props
}: TableProps<T>) {
  const hasColumnSelector = useMemo(
    () => props.fields.some(({ canNotBeHidden }) => !canNotBeHidden),
    [props.fields]
  );
  const [visibleColumns, setVisibleColumns] = useState<Record<string, string | boolean>>(
    getInitialVisibleItems(props.fields, (item) => item.id, storage.get(id)?.visibleColumns)
  );

  // to keep backward compatibility with prev structure of table config
  useEffectOnce(() => {
    const config = storage.get(id);

    if (!config?.visibleColumns) {
      storage.set(id, {
        visibleColumns:
          config || props.fields.reduce((a, v) => ({ ...a, [v.id]: !v.initiallyHidden }), {}),
        columnsSizes: {},
      });
    }
  });

  return (
    <AsyncBoundary>
      <TableContainer maxHeight={!props.scrollOutsideTable}>
        <TableHeader>
          <TotalResults
            total={total}
            maxLimit={MAX_ITEMS}
            itemLabel={totalPlaceholder.singular}
            itemsLabel={totalPlaceholder.plural}
            warning={warning}
          />

          <PullRightContainer>
            {hasColumnSelector && (
              <DropdownWithCheckboxSelect
                items={props.fields}
                selectedItems={visibleColumns}
                onChange={(columns) => {
                  const config = storage.get(id);
                  storage.set(id, {
                    ...config,
                    visibleColumns: columns,
                  });
                  setVisibleColumns(columns);
                }}
                itemToString={(item) => item.label as string}
                itemToIdentifier={({ id: colId }) => colId}
                triggerElement={
                  <Button color="gray" css={{ margin: '$12 $16 0 0' }}>
                    <ColumnsIcon />
                    Columns
                  </Button>
                }
              />
            )}
            {pullRightHeaderComponent}
          </PullRightContainer>
        </TableHeader>
        {/* @ts-ignore */}
        <DeprecatedToUseDirectlyTable
          id={id}
          {...props}
          visibleFields={props.fields
            .filter((field) => visibleColumns[field.id])
            .map((field) => field.id)}
          loading={loading}
          styles={tableStyles}
        />
      </TableContainer>
    </AsyncBoundary>
  );
}

const TableContainer = styled('div', {
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  backgroundColor: '$white',
  border: '1px solid $gray400',
  borderRadius: '$4',
  boxShadow: '0px 0px 4px 1px rgba(203, 205, 208, 0.1), 0px 2px 4px 0px rgba(80, 80, 80, 0.05)',
  flexGrow: '1',
  variants: {
    maxHeight: {
      true: {
        height: '100%',
      },
    },
  },
});

const TableHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});

const tableStyles = {
  container: css({
    flex: 1,
    height: 'auto',
  }),
  table: css({
    '& tr': {
      borderTop: '1px solid $gray300',
      '&:first-child': {
        borderTop: 0,
      },
    },
    '& tr:hover': {
      background: '$gray200',
    },
    '& tr.selected': {
      background: '$iris200',
      borderTop: '1px solid $white',
    },
    '& tr.quickView': {
      background: '$gray300',
    },
  }),
  thead: css({
    '& th': {
      '&.selection': {
        width: '3.5rem',
      },
    },
  }),
  cell: css({
    borderTop: 0,
  }),
};

const PullRightContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
});
