import React, { ReactElement, useState } from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';
import { styled, keyframes } from '@/stitches.config';
import { StyledComponent } from '@/models/common';
import { ChevronUpIcon, ChevronDownIcon } from '../icons';
import { AsyncBoundary } from './bounderies/async-boundary';

type CollapsableCardProps = {
  header: string;
  headerElement?: ReactElement;
  children: ReactElement;
  initialOpenState?: boolean;
  background?: string;
};

export function CollapsableCard({
  header,
  headerElement = undefined,
  children,
  initialOpenState = true,
  background = '$gray200',
}: CollapsableCardProps) {
  const [open, setOpen] = useState(initialOpenState);

  return (
    <CollapsableCardContainer css={{ background }}>
      <Collapsible.Root open={open} onOpenChange={setOpen}>
        <Header>
          <ArrowSection>{open ? <ChevronUpIcon /> : <ChevronDownIcon />}</ArrowSection>
          {headerElement || <h3>{header}</h3>}
        </Header>

        <CollapsibleContent>
          <AsyncBoundary>{children}</AsyncBoundary>
        </CollapsibleContent>
      </Collapsible.Root>
    </CollapsableCardContainer>
  );
}

const CollapsableCardContainer: StyledComponent = styled('div', {
  margin: '0 0 $16 0',
  background: '$gray200',
  borderRadius: '$4',

  '&:last-of-type': {
    margin: '0',
  },
});

const ArrowSection = styled('div', {
  width: '$24',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '& svg': {
    cursor: 'pointer',
    fontSize: '$24',
  },
});

const Header = styled(Collapsible.Trigger, {
  display: 'flex',
  width: '100%',
  padding: '$16',
  cursor: 'pointer',
  backgroundColor: 'transparent',
  alignItems: 'center',

  '& h3': {
    flexGrow: '1',
    textAlign: 'left',
    marginLeft: '$8',
    fontWeight: '$600',
    fontSize: '$16',
    lineHeight: '$24',
    letterSpacing: '0.0015em',
  },
});

const open = keyframes({
  from: { height: 0 },
  to: { height: 'var(--radix-collapsible-content-height)' },
});

const close = keyframes({
  from: { height: 'var(--radix-collapsible-content-height)' },
  to: { height: 0 },
});

const CollapsibleContent = styled(Collapsible.Content, {
  padding: '$16 $48',
  overflow: 'hidden',
  '&[data-state="open"]': { animation: `${open} 300ms ease-out forwards` },
  '&[data-state="closed"]': { animation: `${close} 300ms ease-out forwards` },
});
