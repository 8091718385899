import { BaseResource } from '../base-resource';

export default class IncidentResource extends BaseResource {
  id = undefined;
  title = '';
  category = '';
  tactics = [];
  status = '';
  severity = '';
  content = {};
  techniques = [];
  complianceTags = {};
  complianceStandards = [];
  entities = [];
  policyId = undefined;
  policy = {};
  hasSuggestedPolicy = undefined;
  eventContexts = [];

  pk() {
    return this.id?.toString();
  }

  static batchUpdate() {
    const endpoint = this.create();

    return endpoint.extend({
      schema: { data: [this] },
      url() {
        return `/api/issues-batch`;
      },
      fetch(...args) {
        return endpoint.fetch.call(this, ...args);
      },
    });
  }

  static triggerWebHook() {
    const endpoint = this.detail();

    return endpoint.extend({
      url({ id }) {
        return `${IncidentResource.urlRoot}/${id}/trigger-webhook`;
      },
      fetch(...args) {
        return endpoint.fetch.call(this, ...args);
      },
    });
  }

  static triggerWebHookById() {
    const endpoint = this.detail();

    return endpoint.extend({
      url({ id, webhookId }) {
        return `${IncidentResource.urlRoot}/${id}/webhooks/${webhookId}`;
      },
      fetch(...args) {
        return endpoint.fetch.call(this, ...args);
      },
    });
  }

  static urlRoot = '/api/incidents';
}
