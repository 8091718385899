import { BaseResource } from './base-resource';

export default class StorageFileResource extends BaseResource {
  url = '';
  fileName = '';

  pk(response) {
    return response?.url || '';
  }

  static uploadUrl() {
    const endpoint = this.detail();

    return this.detail().extend({
      async fetch({ fileExtension }) {
        const response = await endpoint.fetch.call(this, { fileExtension });
        // To use url as cache key in pk method
        response.url = this.url({ fileExtension });
        return response;
      },
      url({ fileExtension }) {
        const queryParams = new URLSearchParams({ fileExtension });

        return `${StorageFileResource.urlRoot}/upload?${queryParams}`;
      },
    });
  }

  static downloadUrl() {
    const endpoint = this.detail();

    return this.detail().extend({
      async fetch(params) {
        const response = await endpoint.fetch.call(this, params);
        // To use url as cache key in pk method
        response.url = this.url(params);
        return response;
      },
      url({ fileName }) {
        const queryParams = new URLSearchParams({ fileName });

        return `${StorageFileResource.urlRoot}/download?${queryParams}`;
      },
    });
  }

  static publicUploadUrl() {
    const endpoint = this.detail();

    return this.detail().extend({
      async fetch({ fileExtension }) {
        const response = await endpoint.fetch.call(this, { fileExtension });
        // To use url as cache key in pk method
        response.url = this.url({ fileExtension });
        return response;
      },
      url({ fileExtension }) {
        const queryParams = new URLSearchParams({ fileExtension });

        return `${StorageFileResource.urlRoot}/public/upload?${queryParams}`;
      },
    });
  }

  static urlRoot = '/api/storage';
}
