import { styled } from '@/stitches.config';

export const SettingsPageContent = styled('div', {
  width: '100%',
  padding: '1.5rem 2rem',
});

export const SettingsPageTitle = styled('h3', {
  fontWeight: '600',
  fontSize: '1.25rem',
  letterSpacing: '0.1px',
  color: '$blue800',
});
