/**
 * Generated by orval v6.17.0 🍺
 * Do not edit manually.
 * Authomize Application API
 * OpenAPI spec version: 1.0
 */
import axios from 'axios';
import type { AxiosRequestConfig, AxiosResponse } from 'axios';
import type {
  GetApiUsers200,
  GetApiUsersParams,
  PostApiUsers200,
  PostApiUsersBody,
  PatchApiUsersUserKey200,
  PatchApiUsersUserKeyBody,
  PatchApiUsersUserKeyBlock200,
  PatchApiUsersUserKeyBlockBody,
  PostApiUsersUserKeyResetPassword200,
  PostApiUsersChangePassword200,
  ChangePassword,
  PostApiUsersSearch200,
  PostApiUsersSearchBody,
  GetApiUsersSearch200,
  GetApiUsersSearchParams,
  GetApiUsersUserId200,
} from '../api.schemas';

export const getApiUsers = <TData = AxiosResponse<GetApiUsers200>>(
  params?: GetApiUsersParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/users`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
export const postApiUsers = <TData = AxiosResponse<PostApiUsers200>>(
  postApiUsersBody: PostApiUsersBody,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/api/users`, postApiUsersBody, options);
};
export const patchApiUsersUserKey = <TData = AxiosResponse<PatchApiUsersUserKey200>>(
  userKey: string,
  patchApiUsersUserKeyBody: PatchApiUsersUserKeyBody,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.patch(`/api/users/${userKey}`, patchApiUsersUserKeyBody, options);
};
export const deleteApiUsersUserKey = <TData = AxiosResponse<void>>(
  userKey: string,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.delete(`/api/users/${userKey}`, options);
};
export const patchApiUsersUserKeyBlock = <TData = AxiosResponse<PatchApiUsersUserKeyBlock200>>(
  userKey: string,
  patchApiUsersUserKeyBlockBody: PatchApiUsersUserKeyBlockBody,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.patch(`/api/users/${userKey}/block`, patchApiUsersUserKeyBlockBody, options);
};
export const postApiUsersUserKeyResetPassword = <
  TData = AxiosResponse<PostApiUsersUserKeyResetPassword200>
>(
  userKey: string,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/api/users/${userKey}/reset-password`, undefined, options);
};
export const postApiUsersChangePassword = <TData = AxiosResponse<PostApiUsersChangePassword200>>(
  changePassword: ChangePassword,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/api/users/change-password`, changePassword, options);
};
export const postApiUsersSearch = <TData = AxiosResponse<PostApiUsersSearch200>>(
  postApiUsersSearchBody: PostApiUsersSearchBody,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.post(`/api/users/search`, postApiUsersSearchBody, options);
};
export const getApiUsersSearch = <TData = AxiosResponse<GetApiUsersSearch200>>(
  params?: GetApiUsersSearchParams,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/users/search`, {
    ...options,
    params: { ...params, ...options?.params },
  });
};
export const getApiUsersUserId = <TData = AxiosResponse<GetApiUsersUserId200>>(
  userId: string,
  options?: AxiosRequestConfig
): Promise<TData> => {
  return axios.get(`/api/users/${userId}`, options);
};
