import { Body, Subtitle } from '@/components/text';
import React from 'react';
import {
  HoverCardArrow,
  HoverCardNameLink,
  HoverCardRow,
} from '@/components/entity-cards/entity-card-styles';
import { AppIdsIconsList } from '@/components/app-icons-list';
import { TagsList } from '@/components/tags-list';
import { EntityTypeIcon, IconWrapper } from '@/components/entity-icon';
import { HoverCardActions } from '@/components/entity-cards/hover-card-actions';
import { IdentityModel } from '@/models/api';

export function IdentityHoverCard({ identity }: { identity: IdentityModel }) {
  return (
    <>
      <HoverCardArrow />
      <HoverCardRow css={{ flexDirection: 'row', gap: '$8', alignItems: 'center' }}>
        <IconWrapper circle css={{ display: 'inline-block', minWidth: '40px' }} size="sm">
          <EntityTypeIcon entity={identity} />
        </IconWrapper>
        <HoverCardNameLink href={`/inventory/identities/${identity.id}`}>
          {identity.name}
        </HoverCardNameLink>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Email
        </Subtitle>
        <Body size="3">{identity?.email || '-'}</Body>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Title
        </Subtitle>
        <Body size="3">{identity.title || '-'}</Body>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Department
        </Subtitle>
        <Body size="3">{identity.department || '-'}</Body>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Incidents
        </Subtitle>
        <Body size="3">{identity.openIncidentsCount || '-'}</Body>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Accounts ({identity.accountIds?.length || 0})
        </Subtitle>
        <AppIdsIconsList
          appIds={identity?.sourceAppIds || identity?.sourceApps!.map(({ id }) => id)}
          maxLimit={5}
        />
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Tags ({identity.tags?.length})
        </Subtitle>
        <TagsList tags={identity.tags} maxLimit={3} />
      </HoverCardRow>
      <HoverCardActions
        SPELink={`/inventory/identities/${identity.id}`}
        graphLink={`/access-explorer?sourceId=${identity.id}&sourceType=identity`}
        showExternal={false}
      />
    </>
  );
}
