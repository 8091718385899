import React, { useEffect } from 'react';
import { useReactFlow } from 'reactflow';
import { useFullscreen } from 'rooks';
import { Link } from 'react-router-dom';
import { styled } from '@/stitches.config';
import {
  PlusIcon,
  MinusIcon,
  ExpandIcon,
  ContractIcon,
  CloseIcon,
  SearchIcon,
  TagsIcon,
} from '@/icons';
import { Button } from '@/components/buttons/button';
import { Tooltip } from '@/components/tooltip';
import { atom, useAtom, useAtomValue } from 'jotai';
import { latestGraphDataTimestamp } from '@/views/inventory/access-explorer/use-graph-data';
import { HighlightEdgesByTimeAdded } from '@/views/inventory/access-explorer/components/highlight-edges-by-time-added';
import { GRAPH_VIEW_ZOOM_LEVEL } from '../graph-view';

export const showNodesExtendedDetails = atom(true);

export function GraphControls({ reactFlowRef, container, navigateToExplore, graphUrl }) {
  const { zoomIn, zoomOut } = useReactFlow();
  const { toggleFullscreen, isFullscreenEnabled } = useFullscreen({ target: container });
  const [showDetails, setShowDetails] = useAtom(showNodesExtendedDetails);
  const timestamp = useAtomValue(latestGraphDataTimestamp);

  useEffect(() => {
    if (timestamp) {
      reactFlowRef.current?.fitView({
        maxZoom: GRAPH_VIEW_ZOOM_LEVEL,
        minZoom: 0.5,
      });
    }
  }, [isFullscreenEnabled, timestamp, reactFlowRef]);

  return (
    <ControlsWrapper>
      <Wrapper>
        <Tooltip label="Zoom in">
          <Button color="gray" circle size="lg" onClick={() => zoomIn()} css={{ zIndex: '$4' }}>
            <PlusIcon />
          </Button>
        </Tooltip>
        <Tooltip label="Zoom out">
          <Button color="gray" circle size="lg" onClick={() => zoomOut()} css={{ zIndex: '$4' }}>
            <MinusIcon />
          </Button>
        </Tooltip>
        <Tooltip label="Show/hide entities tags">
          <Button
            color={showDetails ? 'primary' : 'gray'}
            circle
            size="lg"
            onClick={() => setShowDetails(!showDetails)}
            css={{ zIndex: '$4' }}>
            <TagsIcon />
          </Button>
        </Tooltip>

        <Tooltip label={isFullscreenEnabled ? 'Collapse Full Screen' : 'Open in Full Screen'}>
          <Button color="gray" circle size="lg" onClick={toggleFullscreen} css={{ zIndex: '$4' }}>
            {isFullscreenEnabled ? <ContractIcon /> : <ExpandIcon />}
          </Button>
        </Tooltip>
        <HighlightEdgesByTimeAdded />
        {navigateToExplore && (
          <Tooltip label="Investigate">
            <Button color="gray" circle size="lg" as={Link} to={graphUrl} css={{ zIndex: '$4' }}>
              <SearchIcon />
            </Button>
          </Tooltip>
        )}
      </Wrapper>
      {isFullscreenEnabled && (
        <Button color="gray" circle size="lg" onClick={toggleFullscreen} css={{ zIndex: '$4' }}>
          <CloseIcon />
        </Button>
      )}
    </ControlsWrapper>
  );
}

const ControlsWrapper = styled('div', {
  width: '100%',
  height: '100%',
  display: 'flex',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '1rem',
});

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignSelf: 'flex-end',
  justifyContent: 'space-between',
  gap: '0.5rem',
});
