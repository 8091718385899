import React from 'react';
import { styled, css } from '@/stitches.config';
import SurpriseIcon from './icons/suprise.svg';

export function AddedNotification({ title, description }: { title: string; description: string }) {
  return (
    <Container>
      <TextContainer>
        <Title>{title}</Title>
        <Description>{description}</Description>
      </TextContainer>
      <SurpriseIcon />
    </Container>
  );
}

export const wrapperStyle = css({
  minWidth: '36rem',
  backgroundColor: '$iris100',
  border: '1px solid $gray300',
  borderRadius: '1rem',
});

const Container = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flex: '1',

  '& > svg': {
    width: '5rem',
    height: '5rem',
  },
});

const TextContainer = styled('div', {
  maxWidth: '25rem',
  marginRight: '1rem',
});

const Title = styled('h1', {
  fontSize: '1.5rem',
  color: 'var(--color-deepblue)',
  fontWeight: '600',
  marginBottom: '0.5rem',
});

const Description = styled('p', {
  fontSize: '0.8rem',
  color: '$slate800',
  fontWeight: '400',
});
