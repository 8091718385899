import { Body, Subtitle } from '@/components/text';
import React from 'react';
import {
  HoverCardNameLink,
  HoverCardArrow,
  hoverCardLogo,
  HoverCardRow,
} from '@/components/entity-cards/entity-card-styles';
import { TagsList } from '@/components/tags-list';
import { IconWrapper } from '@/components/entity-icon';
import { SourceIcon } from '@/components/icons/sources';
import { useApps } from '@/hooks/use-apps';
import { HoverCardActions } from '@/components/entity-cards/hover-card-actions';
import { AccountModel, GroupModel } from '@/models/api';

export function GroupHoverCard({ group }: { group: GroupModel }) {
  const { getAppById } = useApps();

  return (
    <>
      <HoverCardArrow />
      <HoverCardRow css={{ flexDirection: 'row', gap: '$8', alignItems: 'center' }}>
        <IconWrapper css={{ display: 'inline-block', minWidth: '40px' }} size="sm">
          <SourceIcon
            {...(group.sourceApp || getAppById(group.sourceAppId))}
            className={hoverCardLogo}
          />
        </IconWrapper>
        <HoverCardNameLink href={`/inventory/groups/${group.id}`}>
          {`${group.name}` || '-'}
        </HoverCardNameLink>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Alternative Name
        </Subtitle>
        <Body size="3">{group.alternativeName || '-'}</Body>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Origin Type
        </Subtitle>
        <Body size="3">{group.externalType || '-'}</Body>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Owner
        </Subtitle>
        <Body size="3">{(group?.owner as AccountModel)?.name || '-'}</Body>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Members
        </Subtitle>
        <Body size="3">{group.membersCount}</Body>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Source App
        </Subtitle>
        <Body size="3">{(group.sourceApp || getAppById(group.sourceAppId))?.name}</Body>
      </HoverCardRow>

      <HoverCardRow>
        <Subtitle size="verySmall" weight="semibold" color="lightgrey">
          Tags ({group.tags?.length})
        </Subtitle>
        <TagsList tags={group.tags} maxLimit={3} />
      </HoverCardRow>
      <HoverCardActions
        SPELink={`/inventory/groups/${group.id}`}
        graphLink={`/access-explorer?sourceId=${group.id}&sourceType=group`}
        externalLink={group?.href}
      />
    </>
  );
}
