import React, { ReactElement } from 'react';
import { styled } from '@/stitches.config';
import { TagModel } from '@/models/api';
import { Tooltip } from './tooltip';
import { Tag } from './tag';
import { AsyncBoundary } from './bounderies/async-boundary';
import { ErrorIconFallback } from './error-icon-fallback';

type TagsListProps = {
  tags: TagModel[];
  maxLimit: number;
  emptyPlaceholder?: ReactElement | null;
  onRemove?: (tag: TagModel) => Promise<void>;
};

export function TagsList(props: TagsListProps) {
  return (
    <AsyncBoundary errorFallback={<ErrorIconFallback />}>
      <TagsListInner {...props} />
    </AsyncBoundary>
  );
}

function TagsListInner({
  tags = [],
  maxLimit = 2,
  emptyPlaceholder = null,
  onRemove,
}: TagsListProps) {
  tags = tags.filter(Boolean);

  if (tags.length === 0) {
    return emptyPlaceholder;
  }

  const visibleTags = tags.slice(0, maxLimit);
  const tooltipTags = tags.slice(maxLimit);
  const hasAdditionalTags = visibleTags.length < tags.length;

  return (
    <List>
      {visibleTags.map((tag) => (
        <Tag key={tag.name} tag={tag} onRemove={onRemove} />
      ))}
      {hasAdditionalTags && (
        <Tooltip
          label={
            <List css={{ flexDirection: 'column' }}>
              {tooltipTags.map((tag) => (
                <Tag key={tag.name} tag={tag} />
              ))}
            </List>
          }>
          <AdditionalNumber>+{tags.length - visibleTags.length}</AdditionalNumber>
        </Tooltip>
      )}
    </List>
  );
}

const List = styled('ul', {
  display: 'inline-flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '$8',
});

const AdditionalNumber = styled('div', {
  height: '$24',
  width: '$24',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.1rem 0.2rem',
  color: '$black',
  fontStyle: 'normal',
  fontWeight: '$600',
  fontSize: '$12',
  background: '$white',
  border: '$1 solid $gray200',
  boxSizing: 'border-box',
  boxShadow: '0px 0px 2px rgba(154, 162, 185, 0.1), 0px 2px 4px rgba(211, 211, 211, 0.32)',
  borderRadius: '$circle',
});
