import { settingsMenuWidth, styled } from '@/stitches.config';

export const HeaderContainer = styled('div', {
  width: '100%',

  '@media print': {
    display: 'none',
  },
});

export const SettingsSidebarContainer = styled('div', {
  minWidth: `${settingsMenuWidth}px`,
  height: '100%',
});
