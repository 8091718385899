import axios from 'axios';
import qs from 'qs';

const baseURL = import.meta.env.VITE_API_URL || '';
axios.defaults.baseURL = baseURL;
export const client = axios.create({
  baseURL: `${baseURL}/api/`,
  paramsSerializer: qs.stringify,
});

// TODO: hasNextPage should be provided by API
export function hasNextPage(pagination) {
  if (!pagination) return false;

  if (typeof pagination?.hasMore === 'boolean') {
    return pagination.hasMore;
  }

  const { skip, limit, total } = pagination;
  if (total === 0) return false;
  if (total < limit) return false;
  if (skip + limit > total) return false;

  return true;
}

export function getErrorMessage(error) {
  if (error?.response) {
    const { data, statusText } = error.response;
    return data?.message || data?.error || statusText;
  }
  return error?.message;
}
