/* eslint-disable react/self-closing-comp */
import React, { Suspense } from 'react';
import { Route, Routes, useMatch } from 'react-router-dom';
import { NetworkErrorBoundary } from 'rest-hooks';
import { styled } from '@/stitches.config';
import { ErrorBoundary } from '@/components/bounderies/error-boundary';
import { Loading } from '@/components/loading';
import { useSession } from '@/hooks';
import { useCurrentRouteHasCustomNavigation } from '@/hooks/use-current-route-has-custom-navigation';
import { ROUTES_WITH_CUSTOM_NAVIGATION, useRoutes } from './routes';

const DefaultLayoutContainer = styled('div', {
  position: 'absolute',
  top: '0',
  left: '0',
  right: '0',
  bottom: '0',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  overflow: 'hidden',
});

const ContentContainer = styled('div', {
  display: 'flex',
  width: '100%',
  flexWrap: 'nowrap',
  overflow: 'hidden',
  flexGrow: '1',
  height: '0',

  '@media print': {
    height: '100%',
  },
});

const Content = styled('div', {
  position: 'relative',
  height: '100%',
  flexGrow: '1',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  background: '$gray200',
});

export default function DefaultLayout() {
  const { isAdmin } = useSession();
  const { pathname: url } = useMatch('*');
  const routes = useRoutes();
  const { header, sidebar } = useCurrentRouteHasCustomNavigation(
    url,
    ROUTES_WITH_CUSTOM_NAVIGATION
  );

  return (
    <DefaultLayoutContainer>
      {!isAdmin && header}
      <ContentContainer id="content">
        {isAdmin && sidebar}
        <ContentAndFooterWrapper>
          <ContentAndScrollToTopWrapper>
            <Content>
              <Suspense fallback={<Loading />}>
                <NetworkErrorBoundary>
                  <Routes>
                    {routes.map(({ component: Component, ...props }, i) => (
                      <Route
                        key={i}
                        {...props}
                        element={
                          <ErrorBoundary key={props.path}>
                            <Component />
                          </ErrorBoundary>
                        }
                      />
                    ))}
                  </Routes>
                </NetworkErrorBoundary>
              </Suspense>
            </Content>
            <div id="scroll-to-top-btn-container"></div>
          </ContentAndScrollToTopWrapper>

          <Footer style={{ flex: '0 0 auto' }} id="table-bulk-actions-container" />
        </ContentAndFooterWrapper>
      </ContentContainer>
    </DefaultLayoutContainer>
  );
}

const ContentAndFooterWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  flexGrow: '1',
  width: '0',
});

const ContentAndScrollToTopWrapper = styled('div', {
  flex: '0 1 100%',
  overflow: 'hidden',
  position: 'relative',
});

const Footer = styled('div', {
  flex: '0 0 auto',
});
