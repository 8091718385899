import { styled } from '@/stitches.config';

export const DatePickerContainer = styled('div', {
  position: 'relative',
  padding: '$8',
  backgroundColor: '$white',
  boxShadow: '0px 2px 10px rgba(25, 1, 52, 0.12)',

  '.rdp-caption_label': {
    fontSize: '$20',
  },

  '.rdp-button:focus, .rdp-button:active': {
    border: 'none',
  },

  '.datepicker-selected:not([disabled])': {
    backgroundColor: '$iris200',
  },

  '.datepicker-selected:hover:not([disabled])': {
    color: '$iris900',
  },

  '.rdp-button.datepicker-today': {
    fontWeight: 'bold',
    color: '$iris900',
  },

  '.datepicker-range-start': {
    border: 'none',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
  },

  '.datepicker-range-end': {
    border: 'none',
    borderTopLeftRadius: '0',
    borderBottomLeftRadius: '0',
  },

  '.datepicker-range-start.datepicker-range-end': {
    borderRadius: '$circle',
  },
});
