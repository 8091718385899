import React from 'react';
import { InfoIcon } from '../../../icons';

export function StepDescription({ index, title, description, linkTitle, link }) {
  return (
    <>
      <h3>
        {index + 1}. {title}
      </h3>
      <p>{description}</p>
      {linkTitle && link && (
        <a href={link} target="_blank">
          <InfoIcon />
          {linkTitle}
        </a>
      )}
    </>
  );
}
