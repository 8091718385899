import React, { cloneElement, useCallback, useMemo, useRef, useState } from 'react';
import * as Popover from '@radix-ui/react-popover';
import { useCombobox } from 'downshift';
import { FixMeLater } from '@/models/common';
import { nFormatter } from '@/lib/utils';
import { DataFetcher, useSearchAsYouType } from '@/hooks';
import { ChevronDownIcon } from '@/icons';
import {
  ComboboxWrapper,
  HiddenInput,
  List,
  ListItem,
  ListWrapper,
  Placeholder,
  PopoverWrapper,
  Search,
  SelectedOptionsAndInput,
  TriggerButton,
} from '@/components/comboboxes/combobox-shared-styles';
import { LoadingDots } from '../loading';
import { FetchingButton } from '../buttons/fetching-button';
import { Small } from '../text';
import { useExpandValues } from '../filters/use-expand-values';
import { OptionsMap } from '../filters/models';

const CREATE_ITEM_IDENTIFIER = '___CREATE_ITEM_ID___';

type ItemToString<Item> = (item: Item | null | undefined) => string;

type SingleSelectComboboxProps<Item> = {
  disabled: boolean;
  triggerElement?: React.ReactNode;
  itemElement: React.ReactNode;
  itemToIdentifier: ItemToString<Item>;
  itemToString: ItemToString<Item>;
  selectedItem?: Item;
  emptyItem?: Item | null;
  items?: Item[];
  fetchItems: DataFetcher;
  onChange: (selectedItem: Item | null) => void;
  allowEmptySelection?: boolean;
  placeholder: string;
  required?: boolean;
  allowCreate?: boolean;
  onCreate?: (inputValue: string) => void;
  expandSelectedValues?: (values?: string[]) => Promise<OptionsMap>;
};

type SingleSelectPopoverProps<Item> = Omit<
  SingleSelectComboboxProps<Item>,
  'disabled' | 'triggerElement' | 'placeholder'
> & {
  closePopover: () => void;
};

export function SingleSelectCombobox<Item>({
  disabled,
  triggerElement = <DefaultTrigger placeholder="" itemToString={() => ''} selectedItem={null} />,
  required = false,
  placeholder = '',
  expandSelectedValues,
  selectedItem: rawSelectedItem,
  onChange,
  emptyItem: rawEmptyItem,
  ...props
}: SingleSelectComboboxProps<Item>) {
  const emptyItem = rawEmptyItem || 'None';
  const [isOpen, setIsOpen] = useState(false);
  const closePopover = useCallback(() => setIsOpen(false), []);
  const { expandedValues, addIfNotExists, isLoading } = useExpandValues({
    expandFn: expandSelectedValues,
    values: [rawSelectedItem] as unknown as string[],
    once: true,
  });

  const onSelectChange = useCallback(
    (selectedValue: FixMeLater) => {
      addIfNotExists(selectedValue);

      onChange(selectedValue);
    },
    [addIfNotExists, onChange]
  );

  const selectedItem: Item = expandSelectedValues
    ? ((expandedValues.get(rawSelectedItem as unknown as string) || {
        value: rawSelectedItem,
        label: rawSelectedItem,
      }) as unknown as Item)
    : (rawSelectedItem as Item);

  if (!React.isValidElement(triggerElement)) return null;

  return (
    <PopoverWrapper>
      <Popover.Root open={isOpen} onOpenChange={setIsOpen}>
        <Popover.Anchor />
        <Popover.Trigger asChild>
          {isLoading ? (
            <LoadingDots />
          ) : (
            cloneElement(triggerElement, {
              disabled,
              itemToString: props.itemToString,
              placeholder,
              selectedItem: selectedItem || emptyItem,
            } as FixMeLater)
          )}
        </Popover.Trigger>
        <Popover.Anchor asChild>
          <HiddenInput
            name="hidden"
            type="text"
            required={required}
            defaultValue={props.itemToIdentifier(selectedItem)}
          />
        </Popover.Anchor>
        <Popover.Content align="start">
          <SingleSelectPopover closePopover={closePopover} onChange={onSelectChange} {...props} />
        </Popover.Content>
      </Popover.Root>
    </PopoverWrapper>
  );
}

function SingleSelectPopover<Item>({
  items: initialItems = [],
  fetchItems,
  selectedItem,
  onChange,
  itemToIdentifier,
  itemToString,
  itemElement = <Item itemToString={() => ''} item={null} />,
  closePopover,
  allowEmptySelection = false,
  emptyItem,
  allowCreate = false,
  onCreate,
}: SingleSelectPopoverProps<Item>) {
  if (selectedItem && !onChange) {
    console.warn('SingleSelectCombobox:: received selectedItem prop without onChange');
  }

  // Only fetch items if not options were passed explicitly
  const fetchItemCallback =
    initialItems.length > 0
      ? async (search: string) =>
          initialItems.filter((item) =>
            itemToString(item).toLowerCase().includes(search.toLowerCase())
          )
      : fetchItems;
  const inputRef = useRef<HTMLInputElement | null>(null);

  const {
    searchQuery: inputValue,
    setSearchQuery: setInputValue,
    loading: isLoading,
    results: items,
    hasMoreResults,
    fetchMore,
    pagination,
  } = useSearchAsYouType(fetchItemCallback, {
    initialResults: initialItems,
    resetWhenQueryEmpty: false,
    fetchWithEmptyQuery: true,
  });

  const itemsWithEmptyOrCreate = useMemo(() => {
    if (allowEmptySelection && items.length > 0 && emptyItem) {
      return [emptyItem, ...items];
    }

    const itemNotFound = !items.find((item) => itemToString(item) === inputValue);

    if (allowCreate && inputValue?.length > 0 && !isLoading && itemNotFound) {
      return [
        ...items,
        { id: CREATE_ITEM_IDENTIFIER, name: `${inputValue} (Add New)` } as unknown as Item,
      ];
    }
    return items;
  }, [items, emptyItem, allowEmptySelection, allowCreate, inputValue, isLoading, itemToString]);

  const { getMenuProps, getInputProps, getComboboxProps, highlightedIndex, getItemProps } =
    useCombobox({
      isOpen: true,
      selectedItem,
      inputValue,
      items: itemsWithEmptyOrCreate,
      itemToString,
      onStateChange: ({ inputValue: value, type }) => {
        switch (type) {
          case useCombobox.stateChangeTypes.InputChange:
            setInputValue(value || '');
            // Reset selected value if input is empty
            if (value === '' && onChange) {
              onChange(null);
            }
            break;
          case useCombobox.stateChangeTypes.FunctionOpenMenu:
            inputRef.current?.focus();
            break;
          default:
            break;
        }
      },
      onSelectedItemChange: ({ selectedItem: selectedValue }) => {
        // Handle Item Creation
        if (allowCreate && onCreate && itemToIdentifier(selectedValue) === CREATE_ITEM_IDENTIFIER) {
          onCreate(inputValue);
          return;
        }

        if (onChange) {
          onChange(selectedValue || null);
        }
      },
      onIsOpenChange: ({ isOpen }) => {
        if (isOpen === false) {
          closePopover();
        }
      },
    });

  if (!React.isValidElement(itemElement)) return null;

  return (
    <ComboboxWrapper>
      <SelectedOptionsAndInput {...getComboboxProps()}>
        <Search placeholder="Search..." {...getInputProps({ ref: inputRef })} />
      </SelectedOptionsAndInput>
      <ListWrapper>
        <List {...getMenuProps()}>
          {isLoading && (
            <ListItem>
              <LoadingDots />
            </ListItem>
          )}
          {!isLoading && itemsWithEmptyOrCreate.length === 0 && (
            <ListItem empty>No results</ListItem>
          )}
          {!isLoading &&
            itemsWithEmptyOrCreate.map((item, index) =>
              cloneElement(itemElement, {
                key: `${itemToString(item)}${index}`,
                highlighted: highlightedIndex === index,
                selected: itemToIdentifier(selectedItem) === itemToIdentifier(item),
                item,
                itemToString,
                ...getItemProps({ item, index }),
              })
            )}
          {hasMoreResults && (
            <ListItem>
              <FetchingButton
                kind="text"
                color="primary"
                size="sm"
                block
                css={{ paddingLeft: 0, justifyContent: 'start' }}
                onClick={fetchMore}>
                Load more
              </FetchingButton>
            </ListItem>
          )}
        </List>
        {pagination && pagination.total > 0 && (
          <ListItem>
            <Small size={2} css={{ color: '$slate700' }}>
              {nFormatter(items.length)} of{' '}
              {isLoading ? (
                <LoadingDots />
              ) : (
                nFormatter(pagination.total + (allowEmptySelection ? 1 : 0))
              )}
            </Small>
          </ListItem>
        )}
      </ListWrapper>
    </ComboboxWrapper>
  );
}

type TriggerProps<Item> = {
  itemToString: ItemToString<Item>;
  selectedItem: Item;
  placeholder: string;
};

function DefaultTriggerInner<Item>(
  { itemToString, selectedItem, placeholder, ...props }: TriggerProps<Item>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <TriggerButton kind="outlined" color="gray" size="md" block {...props} ref={ref}>
      {selectedItem && selectedItem !== 'None' ? (
        <span className="ellipsis">{itemToString(selectedItem)}</span>
      ) : (
        <Placeholder>{placeholder}</Placeholder>
      )}
      <ChevronDownIcon />
    </TriggerButton>
  );
}

const DefaultTrigger = React.forwardRef(DefaultTriggerInner);

type ItemProps<Item> = {
  itemToString: ItemToString<Item>;
  item: Item;
};

function ItemInner<Item>(
  { item, itemToString, ...props }: ItemProps<Item>,
  ref: React.ForwardedRef<HTMLLIElement>
) {
  return (
    <ListItem {...props} ref={ref}>
      <span>{itemToString(item)}</span>
    </ListItem>
  );
}

const Item = React.forwardRef(ItemInner);
