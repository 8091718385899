import { createStitches } from '@stitches/react';
import clsx from 'clsx';

export const SCREEN_BREAKPOINTS = {
  XS: 480,
  SM: 768,
  MD: 1224,
  LG: 1492,
  XL: 1824,
};
export const headerHeight = 56;
export const basePageHeaderHeight = 64;
export const tabsHeight = 42;
export const singleEntityPageSidebarWidth = 240;
export const settingsMenuWidth = 264;

export const { styled, css, globalCss, keyframes, getCssText, theme, createTheme, config, media } =
  createStitches({
    theme: {
      colors: {
        iris100: '#F7F9FF',
        iris200: '#EAEEFF',
        iris300: '#D5DDFF',
        iris400: '#ABBBFF',
        iris500: '#9AADFF',
        iris600: '#7F97FF',
        iris700: '#6481FF',
        iris800: '#4266FF',
        iris900: '#2247E4',
        red100: '#F9E7E8',
        red200: '#EAA9AE',
        red300: '#D4545C',
        red400: '#CE3B45',
        red500: '#C8323C',
        red600: '#A52525',
        red700: '#8E232A',
        red800: '#6F1919',
        red900: '#64191E',
        green100: '#D1F3E0',
        green200: '#B3F4CF',
        green300: '#9AF1BF',
        green400: '#87E1AE',
        green500: '#32C873',
        green600: '#37CD78',
        green700: '#29A55F',
        green800: '#21834B',
        green900: '#103F24',
        blue100: '#BAC9F6',
        blue200: '#94ACF2',
        blue300: '#7A98EF',
        blue400: '#5B80EB',
        blue500: '#3260E5',
        blue600: '#1946C9',
        blue700: '#0D266C',
        blue800: '#0A1C50',
        blue900: '#081741',
        orange100: '#FFFAF6',
        orange200: '#FFE7DC',
        orange300: '#FFC0A4',
        orange400: '#FFAB86',
        orange500: '#FF9160',
        orange600: '#FF824B',
        orange700: '#FF7C42',
        orange800: '#FF7408',
        orange900: '#CB5900',
        yellow100: '#FFF3C5',
        yellow200: '#FFF1B6',
        yellow300: '#FFE67C',
        yellow400: '#FFE15F',
        yellow500: '#FFDB42',
        yellow600: '#FFD626',
        yellow700: '#FFCF05',
        yellow800: '#DFB500',
        yellow900: '#C9A200',
        purple100: '#E5DFF0',
        purple200: '#D0BCFF',
        purple300: '#A178FF',
        purple400: '#8F5DFF',
        purple500: '#7C42FF',
        purple600: '#601AFF',
        purple700: '#4A00F1',
        purple800: '#2B008C',
        purple900: '#1F0064',
        pink100: '#FCF0FF',
        pink200: '#F1B6FF',
        pink300: '#E67CFF',
        pink400: '#E15FFF',
        pink500: '#DB42FF',
        pink600: '#D522FF',
        pink700: '#B600E1',
        pink800: '#8200A1',
        pink900: '#680080',
        slate100: '#C1C6D5',
        slate200: '#B3B9CB',
        slate300: '#A7AEC3',
        slate400: '#9AA2B8',
        slate500: '#697495',
        slate600: '#5F6A8A',
        slate700: '#57617D',
        slate800: '#3D4458',
        slate900: '#2D3240',
        gray100: '#FBFBFD',
        gray200: '#F9F9FB',
        gray300: '#EBECF1',
        gray400: '#E2E4EA',
        gray500: '#D8DBE3',
        white: '#fff',
        black: '#000',
      },
      space: {
        2: '2px',
        4: '4px',
        6: '6px',
        8: '8px',
        12: '12px',
        14: '14px',
        16: '16px',
        20: '20px',
        24: '24px',
        28: '28px',
        32: '32px',
        36: '36px',
        40: '40px',
        48: '48px',
        56: '56px',
        64: '64px',
        80: '80px',
      },
      fontSizes: {
        12: '12px',
        14: '14px',
        16: '16px',
        20: '20px',
        24: '24px',
        32: '32px',
        48: '48px',
        56: '56px',
        72: '72px',
      },
      fonts: {},
      fontWeights: {
        300: 300,
        400: 400,
        600: 600,
        700: 700,
      },
      lineHeights: {
        4: '4px',
        6: '6px',
        8: '8px',
        12: '12px',
        14: '14px',
        16: '16px',
        20: '20px',
        24: '24px',
        28: '28px',
        32: '32px',
        36: '36px',
        40: '40px',
        48: '48px',
        56: '56px',
        64: '64px',
        72: '72px',
        80: '80px',
        120: '120px',
      },
      letterSpacings: {},
      sizes: {
        16: '16px',
        24: '24px',
        32: '32px',
        40: '40px',
        48: '48px',
        64: '64px',

        singleEntityPageSidebarWidth: `${singleEntityPageSidebarWidth}px`,
      },
      borderWidths: {
        0: 0,
        1: '1px',
        2: '2px',
        4: '4px',
      },
      borderStyles: {},
      radii: {
        0: 0,
        2: '2px',
        4: '4px',
        6: '6px',
        8: '8px',
        12: '12px',
        16: '16px',
        24: '24px',
        circle: '50%',
      },
      shadows: {},
      zIndices: {
        0: 0,
        1: 1,
        2: 10,
        3: 20,
        4: 50,
        5: 100,
        6: 150,
        7: 200,
        8: 300,
        9: 400,
        10: 1000,
        11: 1250,
        12: 1500,
        13: 1750,
        14: 2000,
        15: 3000,
      },
      transitions: {},
    },
    utils: {
      marginX: (value) => ({ marginLeft: value, marginRight: value }),
    },
    media: {
      xs: `(min-width: ${SCREEN_BREAKPOINTS.XS}px)`,
      sm: `(min-width: ${SCREEN_BREAKPOINTS.SM}px)`,
      md: `(min-width: ${SCREEN_BREAKPOINTS.MD}px)`,
      lg: `(min-width: ${SCREEN_BREAKPOINTS.LG}px)`,
      xl: `(min-width: ${SCREEN_BREAKPOINTS.XL}px)`,
    },
  });

export function cx(...args) {
  return clsx(...args.map((arg) => argumentToString(arg)));
}

function argumentToString(arg) {
  switch (typeof arg) {
    case 'function':
      return arg.toString();
    case 'string':
      return arg;
    case 'object':
      return String(arg);
    default:
      return arg;
  }
}
