import React, { ReactNode } from 'react';
import { styled } from '@/stitches.config';
import { getSeverityColor } from '@/components/severity-dot';

type SeverityProps = {
  severity: string;
  children?: ReactNode;
  bold?: boolean;
  size?: 'sm' | 'lg';
};

export function Severity({ severity, children, bold = false, size = 'sm' }: SeverityProps) {
  return (
    <SeverityLabel
      size={size}
      css={{
        fontWeight: bold ? '600' : '400',
        borderLeftColor: getSeverityColor(severity),
      }}>
      {children || severity}
    </SeverityLabel>
  );
}

const SeverityLabel = styled('span', {
  backgroundColor: '$white',
  border: '1px solid $gray300',
  borderRadius: '$2',
  borderLeftStyle: 'solid',
  borderLeftWidth: '2px',
  padding: '$2 $8',
  fontSize: '1em',
  color: '$black',
  textTransform: 'capitalize',

  variants: {
    size: {
      sm: {
        fontSize: '$12',
        lineHeight: '16px',
      },
      lg: {
        fontSize: '$14',
        lineHeight: '19px',
      },
    },
  },

  defaultVariants: {
    size: 'sm',
  },
});
