import React, { ReactElement } from 'react';
import * as HoverCardPrimitive from '@radix-ui/react-hover-card';
import { styled } from '@/stitches.config';

type HoverCardProps = {
  label: string | ReactElement;
  children: ReactElement;
  side?: 'bottom' | 'top' | 'left' | 'right';
  sideOffset?: number;
};

export function HoverCard({ label, children, side = 'bottom', sideOffset = 0 }: HoverCardProps) {
  return (
    <HoverCardPrimitive.Root>
      <HoverCardPrimitive.Trigger asChild>{children}</HoverCardPrimitive.Trigger>
      <HoverCardPrimitive.Portal>
        <StyledContent side={side} sideOffset={sideOffset}>
          <HoverCardArrow />
          {label}
        </StyledContent>
      </HoverCardPrimitive.Portal>
    </HoverCardPrimitive.Root>
  );
}

const HoverCardArrow = styled(HoverCardPrimitive.Arrow, {
  fill: '$white',
});

const StyledContent = styled(HoverCardPrimitive.Content, {
  maxWidth: '20rem',
  maxHeight: '20rem',
  overflow: 'auto',
  wordBreak: 'break-word',
  borderRadius: '$8',
  padding: '$16',
  fontSize: '$14',
  lineHeight: '$20',
  fontWeight: '$300',
  zIndex: '$10',
  color: '$black',
  backgroundColor: '$white',
  boxShadow: 'hsl(206 22% 7% / 35%) 0px 10px 38px -10px, hsl(206 22% 7% / 20%) 0px 10px 20px -15px',
});
