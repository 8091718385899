import { useMemo } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { alphabeticalSort, makeUrl } from '../lib/utils';

export function useQueryParams() {
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const queryParams = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);

  return {
    queryParams,
    updateQueryParams,
    replaceQueryParams: compareParamsAndPush,
    clearQueryParams,
  };

  function compareParamsAndPush(params) {
    if (
      qs.stringify(queryParams, { sort: alphabeticalSort }) !==
      qs.stringify(params, { sort: alphabeticalSort })
    ) {
      navigate(makeUrl(pathname, params));
    }
  }

  /**
   * Update query parameters
   * @param params
   */
  function updateQueryParams(params) {
    const newQueryParams = { ...queryParams, ...params };
    compareParamsAndPush(newQueryParams);
  }

  /**
   * This function support clearing search query
   * @returns {any}
   */
  function clearQueryParams() {
    navigate(makeUrl(pathname, {}));
  }
}

export function useNavigation() {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { queryParams, updateQueryParams } = useQueryParams();

  return {
    location,
    params,
    queryParams,
    updateQueryParams,
    navigateTo(path, query, state) {
      navigate(
        query
          ? {
              pathname: path,
              search: qs.stringify(query),
            }
          : path,
        { state }
      );
    },
    makeUrl,
  };
}
