import { styled } from '@/stitches.config';

export const ErrorBlock = styled('div', {
  padding: '1rem',
  background: '$white',
  color: 'var(--color-red)',
  border: '1px solid $gray300',
  borderRadius: '$4',
});

export const ErrorInline = styled('span', {
  color: 'var(--color-red)',
});
