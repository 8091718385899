export function getParametersInitialState(parameters) {
  const state = {};

  for (const param of parameters) {
    state[param.id] = getFilterInitialValue(param);
  }

  return state;
}

export function canSubmit(parameters, state) {
  for (const param of parameters) {
    if (param.required && isEmptyValue(state[param.id])) {
      return false;
    }
  }

  return true;
}

export function castValue(toType, value) {
  switch (toType) {
    case 'int':
      return parseInt(value, 10);
    case 'bool':
      return value === 'True';
    case 'strList':
      // Assume value is Array<{label: String, value: String}>
      if (Array.isArray(value)) {
        return value.map((singleValue) => singleValue.value);
      }
      console.warn('castValue:: value is not array', value);
      return value;
    case 'str':
      return value;
  }
}

export function getFilterInitialValue(filter) {
  const { valueType, defaultValue } = filter;

  switch (valueType) {
    case 'str':
      return defaultValue || '';
    case 'int':
      return defaultValue || 0;
    case 'bool':
      return defaultValue === undefined ? null : defaultValue;
    case 'strList':
      return defaultValue || [];
  }

  return defaultValue;
}

export function isEmptyValue(value) {
  return (
    value === undefined ||
    value === null ||
    value === '' ||
    (Array.isArray(value) && value.length === 0)
  );
}

export function getFormData(formElement) {
  const formData = new FormData(formElement);

  const data = {};
  for (const key of formData.keys()) {
    data[key] = formData.get(key);
  }
  return data;
}

export const handleFormParameterChange = (setState) => (e) => {
  const { name, value } = e.target;

  setState((state) => ({
    ...state,
    [name]: value,
  }));
};
