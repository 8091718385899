import { styled } from '@/stitches.config';

export const InputWithIcon = styled('div', {
  position: 'relative',

  '& input': {
    width: '100%',
  },

  '& svg': {
    position: 'absolute',
    width: '$16',
    height: '$16',
    top: 'calc(50% - $8)',
    color: '$slate600',
  },

  variants: {
    iconLeft: {
      true: {
        '& input': {
          paddingLeft: '2rem !important',
        },
        '& svg': {
          left: '0.5rem',
        },
      },
    },
    iconRight: {
      true: {
        '& input': {
          paddingRight: '2rem !important',
        },
        '& svg': {
          right: '0.5rem',
          cursor: 'pointer',
        },
      },
    },
  },

  compoundVariants: [
    {
      iconLeft: true,
      iconRight: true,
      css: {
        '& svg:last-of-type': {
          left: 'auto',
          right: '0.5rem',
        },
        '& svg:first-of-type': {
          right: 'auto',
          left: '0.5rem',
        },
      },
    },
  ],
});
