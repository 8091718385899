import React, { useCallback } from 'react';
import { toast } from 'react-hot-toast';

import { styled } from '@/stitches.config';
import { SingleSelectCombobox } from '@/components/comboboxes/single-select-combobox';
import { Body } from '@/components/text';
import { StyledComponent } from '@/models/common';
import { DataFetcher } from '@/hooks';
import { PaginationOptions } from '@/resources/models';
import { postApiUsersSearch } from '@/models/api';
import { PartialUserModel } from '@/models/extended/user';
import { BOT_ROLES, useUserById } from '@/queries/use-users';
import { LoadingDots } from '../loading';

type UserPickerProps = {
  value?: PartialUserModel | null;
  inputProps?: {
    disabled?: boolean;
    required?: boolean;
    placeholder?: string;
  };
  onSelected: (user: PartialUserModel) => void;
  excludeReviewerRole?: boolean;
};

type UserPickerForUserIdProps = Omit<UserPickerProps, 'value'> & {
  value?: string | null;
};

export function UserPickerForUserId({ value, ...props }: UserPickerForUserIdProps) {
  const shouldFetchUser = typeof value === 'string' && value !== '';

  const { data: userData, isLoading, error } = useUserById(shouldFetchUser ? value : undefined);

  if (isLoading) {
    return <LoadingDots />;
  }

  if (error) {
    console.warn(`UserPicker:: Unable to fetch user: ${value}`, error);
    toast.error(`Unable to fetch user with id: ${value}`);
  }

  const user = shouldFetchUser ? userData : value || null;

  return <UserPicker {...props} value={user} />;
}

export function UserPicker({
  value,
  inputProps = {},
  onSelected,
  excludeReviewerRole = false,
}: UserPickerProps) {
  const { disabled = false, required = false, placeholder = 'Select user' } = inputProps;
  const fetchUsers: DataFetcher = useCallback(
    async (query: string, opts?: PaginationOptions) => {
      const nameFilter = query ? { name: { $contains: query } } : {};
      const filterRoles = [...BOT_ROLES];

      if (excludeReviewerRole) {
        filterRoles.push('reviewer');
      }

      return (
        await postApiUsersSearch({
          ...opts,
          filter: {
            ...nameFilter,
            role: { $nin: filterRoles },
          },
        })
      ).data;
    },
    [excludeReviewerRole]
  );

  return (
    <SingleSelectCombobox
      placeholder={placeholder}
      fetchItems={fetchUsers}
      selectedItem={value || undefined}
      onChange={(selectedValue) => {
        if (selectedValue) {
          onSelected(selectedValue);
        }
      }}
      itemToString={userToString}
      itemToIdentifier={userToIdentifier}
      emptyItem={null}
      itemElement={<UserItem item={null} itemToString={(item) => item?.email || ''} />}
      disabled={disabled}
      required={required}
    />
  );
}

type UserItemProps = {
  item: PartialUserModel | null;
  itemToString: (item: PartialUserModel) => string;
};

export const UserItem = React.forwardRef(
  ({ item: user, itemToString, ...props }: UserItemProps, ref) => {
    return (
      <ListItem {...props} ref={ref}>
        <Body color="black" className="ellipsis">
          {user ? itemToString(user) : '-'}
        </Body>
        <Body size="2" className="ellipsis">
          {user?.email}
        </Body>
      </ListItem>
    );
  }
);

const ListItem: StyledComponent = styled('li', {
  display: 'flex',
  flexDirection: 'column',
  gap: '2px',
  padding: '$8 $12',
  fontSize: '$14',
  borderTop: '1px solid $gray300',

  '&:first-child': {
    borderTop: 0,
  },

  variants: {
    highlighted: {
      true: {
        backgroundColor: '$gray200',
        borderLeftColor: '$gray200',
      },
    },
    selected: {
      true: {
        backgroundColor: '$iris200',
        borderLeftColor: '$iris600',
      },
    },
  },
});

export const userToString = (user: PartialUserModel | undefined | null): string => {
  if (!user) {
    return 'Select a user';
  }

  if (user.firstName && user.lastName) {
    return `${user.firstName || ''} ${user.lastName || ''}`.trim();
  }

  if (!user.firstName && !user.lastName && user.name) {
    return user.name;
  }

  return user.email || '';
};

export const userToIdentifier = (user: PartialUserModel | undefined | null) => user?.id || '';
