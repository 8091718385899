import React from 'react';
import { styled } from '@/stitches.config';
import { useApps } from '@/hooks/use-apps';
import { StyledComponent } from '@/models/common';
import { SourceIcon } from './icons/sources';
import { Tooltip } from './tooltip';
import { HoverCard } from './hover-card';
import { AsyncBoundary } from './bounderies/async-boundary';
import { ErrorIconFallback } from './error-icon-fallback';

type AppResource = {
  id: string;
  name: string;
  displayName?: string;
  connectorName?: string;
};

type AppIconsListProps = {
  apps: AppResource[];
  maxLimit?: number;
  size?: 'small' | 'large';
  wrap?: boolean;
};

type AppIdsIconsListProps = Omit<AppIconsListProps, 'apps'> & {
  appIds: string[];
};

export function AppIdsIconsList({ appIds = [], ...props }: AppIdsIconsListProps) {
  const { getAppById } = useApps();
  const apps = appIds.map(getAppById);

  return <AppIconsList apps={apps} {...props} />;
}

export function AppIconsList(props: AppIconsListProps) {
  return (
    <AsyncBoundary errorFallback={<ErrorIconFallback />}>
      <AppIconsListInner {...props} />
    </AsyncBoundary>
  );
}

export function AppIconsListInner({
  apps = [],
  maxLimit = 3,
  size = 'small',
  wrap = false,
}: AppIconsListProps) {
  // Remove undefined or null apps
  apps = apps.filter(Boolean);

  if (apps.length === 0) {
    return null;
  }

  // If apps.length is bigger than maxLimit by one app let's show the full list
  const visibleApps = apps.length === maxLimit + 1 ? apps : apps.slice(0, maxLimit);
  const tooltipApps = apps.slice(maxLimit);
  const hasAdditionalApps = visibleApps.length < apps.length;

  return (
    <ListContainer wrap={wrap}>
      {visibleApps.map((app, i) => (
        <Tooltip key={`${app.name}-${i}`} label={app.displayName || app.name || app.connectorName}>
          <IconWrapper size={size}>
            <SourceIcon {...app} />
          </IconWrapper>
        </Tooltip>
      ))}
      {hasAdditionalApps && (
        <HoverCard
          label={
            <ul>
              {tooltipApps.map((app) => (
                <HoverCardApp key={app.name}>
                  <IconWrapper>
                    <SourceIcon {...app} />
                  </IconWrapper>
                  {app.name}
                </HoverCardApp>
              ))}
            </ul>
          }>
          <AdditionalAppsNumber size={size}>
            +{apps.length - visibleApps.length}
          </AdditionalAppsNumber>
        </HoverCard>
      )}
    </ListContainer>
  );
}

type ConnectorIconListProps = Omit<AppIconsListProps, 'apps'> & {
  connectorNames?: string[];
};

// TODO: Remove once API return apps
export function ConnectorIconList({ connectorNames = [], ...props }: ConnectorIconListProps) {
  const apps = connectorNames.map((name: string) => ({ name })) as AppResource[];
  return <AppIconsList apps={apps} {...props} />;
}

const ListContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem',

  variants: {
    wrap: {
      true: {
        flexWrap: 'wrap',
      },
    },
  },
});

export const IconWrapper: StyledComponent = styled('span', {
  height: '1.5rem',
  width: '1.5rem',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.25rem',
  background: '$white',
  border: '1px solid $gray200',
  boxSizing: 'border-box',
  boxShadow: '0px 0px 2px rgba(154, 162, 185, 0.1), 0px 2px 4px rgba(211, 211, 211, 0.32)',
  borderRadius: '50%',

  '& svg,  img': {
    height: '1rem',
    width: '1rem',
  },

  variants: {
    size: {
      large: {
        width: '2.5rem',
        height: '2.5rem',

        '& svg,  img': {
          width: '1.5rem',
          height: '1.5rem',
        },
      },
    },
  },
});

const AdditionalAppsNumber: StyledComponent = styled('div', {
  height: '1.5rem',
  width: '1.5rem',
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0.25rem',
  color: '$black',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '10px',
  background: '$white',
  border: '1px solid $gray200',
  boxSizing: 'border-box',
  boxShadow: '0px 0px 2px rgba(154, 162, 185, 0.1), 0px 2px 4px rgba(211, 211, 211, 0.32)',
  borderRadius: '50%',

  variants: {
    size: {
      large: {
        width: '2.5rem',
        height: '2.5rem',
      },
    },
  },
});

const HoverCardApp = styled('li', {
  display: 'flex',
  alignItems: 'center',
  gap: '0.25rem',
  paddingTop: '0.5rem',
  fontSize: '0.875rem',

  '&:first-of-type': {
    paddingTop: '0',
  },
});
