import { useState, useEffect, useRef, useCallback } from 'react';
import useEventListener from '@/hooks/use-event-listener';

/**
 * Important! This is a local copy of npm module use-persisted-state that is no longer supported by the creator.
 * We had to make a local copy of this hook as the npm module does not support latest versions of React.
 * https://www.npmjs.com/package/use-persisted-state
 */
const globalState = {};

function createPersistedState(key, provider = getProvider()) {
  if (provider) {
    const storage = createStorage(provider);
    return (initialState) => usePersistedState(initialState, key, storage);
  }
  return useState;
}

export default createPersistedState;

function createGlobalState(key, thisCallback, initialValue) {
  if (!globalState[key]) {
    globalState[key] = { callbacks: [], value: initialValue };
  }
  globalState[key].callbacks.push(thisCallback);
  return {
    deregister() {
      const arr = globalState[key].callbacks;
      const index = arr.indexOf(thisCallback);
      if (index > -1) {
        arr.splice(index, 1);
      }
    },
    emit(value) {
      if (globalState[key].value !== value) {
        globalState[key].value = value;
        globalState[key].callbacks.forEach((callback) => {
          if (thisCallback !== callback) {
            callback(value);
          }
        });
      }
    },
  };
}

function createStorage(provider) {
  return {
    get(key, defaultValue) {
      const json = provider.getItem(key);
      // eslint-disable-next-line no-nested-ternary
      return json === null || typeof json === 'undefined'
        ? typeof defaultValue === 'function'
          ? defaultValue()
          : defaultValue
        : JSON.parse(json);
    },
    set(key, value) {
      provider.setItem(key, JSON.stringify(value));
    },
  };
}

function getProvider() {
  if (typeof global !== 'undefined' && global.localStorage) {
    return global.localStorage;
  }
  // eslint-disable-next-line no-undef
  if (typeof globalThis !== 'undefined' && globalThis.localStorage) {
    // eslint-disable-next-line no-undef
    return globalThis.localStorage;
  }
  if (typeof window !== 'undefined' && window.localStorage) {
    return window.localStorage;
  }
  if (typeof localStorage !== 'undefined') {
    return localStorage;
  }
  return null;
}

function usePersistedState(initialState, key, { get, set }) {
  const glblState = useRef(null);
  const [state, setState] = useState(() => get(key, initialState));

  // subscribe to `storage` change events
  useEventListener('storage', ({ key: k, newValue }) => {
    if (k === key) {
      const newState = JSON.parse(newValue);
      if (state !== newState) {
        setState(newState);
      }
    }
  });

  // only called on mount
  useEffect(() => {
    // register a listener that calls `setState` when another instance emits
    glblState.current = createGlobalState(key, setState, initialState);

    return () => {
      glblState.current.deregister();
    };
  }, [initialState, key]);

  const persistentSetState = useCallback(
    (newState) => {
      const newStateValue = typeof newState === 'function' ? newState(state) : newState;

      // persist to localStorage
      set(key, newStateValue);

      setState(newStateValue);

      // inform all of the other instances in this tab
      glblState.current.emit(newState);
    },
    [state, set, key]
  );

  return [state, persistentSetState];
}
