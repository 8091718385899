import { useCallback, useEffect, useRef, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { hasNextPage } from '@/lib/api';
import {
  PaginationOptions,
  PaginationMetadata,
  ResponseDataArray,
  ResponseWithPagination,
} from '@/resources/models';

export type DataFetcher = (
  query: string,
  opts?: PaginationOptions
) => Promise<ResponseWithPagination | ResponseDataArray>;

type SearchAsYouTypeOptions<Item> = {
  fetchWithEmptyQuery?: boolean;
  resetWhenQueryEmpty?: boolean;
  debounceDelay?: number;
  initialResults?: Item[];
  limit?: number;
};

type SearchResult<Item> = {
  loading: boolean;
  results: Item[];
  error: unknown;
  pagination: PaginationMetadata | null;
  hasMoreResults: boolean;
  fetchMore: () => Promise<void | null>;
  searchQuery: string;
  setSearchQuery: (query: string) => void;
};

export function useSearchAsYouType<Item>(
  dataFetcher?: DataFetcher,
  opts?: SearchAsYouTypeOptions<Item>
): SearchResult<Item> {
  const {
    fetchWithEmptyQuery = false,
    resetWhenQueryEmpty = true,
    debounceDelay = 300,
    initialResults = [],
    limit = 50,
  } = opts || {};
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [results, setResults] = useState<Item[]>(initialResults);
  const [pagination, setPagination] = useState<PaginationMetadata | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const shouldCancel = useRef<boolean>();

  const handleDebouncedQuery = useDebouncedCallback((debouncedQuery) => {
    if (!dataFetcher) return;
    setError(null);
    setLoading(true);
    dataFetcher(debouncedQuery, { skip: 0, limit })
      .then((response) => {
        if (shouldCancel.current) return;

        if (Array.isArray(response)) {
          setResults((response as Item[]) || []);
        } else {
          // Support paginated response
          setResults((response?.data as Item[]) || []);
          setPagination(response?.pagination);
        }
      })
      .catch((err) => {
        setError(err);
        console.error('Error fetching results', err);
      })
      .finally(() => {
        if (!shouldCancel.current) {
          setLoading(false);
        }
      });
  }, debounceDelay);

  // Trigger fetching on initial load
  useEffect(() => {
    if (fetchWithEmptyQuery || searchQuery) {
      handleDebouncedQuery(searchQuery);
    }
  }, [fetchWithEmptyQuery, searchQuery, handleDebouncedQuery]);

  useEffect(() => {
    if (!searchQuery && resetWhenQueryEmpty && results.length) {
      setResults([]);
    }
  }, [resetWhenQueryEmpty, searchQuery, results.length]);

  // Avoid setting state after component unmounts
  useEffect(() => {
    return () => {
      shouldCancel.current = true;
    };
  }, []);

  const handleSetQuery = useCallback(
    (query: string) => {
      setSearchQuery(query);
      setPagination(null);
      handleDebouncedQuery(query);
    },
    [handleDebouncedQuery]
  );

  const fetchMore = useCallback(async () => {
    if (!dataFetcher) return null;

    const params = pagination
      ? {
          skip: pagination.skip + pagination.limit,
          limit: pagination.limit,
        }
      : undefined;

    return dataFetcher(searchQuery, params)
      .then((response) => {
        if (Array.isArray(response)) {
          setResults((response as Item[]) || []);
        } else {
          // Support paginated response
          setResults((prevResults) => [...prevResults, ...(response?.data as Item[])]);
          setPagination(response?.pagination);
        }
      })
      .catch((err) => {
        setError(err);
        console.error('Error fetching results', err);
      });
  }, [dataFetcher, searchQuery, pagination]);

  return {
    loading,
    results,
    error,
    pagination,
    hasMoreResults: hasNextPage(pagination),
    fetchMore,
    searchQuery,
    setSearchQuery: handleSetQuery,
  };
}
