import React, { useMemo } from 'react';
import { keyframes, styled } from '@stitches/react';
import { times, inRange, clamp } from 'lodash';

const ITEM_PADDING = 6;
const LINE_MARGIN = 10;
const MAX_LINES = 4;
const MIN_LINE_HEIGHT = 8;
const MAX_LINE_HEIGHT = 20;
const DEFAULT_ITEMS_NUMBER = 3;
const DEFAULT_ITEM_HEIGHT = 49;
const DEFAULT_ITEM_WIDTH = '100%';
const DEFAULT_ITEM_MARGIN = '0 0 $16 0';
const DEFAULT_ITEM_BACKGROUND = 'transparent';

export function ItemsLoading({
  count = DEFAULT_ITEMS_NUMBER,
  itemHeight = DEFAULT_ITEM_HEIGHT,
  itemWidth = DEFAULT_ITEM_WIDTH,
  itemMargin = DEFAULT_ITEM_MARGIN,
  itemBackground = DEFAULT_ITEM_BACKGROUND,
  maxLines = MAX_LINES,
  showAvatar = false,
  animate = false,
}) {
  const linesCount = useMemo(() => getLinesNumber(itemHeight, maxLines), [itemHeight, maxLines]);
  const lineHeight = useMemo(() => getLineHeight(itemHeight, linesCount), [itemHeight, linesCount]);

  return (
    <LoadingContainer animate={animate}>
      {times(count, (i) => (
        <Item
          key={i}
          css={{
            height: itemHeight,
            width: itemWidth,
            background: itemBackground,
            margin: itemMargin,
            animationDelay: `${i * 100}ms !important`,
          }}>
          {showAvatar && (
            <Avatar
              css={{ height: itemHeight - ITEM_PADDING * 2, width: itemHeight - ITEM_PADDING * 2 }}
            />
          )}
          <LinesContainer>
            {times(linesCount, (index) => (
              <Line key={index} css={{ height: lineHeight }} />
            ))}
          </LinesContainer>
        </Item>
      ))}
    </LoadingContainer>
  );
}

// Depending on the height of the item there might be 2 to 4 indicator lines
function getLinesNumber(height, maxNumber) {
  const isSmallItem = inRange(height, 0, 50);
  const isMediumItem = inRange(height, 51, 90);

  if (isSmallItem) {
    return Math.min(maxNumber, 2); // small item
  }
  if (isMediumItem) {
    return Math.min(maxNumber, 3); // medium item
  }
  return Math.min(maxNumber, 4); // big item
}

function getLineHeight(itemHeight, linesCount) {
  const calculatedLineHeight = Math.round(
    (itemHeight - LINE_MARGIN * (linesCount - 1) - ITEM_PADDING * 2) / linesCount
  );

  return clamp(calculatedLineHeight, MIN_LINE_HEIGHT, MAX_LINE_HEIGHT);
}

const fadeInAnimation = keyframes({
  '0%, 20%': { opacity: '0', transform: 'translateY(100%)' },
  '40%, 100%': { opacity: '1', transform: 'translateY(0)' },
});

const gradientAnimation = keyframes({
  '0%': { backgroundPosition: '100% 0' },
  '100%': { backgroundPosition: '-100% 0' },
});

const Item = styled('div', {
  display: 'inline-flex',
  overflow: 'auto',
  padding: '$6',
});

const Avatar = styled('div', {
  background: '$gray400',
  borderRadius: '$6',
});

const LinesContainer = styled('div', {
  display: 'inline-flex',
  flexDirection: 'column',
  justifyContent: 'space-around',
  flexGrow: '1',
  marginLeft: '$8',
});

const Line = styled('div', {
  width: '100%',
  marginBottom: `${LINE_MARGIN}px`,
  borderRadius: '$4',
  animation: `${gradientAnimation} 1.5s ease-in-out forwards infinite`,
  background: 'linear-gradient(to right, $gray400, $gray300, $gray300, $gray400)',
  backgroundSize: '200%',

  '&:last-of-type': {
    marginBottom: 0,
  },
});

const LoadingContainer = styled('div', {
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexWrap: 'wrap',

  [`${Item} ${Line}`]: {
    width: '90%',
  },

  [`${Item} ${Line} ~ ${Line}`]: {
    width: '75%',
  },

  [`${Item} ${Line} ~ ${Line} ~ ${Line}`]: {
    width: '45%',
  },

  [`${Item} ${Line} ~ ${Line} ~ ${Line} ~ ${Line}`]: {
    width: '35%',
  },

  variants: {
    animate: {
      true: {
        [`${Item}`]: {
          opacity: '0',
          animation: `${fadeInAnimation} 3s ease-in-out forwards`,
        },
      },
    },
  },
});
