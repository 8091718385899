import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { headerHeight, styled } from '@/stitches.config';
import SupportActionsMenu from '@/views/layout/components/support-actions-menu';
import { useSession } from '@/hooks';
import { SettingOutlineIcon } from '@/icons';
import { OrganizationLogo } from '@/components/organization-logo';
import { Button } from '@/components/buttons/button';
import { AccountDropdown } from '../settings/users/account-dropdown';

export default function LayoutHeader() {
  const { isAdmin } = useSession();

  return (
    <Header>
      <LogoSection>
        <Link to="/">
          <OrganizationLogo shouldIncludeCompanyName />
        </Link>
      </LogoSection>

      <AdminSection
        css={{
          display: 'auto',
        }}>
        {isAdmin && (
          <Button kind="text" color="secondary" circle as={NavLink} to="/settings">
            <SettingOutlineIcon />
          </Button>
        )}
        <SupportActionsMenu />
        <AccountDropdown />
      </AdminSection>
    </Header>
  );
}
const Header = styled('div', {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gridArea: 'header',
  background: '$blue800',
  boxShadow: '0px 1px 2px rgba(77, 77, 77, 0.08), 0px 2px 8px rgba(41, 47, 44, 0.06)',
  height: headerHeight,
});

const LogoSection = styled('div', {
  paddingLeft: '$20',

  a: {
    height: '$32',
  },

  '@sm': {
    width: '20%',
  },
});

const AdminSection = styled('div', {
  display: 'flex',
  gap: '$8',
  width: '50%',
  justifyContent: 'end',
  paddingRight: '$16',

  '@sm': {
    width: '20%',
  },
});
