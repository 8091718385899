import React, { useCallback, useMemo, useState } from 'react';
import { format } from 'date-fns';
import { useFetcher } from 'rest-hooks';
import toast from 'react-hot-toast';
import clipboardCopy from 'clipboard-copy';
import { styled } from '@/stitches.config';
import { Tooltip } from '@/components/tooltip';
import { InfoIcon, CopyIcon } from '@/icons';
import { SourceIcon } from '@/components/icons/sources';
import { ConfirmationModal } from '@/components/confirmation-modal';
import { Loading } from '@/components/loading';
import { CONNECTOR_MODAL_MODE, ConnectorModal } from '@/components/connector-modal/connector-modal';
import IntegrationResource from '@/resources/integration';
import { ActionsMenu } from '@/components/actions-menu';
import { Button } from '@/components/buttons/button';
import { Body } from '@/components/text';
import { CONNECTORS_WITH_PARENT, FILE_PROVIDER, GOOGLE, MS } from '../constants';
import { IntegrationErrorModal } from './integration-error-modal';
import { WorkflowsStatuses } from './workflows-statuses';
import { StatusesItem } from './statuses-item';
import { BreakdownItem } from './common';

export function ConnectedAppItem({
  id,
  displayName,
  name,
  canRefetchData,
  showIntegrationId,
  logoUrl,
  availableConnectorId,
  setName,
  ownerEmail,
  extendedConfig,
  lastSyncStatus,
  lastError,
  installLink,
  configSchema,
  steps,
  onRefresh,
  type,
  subType,
  workflowsStatuses = [],
}) {
  const deleteConnector = useFetcher(IntegrationResource.delete());
  const fetchNewDataForConnector = useFetcher(IntegrationResource.fetchNewData());
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const title = displayName || setName || name.replace('FileProvider.', '');
  const parentConnectorName = getParentConnectorName(
    availableConnectorId,
    displayName,
    setName,
    name
  );

  const fetchNewData = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await fetchNewDataForConnector({ id }, {});

      if (result.ok) {
        toast.success('Data fetching has started successfully.');
        onRefresh();
      } else {
        toast.error('Failed to fetch new data for this integration, please try again later.');
      }
    } catch (e) {
      toast.error('Failed to fetch new data for this integration, please try again later.');
    }
    setIsLoading(false);
  }, [id, setIsLoading, fetchNewDataForConnector, onRefresh]);

  const deleteAppIntegration = async () => {
    try {
      setIsLoading(true);
      const result = await deleteConnector({ id }, {});
      setIsLoading(false);
      if (result.id) {
        toast.success('App integration was deleted successfully.');
        onRefresh();
      } else {
        toast.error('Failed to delete an integration, please try again later.');
      }
    } catch (e) {
      setIsLoading(false);
      toast.error('Failed to delete an integration, please try again later.');
    }
  };

  const menuItems = useMemo(() => {
    return [
      ...(canRefetchData
        ? [
            {
              text: 'Fetch data now',
              onSelect() {
                fetchNewData();
              },
            },
          ]
        : []),
      {
        text: 'Edit',
        onSelect() {
          setShowEditModal(true);
        },
      },
      {
        text: 'Delete',
        onSelect() {
          setShowDeleteModal(true);
        },
      },
    ];
  }, [canRefetchData, fetchNewData, setShowEditModal, setShowDeleteModal]);

  const parentConnectorNameTooltip = useMemo(() => {
    return availableConnectorId === FILE_PROVIDER
      ? 'This is a custom app created using a data file upload. To update it, please Edit it and upload a new data file.'
      : `${title} is a ${parentConnectorName} app. By editing it, you will edit the entire ${parentConnectorName} connector.`;
  }, [availableConnectorId, title, parentConnectorName]);

  const onConnectorModalCancel = useCallback(() => {
    setShowEditModal(false);
  }, [setShowEditModal]);

  const oldestLastSyncedItem =
    workflowsStatuses?.reduce((max, current) => {
      const maxDateTime = new Date(max.lastSuccess);
      const currentDateTime = new Date(current.lastSuccess);

      return maxDateTime < currentDateTime ? max : current;
    }, workflowsStatuses[0]) || [];

  const lastSyncedAt = oldestLastSyncedItem?.lastSuccess;

  return (
    <ConnectorContainer>
      {showEditModal && (
        <ConnectorModal
          mode={CONNECTOR_MODAL_MODE.EDIT}
          id={id}
          extendedConfig={extendedConfig}
          connector={{
            id,
            title,
            installLink,
            configSchema,
            steps,
            lastSyncStatus,
            lastError,
          }}
          onSubmit={onRefresh}
          onClose={onConnectorModalCancel}
        />
      )}
      {showDeleteModal && (
        <ConfirmationModal
          kind="attention"
          onCancel={() => setShowDeleteModal(false)}
          onConfirm={() => {
            setShowDeleteModal(false);
            deleteAppIntegration();
          }}
          cancelButtonText="Cancel"
          confirmButtonText="Yes, Delete App"
          confirmationHeader="Delete Connected App"
          confirmationTitle="Are you sure you want to delete this App?"
          confirmationDescription={`All the fetched data will be deleted. ${
            availableConnectorId === GOOGLE || availableConnectorId === MS
              ? `Doing this will also delete all ${parentConnectorName} apps.`
              : ''
          }`}
        />
      )}
      {showErrorModal && (
        <IntegrationErrorModal
          name={name}
          workflowsStatuses={workflowsStatuses}
          onClose={() => setShowErrorModal(false)}
        />
      )}

      {isLoading ? (
        <Loading />
      ) : (
        <>
          <MenuContainer>
            <ActionsMenu items={menuItems} />
          </MenuContainer>

          <TitleContainer>
            <IconWrapper>
              <SourceIcon name={name} logoUrl={logoUrl} />
            </IconWrapper>

            <Tooltip label={title}>
              <Body color="black">{title}</Body>
            </Tooltip>
            {parentConnectorName && (
              <div className="connector-name">
                {parentConnectorName}
                <Tooltip label={parentConnectorNameTooltip}>
                  <span>
                    <InfoIcon />
                  </span>
                </Tooltip>
              </div>
            )}
          </TitleContainer>
          {workflowsStatuses && (
            <WorkflowsStatuses
              workflowsStatuses={workflowsStatuses}
              setShowErrorModal={setShowErrorModal}
            />
          )}
          <DetailsBreakdown>
            {showIntegrationId && (
              <BreakdownItem>
                <div className="label">ID</div>
                <div className="value">
                  <span>{id || '-'}</span>
                </div>
                <Button color="gray" kind="text" size="sm" circle onClick={() => clipboardCopy(id)}>
                  <CopyIcon />
                </Button>
              </BreakdownItem>
            )}

            {!workflowsStatuses && (
              <StatusesItem setShowErrorModal={setShowErrorModal} lastSyncStatus={lastSyncStatus} />
            )}

            <BreakdownItem>
              {type !== 'Communication' && <div className="label">Last sync</div>}
              {type !== 'Communication' && (
                <div className="value">
                  <Tooltip
                    label={
                      lastSyncedAt
                        ? format(new Date(lastSyncedAt), 'Pp')
                        : 'No information is available regarding the last sync'
                    }>
                    <span>{lastSyncedAt ? format(new Date(lastSyncedAt), 'Pp') : '-'}</span>
                  </Tooltip>
                </div>
              )}
            </BreakdownItem>
            <BreakdownItem>
              <div className="label">Owner</div>
              <div className="value">
                <Tooltip label={ownerEmail || 'Owner information is not available'}>
                  <span>{ownerEmail || '-'}</span>
                </Tooltip>
              </div>
            </BreakdownItem>
            <BreakdownItem>
              {subType !== 'Other' && <div className="label">Type</div>}
              {subType !== 'Other' && (
                <div className="value">
                  {subType === 'SCIM' && <div>SCIM</div>}
                  {subType === 'RestApi' && <div>Rest API</div>}
                  {subType === 'FileProvider' && <div>File Provider</div>}
                  {subType === 'OutOfTheBox' && <div>Built by Authomize</div>}
                </div>
              )}
            </BreakdownItem>
          </DetailsBreakdown>
        </>
      )}
    </ConnectorContainer>
  );
}

const ConnectorContainer = styled('div', {
  position: 'relative',
  display: 'flex',
  flexWrap: 'wrap',
  width: 'calc(100% - 1rem)',
  padding: '1.5rem',
  background: 'var(--color-white)',
  boxShadow: '0px 0px 2px rgba(181, 181, 181, 0.15), 0px 4px 4px rgba(176, 176, 176, 0.2)',
  borderRadius: '0.5rem',
  margin: '0.5rem',

  '@media screen and (min-width: 480px)': {
    '&': {
      width: 'calc(50% - 1rem)',
    },
  },

  '@media screen and (min-width: 768px)': {
    '&': {
      width: 'calc(33.3% - 1rem)',
    },
  },

  '@media screen and (min-width: 1224px)': {
    '&': {
      width: 'calc(25% - 1rem)',
    },
  },
});

const MenuContainer = styled('div', {
  position: 'absolute',
  top: '0.5rem',
  right: '0.5rem',
});

const TitleContainer = styled('div', {
  display: 'flex',
  overflow: 'hidden',
  flexWrap: 'wrap',

  '& .connector-name': {
    width: 'calc(100%)',
    paddingLeft: '2.25rem',
    fontSize: '0.75rem',
    lineHeight: '1rem',
    letterSpacing: '0.4px',
    color: '$slate600',

    '& svg': {
      position: 'relative',
      top: '2px',
      cursor: 'pointer',
      marginLeft: '0.25rem',
    },
  },

  '& h6': {
    width: 'calc(100% - 2.25rem)',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '1.25rem',
    lineHeight: '2rem',
    color: '$slate900',
  },
});

const IconWrapper = styled('div', {
  position: 'relative',
  width: '1.5rem',
  height: '1.5rem',
  borderRadius: '50%',
  marginRight: '0.75rem',

  '& > svg': {
    display: 'inline-block',
    width: '100%',
    height: '100%',
  },
});

const DetailsBreakdown = styled('ul', {
  width: '100%',
  marginTop: '1.5rem',
});

function getParentConnectorName(connectorId, displayName, setName, name) {
  return CONNECTORS_WITH_PARENT.includes(connectorId) ? displayName || setName || name : null;
}
