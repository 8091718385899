import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { pageView } from '@/lib/analytics';

export function useTracking() {
  const location = useLocation();

  useEffect(() => {
    const page = location.pathname + location.search;
    pageView(page);
  }, [location]);
}
