import { useState } from 'react';
import toast from 'react-hot-toast';

export function useChangePassword() {
  const passwordRequirements = /^(?=.*[0-9])(?=.*[!-/:-@[-`{-~])[a-zA-Z0-9!-/:-@[-`{-~]{10,50}$/;
  const [plainTextFields, setPlainTextFields] = useState([]);

  const togglePasswordVisibility = (inputName) => {
    setPlainTextFields((fields) =>
      fields.includes(inputName)
        ? fields.filter((name) => name !== inputName)
        : [...fields, inputName]
    );
  };

  const [passwordsState, setPasswordsState] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  });

  const handleInputPasswordsChanged = (e) => {
    const { name, value } = e.target;
    setPasswordsState((oldState) => ({ ...oldState, [name]: value }));
  };

  const checkPasswordsValidations = () => {
    if (passwordsState.confirmPassword !== passwordsState.newPassword) {
      toast.error('New passwords should be equal to confirm password');
      return false;
    }
    if (!passwordsState.newPassword.match(passwordRequirements)) {
      toast.error(
        'New password should contain at least 10 characters, one number, and one symbol.'
      );
      return false;
    }
    if (passwordsState.newPassword === passwordsState.oldPassword) {
      toast.error('New password can not be equal to the old password.');
      return false;
    }
    return true;
  };

  return {
    checkPasswordsValidations,
    handleInputPasswordsChanged,
    togglePasswordVisibility,
    plainTextFields,
    passwordsState,
    setPasswordsState,
  };
}
