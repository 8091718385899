export const GOOGLE = 'google';
export const MS = 'microsoft-azure-ad-sp';
export const FILE_PROVIDER = 'fileprovider';
export const CONNECTORS_WITH_PARENT = [GOOGLE, MS, FILE_PROVIDER];

export const WORKFLOW_STATUSES = {
  SYNCED: 'Synced',
  INIT: 'Initializing',
  ERROR: 'Error',
  FETCHING: 'Fetching',
};
