import React from 'react';
import { css, styled, cx } from '@/stitches.config';
import SpinnerIcon from './icons/spinner.svg';
import { blink, spin } from '../styles/animations';

export const Spinner = styled(SpinnerIcon, {
  width: '1em',
  animation: `${spin} 1250ms infinite linear`,
});

const loadingStyle = css({
  padding: '20px',
  fontSize: '26px',
  margin: 'auto',
  textAlign: 'center',
});

const centerLoadingStyle = css({
  display: 'flex',
  fontSize: '26px',
  alignItem: 'center',
  justifyContent: 'center',
  height: '100%',
});

// The "loading" className is used by pdf-service (headless chrome)
// To understand when we finish loading the page and can print it
export function Loading({ className = loadingStyle, center = false }) {
  return (
    <div className={cx('loading', center ? centerLoadingStyle : className)}>
      <Spinner />
    </div>
  );
}

const dotsStyle = css({
  '& > span': {
    animationName: blink,
    animationDuration: '1.4s',
    animationIterationCount: 'infinite',
    animationFillMode: 'both',
  },

  '& > span:nth-child(2)': {
    animationDelay: '0.2s',
  },

  '& > span:nth-child(3)': {
    animationDelay: '0.4s',
  },
});

export function LoadingDots() {
  return (
    <span className={dotsStyle()}>
      <span>.</span>
      <span>.</span>
      <span>.</span>
    </span>
  );
}
