import React from 'react';
import { IncidentActivitiesWidget } from './incident-activities-widget';
import { IncidentGraphWidget } from './incident-graph-widget';
import { IncidentSimilarIncidents } from './incident-similar-incidents';
import { IncidentAccountTakeoverRiskWidget } from './incidents-account-takeover-risk';
import { IncidentBlastRadiusRiskWidget } from './incidents-blast-radius-risk';

export const SEVERITIES = {
  Low: 'Low',
  Medium: 'Medium',
  High: 'High',
  Critical: 'Critical',
};

export const STATUSES = {
  Open: 'Open',
  InProgress: 'InProgress',
  WaitingForInput: 'WaitingForInput',
  Closed: 'Closed',
};

export const EVENT_CONTEXTS = {
  ENTITLEMENT_USE: 'ENTITLEMENT_USE',
  ACCOUNT_TAKEOVER_RISK: 'ACCOUNT_TAKEOVER_RISK',
  EFFECTIVE_ACCESS: 'EFFECTIVE_ACCESS',
  CONFIGURATION_CHANGING_ACTIVITIES: 'CONFIGURATION_CHANGING_ACTIVITIES',
  BLAST_RADIUS: 'BLAST_RADIUS',
};

export const OPEN_STATUSES = [STATUSES.Open, STATUSES.InProgress, STATUSES.WaitingForInput];

export const widgetComponentsMap = {
  [EVENT_CONTEXTS.ENTITLEMENT_USE]: (props) => (
    <IncidentActivitiesWidget {...props} isTabView={false} />
  ),
  [EVENT_CONTEXTS.ACCOUNT_TAKEOVER_RISK]: IncidentAccountTakeoverRiskWidget,
  [EVENT_CONTEXTS.EFFECTIVE_ACCESS]: IncidentGraphWidget,
  [EVENT_CONTEXTS.CONFIGURATION_CHANGING_ACTIVITIES]: IncidentActivitiesWidget,
  [EVENT_CONTEXTS.RELATED_INCIDENTS]: IncidentSimilarIncidents,
  [EVENT_CONTEXTS.BLAST_RADIUS]: IncidentBlastRadiusRiskWidget,
};

export const optionsToFiltersMap = {
  identities: 'distinctIdentitiesIds',
  assets: 'resourcesIds',
  groups: 'groupIds',
  sourceApps: 'sourceAppIds',
  policies: 'policyIds',
  severity: 'severities',
};
