import React, { useRef, useState } from 'react';
import { styled } from '@/stitches.config';

import DashboardIcon from '@/components/icons/dashboard.svg';
import InventoryIcon from '@/components/icons/inventory.svg';
import { OPEN_STATUSES } from '@/views/incidents/constants';
import { Button } from '@/components/buttons/button';
import { OrganizationLogo } from '@/components/organization-logo';
import { AccountSections } from '@/views/layout/components/sidebar/account-sections';
import { useRemoteConfig } from '@/hooks';
import {
  AccessReviewIcon,
  AlertTriangleIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  DynamicScopeIcon,
  FileTextIcon,
  GraphIcon,
  IntegrationsIcon,
  SettingOutlineIcon,
  ShieldCheckmark,
} from '@/icons';
import { NavItem } from './nav-item';

const MENU_LOCAL_STORAGE_FLAG = 'authomize-menu-opened';

export default function LayoutSidebar() {
  const collapseMenuByDefault = localStorage.getItem(MENU_LOCAL_STORAGE_FLAG) === 'false';
  const [isOpened, setIsOpened] = useState(!collapseMenuByDefault);
  const [isHovering, setIsHovering] = useState(false);
  const [forceClose, setForceClose] = useState(false);
  const buttonRef = useRef();
  const {
    featureFlags: { enablePostureView = false, enableAlerts = false },
  } = useRemoteConfig();
  let timeout;

  const items = [
    {
      title: 'Dashboards',
      icon: DashboardIcon,
      path: '/dashboard',
    },
    {
      title: 'Inventories',
      icon: InventoryIcon,
      items: [
        {
          title: 'Identities',
          path: '/inventory/identities',
          query: {
            view: 'Identities',
          },
        },
        {
          title: 'Groups',
          path: '/inventory/groups',
        },
        {
          title: 'Assets',
          path: '/inventory/assets',
        },
        {
          title: 'Memberships',
          path: '/inventory/memberships',
          query: {
            privilegeFilter: JSON.stringify({
              $and: [{ 'membership.isDirect': { $eq: 'true' } }],
            }),
          },
        },
        {
          title: 'Access Policies',
          path: `/inventory/access-policies`,
          query: {
            privilegeFilter: JSON.stringify({
              $and: [{ 'accessPolicy.isDirect': { $eq: 'true' } }],
            }),
          },
        },
        {
          title: 'Privileges',
          path: `/inventory/privileges`,
        },
        {
          title: 'Activities',
          path: '/inventory/activities',
          query: {
            filter: JSON.stringify({
              $and: [
                { 'activity.createdAt': { $gte: 'now-7d' } },
                { 'activity.isVirtual': { $eq: false } },
              ],
            }),
          },
        },
        {
          title: 'Dynamic Scope',
          icon: DynamicScopeIcon,
          path: '/dynamic-scope',
        },
      ].filter(Boolean),
    },
    {
      title: 'Access Explorer',
      icon: GraphIcon,
      path: '/access-explorer',
    },
    {
      title: 'Threat Center',
      icon: AlertTriangleIcon,
      items: [
        {
          title: 'Detection Rules',
          path: '/policies',
          query: {
            filters: JSON.stringify({
              status: {
                values: ['Enabled'],
              },
            }),
          },
        },
        {
          title: 'Incidents',
          path: '/incidents',
          query: {
            groupBy: 'policies',
            filter: JSON.stringify({
              $and: [
                {
                  statuses: {
                    $in: OPEN_STATUSES,
                  },
                },
                {
                  isResolved: {
                    $eq: 'false',
                  },
                },
              ],
            }),
          },
        },
        enableAlerts && {
          title: 'Alerts',
          path: '/alerts',
        },
      ],
    },
    enablePostureView && {
      title: 'Identity Posture',
      icon: ShieldCheckmark,
      path: '/posture',
      query: {
        enabled: true,
      },
    },
    { title: 'Access Review', icon: AccessReviewIcon, path: '/campaigns' },
    {
      title: 'Integrations',
      icon: IntegrationsIcon,
      path: '/data-sources',
    },
    {
      title: 'Reports',
      icon: FileTextIcon,
      path: '/reports',
    },
    {
      title: 'Settings',
      icon: SettingOutlineIcon,
      path: '/settings',
    },
  ];

  return (
    <>
      {!isOpened && <SidebarSpacer />}

      <Sidebar
        open={isOpened}
        hovered={isHovering}
        forceClose={forceClose}
        onMouseEnter={(e) => {
          if (buttonRef.current !== e.target) {
            timeout = setTimeout(() => {
              setIsHovering(true);
            }, 300);
          }
        }}
        onMouseLeave={() => {
          setIsHovering(false);
          setForceClose(false);
          clearTimeout(timeout);
        }}>
        <ToggleMenuButton
          ref={buttonRef}
          circle
          color="gray"
          size="sm"
          onClick={() => {
            if (isOpened) {
              setForceClose(true);
            }
            setIsOpened(!isOpened);
            localStorage.setItem(MENU_LOCAL_STORAGE_FLAG, !isOpened);
          }}>
          {isOpened ? <ArrowLeftIcon /> : <ArrowRightIcon />}
        </ToggleMenuButton>

        <BrandingContainer css={{ display: isHovering || isOpened ? 'initial' : 'none' }}>
          <OrganizationLogo shouldIncludeCompanyName />
        </BrandingContainer>
        <ItemsContainer>
          <ul>
            {items
              .filter((item) => item)
              .map((item, key) => (
                <NavItem key={key} {...item} isMenuOpen={isOpened || isHovering} />
              ))}
          </ul>

          <AccountSections isHovering={isHovering} isOpened={isOpened} />
        </ItemsContainer>
      </Sidebar>
    </>
  );
}

const SidebarSpacer = styled('div', {
  width: '14px',
  height: '100%',

  '@media print': {
    display: 'none !important',
  },
});

const Sidebar = styled('nav', {
  position: 'absolute',
  display: 'flex',
  height: '100%',
  width: '14px',
  top: '0',
  boxShadow: '5px 0 9px -2px rgba(0, 0, 0, 0.1)',
  backgroundColor: '$blue800',
  transition: '180ms all ease-in-out',
  flexDirection: 'column',
  overflowX: 'visible',
  zIndex: '$10',

  variants: {
    hovered: {
      true: {
        width: '188px',
      },
    },
    open: {
      true: {
        position: 'relative',
        width: '188px',
      },
    },
    forceClose: {
      true: {
        width: '14px !important',
      },
    },
  },

  '@media print': {
    display: 'none !important',
  },
});

const BrandingContainer = styled('div', {
  margin: '12px',
});

const ItemsContainer = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  flexGrow: 1,
  overflow: 'auto',
});

const ToggleMenuButton = styled(Button, {
  position: 'absolute',
  right: '-12px',
  top: '27px',
  border: '1px solid $iris800',

  variants: {
    open: {
      true: {},
    },
  },
});
