import toast from 'react-hot-toast';
import React from 'react';
import { AddedNotification } from '../../../../components/added-notification';
import { css } from '../../../../stitches.config';

export function toastAppConnected() {
  toast(
    () => (
      <AddedNotification
        title="All set!"
        description={
          <span>
            We are fetching and processing data. This may take a while. We’ll let you know when
            we’re done{' '}
          </span>
        }
      />
    ),
    {
      duration: 5000,
      className: wrapperStyle,
    }
  );
}

const wrapperStyle = css({
  minWidth: '36rem',
  backgroundColor: '$iris100',
  border: '1px solid $gray300',
  borderRadius: '1rem',
});
