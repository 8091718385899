import React, { ReactNode, useState } from 'react';
import { keyframes, styled } from '@/stitches.config';
import * as AlertDialogPrimitive from '@radix-ui/react-alert-dialog';
import { H4, Subtitle } from '@/components/text';
import { AlertTriangleIcon, CloseIcon } from '@/icons';
import { Tooltip } from '@/components/tooltip';
import { Button } from '@/components/buttons/button';
import { FetchingButton } from '@/components/buttons/fetching-button';

type ConfirmationModalProps = {
  kind?: 'info' | 'attention';
  trigger?: ReactNode;
  onConfirm: () => Promise<void> | void;
  confirmButtonText?: string;
  onCancel?: () => void;
  cancelButtonText?: string;
  confirmationTitle?: string;
  confirmationHeader?: string;
  confirmationDescription?: string;
  confirmationIcon?: React.ElementType;
  cancelButtonProps?: {
    kind: string;
    color: string;
  };
};

export function ConfirmationModal({
  kind = 'info',
  trigger,
  onConfirm,
  onCancel,
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  cancelButtonProps = {
    kind: 'text',
    color: 'grey',
  },
  confirmationTitle,
  confirmationHeader,
  confirmationDescription,
  confirmationIcon: ConfirmationIcon,
}: ConfirmationModalProps) {
  const [open, setOpen] = useState(!trigger);
  const [disabled, setDisabled] = useState(false);

  const handleSubmit = async () => {
    setDisabled(true);
    await onConfirm();
    setDisabled(false);
    setOpen(false);
    onCancel?.();
  };

  const handleCancel = async () => {
    setOpen(false);
    onCancel?.();
  };

  return (
    <AlertDialogPrimitive.Root
      open={open}
      onOpenChange={(openValue: boolean) => {
        setOpen(openValue);
        if (!openValue) {
          onCancel?.();
        }
      }}>
      {trigger && <AlertDialogPrimitive.Trigger asChild>{trigger}</AlertDialogPrimitive.Trigger>}
      <AlertDialogContent>
        <AlertDialogTitle>
          <Subtitle weight="semibold" size={2} css={{ lineHeight: '32px' }}>
            {confirmationHeader || 'Are you sure you want to proceed?'}
          </Subtitle>

          <Tooltip label="Clicking close icon will cancel your action.">
            <Button color="grey" circle="true" disabled={disabled} onClick={handleCancel}>
              <CloseIcon />
            </Button>
          </Tooltip>
        </AlertDialogTitle>
        <AlertDialogDescription>
          <div>
            <H4 css={{ marginBottom: '$8' }}>{confirmationTitle}</H4>
            <Subtitle size={2} color="grey">
              {confirmationDescription}
            </Subtitle>
          </div>
          <DescriptionIconWrapper kind={kind}>
            {ConfirmationIcon ? <ConfirmationIcon /> : <AlertTriangleIcon />}
          </DescriptionIconWrapper>
        </AlertDialogDescription>
        <Footer>
          <AlertDialogPrimitive.Cancel asChild>
            <Button
              onClick={(event: React.MouseEvent<HTMLElement>) => {
                event.stopPropagation();
                handleCancel();
              }}
              disabled={disabled}
              {...cancelButtonProps}>
              {cancelButtonText}
            </Button>
          </AlertDialogPrimitive.Cancel>
          <FetchingButton color={kind === 'attention' ? 'red' : 'primary'} onClick={handleSubmit}>
            {confirmButtonText}
          </FetchingButton>
        </Footer>
      </AlertDialogContent>
    </AlertDialogPrimitive.Root>
  );
}

function AlertDialogContent({ children, ...props }: { children: ReactNode; props?: unknown }) {
  return (
    <AlertDialogPrimitive.Portal>
      <StyledOverlay onClick={(event) => event.stopPropagation()} />
      <StyledContent
        {...props}
        onClick={(event) => {
          event.stopPropagation();
        }}>
        {children}
      </StyledContent>
    </AlertDialogPrimitive.Portal>
  );
}

const overlayShow = keyframes({
  '0%': { opacity: 0 },
  '100%': { opacity: 1 },
});

const contentShow = keyframes({
  '0%': { opacity: 0, transform: 'translate(-50%, -48%) scale(.96)' },
  '100%': { opacity: 1, transform: 'translate(-50%, -50%) scale(1)' },
});

const StyledOverlay = styled(AlertDialogPrimitive.Overlay, {
  backgroundColor: 'rgba(0, 0, 0, 0.7)',
  position: 'fixed',
  inset: 0,
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${overlayShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  zIndex: '$10',
});

const StyledContent = styled(AlertDialogPrimitive.Content, {
  backgroundColor: '$gray100',
  borderRadius: '16px',
  boxShadow: '0px 3px 10px rgba(83, 98, 164, 0.1)',
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '600px',
  zIndex: '$11',
  '@media (prefers-reduced-motion: no-preference)': {
    animation: `${contentShow} 150ms cubic-bezier(0.16, 1, 0.3, 1)`,
  },
  '&:focus': { outline: 'none' },
});

const AlertDialogTitle = styled(AlertDialogPrimitive.Title, {
  display: 'flex',
  justifyContent: 'space-between',
  padding: '$2 $12 $2 $24',
  borderBottom: '1px solid $gray400',
});

const AlertDialogDescription = styled('div', {
  display: 'flex',
  padding: '$32',
  justifyContent: 'space-between',
  gap: '72px',
});

const DescriptionIconWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '$slate700',
  fontSize: '80px',
  marginRight: '$40',

  variants: {
    kind: {
      info: {
        color: '$iris400',
      },
      attention: {
        color: '$red200',
      },
    },
  },

  defaultVariants: {
    kind: 'info',
  },
});

const Footer = styled('div', {
  display: 'flex',
  padding: '$8 $24',
  boxShadow: '0px -2px 14px rgba(66, 102, 255, 0.15)',
  justifyContent: 'flex-end',
  gap: '$16',
});
