import { styled } from '@/stitches.config';
import { Body } from '@/components/text';
import { StyledComponent } from '@/models/common';

export const FormFieldName = styled(Body, {
  display: 'block',
  fontWeight: '$600',
  fontSize: '$14',
  lineHeight: '20px',
  letterSpacing: '0.1px',
  color: '$blue700',
  marginBottom: '0.25rem',
});

export const FormFieldDescription = styled(Body, {
  display: 'block',
  marginBottom: '0.25rem',
  fontWeight: '$300',
  color: '$slate500',
  fontSize: '$14',
  lineHeight: '20px',
  letterSpacing: '0.1px',
});

export const InputFormHelperText: StyledComponent = styled(Body, {
  display: 'block',
  fontSize: '$12',
  lineHeight: '16px',
  color: '$slate500',
  fontWeight: '$600',
  letterSpacing: '0.4px',
  variants: {
    kind: {
      error: {
        color: '$red500',
      },
      success: {
        color: '$green800',
      },
    },
  },
});
