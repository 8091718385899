import React from 'react';
import { styled } from '@/stitches.config';
import createPersistedState from '@/hooks/use-persisted-state';
import { AlertCircleIcon, CloseIcon } from '../icons';
import { Button } from './buttons/button';

export const INFO_BANNER_TYPE = {
  INFO: 'INFO',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
  WARN: 'WARN',
};

export function InfoBanner({ id, type, allowDismiss, children }) {
  const useInfoBannersState = createPersistedState(`info-banners-${id}`, sessionStorage);
  const [dismissed, setDismissed] = useInfoBannersState(false);

  return !dismissed ? (
    <InfoBannerContainer type={type} className="info-banner">
      <AlertCircleIcon />
      <Content>{children}</Content>

      {allowDismiss && (
        <Button
          kind="text"
          color="gray"
          circle
          css={{ marginTop: '-$32', marginRight: '-$8' }}
          onClick={() => {
            setDismissed(true);
          }}>
          <CloseIcon />
        </Button>
      )}
    </InfoBannerContainer>
  ) : null;
}

const InfoBannerContainer = styled('div', {
  display: 'flex',
  alignItems: 'center',
  borderRadius: '4px',
  padding: '1rem',
  fontSize: '1rem',
  lineHeight: '1.5rem',
  letterSpacing: '0.1px',
  color: '$black',

  '& > svg': {
    fontSize: '2rem',
    fontWeight: '600',
    marginRight: '1rem',
  },

  variants: {
    type: {
      [INFO_BANNER_TYPE.INFO]: {
        backgroundColor: '$white',
        '& > svg': { color: '$iris800' },
      },
      [INFO_BANNER_TYPE.SUCCESS]: {
        backgroundColor: '$green100',
        '& > svg': { color: '$green800' },
      },
      [INFO_BANNER_TYPE.WARN]: {
        backgroundColor: '$yellow100',
        '& > svg': { color: '$yellow900' },
      },
      [INFO_BANNER_TYPE.ERROR]: {
        backgroundColor: '$red100',
        '& > svg': { color: '$red600' },
      },
    },
  },
});

const Content = styled('div', {
  flexGrow: '1',
});
